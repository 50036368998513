import {
  IBinesQueryRequest,
  IBinesQueryResponse,
  INiubizWaitTime,
  IParameterHBK,
  IPaymentExecutionRequest,
  IPaymentExecutionResponse,
  IPaymentInformationRequest,
  IPaymentInformationResponse,
  IPaymentValidationRequest,
  IPaymentValidationResponse
} from '@common/interfaces/payment.interface';
import {
  PAYMENT_INFORMATION_MOCK,
  PAYMENT_VALIDATION_MOCK,
  PAYMENT_EXECUTION_MOCK,
  PAYMENT_NIUBIZ_WAIT_TIME,
  PAYMENT_CUT_OFF_TIME
} from '@common/mocks/payment.mocks';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private http: HttpService,
  ) { }
  
  public async getNiubizWaitTime(): Promise<INiubizWaitTime> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/CTPAGNIUB';
    return this.http.get(url, PAYMENT_NIUBIZ_WAIT_TIME).toPromise();
  }

  public async getCutOffTime(): Promise<IParameterHBK> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/HRCPTS';
    return this.http.get(url, PAYMENT_CUT_OFF_TIME).toPromise();
  }

  public getPaymentInformation(request: IPaymentInformationRequest): Promise<IPaymentInformationResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/get-information';
    return this.http.post(url, request, PAYMENT_INFORMATION_MOCK).toPromise();
  }
  public validatePaymentProcess(request: IPaymentValidationRequest): Promise<IPaymentValidationResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/validate-process';
    return this.http.post(url, request, PAYMENT_VALIDATION_MOCK).toPromise();
  }

  public valitadeBin(request: IBinesQueryRequest): Promise<IBinesQueryResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/validate-card-bin';
    return this.http.post(url, request, PAYMENT_VALIDATION_MOCK).toPromise();
  }

  public executePayment(request: IPaymentExecutionRequest): Promise<IPaymentExecutionResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/productshbk/product/payment/execution';
    return this.http.post(url, request, PAYMENT_EXECUTION_MOCK).toPromise();
  }
}
