export const TAG_TARGET_CREDIT_SIMULATE = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "TC_simular"
}

export const PAY_CARD = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "TC_pagar"
}

export const SEE_MOVEMENTS = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "TC_movimientos"
}

export const STATE_COUNT = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "TC_EECC"
}

export const TAG_SEF = {
    event: "Home_PWA",
    event_category: "Cintillo",
    event_action: "clic",
    event_label: "Sef"
}

export const TAG_EFEX = {
    event: "Home_PWA",
    event_category: "Cintillo",
    event_action: "clic",
    event_label: "Efex"
}

export const TRANS_A_PLUS = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "A-plus_TEF"
}

export const TRANS_SIMPLE = {
    event: "Home_PWA",
    event_category: "Slider",
    event_action: "clic",
    event_label: "Cta-simple_TEF"
}

export const POINT_RIPLEY = {
    event: "Home_PWA",
    event_category: "header",
    event_action: "clic",
    event_label: "RPGO"
}