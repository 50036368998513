import { IOAuthTokens } from '@common/interfaces/auth.interface';
import { SIGN_IN_MOCK, REVOCATION_MOCK } from '@common/mocks/auth.mocks';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { IUserLogin } from '@common/interfaces/auth.interface';
import {
    CREATE_TOKEN,
    REFRESH_TOKEN,
    RESCUE_SESSION_TOKEN,
    RESCUE_IDENTIFIER,
    USER_INFO,
    CREATE_TOKEN_PATH,
    REFRESH_TOKEN_PATH,
    RESCUE_TOKEN_EXPIRES,
    USER_ID,
    SECONDS_TO_MILLISECONDS,
    REFRESH_TOKEN_REQUEST_DIFFERENTIAL,
    RESCUE_REFRESH_TOKEN,
} from '@common/constants/auth.constants';
import { Subscription, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';
import { UserService } from '@services/user/user.service';
import {
    PRODUCT_CDD,
    PRODUCT_EFEX,
    PRODUCT_SEF,
    LEAD_EXECUTION_CODE,
    OFFERS_LOAN,
} from '@common/constants/offerings.constants';
import { FirebaseService } from '@services/firebase/firebase.service';
import { OPEN_ACCOUNT_SUCCESS_KEY, PRE_REGISTER_KEY, TYPE_ACCOUNT_SELECTED } from '@common/constants/open-account.constants';
import { INFORMATIVE_MODALS_VIEWED } from '@common/constants/misc.constants';
import { MENU_OPTIONS } from '@common/constants/routes.constants';
import { OpenAccountService } from '@services/open-account/open-account.service';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public isLoggedIn: boolean;
    public currentToken: IOAuthTokens;
    public subscription: Subscription;
    public identifier: number;
    public refreshTokenExpired: Subject<boolean>;
    public refreshTokenFailed: boolean;
    public refreshTokenRunning: boolean;
    public tokenExpiresMillis: number;
    public refreshTokenExpiresMillis: number;

    constructor(
        private http: HttpService,
        private userService: UserService,
        private storage: Storage,
        private firebaseService: FirebaseService,
        private utilsService: UtilsService,
        private openAccountService: OpenAccountService
    ) {
        this.refreshTokenExpired = new Subject<boolean>();
    }

    public isAuthenticated(): boolean {
        this.checkIfReloadedPage();
        return this.currentToken !== null && this.currentToken !== undefined;
    }

    private checkIfReloadedPage(): void {
        if (!this.currentToken) {
            const token = this.getSessionToken();
            if (token) {
                this.setSessionInfo(token);
                UtilsService.setInformativeModalsViewed();
            }
            const identifier = UtilsService.getIdentifier();
            if (identifier) {
                this.identifier = identifier;
                this.setIdentifier(identifier);
            }
        }
        if (!this.tokenExpiresMillis || !this.refreshTokenExpiresMillis) {
            const tokenExpires = this.getSessionTokenExpires();
            if (tokenExpires) {
                this.setTokenExpires(tokenExpires);
                UtilsService.setInformativeModalsViewed();
            }
        }
    }

    public async signIn(loginInfo: IUserLogin, identifier: number): Promise<IOAuthTokens> {
        this.identifier = identifier;
        await this.storage.set(USER_INFO, loginInfo);
        this.setIdentifier(identifier);
        return await this.generateToken(loginInfo, CREATE_TOKEN, identifier);
    }

    private setIdentifier(identifier: number): void {
        sessionStorage.setItem(RESCUE_IDENTIFIER, JSON.stringify(identifier));
    }

    public async signOut(): Promise<void> {
        let headers = {} as HttpHeaders;
        const identifier = this.identifier;
        headers = new HttpHeaders().set('Identifier', identifier.toString());
        headers = headers.set('Channel', this.utilsService.getChannelCode());
        const encodedObject = { revokeToken: this.currentToken ? this.currentToken.refreshToken : sessionStorage.getItem(RESCUE_REFRESH_TOKEN) };
        const url = environment.BASE_BACKEND_WORKER + '/admin/user/revoke/token';

        await this.http.post(url, encodedObject, REVOCATION_MOCK, headers).toPromise();
        this.cleanSessionInfo();
        return Promise.resolve();
    }

    public async signOut_two(): Promise<void> {
        let headers = {} as HttpHeaders;
        const identifier = UtilsService.getIdentifier();
        const refreshToken = this.getTokenRefreshLogout();

        headers = new HttpHeaders().set('Identifier', identifier.toString());
        headers = headers.set('Channel', this.utilsService.getChannelCode());
        const encodedObject = { revokeToken: refreshToken };
        const url = environment.BASE_BACKEND_WORKER + '/admin/user/revoke/token';

        await this.http.post(url, encodedObject, REVOCATION_MOCK, headers).toPromise();
        this.cleanSessionInfo();
        return Promise.resolve();
    }

    public setTokenExpires(tokenExpires: { token: number, refresh: number }): void {
        this.tokenExpiresMillis = tokenExpires.token;
        this.refreshTokenExpiresMillis = tokenExpires.refresh;
        sessionStorage.setItem(RESCUE_TOKEN_EXPIRES, JSON.stringify({
            token: this.tokenExpiresMillis,
            refresh: this.refreshTokenExpiresMillis
        }));
    }

    public async refreshIfTokenIsInvalid(): Promise<boolean> {
        if (!this.currentToken) { return false; }
        const currentTime = Date.now();
        if (currentTime >= this.refreshTokenExpiresMillis) {
            if (!this.refreshTokenFailed) { this.refreshTokenExpired.next(true); }
            return this.refreshTokenFailed = true;
        }
        if (currentTime >= this.tokenExpiresMillis && !this.refreshTokenRunning) {
            this.refreshTokenRunning = true;
            const requestBody = { refreshToken: this.currentToken.refreshToken };

            await this.generateToken(requestBody, REFRESH_TOKEN, this.identifier);
            this.refreshTokenRunning = false;
        }
        return false;
    }

    private async generateToken(encodedObject: object, serviceType: number, identifier: number): Promise<IOAuthTokens> {
        let headers = {} as HttpHeaders;
        if (identifier) {
            headers = new HttpHeaders().set('Identifier', identifier.toString());
            headers = headers.set('Channel', this.utilsService.getChannelCode());
        } else {
            headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
        }
        const urlAction = serviceType === CREATE_TOKEN ? CREATE_TOKEN_PATH : REFRESH_TOKEN_PATH;
        const url = environment.BASE_BACKEND_WORKER + `/admin/user/${urlAction}/token`;
        try {
            const token = await this.http.post(url, encodedObject, SIGN_IN_MOCK, headers).toPromise();
            this.setSessionInfo(token);
            if (urlAction === CREATE_TOKEN_PATH) {
                await this.firebaseSignInWithCustomToken(token);
            }
        } catch (e) {
            this.refreshTokenFailed = true;
            this.refreshTokenExpired.next(true);
            if (urlAction === CREATE_TOKEN_PATH) { throw e.error; }
        }
        return this.currentToken;
    }

    public getSessionToken(): IOAuthTokens {
        return JSON.parse(sessionStorage.getItem(RESCUE_SESSION_TOKEN));
    }

    public getUserIdToken(): string {
        return sessionStorage.getItem(USER_ID);
    }

    public getTokenRefreshLogout(): string {
        return sessionStorage.getItem(RESCUE_REFRESH_TOKEN);
    }


    public setSaveUserIdToken(): string {
        return sessionStorage.getItem(USER_ID);
    }

    private getSessionTokenExpires(): { token: number, refresh: number } {
        return JSON.parse(sessionStorage.getItem(RESCUE_TOKEN_EXPIRES));
    }

    private getFbSessionToken(): string {
        const token = this.getSessionToken();
        if (token) { return token.fbSession ? token.fbSession : null; }
        return null;
    }

    public setSessionInfo(token: IOAuthTokens): void {
        const storedFbSession = this.getFbSessionToken();
        if (storedFbSession && !token.fbSession) {
            token.fbSession = storedFbSession;
        }
        this.currentToken = token;
        sessionStorage.setItem(RESCUE_SESSION_TOKEN, JSON.stringify(token));
        sessionStorage.setItem(USER_ID, `${token.userId}`);
        sessionStorage.setItem(RESCUE_REFRESH_TOKEN, `${this.currentToken.refreshToken}`);

        this.refreshTokenFailed = false;
        this.setTokenExpires({
            token: Date.now() + REFRESH_TOKEN_REQUEST_DIFFERENTIAL * this.currentToken.expiresIn * SECONDS_TO_MILLISECONDS,
            refresh: Date.now() + this.currentToken.refreshExpiresIn * SECONDS_TO_MILLISECONDS,
        });
    }

    private async firebaseSignInWithCustomToken(token: IOAuthTokens): Promise<void> {
        if (environment.ENV !== 'local') {
            try {
                const credential = await this.firebaseService.signInWithCustomToken(token.fbSession);
                if (!credential) {
                    console.warn('Cannot authenticate with Firebase');
                }
                token.fbSession = await credential.user.getIdToken();
            } catch (error) {
                console.error('firebaseSignInWithCustomToken Error', error);
            }
        }
        return this.setSessionInfo(token);
    }

    private cleanSessionInfo(): void {
        this.userService.userInformation = null;
        this.currentToken = null;
        this.openAccountService.resetVars();
        sessionStorage.removeItem(RESCUE_SESSION_TOKEN);
        sessionStorage.removeItem(RESCUE_REFRESH_TOKEN);
        sessionStorage.removeItem(RESCUE_IDENTIFIER);
        sessionStorage.removeItem(PRODUCT_SEF);
        sessionStorage.removeItem(PRODUCT_CDD);
        sessionStorage.removeItem(PRODUCT_EFEX);
        this.storage.remove(USER_INFO);
        sessionStorage.removeItem(PRE_REGISTER_KEY);
        sessionStorage.removeItem(LEAD_EXECUTION_CODE);
        sessionStorage.removeItem(OFFERS_LOAN);
        sessionStorage.removeItem(RESCUE_TOKEN_EXPIRES);
        sessionStorage.removeItem(OPEN_ACCOUNT_SUCCESS_KEY);
        sessionStorage.removeItem(INFORMATIVE_MODALS_VIEWED);
        sessionStorage.removeItem(TYPE_ACCOUNT_SELECTED);
        sessionStorage.removeItem(USER_ID);
        localStorage.removeItem(MENU_OPTIONS);

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public async refreshToken(): Promise<boolean> {
      if (!this.currentToken) { return false; }
      this.refreshTokenRunning = true;
      const requestBody = { refreshToken: this.currentToken.refreshToken };
      await this.generateToken(requestBody, REFRESH_TOKEN, this.identifier);
      this.refreshTokenRunning = false;
      return false;
    }

}
