// TAGEO LOGIN Y OLVIDO DE CLAVE PWA
export const CLOSE_LOGIN = {
  event: "Home_PWA",
  event_category: "header",
  event_action: "clic",
  event_label: "cerrar_sesion"
}


export const LOGIN_SHOW_LOGIN_PWA = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_1',
    event_label: 'ingreso_landing_pwa_login'​
  };

export const LOGIN_ENTER_DOC_NUMBER_PWA = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_2',
    event_label: 'ingreso_numero_documento_login_pwa'​
  };

export const LOGIN_PASSWORD_FORGOT = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_2.1',
    event_label: 'olvidaste_clave_login_pwa'​
  };

export const LOGIN_PRESS_ENTER = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_2.2',
    event_label: 'boton_ingresar_banca_login_pwa'​
  };

export const LOGIN_CLIC_CONTINUE = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_3',
    event_label: 'boton_continuar_inicio_olvido_clave_pwa'​
  };

export const LOGIN_SET_INPUT_CODE_SMS = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_4',
    event_label: 'ingreso_codigo_sms_olvido_clave_pwa'​
  };

export const LOGIN_ERROR_CODE_MODAL = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_4.1',
    event_label: 'modal_error_codigo_sms_olvido_clave_pwa'​
  };

export const LOGIN_NO_MY_NUMBER_FORGET_PASSWORD = {
    event: 'loginClave_event',
    event_category: 'flujoLoginCambioClavePwa',
    event_action: 'step_4.2',
    event_label: 'no_es_mi_numero_olvido_clave_pwa'
  };


export const LOGIN_NO_MY_NUMBER = {
    event: 'loginClave_event',
    event_category: 'flujoLoginCambioClavePwa',
    event_action: 'step_4.3',
    event_label: 'modal_no_es_mi_numero_pwa'
  };

export const LOGIN_CONFIRM_PASSWORD = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_4.4',
    event_label: 'confirmar_desde_olvido_clave_pwa'​
  };

export const LOGIN_CANCEL_PASSWORD = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_4.5',
    event_label: 'cancelar_desde_olvido_clave_pwa'​
  };

export const LOGIN_ACCEPT_CHANGE_PASSWORD = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_5',
    event_label: 'boton_cambio_de_clave_pwa'​
  };

export const LOGIN_CANCEL_CHANGE_PASSWORD = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_5.1',
    event_label: 'cancelar_cambio_de_clave_pwa'​
  };

export const LOGIN_OPEN_MODAL_OK = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_6',
    event_label: 'modal_exito_cambio_clave_pwa'​
  };

export const LOGIN_CLICK_RIPLEY_BANK = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_6.1',
    event_label: 'boton_ingresar_desde_cambio_clave_pwa'​​
  };

export const LOGIN_CLICK_RIPLEY_URL = {
    event: 'loginClave_event',​
    event_category: 'flujoLoginCambioClavePwa',​
    event_action: 'step_6.2',
    event_label: 'ir_a_bancoRipley_desde_cambio_clave_pwa'​​
  };


  // TAGGEOS DE PIN + PAN

export const LOGIN_SCREEN_PIN_PAN = {

  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.1',
  event_label: 'vista_pinpan'
  
  }
  
  // CUANDO EL CLIENTE AGREGA EL NÚMERO DE TARJETA
  
  export const LOGIN_CARD_TEXT = {
  
  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.2',
  event_label: 'ingresar_tarjeta'
  }
  
  
  // CUANDO SE AGREGA EL PIN 
  export const LOGIN_PIN_TEXT = {
  
  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.3',
  event_label: 'ingresar_pin' 
  }
  
  
  export const LOGIN_PRESS_BUTTON_ACCEPT ={
  
  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.4',
  event_label: 'continuar_enrolamiento_pinpan'
  }
  
  
  export const LOGIN_PRESS_BUTTON_CANCEL ={
  
  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.5',
  event_label: 'cancelar_enrolamiento_pinpan'
  }
  
  
  export const LOGIN_ERROR_CARD_NUMBER = {
  
  event: 'loginClave_event',
  event_category: 'FlujoLoginCambioClavePWA',
  event_action: 'step_4.2.6',
  event_label: 'error_num_tarjeta_invalida'
  }
  
  export const LOGIN_MODAL_SALIR = {
  
    event: 'loginClave_event',
    event_category: 'FlujoLoginCambioClavePWA',
    event_action: 'step_4.2.7',
    event_label: 'vista_pregunta_cancelar_registro'
  }


  
  