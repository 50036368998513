
export const OP_SHOW_BENEFITS = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_1',
    event_label: 'ver_beneficios_en_ofertas_ahorro'
};

export const OP_INIT = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_1.1',
    event_label: 'abrir_cuenta_en_promociones_ahorro'
};

export const OP_STEP1 = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_2',
    event_label: 'despliega_declaracion_jurada_ahorro'
};

export const OP_STEP1_MORE_INFORMATION = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_2.1',
    event_label: 'presionar_mas_informacion_declaracion'
};

export const OP_STEP1_CONTINUE = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_2.2',
    event_label: 'continuar_en_declaracion_ahorro'
};

export const OP_STEP1_CANCEL = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_2.3',
    event_label: 'cancelar_en_declaracion_ahorro'
};

export const OP_MORE_INFORMATION_MODAL = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_2.4',
    event_label: 'modal_mas_informacion_declaracion'
};

export const OP_STEP2 = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_3',
    event_label: 'despliega_resumen_apertura_cuenta_ahorro'
};

export const OP_STEP2_TERMS_CHECKED = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_3.1',
    event_label: 'acepto_termino_contrato_ahorro'
};

export const OP_STEP2_EMAIL_CHECKED = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_3.2',
    event_label: 'acepto_envio_de_constancia'
};

export const OP_STEP3 = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4',
    event_label: 'despliega_pantalla_ingreso_codigo_sms'
};

export const OP_STEP3_NOT_MY_NUMBER = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.1',
    event_label: 'boton_presionar_no_es_mi_numero_ahorro'
};

export const OP_STEP3_CODE_ENTERED = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.2',
    event_label: 'escribe_codigo_sms_ahorro'
};

export const OP_STEP3_OPEN_ACCOUNT = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.3',
    event_label: 'boton_presionar_abrir_cuenta_ahorro'
};

export const OP_STEP3_CANCEL = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.4',
    event_label: 'boton_presionar_cancelar_apertura_cuenta'
};

export const OP_STEP3_RESEND_CODE = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.5',
    event_label: 'pedir_nuevamente_codigo_sms_ahorro'
};

export const OP_STEP3_CODE_INCORRECT = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.6',
    event_label: 'codigo_incorrecto_sms_ahorro'
};

export const OP_NOT_MY_NUMBER_MODAL = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_4.7',
    event_label: 'modal_no_es_mi_numero_ahorro'
};

export const OP_SUCCESS = {
    event: 'ahorroPlus_event',
    event_category: 'flujoAhorroPlus',
    event_action: 'step_5',
    event_label: 'exito_cuenta_ahorro_plus'
};
