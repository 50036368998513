import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PermissionDiagnosticService {
  public cameraPermissions;
  constructor(
    private alertCtrl: AlertController,
    private diagnostic: Diagnostic,
    private openNativeSettings: OpenNativeSettings
  ) { }
  public async askForCameraPermission(): Promise<any> {
    const permissionStatus = this.diagnostic.permissionStatus;
    return await new Promise((resolve) => {
      this.diagnostic.requestCameraAuthorization(false)
        .then((state) => {
          switch (state) {
            case permissionStatus.GRANTED:
              this.cameraPermissions = true;
              resolve(true);
              break;
            case permissionStatus.DENIED:
              this.showAlertWithCss(
                `<ion-grid>
              <ion-row>
              <img class="center-image" src='./assets/icons/attention-sign-i.svg'>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p class="bold-purple">Necesitamos usar la cámara</p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p>Activa la cámara para la app Banco Ripley</p>
                </ion-col>
              </ion-row>
             </ion-grid>`, 'facial-scanner-prompt', async () => {
                const permit = await this.askForCameraPermission();
                this.cameraPermissions = permit;
                resolve(permit);
              });
              break;
            case permissionStatus.DENIED_ONCE:
              this.showAlertWithCss(
                `<ion-grid>
              <ion-row>
              <img class="center-image" src='./assets/icons/i-rpass-camera.svg'>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p class="bold-purple">Necesitamos usar la cámara</p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p>Activa la cámara para la app Banco Ripley</p>
                </ion-col>
              </ion-row>
             </ion-grid>`, 'facial-scanner-prompt', async () => {
                const permit = await this.askForCameraPermission();
                this.cameraPermissions = permit;
                resolve(permit);
              });
              break;
            case permissionStatus.DENIED_ALWAYS:
              this.showAlertWithCss(
                `<ion-grid>
              <ion-row>
              <img class="center-image" src='./assets/icons/i-rpass-camera.svg'>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p class="bold-purple">Necesitamos usar la cámara</p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p>Dírigete a configuración y activa la cámara para Banco Ripley.</p>
                </ion-col>
              </ion-row>
             </ion-grid>`, 'facial-scanner-prompt', async () => {
                await this.openNativeSettings.open('application_details').then(() => {
                  resolve(false);
                });
              });
              break;
          }
        });
    });
  }

  public getPermissionState(): Promise<string> {
    return this.diagnostic.getCameraAuthorizationStatus()
      .then((res) => res)
      .catch(() => '');
  }

  public async showAlertWithCss(message: string, cssClass: string, customAction): Promise<void> {
    let alert: HTMLIonAlertElement;
    const promptButtons = [{
      text: 'Entendido!',
      cssClass: 'button-activate-for-rpass',
      handler: () => { alert.dismiss(true); }
    }];
    alert = await this.alertCtrl.create({
      message,
      buttons: promptButtons,
      cssClass: cssClass || ''
    });
    alert.onDidDismiss().then((accepted) => {
      if (accepted.data) {
        customAction(accepted.data);
      }
    });
    await alert.present();
  }

  public async openPermissionSettings() {
    return this.diagnostic.switchToSettings();
  }

}
