import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IUserInformation } from '@common/interfaces/user.interface';
import { ModalController } from '@ionic/angular';
import {
  PHONE_NUMBER_LENGTH,
  TRANSFER_OPTIONS_ROUTE,
  TRANSFER_PROGRESS_ADDITION,
} from '@common/constants/transfers.constants';
import { UserService } from '@services/user/user.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { TRANSFER_ERROR_GENERAL, TRANSFER_ORIGIN_ACCOUNT_ERROR_GENERAL} from '@common/constants/modal-props.constants';
import { UtilsService } from '@services/utils/utils';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { IContactDeviceList, IDetailsSavingAccount, IInfoDetailTransfer } from '@common/interfaces/savings.interface';
import { SavingsService } from '@services/savings/savings.service';
import { IDataLoaded } from '@common/interfaces/default.interface';
import { CONTACT_LIST_MOCK } from '@common/mocks/transfer.mocks';
import { Contact, ContactFieldType, Contacts, ContactFindOptions } from '@ionic-native/contacts/ngx';
import { Router } from '@angular/router';
import { TRANSFERS_ROUTE } from '@common/constants/routes.constants';


@Component({
  selector: 'app-set-contact-number',
  templateUrl: './set-contact-number.component.html',
  styleUrls: ['./set-contact-number.component.scss'],
})
export class SetContactNumberComponent implements OnInit {

  contactNumberLength: number;
  isOtherMobileNumber: boolean;
  isValidNumber: boolean; 
  isKeyEnterDown: boolean;
  isContactLoaded: boolean;
  isContactNumberDataLoading: boolean; 
  messageerror: string;
  messageerror0: string;
  contactString: string;
  elementContactString: string;
  userDataLoaded: IDataLoaded; 
  errorModal: HTMLIonModalElement;
  contactList: IContactDeviceList[];
  contactListAux: IContactDeviceList[];
  contactForm: FormGroup;  
  @Input() infoDetailsTransfer: IInfoDetailTransfer;  
  @Input() subStep: number;
  @Output() setContactEvent = new EventEmitter<{info:IInfoDetailTransfer,substep:number}>();

  constructor(
    private modalErrorCtrl: ModalController,
    private formBuilder: FormBuilder,
    private userService: UserService,    
    private contacts: Contacts,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    
    this.initializeVar();
 }

 public get percentageProgress(): number { return TRANSFER_PROGRESS_ADDITION; }
 get contactNumber() { return this.contactForm.controls.contactNumber;}
 set contactNumber(value){ this.contactForm.controls.contactNumber.setValue(value); }
 get contactName() {return this.contactForm.controls.contactName;}
 set contactName(value) {this.contactForm.controls.contactName.setValue(value);}


 initializeVar(){   
  this.contactNumberLength = PHONE_NUMBER_LENGTH; 
  this.userDataLoaded = {
    isLoaded: false,
    hasErrors: false,
  };
  this.isContactNumberDataLoading = false;
  this.isOtherMobileNumber = false;
  this.isValidNumber = false;
  this.contactListAux = [];
  
 }

  async ngOnInit() {
    this.createContactForm(); 
    this.getContatsDevice(); 
   //this.getContactDeviceList();
  } 

  ionViewWillEnter() {
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }

  private getContactDeviceList(){
 //  this.contactListAux = CONTACT_LIST_MOCK;
  // this.contactList = CONTACT_LIST_MOCK;
  }

  private createContactForm() {
    this.contactForm = this.formBuilder.group({
      contactName: [
        { value: null, disabled: false },
        [Validators.required]
      ],
      contactNumber: [
        {value: ''},
        [Validators.required]
      ]
    });

    this.contactNumber.setValue('');
    this.subscribeToContactNumber();
    this.subscribeToContactName();
  }

  getInfoClean(value:string):string{    
    let rexSpace = /\s/g;
    value = value.replace(rexSpace,'');
    return value;
  }

  private subscribeToContactName(){
    this.contactName.valueChanges.subscribe((texto)=>{
    
      this.contactListAux =  this.contactList.filter((item)=>(item.name.toUpperCase().includes(texto.toUpperCase()) 
      || this.getInfoClean(item.phoneNumber).includes(this.getInfoClean(texto)) 
      || item.familyName.toUpperCase().includes(texto.toUpperCase()))
      || item.name.concat(' ' + item.familyName).toUpperCase().includes(texto.toUpperCase));

      this.contactListAux = this.contactListAux.sort((a,b)=>a.name.localeCompare(b.name));
    });
  }

  private subscribeToContactNumber() {
    this.contactNumber.valueChanges.subscribe((phoneNumber: string) => {  
      this.isValidNumber = (phoneNumber.length == 9)?true:false;
      this.infoDetailsTransfer.phoneNumber = phoneNumber;
    });
  }

  setOtherPhone(){
  
    if(!this.isKeyEnterDown){
      this.isOtherMobileNumber = true;
      this.subStep++;
      this.setContactEvent.emit({info:null, substep:this.subStep});
    }
  }

  async setContactNumber(value){  
    
    if(!this.isKeyEnterDown){
      this.infoDetailsTransfer.phoneNumber = (value==undefined?this.infoDetailsTransfer.phoneNumber:value);
      this.setContactEvent.emit({info:this.infoDetailsTransfer, substep:this.subStep});
    }
     return;
  }
 
  public dismissModal() {
    this.modalErrorCtrl.dismiss();
  }

  getContatsDevice(){
    this.userDataLoaded.isLoaded = false;
    this.userDataLoaded.hasErrors = false;  

    const options = new ContactFindOptions('',true,['name','phoneNumbers'],true);
 
    this.contactListAux = [];
    this.contactList = [];

    try {
      
      //this.contactString = JSON.stringify(this.contacts);
      this.contacts.find(['*'], options).then((contacts: Contact[]) => {     
          contacts.forEach(element => {
            try {
             // this.elementContactString = JSON.stringify(element);
             // if((element.phoneNumbers!==null || element.phoneNumbers!==undefined)){                  
                    let contactAux = {
                      name: (element.name.givenName!==undefined)?element.name.givenName:'',
                      familyName: (element.name.familyName!==undefined)?element.name.familyName:'',                     
                      phoneNumber: (element.phoneNumbers!==undefined)?(element.phoneNumbers!==null?element.phoneNumbers[0].value:''):''                    
                    }
                    this.contactListAux.push(contactAux);
                    this.contactList.push(contactAux);                      
              //}
            } catch (error) {
             
            }           
          });         
          //this.contactListAux = this.contactListAux.sort((a,b)=>a.name.localeCompare(b.name));
        })

    } catch (error) {     
      this.userDataLoaded.hasErrors = true;     
    }finally{
      this.userDataLoaded.isLoaded = true;
    }
  }
  @HostListener('keydown.enter', ['$event']) onEnter(e) {  
    this.isKeyEnterDown = true;
   
  }
  @HostListener('click', ['$event']) onClick(e) {  
    this.isKeyEnterDown = false;
   
  }
  
}
