import { TooltipModule } from 'ng2-tooltip-directive';

import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BannerCampaignComponent } from '@components/banner-campaign/banner-campaign.component';
import { BannerSaleComponent } from '@components/banner-sale/banner-sale.component';
import { CardDetailsBoxComponent } from '@components/card-details-box/card-details-box.component';
import {
  CardDetailsHeaderComponent
} from '@components/card-details-header/card-details-header.component';
import { CardSummaryComponent } from '@components/card-summary/card-summary.component';
import { CopyButtonComponent } from '@components/copy-button/copy-button.component';
import {
  DescriptionTableComponent
} from '@components/description-table/description-table.component';
import {
  DesktopCardStatementsComponent
} from '@components/desktop-card-statements/desktop-card-statements.component';
import {
  AuthorizationEfexComponent
} from '@components/efex/authorization-efex/authorization-efex.component';
import { DetailsEfexComponent } from '@components/efex/details-efex/details-efex.component';
import {
  AlternativeEfexComponent
} from '@components/efex/modals/alternative-efex/alternative-efex.component';
import { TceaComponent } from '@components/efex/modals/tcea/tcea.component';
import { PromotionalsComponent } from '@components/efex/promotionals-list/promotionals.component';
import {
  SmsValidationInputEfexComponent
} from '@components/efex/sms-validation-input-efex/sms-validation-input-efex.component';
import { ExternalFormComponent } from '@components/external-form/external-form.component';
import { FaqColumnsComponent } from '@components/faq-columns/faq-columns.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { GlobalHeaderComponent } from '@components/global-header/global-header.component';
import {
  InformationModalComponent
} from '@components/information-modal/information-modal.component';
import {
  InformativeModalComponent
} from '@components/informative-modal/informative-modal.component';
import { ListModalComponent } from '@components/list-modal/list-modal.component';
import { OfferCardComponent } from '@components/offer-card/offer-card.component';
import { OfferingModalComponent } from '@components/offering-modal/offering-modal.component';
import { OnboardingModalComponent } from '@components/onboarding-modal/onboarding-modal.component';
import { PdfViewerComponent } from '@components/pdf-viewer/pdf-viewer.component';
import {
  PromotionsTermsAndConditionsModalComponent
} from '@components/promotions-terms-and-conditions-modal/promotions-terms-and-conditions-modal.component';
import {
  RewardPointsFooterComponent
} from '@components/reward-points-footer/reward-points-footer.component';
import { RipleyCardComponent } from '@components/ripley-card/ripley-card.component';
import {
  SavingAccountDetailsHeaderComponent
} from '@components/saving-account-details-header/saving-account-details-header.component';
import { SavingsModalComponent } from '@components/savings-modal/savings-modal.component';
import { SimpleTooltipComponent } from '@components/simple-tooltip/simple-tooltip.component';
import {
  SmsValidationInputComponent
} from '@components/sms-validation-input/sms-validation-input.component';
import { StepperComponent } from '@components/stepper/stepper.component';
import { StoreMessageComponent } from '@components/store-message/store-message.component';
import { SwitchButtonComponent } from '@components/switch-button/switch-button.component';
import {
  TermsAndConditionsComponent
} from '@components/terms-and-conditions/terms-and-conditions.component';
import { ToggleItemComponent } from '@components/toggle-item/toggle-item.component';
import {
  TokenizedKeyboardComponent
} from '@components/tokenized-keyboard/tokenized-keyboard.component';
import { TransferCardComponent } from '@components/transfer-card/transfer-card.component';
import {
  TransferErrorModalComponent
} from '@components/transfer-error-modal/transfer-error-modal.component';
import { TransferModalComponent } from '@components/transfer-modal/transfer-modal.component';
import {
  TransfersScheduleModalComponent
} from '@components/transfers-schedule-modal/transfers-schedule-modal.component';
import { DirectivesModule } from '@directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '@pipes/pipes.module';
import { DigitOnlyModule } from '@uiowa/digit-only';

import { BannerSefEfexComponent } from './banner-sef-efex/banner-sef-efex.component';
import { OfferCardPurpleComponent } from './offer-card-purple/offer-card-purple.component';
import {
  AuthorizationOpenAccountComponent
} from './open-account/authorization-open-account/authorization-open-account.component';
import {
  AlternativeOpenAccountComponent
} from './open-account/modals/alternative-open-account/alternative-open-account.component';
import {
  PepInformationComponent
} from './open-account/modals/pep-information/pep-information.component';
import { UpdateInfoComponent } from './open-account/modals/update-info/update-info.component';
import { AlertModalComponent } from './payment-modal/alert-modal/alert-modal.component';
import { NotificationsBellComponentModule } from './notifications-bell/notifications-bell.module';
import { NotificationsBellComponent } from './notifications-bell/notifications-bell.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QRCodeModule } from 'angularx-qrcode';
import { NodebtModalComponent } from './payment-modal/nodebt-modal/nodebt-modal/nodebt-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { OfferingModalSefSuccessComponent } from './offering-modal-sef-success/offering-modal-sef-success.component';
import { OnOffOptionsComponent } from './block-cards/block-detail/on-off-options.component';
import { OnOffModalComponent } from './block-cards/modal/on-off-modal.component';
import { OnOffModalTimerComponent } from './block-cards/modal-timer/on-off-modal-timer.component';
import { AditionalTermsModalComponent } from './aditional-card-modal/aditional-terms/aditional-terms-modal.component';
import { CreditInfoModalComponent } from './credits/credit-modal/credit-info-modal.component';
import { CreditTableComponent } from './credit-table/credit-table.component';
import { BenefitsEfexComponent } from './efex-automatic/modals/benefits-efex/benefits-efex.component';
import { BenefitsSefComponent } from './sef-automatic/modals/benefits-sef/benefits-sef.component';
import { BenefitsOpenAccountComponent } from './efex-sef-automatic/modals/benefits-open-account/benefits-open-account.component';
import { EfexSefFeedbackComponent } from './efex-sef-automatic/modals/efex-sef-feedback/efex-sef-feedback.component';
import { EfexSefSmsAuthComponent } from './efex-sef-automatic/efex-sef-sms-auth/efex-sef-sms-auth.component';
import { AlternativeOfferComponent } from './efex-sef-automatic/modals/alternative-offer/alternative-offer.component';
import { OfferCardPurpleDarkComponent } from './offer-card-purple-dark/offer-card-purple-dark.component';
import { LeaveOfferComponent } from './efex-sef-automatic/modals/leave-offer/leave-offer.component';
import { LeadSuccessComponent } from './leads-modal/lead-success/lead-success.component';
import { AlternativeDisbursementComponent } from './efex-sef-automatic/modals/alternative-disbursement/alternative-disbursement.component';
import { DisbursementErrorComponent } from './efex-sef-automatic/modals/disbursement-error/disbursement-error.component';
import { SimulatorComponent } from './reprogramming/simulator/simulator.component';
import { ReprogrammingQuestionsComponent } from './reprogramming/reprogramming-questions/reprogramming-questions.component';
import { ReprogrammingDetailsComponent } from './reprogramming/reprogramming-details/reprogramming-details.component';
import { CardImageComponent } from './card-image/card-image.component';
import { EnrollModalComponent } from './enroll-modal/enroll-modal.component';
import { BenefitsComponent } from './efex/modals/benefits/benefits.component';
import { OnOffModalCancelComponent } from './block-cards/modal-cancel/on-off-modal-cancel.component';
import { FeedbackComponent } from './efex/modals/feedback/feedback.component';
import { RedeemDetailsModalComponent } from './redeem-details-modal/redeem-details-modal.component';
import { LeadExistModalComponent } from './leads-modal/lead-exist-modal/lead-exist-modal.component';
import { LeadGenericModalComponent } from './leads-modal/lead-generic-modal/lead-generic-modal.component';
import { SelectOtpComponent } from './reprogramming/select-otp/select-otp.component';
import { SessionExpireSoonComponent } from './payment-modal/session-expire-soon/session-expire-soon.component';
import { BannerSliderCardComponent } from './banner-slider-card/banner-slider-card.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AlertComponent } from './alert/alert.component';
import { CvvHelperComponent } from './payment-modal/cvv-helper/cvv-helper.component';
import { CvvHelperCardComponent } from './payment-modal/cvv-helper-card/cvv-helper-card.component';
import { CvvHelperPopoverComponent } from './payment-modal/cvv-helper-popover/cvv-helper-popover.component';
import { BranchStoreComponent } from './branch-store/branch-store.component';
import { MapWithIndicationComponent } from './map-width-indication/map-width-indication.component';
import { FooterLoginComponent } from './footer-login/footer-login.component';
import { SefT24ModalComponent } from './sef-semiautomatic/modals/sef-t24-modal/sef-t24-modal.component';
import { SeeFeesComponent } from './sef-semiautomatic/modals/see-fees/see-fees.component';
import { PoliticModalComponent } from './politic-modal/politic-modal.component';
import { RPassAuthenticationComponent } from './r-pass-authentication/r-pass-authentication.component';
import { ScannerQrPromptComponent } from './scanner-qr-prompt/scanner-qr-prompt.component';
import { ForgottenRpassModalComponent } from './forgotten-rpass-modal/forgotten-rpass-modal.component';
import { GenericRpassModalComponent } from './generic-rpass-modal/generic-rpass-modal.component';
import { HelpMessageComponent } from './help-message/help-message.component';
import { DeclarationComponent } from './efex-sef-automatic/modals/declaration/declaration.component';
import { DigitalAuthComponent } from './digital-auth/digital-auth.component';
import { WidgetActivationComponent } from './widget-activation/widget-activation.component';
import { WidgetAuthenticationComponent } from './widget/widget-authentication.component';
import { DigitalCardModalComponent } from './digital-card-modal/digital-card-modal.component';
import { RipleyCardButtonShowComponent } from './ripley-card-button-show/ripley-card-button-show.component';
import { RipleyLoaderComponent } from './ripley-loader/ripley-loader.component';
import { DisabledVirtualCardPopoverComponent } from './card-details-header/disabled-virtual-card-popover/disabled-virtual-card-popover.component';
import { TransferModalOptionsComponent } from './transfer-modal-options/transfer-modal-options.component';
import { TransfersScheduleModalComponentOld } from './transfers-schedule-modal-old/transfers-schedule-modal-old.component';
import { InvestDetailModalComponent } from './investment-modal/invest-detail-modal.component';
import { PerseguidoraComponent } from './perseguidora/perseguidora.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { CanjeProductComponent } from './canje-product/canje-product.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GlobalHeaderRpgoComponent } from './global-header-rpgo/global-header-rpgo.component'; 
import { SendOtpComponent } from './transfer-interop/account-affiliation/send-otp/send-otp.component';
import { SetAccountTypeComponent } from './transfer-interop/account-affiliation/set-account-type/set-account-type.component';
import { SetContactNumberComponent } from './transfer-interop/transfer-to-number/set-contact-number/set-contact-number.component';
import { SetDestinataryAccountComponent } from './transfer-interop/transfer-to-number/set-destinatary-account/set-destinatary-account.component';
import { SelectSearchComponent } from './generic-controls/select-search-control/select-search-control.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { VisorAccountStateComponent } from './visor-account-state/visor-account-state.component';

@NgModule({
  declarations: [
    SwitchButtonComponent,
    SimpleTooltipComponent,
    TokenizedKeyboardComponent,
    GenericModalComponent,
    EnrollModalComponent,
    PaymentModalComponent,
    InformationModalComponent,
    ExternalFormComponent,
    GlobalHeaderComponent,
    RipleyCardComponent,
    RewardPointsFooterComponent,
    CardDetailsHeaderComponent,
    CardDetailsBoxComponent,
    CreditTableComponent,
    DescriptionTableComponent,
    PdfViewerComponent,
    BannerSaleComponent,
    BannerCampaignComponent,
    CardSummaryComponent,
    FaqColumnsComponent,
    SmsValidationInputComponent,
    SmsValidationInputEfexComponent,
    StoreMessageComponent,
    DesktopCardStatementsComponent,
    PromotionsTermsAndConditionsModalComponent,
    TermsAndConditionsComponent,
    OfferCardComponent,
    OfferCardPurpleComponent,
    OnboardingModalComponent,
    StepperComponent,
    SavingAccountDetailsHeaderComponent,
    CopyButtonComponent,
    SavingsModalComponent,
    ListModalComponent,
    InformativeModalComponent,
    OfferingModalComponent,
    TransferCardComponent,
    TransferModalComponent,
    TransfersScheduleModalComponent,
    TransfersScheduleModalComponentOld,
    TransferErrorModalComponent,
    SendOtpComponent,
    SetAccountTypeComponent,
    SetContactNumberComponent,
    SetDestinataryAccountComponent,
    AuthorizationEfexComponent,
    AlternativeEfexComponent,
    DetailsEfexComponent,
    PromotionalsComponent,
    TceaComponent,
    SelectSearchComponent,
    NodebtModalComponent,
    AlertModalComponent,
    ToggleItemComponent,
    BannerSefEfexComponent,
    AuthorizationOpenAccountComponent,
    AlternativeOpenAccountComponent,
    PepInformationComponent,
    UpdateInfoComponent,
    OfferingModalSefSuccessComponent,
    LeadExistModalComponent,
    OnOffOptionsComponent,
    OnOffModalComponent,
    OnOffModalTimerComponent,
    AditionalTermsModalComponent,
    CreditInfoModalComponent,
    BenefitsEfexComponent,
    EfexSefSmsAuthComponent,
    AlternativeOfferComponent,
    BenefitsSefComponent,
    DeclarationComponent,
    BenefitsOpenAccountComponent,
    EfexSefFeedbackComponent,
    OfferCardPurpleDarkComponent,
    LeaveOfferComponent,
    LeadSuccessComponent,
    AlternativeDisbursementComponent,
    DisbursementErrorComponent,
    SimulatorComponent,
    ReprogrammingQuestionsComponent,
    ReprogrammingDetailsComponent,
    OnOffModalCancelComponent,
    SelectOtpComponent,
    CardDetailsBoxComponent,
    InvestDetailModalComponent,
    LeadGenericModalComponent,
    BenefitsComponent,
    FeedbackComponent,
    RedeemDetailsModalComponent,
    LeadExistModalComponent,
    SessionExpireSoonComponent,
    BannerSliderCardComponent,
    CardImageComponent,
    AlertComponent,
    CvvHelperComponent,
    CvvHelperCardComponent,
    CvvHelperPopoverComponent,
    BranchStoreComponent,
    MapWithIndicationComponent,
    FooterLoginComponent,
    SefT24ModalComponent,
    PoliticModalComponent,
    GenericRpassModalComponent,
    RPassAuthenticationComponent,
    ScannerQrPromptComponent,
    WidgetActivationComponent,
    WidgetAuthenticationComponent,
    DigitalAuthComponent,
    ForgottenRpassModalComponent,
    SeeFeesComponent,
    HelpMessageComponent,
    WidgetActivationComponent,
    WidgetAuthenticationComponent,
    DigitalCardModalComponent,
    RipleyCardButtonShowComponent,
    RipleyLoaderComponent,
    DisabledVirtualCardPopoverComponent,    
    TransferModalOptionsComponent,
    PerseguidoraComponent,
    CanjeProductComponent,
    GlobalHeaderRpgoComponent,
    VisorAccountStateComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    DigitOnlyModule,
    PipesModule,
    TooltipModule,
    FormsModule,
    DirectivesModule,
    NotificationsBellComponentModule,
    NgxBarcodeModule,
    QRCodeModule,
    NgxPaginationModule,
    LottieAnimationViewModule.forRoot(),
    RoundProgressModule,
    PdfJsViewerModule
  ],
  providers: [DecimalPipe],
  exports: [
    SwitchButtonComponent,
    TokenizedKeyboardComponent,
    SimpleTooltipComponent,
    PaymentModalComponent,
    GenericModalComponent,
    EnrollModalComponent,
    InformationModalComponent,
    ExternalFormComponent,
    GlobalHeaderComponent,
    RipleyCardComponent,
    RewardPointsFooterComponent,
    CardDetailsHeaderComponent,
    CardDetailsBoxComponent,
    CreditTableComponent,
    DescriptionTableComponent,
    PdfViewerComponent,
    BannerSaleComponent,
    BannerCampaignComponent,
    CardSummaryComponent,
    FaqColumnsComponent,
    SmsValidationInputComponent,
    SmsValidationInputEfexComponent,
    DesktopCardStatementsComponent,
    PromotionsTermsAndConditionsModalComponent,
    TermsAndConditionsComponent,
    StoreMessageComponent,
    DesktopCardStatementsComponent,
    OfferCardComponent,
    OfferCardPurpleComponent,
    OnboardingModalComponent,
    StepperComponent,
    SavingAccountDetailsHeaderComponent,
    CopyButtonComponent,
    SavingsModalComponent,
    ListModalComponent,
    InformativeModalComponent,
    OfferingModalComponent,
    TransferCardComponent,
    TransferModalComponent,
    TransferModalOptionsComponent,
    TransfersScheduleModalComponent,
    TransfersScheduleModalComponentOld,
    TransferErrorModalComponent,
    SendOtpComponent,
    SetAccountTypeComponent,
    SetContactNumberComponent,
    SetDestinataryAccountComponent,
    AuthorizationEfexComponent,
    AlternativeEfexComponent,
    DetailsEfexComponent,
    PromotionalsComponent,
    TceaComponent,
    SelectSearchComponent,
    NodebtModalComponent,
    AlertModalComponent,
    ToggleItemComponent,
    NotificationsBellComponent,
    ToggleItemComponent,
    BannerSefEfexComponent,
    AuthorizationOpenAccountComponent,
    AlternativeOpenAccountComponent,
    PepInformationComponent,
    UpdateInfoComponent,
    OfferingModalSefSuccessComponent,
    LeadExistModalComponent,
    OnOffOptionsComponent,
    OnOffModalComponent,
    OnOffModalTimerComponent,
    AditionalTermsModalComponent,
    CreditInfoModalComponent,
    BenefitsEfexComponent,
    EfexSefSmsAuthComponent,
    AlternativeOfferComponent,
    BenefitsSefComponent,
    DeclarationComponent,
    BenefitsOpenAccountComponent,
    EfexSefFeedbackComponent,
    OfferCardPurpleDarkComponent,
    LeaveOfferComponent,
    LeadSuccessComponent,
    AlternativeDisbursementComponent,
    DisbursementErrorComponent,
    SimulatorComponent,
    ReprogrammingQuestionsComponent,
    ReprogrammingDetailsComponent,
    OnOffModalCancelComponent,
    SelectOtpComponent,
    CardDetailsBoxComponent,
    LeadGenericModalComponent,
    BenefitsComponent,
    FeedbackComponent,
    RedeemDetailsModalComponent,
    SessionExpireSoonComponent,
    LeadExistModalComponent,
    BannerSliderCardComponent,
    InvestDetailModalComponent,
    AlertComponent,
    CvvHelperComponent,
    CvvHelperCardComponent,
    CvvHelperPopoverComponent,
    BranchStoreComponent,
    MapWithIndicationComponent,
    FooterLoginComponent,
    SefT24ModalComponent,
    SeeFeesComponent,
    HelpMessageComponent,
    CardImageComponent,
    PoliticModalComponent,
    GenericRpassModalComponent,
    RPassAuthenticationComponent,
    ForgottenRpassModalComponent,
    ScannerQrPromptComponent,
    WidgetActivationComponent,
    WidgetAuthenticationComponent,
    DigitalAuthComponent,
    DigitalCardModalComponent,
    RipleyCardButtonShowComponent,
    RipleyLoaderComponent,
    DisabledVirtualCardPopoverComponent,
    PerseguidoraComponent,
    CanjeProductComponent,
    GlobalHeaderRpgoComponent,
  ],
  entryComponents: [
    GenericModalComponent,
    EnrollModalComponent,
    PaymentModalComponent,
    SavingsModalComponent,
    ListModalComponent,
    OfferingModalComponent,
    TransferModalComponent,
    TransferModalOptionsComponent,
    TransfersScheduleModalComponent,
    TransfersScheduleModalComponentOld,
    TransferErrorModalComponent,
    SendOtpComponent,
    SetAccountTypeComponent,
    SetContactNumberComponent,
    SetDestinataryAccountComponent,
    AlternativeEfexComponent,
    TceaComponent,
    SelectSearchComponent,
    NodebtModalComponent,
    AlertModalComponent,
    OnboardingModalComponent,
    AlternativeOpenAccountComponent,
    PepInformationComponent,
    UpdateInfoComponent,
    InvestDetailModalComponent,
    OfferingModalSefSuccessComponent,
    LeadExistModalComponent,
    OnOffModalComponent,
    OnOffModalTimerComponent,
    AditionalTermsModalComponent,
    CreditInfoModalComponent,
    BenefitsEfexComponent,
    EfexSefSmsAuthComponent,
    AlternativeOfferComponent,
    BenefitsSefComponent,
    DeclarationComponent,
    BenefitsOpenAccountComponent,
    EfexSefFeedbackComponent,
    LeaveOfferComponent,
    LeadSuccessComponent,
    AlternativeDisbursementComponent,
    DisbursementErrorComponent,
    CardImageComponent,
    OnOffModalCancelComponent,
    LeadGenericModalComponent,
    BenefitsComponent,
    FeedbackComponent,
    RedeemDetailsModalComponent,
    SessionExpireSoonComponent,
    LeadExistModalComponent,
    BannerSliderCardComponent,
    AlertComponent,
    CvvHelperComponent,
    CvvHelperCardComponent,
    CvvHelperPopoverComponent,
    BranchStoreComponent,
    MapWithIndicationComponent,
    FooterLoginComponent,
    SefT24ModalComponent,
    SeeFeesComponent,
    HelpMessageComponent,
    PoliticModalComponent,
    GenericRpassModalComponent,
    RPassAuthenticationComponent,
    ForgottenRpassModalComponent,
    ScannerQrPromptComponent,
    DigitalAuthComponent,
    DigitalCardModalComponent,
    RipleyCardButtonShowComponent,
    RipleyLoaderComponent,
    DisabledVirtualCardPopoverComponent,
    PerseguidoraComponent,
    CanjeProductComponent,
    VisorAccountStateComponent
  ]
})
export class ComponentsModule { }
