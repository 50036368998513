import { Injectable, Injector } from '@angular/core';
import { firebase } from '@firebase/app';
import '@firebase/messaging';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FirebaseService } from '@services/firebase/firebase.service';
import {
  DEFAULT_BROWSER_NOTIFICATION_PERMISSION, EXTERNAL_NOTIFICATION_TYPE, FIREBASE_SW_PATH,
  FIRESTORE_APP_TOKEN_KEY, FIRESTORE_PWA_TOKEN_KEY,
  GRANTED_BROWSER_NOTIFICATION_PERMISSION, INTERNAL_NOTIFICATION_TYPE,
  NOTIFICATIONS_APP_REDIRECTIONS,
  FIRESTORE_APP_CURRENT_TOKEN_RPASS,
} from '@common/constants/notifications.constants';
import {
  INotificationInformation, INotificationTapped, IPWANotificationData,
  IAppNotificationData,
  IAppNotificationDataRPass,
} from '@common/interfaces/notifications.interface';
import { UtilsService } from '@services/utils/utils';
import * as HandlerBRFCMAcoustic from '../../../../custom-plugins/co.acoustic.mobile.push.sdk/www/js/HandlerBRFCMAcoustic';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public hasNewNotifications: BehaviorSubject<boolean>;
  public notificationList: BehaviorSubject<INotificationInformation[]>;
  private subscription: Subscription;
  private subscribedToNotifications: boolean;

  constructor(
    public firebase: FirebaseService,
    public platform: Platform,
    public firebaseNative: FirebaseX,
    public utils: UtilsService,
    public device: Device,
    public http: HttpClient,
    public router: Router,
    public injector: Injector,
    private utilsService: UtilsService,
  ) {
    this.hasNewNotifications = new BehaviorSubject(false);
    this.notificationList = new BehaviorSubject([]);
    this.subscription = new Subscription();
    this.subscribedToNotifications = false;
  }

  public get isBrowserNotificationDefault(): boolean {
    return Notification.permission === DEFAULT_BROWSER_NOTIFICATION_PERMISSION;
  }

  public get isBrowserNotificationsSupported(): boolean {
    return !!(window as any).Notification;
  }

  public get isNative(): boolean {
    return this.platform.is('cordova');
  }

  public async initializePWANotifications(): Promise<void> {
    if (firebase.messaging.isSupported()) {
      const firebaseServiceWorker = await navigator.serviceWorker.register(FIREBASE_SW_PATH);
      const messaging = firebase.messaging();
      messaging.useServiceWorker(firebaseServiceWorker);
      messaging.usePublicVapidKey(environment.FIREBASE_CONFIG.vapidKey);
    }
  }

  public async onTokenRefresh(dType: string, dNumber: string): Promise<void> {
    if (this.isNative) {
      this.firebaseNative.onTokenRefresh().subscribe(async () => {
        await this.requestPermission(dType, dNumber);
      });
    }
  }

  public async requestPermission(dType: string, dNumber: string, rpassData?: {
    uuidDevice: string,
    userAgent: string,
  }): Promise<void> {
    try {
      if (this.isNative) {
        const token = await this.getNotificationTokenForNative();
        if (rpassData) {
          this.setNotificationTokenToRPassUser(token, dType, dNumber, rpassData.uuidDevice, rpassData.userAgent);
          return;
        }
        this.setNotificationTokenToAppUser(token, dType, dNumber);
      } else {
        if (this.isBrowserNotificationsSupported && this.isBrowserNotificationDefault) {
          const token = await this.getNotificationTokenForWeb();
          this.setNotificationTokenToPWAUser(token, dType, dNumber);
        }
      }
    } catch (err) {/**/ }
  }

  public async removeNotificationToken(dType: string, dNumber: string): Promise<void> {
    try {
      let platformKey = null;
      let token = null;
      if (this.isNative) {
        platformKey = FIRESTORE_APP_TOKEN_KEY;
        token = await this.getNotificationTokenForNative();
      } else {
        platformKey = FIRESTORE_PWA_TOKEN_KEY;
        token = await this.getNotificationTokenForWeb();
      }
      if (token) { this.firebase.removeNotificationToken(dType, dNumber, token, platformKey); }
    } catch (err) {/**/ }
  }

  public removeNotificationSubscription() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    this.subscribedToNotifications = false;
  }

  public fetchNewNotifications(dType: string, dNumber: string) {
    if (!this.subscribedToNotifications) {
      this.subscribedToNotifications = true;
      const notificationRef = this.firebase.fetchNewUserNotifications(dType, dNumber);
      this.subscription.add(notificationRef.onSnapshot((userNotifications) => {
        const notificationList = [];
        userNotifications.forEach((doc) => {
          notificationList.push({ inboxMessageId: doc.id, ...doc.data() } as INotificationInformation);
        });
        notificationList.sort((firstNotification, secondNotification) =>
          secondNotification.sendDate.toDate() - firstNotification.sendDate.toDate()
        );
        this.updateUnreadNotificationStatus(notificationList);
        this.notificationList.next(notificationList);
      }));
    }
  }

  private updateUnreadNotificationStatus(notifications: INotificationInformation[]) {
    this.hasNewNotifications.next(notifications.some((notification) => notification.isRead === false));
  }

  public markNotificationAsRead(notification: INotificationInformation) {
    this.firebase.markNotificationAsRead(notification);
  }

  public deleteNotification(notification: INotificationInformation) {
    this.firebase.deleteNotification(notification);
  }

  public listenToTappedNotifications() {
    this.firebaseNative.onMessageReceived().subscribe(
      async (notification: INotificationTapped) => {
        if(notification.mce && this.utilsService.isNativePlatform('android')) {
          HandlerBRFCMAcoustic.callbackNotificationToMceFcm(
              notification,
              environment.FIREBASE_CONFIG.messagingSenderId,
              (res) => {console.log('SUCCESSS: => ', res)},
              (err) => {console.log('ERR: => ', err)});
          return;
        }

        if (notification.isIdNowSDKMsg) {
          notification.redirectPath = 'rpassAuth';
          this.router.navigate(
            [NOTIFICATIONS_APP_REDIRECTIONS[notification.redirectPath], {
              extraData: notification.dataSdk,
              uuidTransaction: notification.uuidTrx,
              authId: notification.authId
            }]
          );
          return;
        }
        const isRPassNotificationFromMobile = !notification.tap && notification.redirectPath === 'rpassAuth';
        const notificationInformation = await this.firebase.getNotificationInformation(notification);
        const isTappedRPass = notification.redirectPath === 'rpassAuth' && !notificationInformation.isRead;
        if (notification.tap && (isTappedRPass || notification.redirectPath !== 'rpassAuth')) {
          this.openTappedNotification(notification);
        } else if (isRPassNotificationFromMobile) {
          this.router.navigate(
            [NOTIFICATIONS_APP_REDIRECTIONS[notification.redirectPath], { extraData: notification.extraData }]
          );
          this.markNotificationAsReadById(notification.trayId);
        }
      }
    );
  }

  public markNotificationAsReadById(notificationId: string): void {
    if (notificationId) {
      this.firebase.markNotificationAsReadById(notificationId);
    }
  }

  public async hasPermission() {
    return await this.firebaseNative.hasPermission();
  }

  public async grantPermission() {
    await this.firebaseNative.grantPermission();
  }

  private async getNotificationTokenForNative(): Promise<string> {
    let permission = await this.firebaseNative.hasPermission();
    if (!permission) {
      permission = await this.firebaseNative.grantPermission();
    }
    return permission ? this.firebaseNative.getToken() : null;
  }

  private openTappedNotification(notification: INotificationTapped) {
    this.markNotificationAsReadById(notification.trayId);
    const { redirectPath, redirectType } = notification;
    if (redirectPath) {
      if (redirectType === EXTERNAL_NOTIFICATION_TYPE) {
        this.utils.openRedirectPath(redirectPath);
      } else if (redirectType === INTERNAL_NOTIFICATION_TYPE) {
        if (redirectPath === 'rpassAuth') {
          this.router.navigate([NOTIFICATIONS_APP_REDIRECTIONS[redirectPath], { extraData: notification.extraData }]);
        } else {
          const routePath = NOTIFICATIONS_APP_REDIRECTIONS[redirectPath] || 'home';
          this.router.navigateByUrl(routePath);
        }
      }
    }
  }

  private async getNotificationTokenForWeb(): Promise<string> {
    const permission = await Notification.requestPermission();
    return permission === GRANTED_BROWSER_NOTIFICATION_PERMISSION ? firebase.messaging().getToken() : null;
  }

  private async setNotificationTokenToAppUser(token: string, dType: string, dNumber: string): Promise<void> {
    if (token) {
      const [platformKey, extraPlatformKey] = [FIRESTORE_APP_TOKEN_KEY, FIRESTORE_PWA_TOKEN_KEY];
      const notificationData = this.createAppNotificationData(token);
      this.firebase.addNotificationToken(dType, dNumber, notificationData, platformKey, extraPlatformKey);
    }
  }

  private async setNotificationTokenToPWAUser(token: string, dType: string, dNumber: string): Promise<void> {
    if (token) {
      const [platformKey, extraPlatformKey] = [FIRESTORE_PWA_TOKEN_KEY, FIRESTORE_APP_TOKEN_KEY];
      const notificationData = this.createPWANotificationData(token);
      this.firebase.addNotificationToken(dType, dNumber, notificationData, platformKey, extraPlatformKey);
    }
  }

  private async setNotificationTokenToRPassUser(
    token: string,
    dType: string,
    dNumber: string,
    uuidDevice: string,
    userAgent: string
  ): Promise<void> {
    if (token) {
      const [platformKey, extraPlatformKey] = [FIRESTORE_APP_CURRENT_TOKEN_RPASS, FIRESTORE_PWA_TOKEN_KEY];
      const notificationDataRPass = this.createPwaNotificationDataRPass(token, uuidDevice, userAgent);
      this.firebase.addNotificationToken(
        dType,
        dNumber,
        notificationDataRPass,
        platformKey,
        extraPlatformKey)
        .catch((error) => {
          console.log('error', error);
        });
    }
  }

  public async storeNotificationTokenRPass({dType, dNumber, uuidDevice, userAgent}) : Promise<void> {
    if(!this.isNative) return;
    const token = await this.getNotificationTokenForNative();
    this.setNotificationTokenToRPassUser(token, dType, dNumber, uuidDevice, userAgent);
  }

  private createPWANotificationData(token: string): IPWANotificationData {
    return {
      fcmToken: token,
      OS: UtilsService.OStype(),
      type: UtilsService.viewportLabel()
    };
  }

  private createAppNotificationData(token: string): IAppNotificationData {
    return {
      fcmToken: token,
      id: this.device.uuid,
      model: this.device.model,
      manufacturer: this.device.manufacturer
    };
  }

  private createPwaNotificationDataRPass(token: string, uuidDevice: string, userAgent: string): IAppNotificationDataRPass {
    return {
      fcmToken: token,
      id: this.device.uuid,
      model: this.device.model,
      manufacturer: this.device.manufacturer,
      uuidDevice,
      isAuthorizer: true,
      userAgent,
    }
  }
}
