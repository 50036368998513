import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_WIDGET_CONFIG_DATA, JWT_SECRET_KEY } from '@common/constants/digital-auth.constants';
import { IDetail, ITransactionInfo } from '@common/interfaces/digital-auth.interface';
import { IWidgetV3Response } from '@common/interfaces/widget.interface';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { DeviceService } from '@services/device/device.service';
import { UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils';
import { WidgetService } from '@services/widget/widget.service';
import { Subscription } from 'rxjs';

declare function onLoadV3(header: any, data: any, signature: any, tsFunction): any;

const HALF_SECOND = 500;

@Component({
  selector: 'app-widget-auth',
  templateUrl: './widget-authentication.component.html',
  styleUrls: ['./widget-authentication.component.scss'],
})
export class WidgetAuthenticationComponent implements OnInit {

  @Input() public module:string;
  @Input() public clientId: string;
  @Input() public token: string;
  @Input() public transactionInfo: ITransactionInfo;
  @Input() public transactionData: IDetail[] = null;
  @Input() public encryptData: boolean = false;
  @Output() public methodValidity = new EventEmitter<{ data: IWidgetV3Response }>();

  public loading = false;
  public subscription: Subscription;
  public header;
  public data;
  public signature;
  public iosWeb: boolean = false;
  public desktop: boolean = false;
  public androidWeb: boolean = false;

  public get urlJsV3(): string {
    return environment.WIDGET_AUTH_V3 + '/bundle.js';
  }

  constructor(
    private utils: UtilsService,
    private userService: UserService,
    private platform: Platform,
    private deviceService: DeviceService,
    private widgetService: WidgetService,
  ) {
    this.iosWeb = (this.platform.is('ios') && this.platform.is('mobileweb'));
    this.androidWeb = (this.platform.is('android') && this.platform.is('mobileweb'));
    this.desktop = this.platform.is('desktop');
  }

  public async ngOnInit() {
    if (!this.isJsLoaded()) { this.addScriptTag(); }

    const boundOnResultV3 = function(result) {
      const response = { data: result };
      this.methodValidity.emit(response);
    }.bind(this);

    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };

    this.header = btoa(JSON.stringify(header));
    this.data = await this.dataWidgetConfig();
    this.signature = JWT_SECRET_KEY;
    while (!(await onLoadV3(this.header, this.data, this.signature, boundOnResultV3))) {
      await this.utils.delay(HALF_SECOND);
    }
    this.loading = false;
  }

  private async dataWidgetConfig(): Promise<string> {
    const { appId, channel, country, userType } = DEFAULT_WIDGET_CONFIG_DATA;
    const { operation, transactionType, transactionName, subTypeTransaction } = this.transactionInfo;
    const config = {
      widgetConfig: {
        data: {
          appId,
          clientId: this.clientId,
          authToken: this.token,
          userId: this.userService.userInformation.documentNumber,
          documentType: this.userService.userInformation.documentType,
          documentNumber: this.userService.userInformation.documentNumber,
          ip: (await this.deviceService.getIP()).ip,
          userType,
          userAgent: navigator.userAgent,
          os: this.utils.getDeviceOS(),
          country,
          channel,
          operation,
          transactionType,
          transactionName,
          subTypeTransaction,
          transactionData: await this.geTransactionData(),
          localizacion: 'localizacion',
          deviceType: this.platform.is('cordova') ? 'mobile' : 'desktop',
          evento: 'InicioAutenticacion',
          labelButton: 'Transferir',
        }
      }
    };
    return btoa(JSON.stringify(config));
  }

  public async geTransactionData(): Promise<any>  {
    if (!this.encryptData) return this.transactionData
    return await this.widgetService.encryptTransactionData(this.transactionData)
  }

  public isJsLoaded(): boolean {
    let value:boolean = false;
    for (let index = 0; index < document.getElementsByTagName('head')[0]
      .getElementsByTagName('script').length; index++) {
      const src = document.getElementsByTagName('head')[0].getElementsByTagName('script')[index].src;
      if (src === this.urlJsV3) { value=true; break; }
    }

    if (this.module==='TRANSFER'){  
      if(this.desktop){
       document.getElementById('element').style.marginLeft = "-125px";     
      }else{
       document.getElementById('element').style.marginLeft = "0px";     
      }    
  
     }

    return value;
  }

  private addScriptTag() {
    const scriptTag = document.createElement('script');
    const styleTag = document.createElement('link');

    const bootstrapJs = document.createElement('script');
    const bootstrapCss = document.createElement('link');
    const jqueryJs = document.createElement('script');

    scriptTag.type = 'text/javascript';
    styleTag.rel = 'stylesheet';

    bootstrapCss.rel = 'stylesheet';
    bootstrapJs.type = 'text/javascript';
    jqueryJs.type = 'text/javascript';

    scriptTag.src = this.urlJsV3;

    scriptTag.async = true;

    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    document.getElementsByTagName('head')[0].appendChild(styleTag);
  }

}
