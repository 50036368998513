import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OTP_LENGTH, OTP_MAX_TIME } from '@common/constants/enrollment.constants';
import {
  RECAPTCHA_ERROR_CODE
} from '@common/constants/error.constants';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import {
  RECAPTCHA_MODAL_PROPS,
  RESCHEDULING_CANCEL_MODAL,
  SERVICE_ERROR_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import {
  OTP_CODE_ERROR,
  OTP_MAX_ATTEMPTS,
  RESCHEDULING_SCHEDULE,
  RESCHEDULING_SCHEDULE_PROPS,
  RESCHEDULING_SMS_ERROR_CODE,
  RESCHEDULING_SMS_ERROR_GENERAL,
  SESSION_DOESNT_EXIST,
  SESSION_DOESNT_EXIST_PROPS
} from '@common/constants/rescheduling.constans';
import {
  HOME_ROUTE,
  RESCHEDULING_ROUTE,
  VOUCHER_ROUTE
} from '@common/constants/routes.constants';
import { GTM_STEPS } from '@common/constants/tag/tag-rescheduling.constanst';
import {
  IReschedulingSendOtpRequest,
  IReschedulingValidateOtpRequest,
  IReschedulingValidateOtpResponse
} from '@common/interfaces/rescheduling';
import { IUserInformation } from '@common/interfaces/user.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { ReschedulingService } from '@services/rescheduling/rescheduling.service';
import { UserService } from '@services/user/user.service';

const MAX_SMS_LENGTH = 6;

@Component({
  selector: 'app-select-otp',
  templateUrl: './select-otp.component.html',
  styleUrls: ['./select-otp.component.scss'],
})
export class SelectOtpComponent implements OnInit {

  title: string;
  subtitle: string;
  typeCode: string;
  icon: string;
  userPhoneOrEmail: string;
  smsCheckMessage = '';
  selectedType: boolean;
  otpValidating: boolean;
  showTimer: boolean;
  sendingOtp: boolean;
  maxTime: number;
  userInformation: IUserInformation;
  timer: NodeJS.Timeout;
  @Input() emailOption: boolean;
  @Input() phoneOption: boolean;
  @Input() contactData: boolean;
  @Input() checkTermsAndConditions: boolean;
  @Input() reschedulingKey: string;
  @Output() isOtpValid: EventEmitter<boolean> = new EventEmitter();
  routerStateInformation: IReschedulingValidateOtpResponse;
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private reschedulingService: ReschedulingService,
    private router: Router,
    private modalCtrl: ModalController,
    private gtmService: GoogleTagManagerService
    ) {
      this.maxTime = OTP_MAX_TIME;
      this.showTimer = true;
      this.sendingOtp = false;
    }

  public otpForm = new FormGroup({
    otpCode: new FormControl('', Validators.compose([
      Validators.required, Validators.maxLength(OTP_LENGTH),
      Validators.required, Validators.minLength(OTP_LENGTH),
    ])),
  });

  get otpCodeValid() {
    if (this.otpForm.controls.otpCode.valid) {
      this.cleanCheckMessage();
    }
    return this.otpForm.controls.otpCode.valid;
  }

  ngOnInit() {
    this.title = 'Selecciona tu método de autenticación';
    this.userInformation = this.userService.userInformation;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  selectOtpType(value) {
    this.typeCode = value;
    this.selectedType = true;
    if (value === 'sms') {
      this.title = 'Ingresa el código enviado a tu celular';
      this.subtitle = 'Te enviamos un SMS con un código de 6 dígitos al número ';
      this.userPhoneOrEmail = this.userInformation.mobileNumber;
      this.icon = 'i-cellphone';
    } else {
      this.title = 'Ingresa el código enviado a tu correo';
      this.subtitle = 'Hemos enviado un correo con un código de 6 dígitos a ';
      this.userPhoneOrEmail = this.userInformation.email;
      this.icon = 'i-email-option';
    }
    this.sendOtp(value);
    this.gtmService.pushTagV2(GTM_STEPS[this.typeCode].sendOtp);
  }

  async sendOtp(type) {
    try {
      this.sendingOtp = true;
      if (this.smsCheckMessage) { this.cleanCheckMessage(); }
      const request: IReschedulingSendOtpRequest = {
        actionTypeOTP: type,
        reschedulingKey: this.reschedulingKey
      };
      const response = await this.reschedulingService.sendOTP(request);
      if (response.code === 0) {
        this.maxTime = OTP_MAX_TIME;
        this.showTimer = true;
        this.setOtpTimer();
      } else {
        this.updateCheckMessage(response);
      }
    } catch (error) {
      this.updateCheckMessage(error);
    } finally {
      this.sendingOtp = false;
    }
  }

  async validateOtp() {
    try {
      this.otpValidating = true;
      this.gtmService.pushTagV2(GTM_STEPS[this.typeCode].lastStepRescheduleButton);
      const emailSaved = this.reschedulingService.email;
      const request: IReschedulingValidateOtpRequest = {
        reschedulingKey: this.reschedulingKey,
        pin: this.otpForm.controls.otpCode.value,
        termsConditionAccepted: Number(this.checkTermsAndConditions),
        email: emailSaved
      };
      const response = await this.reschedulingService.validateOTP(request);
      if (response.code === 0) {
        this.routerStateInformation = response;
        this.routerStateInformation.email = emailSaved;
        this.routerStateInformation.debtAmount = this.reschedulingService.debtAmount;
        this.modalController.dismiss();
        this.router.navigate([`${RESCHEDULING_ROUTE}/${VOUCHER_ROUTE}`], { replaceUrl: true, state: this.routerStateInformation });
      } else {
        this.updateCheckMessage(response);
      }
    } catch (error) {
      this.updateCheckMessage(error);
    } finally {
      this.otpValidating = false;
    }
  }

  public updateCheckMessage(e) {
    this.showTimer = false;
    clearTimeout(this.timer);
    const message = document.getElementById('sms-check-message');
    const input = document.getElementById('otp-code-input');
    const control = this.otpForm.get('otpCode');
    switch (e.code) {
      case RECAPTCHA_ERROR_CODE:
        this.smsCheckMessage = RECAPTCHA_MODAL_PROPS.message;
        break;
      case SESSION_DOESNT_EXIST:
        this.smsCheckMessage = SESSION_DOESNT_EXIST_PROPS.message;
        break;
      case OTP_MAX_ATTEMPTS:
      case OTP_CODE_ERROR:
        this.smsCheckMessage = e.message;
        break;
      case RESCHEDULING_SMS_ERROR_CODE:
        this.smsCheckMessage = RESCHEDULING_SMS_ERROR_GENERAL;
        break;
      case RESCHEDULING_SCHEDULE:
        this.showErrorModal();
        break;
      default:
        this.smsCheckMessage = SERVICE_ERROR_MODAL_PROPS.message;
        break;
    }
    message.style.color = '#ff0000';
    message.style.display = 'block';
    input.style.borderColor = '#ff0000';
    control.setValue('');
  }

  public cleanCheckMessage() {
    const input = document.getElementById('otp-code-input');
    const message = document.getElementById('sms-check-message');
    message.style.display = 'none';
    input.style.borderColor = '#EDEDED';
  }

  async showErrorModal() {
    const props = RESCHEDULING_SCHEDULE_PROPS;
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
    });
  }

  return() {
    this.title = 'Selecciona tu método de autenticación';
    this.smsCheckMessage = '';
    this.selectedType = false;
    this.showTimer = false;
    this.otpForm.reset();
    this.gtmService.pushTagV2(GTM_STEPS[this.typeCode].changeOtpType);
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.showTimer = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.showTimer = true;
        this.setOtpTimer();
      } else {
        this.showTimer = false;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  async showCancelModal() {
    if (this.typeCode) {
      this.gtmService.pushTagV2(GTM_STEPS[this.typeCode].lastStepCancelButton);
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal cancel-reprogramming',
      componentProps: RESCHEDULING_CANCEL_MODAL,
    });
    await modal.present();
    const option = await modal.onDidDismiss();
    switch (option.data) {
      case 'primaryButtonPressed':
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
      case 'secondaryButtonPressed':
        break;
    }
  }

  enterOtpTagEvent(value: string): void {
    if (value) {
      const length = value.length;
      if (length === MAX_SMS_LENGTH) {
        this.gtmService.pushTagV2(GTM_STEPS[this.typeCode].enterOtpCode);
      }
    }
  }
}
