import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { CampaignEngineService } from '@services/campaigns-engine/campaigns-engine.service';

@Component({
  selector: 'app-banner-slider-card',
  templateUrl: './banner-slider-card.component.html',
  styleUrls: ['./banner-slider-card.component.scss'],
})
export class BannerSliderCardComponent implements OnInit {

  @Input() public sliderCampaign: any;
  @Output() public onBannerButtonClick = new EventEmitter<any>();

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET }
  
  constructor(private campaignEngineService: CampaignEngineService) {}

  ngOnInit() {
    this.addCount('views');
  }

  public getBackgroundImageUrl(): string {
    const imageUrl = this.isMobile ? this.sliderCampaign.mobileUrl : this.sliderCampaign.desktopUrl;
    return 'url(' + imageUrl + ')';
  }

  public clickButton() {
    this.onBannerButtonClick.emit({
      externalURL: this.sliderCampaign.externalURL,
      page: this.sliderCampaign.page,
      params: this.sliderCampaign.params });
  }

  public async addCount(counter: 'views' | 'goals') {
    let sliderCampaignsUserData: firebase.firestore.DocumentData;    
    await Promise.all([      
      this.campaignEngineService.sliderCampaignsUserData.then((snap) => { sliderCampaignsUserData = snap; }),
    ]);          

    this.campaignEngineService.addCount(counter, this.sliderCampaign, sliderCampaignsUserData, this.campaignEngineService.sliderCampaignsUserDataRef, false);
  }

}