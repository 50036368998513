import { Injectable } from '@angular/core';
import { IDNOW_SERVICES_DATA } from '@common/constants/digital-auth-form.constants';
import { APP_ID, DEFAULT_WIDGET_CONFIG_DATA } from '@common/constants/digital-auth.constants';
import { IDNOW_DEVICE_STATUS, IDNOW_SDK_NAME, IDNOW_SUCCESS_CODE } from '@common/constants/idnow.constants';
import { CLIENT_IP_KEY } from '@common/constants/misc.constants';
import { IBiometriaAccount } from '@common/interfaces/account.interface';
import { BlockDeviceRequest, CommonParamsRequest, CommonUserDataRequest, GetDeviceStatusRequest, OnBoardingApiBaseRequest } from '@common/models/digital-auth';
import { DeviceService } from '@services/device/device.service';
import { DigitalAuthService } from '@services/digital-auth/digital-auth.service';
import { UtilsService } from '@services/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class IdnowService {
  private userAgent: string;

  constructor(
    private utils: UtilsService,
    private digitalAuthService: DigitalAuthService,
    private deviceService: DeviceService
  ) {
    this.userAgent = this.deviceService.getUA();
  }

  //* API Methods *//

  /**
 * Function to check if the device is enrolled
 * @param userId user Id - Rut/Dni
 * @param uuidDeviceCurrentToken if is not provided, the SDK will get the uuidDevice to getStatus
 * @returns return a promise with the device status and the uuidDevice
 */
  public async getDeviceStatus(
    userId: any,
    uuidDeviceCurrentToken?: string): Promise<{ isEnrolled: boolean, uuidDeviceReturn: string }> {
    try {
      let getDeviceStatusRequest: GetDeviceStatusRequest = null;
      let uuidDeviceReturn: string = '';
      if (uuidDeviceCurrentToken) {
        getDeviceStatusRequest = await this.getRequestBodyData(userId, uuidDeviceCurrentToken);
        uuidDeviceReturn = uuidDeviceCurrentToken;
      } else {
        const deviceStatusSdkData = await this.getDeviceStatusSDK(userId);
        const { commonParamsRequest, commonUserDataRequest, uuidDevice } = deviceStatusSdkData;
        uuidDeviceReturn = uuidDevice;
        const biometricAccount = await this.getBiometricAccount({ userId }); //! temporary biometricAccount
        const token = (await this.digitalAuthService.getOAuthToken(biometricAccount)).access_token; //! temporary biometricAccount
        getDeviceStatusRequest = new GetDeviceStatusRequest(
          token,
          commonParamsRequest,
          commonUserDataRequest,
          uuidDevice
        );
      }
      const getDeviceStatus = await this.digitalAuthService.getDeviceStatusOnBoardingApi(getDeviceStatusRequest).toPromise();
      let isEnrolled = false;
      if (getDeviceStatus) {
        const { contextData } = getDeviceStatus;
        const contextDataObject = this.transformContextDataToObject(contextData);
        const { code, status } = contextDataObject;
        if (code === IDNOW_SUCCESS_CODE && status) {
          switch (status.toUpperCase()) {
            case IDNOW_DEVICE_STATUS.ENROLLED:
              isEnrolled = true;
              break;
            default:
              isEnrolled = false;
              break;
          }
        }
        return Promise.resolve({ isEnrolled, uuidDeviceReturn });
      }
    } catch (error) {
      throw error;
    }
  }

   /**
 * Function to block device on IdNow SDK
 * @param userId user Id - DNI/DocumentNumber
 * @param uuidDeviceCurrentToken if is not provided, the SDK will get the uuidDevice to block
 * @returns Promise<boolean> true if the device was blocked, false if not or throws error
 */
   public async blockDevice(userId: any, uuidDeviceCurrentToken?: string): Promise<boolean> {
    try {
      let blockDeviceRequest: BlockDeviceRequest = null;
      if (uuidDeviceCurrentToken) {
        blockDeviceRequest = await this.getRequestBodyData(userId, uuidDeviceCurrentToken);
      } else {
        const deviceStatusSdkData = await this.getDeviceStatusSDK(userId);
        const { commonParamsRequest, commonUserDataRequest, uuidDevice } = deviceStatusSdkData;
        const biometricAccount = await this.getBiometricAccount({ userId }); //! temporary biometricAccount
        const token = (await this.digitalAuthService.getOAuthToken(biometricAccount)).access_token;
        blockDeviceRequest = new BlockDeviceRequest(
          token,
          commonParamsRequest,
          commonUserDataRequest,
          uuidDevice
        );
      }
      const blockDeviceResponse = await this.digitalAuthService.blockDeviceOnBoardingApi(blockDeviceRequest).toPromise();
      let blockDeviceExecuted = false;
      if (blockDeviceResponse) {
        const { contextData } = blockDeviceResponse;
        const contextDataObject = this.transformContextDataToObject(contextData);
        const { code } = contextDataObject;
        if (code === IDNOW_SUCCESS_CODE) {
          blockDeviceExecuted = true;
        }
        return Promise.resolve(blockDeviceExecuted);
      }
    } catch (error) {
      throw error;
    }
  }

  //* SDK Methods *//

  public async getDeviceStatusSDK(userId: string): Promise<any> {
    const channel = this.utils.getDeviceOS().toUpperCase();
    const forwaredIp = await this.getForwaredIp();
    const enterpriseCode = '';
    const userType = IDNOW_SERVICES_DATA.userType;
    return new Promise((resolve, reject) => {
      window[IDNOW_SDK_NAME].getDeviceStatusRequest(channel, APP_ID, forwaredIp, this.userAgent, userId, userType, enterpriseCode,
        async (res) => {
          resolve(JSON.parse(res));
        },
        async (err) => {
          reject(JSON.parse(err));
        })
    });
  }

  public async compareSecureData(data, dataKey): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window[IDNOW_SDK_NAME].compareSecureData(data, dataKey, 'secret',
        async (res) => {
          resolve(JSON.parse(res));
        },
        async (err) => {
          reject(false);
        });
    });
  }

  public async clearEnrollmentIdNow(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window[IDNOW_SDK_NAME].clearEnrollment(
        async () => {
          resolve(true);
        },
        async (err) => {
          reject(err);
        });
    });
  }

  //* Utils Methods *//

  private async getRequestBodyData(userId: string, uuidDevice: string): Promise<OnBoardingApiBaseRequest> {
    const biometricAccount = await this.getBiometricAccount({ userId }); //! temporary biometricAccount
    const token = (await this.digitalAuthService.getOAuthToken(biometricAccount)).access_token; //! temporary biometricAccount
    const commonParamsRequest = await this.getCustomCommonParamsRequest();
    const commonUserDataRequest = this.getCustomCommonUserDataRequest(userId);
    return new OnBoardingApiBaseRequest(
      token,
      commonParamsRequest,
      commonUserDataRequest,
      uuidDevice
    );
  }

  private async getCustomCommonParamsRequest(): Promise<CommonParamsRequest> {
    const forwaredIp = await this.getForwaredIp();
    const channel = IDNOW_SERVICES_DATA.channel;
    return new CommonParamsRequest(
      APP_ID,
      channel,
      forwaredIp,
      this.userAgent
    );
  }

  private getCustomCommonUserDataRequest(userId: string): CommonUserDataRequest {
    const enterpriseCode = '';
    const userType = IDNOW_SERVICES_DATA.userType;
    return new CommonUserDataRequest(
      enterpriseCode,
      userId,
      userType
    )
  }

  private async getForwaredIp() {
    const storedIp = localStorage.getItem(CLIENT_IP_KEY);
    if (storedIp) return storedIp;
    const ipAddress = (await this.deviceService.getIP()).ip;
    localStorage.setItem(CLIENT_IP_KEY, ipAddress);
    return ipAddress;
  }

  private transformContextDataToObject(contextData: any[]) {
    const contextDataObject = contextData.reduce((obj, item) => {
      obj[item.nombreKey] = item.valorKey;
      return obj;
    }, {});
    return contextDataObject;
  }

  //! temporary biometricAccount
  private async getBiometricAccount({ userId }): Promise<IBiometriaAccount> {
    const bio: IBiometriaAccount = {
      userId: Number(userId),
      ip: await this.getForwaredIp(),
      deviceType: this.deviceService.getWildField1(),
      userType: DEFAULT_WIDGET_CONFIG_DATA.userType,
      event1: 'InitPoliticas',
      localizacion: '00.00,00.00',
      userAgent: window.navigator.userAgent.toLowerCase(),
      country: DEFAULT_WIDGET_CONFIG_DATA.country,
      channel: DEFAULT_WIDGET_CONFIG_DATA.channel,
      operation: 'RIPLEYPIN2',
      os: this.utils.getDeviceOS().toUpperCase(),
      contextData: '',
    };
    return bio;
  }
}

