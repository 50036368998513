export const SEF_REAL_NAME = 'Súper Efectivo';

export const SEF_MAIN_VIEW = 'SEF_MAIN_VIEW';

export const SEF_SIMULATOR_MAX_ATTEMPS = 6;

export const SEF_RSAT_POLICIES = 600;
export const SEF_T24_POLICIES = 601;

export const TOOLTIP_TCEA_SEF = 'La TCEA se calcula considerando el seguro de desgravamen porcentual sobre el saldo insoluto de la deuda según tarifario vigente. Tasas,' +
    'Tarifas y Contratos en: www.bancoripley.com.pe, sección: Tasas, Tarifas y Contratos, seleccionando el producto contratado.';