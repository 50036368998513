export const AHORROPLUS_VER_BENEFICIOS = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_1',
    event_label: 'ver_beneficios_en_ofertas_ahorro'​
  };

export const AHORROPLUS_ABRIR_CUENTA = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_1.1',
    event_label: 'abrir_cuenta_en_promociones_ahorro'​
  };

export const AHORROPLUS_LOAD_PAGE_DECLARACIONJURADA = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2',
    event_label: 'despliega_declaracion_jurada_ahorro'​
  };

export const AHORROPLUS_MORE_INFORMATION = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2.1',
    event_label: 'presionar_mas_informacion_declaracion'​
  };

export const AHORROPLUS_CLIC_CONTINUE = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2.2',
    event_label: 'continuar_en_declaracion_ahorro'​
  };

export const AHORROPLUS_CLIC_CANCEL = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2.3',
    event_label: 'cancelar_en_declaracion_ahorro'​
  };

export const AHORROPLUS_OPEN_MODAL_MORE_INFORMATION = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2.4',
    event_label: 'modal_mas_informacion_declaracion'​
  };

export const AHORROPLUS_OPEN_MODAL_RESPONSE_YES_SECOND_QUESTION = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_2.5',
    event_label: 'modal_respuesta_si_apertura_cuenta'​
  };

export const AHORROPLUS_RESUME_ACCOUNT_OPENING = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_3',
    event_label: 'despliega_resumen_apertura_cuenta_ahorro'​
  };

export const AHORROPLUS_ACCEPT_TERM_CONDITIONS = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_3.1',
    event_label: 'acepto_termino_contrato_ahorro'​
  };

export const AHORROPLUS_OPEN_STEP_THREE = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4',
    event_label: 'despliega_pantalla_ingreso_codigo_sms'​
  };

export const AHORROPLUS_ISNOT_MY_NUMBER = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.1',
    event_label: 'boton_presionar_no_es_mi_numero_ahorro'​
  };

export const AHORROPLUS_ENTER_CODE = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.2',
    event_label: 'escribe_codigo_sms_ahorro'​
  };

export const AHORROPLUS_SMS_CODE_OK = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.3',
    event_label: 'codigo_sms_correcto_ahorro'​
  };

export const AHORROPLUS_ABRIR_CUENTA_AHORRO = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.4',
    event_label: 'boton_presionar_abrir_cuenta_ahorro'
  };

export const AHORROPLUS_CANCELAR_CUENTA_AHORRO = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.5',
    event_label: 'boton_presionar_cancelar_apertura_cuenta'
  };

export const AHORROPLUS_SOLICITUD_NEW_CODE   = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.6',
    event_label: 'pedir_nuevamente_codigo_sms_ahorro'
  };

export const AHORROPLUS_CODE_INVALID   = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.7',
    event_label: 'codigo_incorrecto_sms_ahorro'
  };

export const AHORROPLUS_IS_NOT_MY_NUMBER   = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_4.8',
    event_label: 'modal_no_es_mi_numero_ahorro'
  };

export const AHORROPLUS_ACCOUNT_ACTIVATE   = {
    event: 'ahorroPlus_event',​
    event_category: 'flujoAhorroPlus',​
    event_action: 'step_5',
    event_label: 'exito_cuenta_ahorro_plus'
  };
