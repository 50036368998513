import { IAccountCreate, IContactEnrollmentInformation, IPasswordChange, IPinPanAccount, IBiometriaAccount, IFailureMonitor } from '@common/interfaces/account.interface';
import {
  ENROLLMENT_ERROR_MESSAGES, LAST_DIGITS_LENGTH, MOBILE_STEPS_WIDTH, OTP_LENGTH, OTP_MAX_TIME, OTP_VALIDATION_ERROR, OTP_VALIDATION_SUCCESSFUL,
  PASSWORDS_DONT_MATCH, SEND_OTP_SUCCESSFUL, SESSION_EXPIRED,  SMS_CHANGE_PASSWORD_TYPE, USER_ALREADY_ENROLLED,USER_ALREADY_ENROLLED_BLOCK, USER_CREATION_SUCCESSFUL,
  USER_WITHOUT_PRODUCTS, PIN_VALIDATION_SUCCESSFUL, PAN_ENROLL_MAX_LENGTH, FIRST_ENROLLMENT_STEP_DESCRIPTION, SECOND_ENROLLMENT_STEP_DESCRIPTION
} from '@common/constants/enrollment.constants';
import {
  CLIENT_WITHOUT_CARD, GET_DOCUMENT_ERROR, GET_RIPLEY_PRODUCTS_ERROR
} from '@common/constants/error.constants';
import {
  Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import {
  DNI_LENGTH, DNI_SEGMENT, FOREIGN_CARD_LENGTH, FOREIGN_CARD_SEGMENT
} from '@common/constants/document-type.constants';
import { Platform } from '@ionic/angular';
import {
  ENROLL_REGISTER,
  DNI_SCREEN_CREATE_RECOVER,
  DNI_WRITE_CREATE_RECOVER,
  CONTINUE_CREATE_RECOVER,
  CANCEL_CREATE_RECOVER,
  SELECC_SCREEN_CREATE,
  SELECC_SCREEN_RECOVER,
  FACIAL_BOTTON_CREATE,
  SMS_BOTTON_CREATE,
  PINPAN_BOTTON_CREATE,
  PINPAN_BOTTON_RECOVER,
  FACIAL_BOTTON_RECOVER,
  SMS_BOTTON_RECOVER,
  SMS_SCREEN_RECOVER,
  SMS_SCREEN_CREATE,
  PINPAN_SCREEN_CREATE,
  PIN_WRITE_CREATE,
  PAN_WRITE_CREATE,
  PINPAN_CONTINUE_BOTTON_CREATE,
  PINPAN_CANCEL_BOTTON_CREATE,
  SMS_AUTH_BOTTON_CREATE,
  SMS_RESEND_BOTTON_CREATE,
  SMS_OTHER_METHOD_BOTTON_CREATE,
  SMS_NO_NUMBER_BOTTON_CREATE,
  PASSW6_CHECK_TERMS_COND_CREATE,
  PASSW6_CHECK_PROTECT_DATA_CREATE,
  PASSW6_SCREEN_CREATE,
  PASSW6_BOTON_CREATE,
  PASSW6_BOTON_CANCEL_CREATE,
  PASSW6_SCREEN_SUCCESS_CREATE,
  PASSW6_BOTON_ENTER_CREATE,
  SMS_AUTH_BOTTON_RECOVER,
  SMS_RESEND_BOTTON_RECOVER,
  SMS_NO_NUMBER_BOTTON_RECOVER,
  SMS_OTHER_METHOD_BOTTON_RECOVER,
  PINPAN_SCREEN_RECOVER,
  PAN_WRITE_RECOVER,
  PIN_WRITE_RECOVER,
  PINPAN_CONTINUE_BOTTON_RECOVER,
  PINPAN_CANCEL_BOTTON_RECOVER,
  PASSW6_SCREEN_RECOVER,
  PASSW6_CHECK_PROTECT_DATA_RECOVER,
  PASSW6_CHECK_TERMS_COND_RECOVER,
  PASSW6_BOTON_RECOVER,
  PASSW6_BOTON_CANCEL_RECOVER,
  PASSW6_SCREEN_SUCCESS_RECOVER,
  PASSW6_BOTON_ENTER_RECOVER,
  SMS_WRITE_CODE_RECOVER,
  SMS_WRITE_CODE_CREATE
} from '@common/constants/tag/tag-enrollment.constants';

import {
  CHANNEL, EMAIL_REGEX, ENROLLMENT_FORM, VALIDATE_PIN_MAX_LENGTH, LOGIN_PASSWORD_MAX_LENGTH, MILLISECONDS_TO_SECOND,
  PASSWORD_LABEL, PASSWORD_RESET_FORM, RIPLEY_BANK_CARD_SITE, RIPLEY_BANK_PUBLIC_SITE_URL
} from '@common/constants/misc.constants';
import {
  ACCESS_BLOCK_PINPAN_PROPS, ACCESS_INVALID_PINPAN_PROPS, ALREADY_ENROLLED_BLOCK_PROPS, ALREADY_ENROLLED_PROPS, CREATED_USER_PROPS,
  ENROLLMENT_BACKLIST_PASSWORD,
  ENROLLMENT_EXIT_PROPS, ENROLLMENT_SESSION_EXPIRED_PROPS, ENROLLMENT_SMS_DATA_ERROR, ENROLLMENT_SMS_INTENTS_INVALID, ENROLLMENT_PINPAN_TIME_INVALID, ENROLLMENT_SMS_TIME_INVALID, GENERIC_ERROR_ENROLL,
  NODATES_CARD, NODATES_PINPAN_PROPS, NOT_MY_NUMBER_PROPS, NOT_PHONE_PROPS, NOT_RIPLEY_USER_PROPS, ONOFF_ERROR_ENROLL
} from '@common/constants/modal-props.constants';
import {
  SHOW_NOT_ENROLLED_USER_MODAL_PROP, SHOW_PASSWORD_CHANGED_MODAL_PROP
} from '@common/constants/modal.constants';
import { SAVED_IDENTIFIER_KEY } from '@common/constants/remember-user.constants';
import {
  ENROLLMENT_ROUTE, LOGIN_ROUTE, PASSWORD_RESET_ROUTE
} from '@common/constants/routes.constants';
import {
  ENROLL_CONFIRM_SMS, ENROLL_MODAL_NO_PRODUCTS, ENROLL_MODAL_NOT_NUMBER,
  ENROLL_MODAL_PRODUCTS_FOUND, ENROLL_NOT_NUMBER, ENROLL_TIMEOUT_SMS
} from '@common/constants/tag/tag-enrollment.constants';
import { ISegmentSelector } from '@common/interfaces/default.interface';
import { ITokenizedKeyboard } from '@common/interfaces/device.interface';
import { IComponentOneButton } from '@common/interfaces/modal.interface';
import {
  IOtpEnrollmentInformation,
  IOtpValidateInformation
} from '@common/interfaces/otp.interface';
import { ITraceability } from '@common/interfaces/traceability.interface';
import { IUserIdentification } from '@common/interfaces/user.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EnrollModalComponent } from '../../components/enroll-modal/enroll-modal.component';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AccountService } from '@services/account/account.service';
import { AlertService } from '@services/alert/alert.service';
import { DeviceService } from '@services/device/device.service';
import { EnrollmentService } from '@services/enrollment/enrollment.service';
import { IOnOff, IOnOffParams } from '@common/interfaces/onoff.interface';
import { OnOffService } from '@services/onoff/onoff.service';
import { DataPinPanService } from '@services/data-pinpan/data-pinpan.service';
import { IPinPanData } from '@common/interfaces/data-pinpan.interface';
import { OtpService } from '@services/otp/otp.service';
import { ProductService } from '@services/product/product.service';
import { TraceabilityService } from '@services/traceability/traceability.service';
import { UtilsService } from '@services/utils/utils';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { ICreditCardDetails } from '@common/interfaces/product.interface';
import { LocationService } from '@services/location/location.service';
import { FIRST_PASSWORD_RESET_STEP_DESCRIPTION, SECOND_PASSWORD_RESET_STEP_DESCRIPTION } from '@common/constants/password-reset.constants';
import { environment } from '@environments/environment';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { ENROLL_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { ENROLL_DOCUMENT_NUMBER } from '@common/constants/auth.constants';
declare var WidgetBR: any;

@Component({
  selector: 'app-external-form',
  templateUrl: './external-form.component.html',
  styleUrls: ['./external-form.component.scss'],
})
export class ExternalFormComponent implements OnInit, OnDestroy {
  utils = UtilsService;
  widgetConfig = {
    widgetConfig: {
      header: "",
      data: "",
      signature: "",
      callback: function (result) {

      }
    }
  };

  @Input() formType: string;
  @Input() firstDesktopStepMessage: string;
  @Input() secondDesktopStepMessage: string;
  @Input() finalButtonText: string;
  @Input() globalStepTitle: string;
  enrollmentForm: FormGroup;
  pinForm: FormGroup;
  passwordForm: FormGroup;
  confirmPasswordForm: FormGroup;
  dniSegment: ISegmentSelector;
  foreignCardSegment: ISegmentSelector;
  otpLength = OTP_LENGTH;
  panLength = PAN_ENROLL_MAX_LENGTH;
  documentNumberLength: number;
  documentNumberPlaceholder: string;
  isDataLoaded: { isLoaded: boolean; hasErrors: boolean; };
  isKeyboardLoaded: { isLoaded: boolean; hasErrors: boolean; };
  enrollmentAvailable: { isLoaded: boolean; hasErrors: boolean; };
  bottonAvailableSMS: { isLoaded: boolean; hasErrors: boolean; };
  bottonAvailableFac: { isLoaded: boolean; hasErrors: boolean; };
  bottonAvailablePin: { isLoaded: boolean; hasErrors: boolean; };
  pinicon: { isLoaded: boolean; hasErrors: boolean; };
  actualStep: number;
  actualGlobalStep: number;
  stepMessage: string;
  hasProducts: boolean;
  hasEnrollment: number;
  smsInvalid:boolean;
  smsActive:boolean[]= new Array(false,false,false,false,false, false);
  hasEnrollmentErrors: boolean;
  contactInformation: IContactEnrollmentInformation;
  successfullEnroll:boolean;
  pindisable: boolean;
  smsdisable:boolean;
  facialdisable: boolean;
  isNative: boolean = this.platform.is('cordova');
  //nuevo pinpan
  formterm:boolean;
  formpoli:boolean;
  selectedTypeButton: boolean;
  timer: NodeJS.Timeout;
  maxTime: any;
  maxTimeString: string;
  enabledResendOTP: boolean = false;
  showTimerSection: boolean;
  errorMessage: string;
  hashCodeLabel: string;
  tokenizedKeyboard: ITokenizedKeyboard;
  identifier: number;
  currentKeyboard: string;
  validatingOtp: boolean;
  CEFacial:boolean;
  emailLoad:Boolean;
  //MUCHA
  validatingPin: boolean;
  validateOTPorPIN: number;
  validateType: number;
  validationType: string = "";
  validatingButton: boolean;
  validButtSMS: boolean;
  validButtRec:boolean;
  validButtPin:boolean;
  creatPass:boolean;
  //
  formLoading: boolean;
  totalSteps: number;
  showDocumentStep: boolean;
  showOtpStep: boolean;
  ShowBioStep:boolean;
  showPinPanStep: boolean;
  showPasswordStep: boolean;
  showTypeStep: boolean;
  showTypeStepNoNumber: boolean;
  selectTypeStep: number;
  passwordError: boolean;
  validateScreenType: boolean;
  validateScreenTypeNONumber:boolean;
  url: string;
  documentLink: string;
  idSession: string;
  cardDetailInfo: ICreditCardDetails;
  //muchay
  soloNumeros:boolean;
  mayorSeisCaracteres:boolean;
  ambasConinciden:boolean;
  numerosContinuos: boolean;
  numerosListaNegra: boolean;
  validacionClave:boolean;
  passwordUno:string;
  passwordDos:string;
  verPasswordDos:boolean;
  verPasswordUno:boolean;
  aceptaTerminos:boolean;
  aceptaPolitica:boolean;
  originEnrollment:string;
  urlQR:string;
  smsBorder: boolean;
  pinButtonDisabled: boolean;
  isOnBiometria: boolean;
  isOnPinPan: boolean;
  isOnSMS: boolean;
  isOnPinPanSMS: boolean;
  isfacialweb: boolean;

  //PinPan
  dataPinPromise: Promise<[boolean, string]>;
  publicKeyPEM: string;
  selector: string = "";
  isMonitorBiometric: boolean = false;
  currentDocumentNumber: string = "";
  currentDocumentType: string = "";
  @ViewChild('downloadButton') downloadButton: ElementRef<HTMLElement>;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setActualStep(this.actualStep);
  }

  public otp: string[] = ['', '', '', '', '', ''];

  constructor(
      private formBuilder: FormBuilder,
      private enrollmentService: EnrollmentService,
      private validationService: OtpService,
      private accountService: AccountService,
      private traceabilityService: TraceabilityService,
      private LocalService: LocationService,
      private productsService: ProductService,
      private deviceService: DeviceService,
      private alertService: AlertService,
      private modalCtrl: ModalController,
      private router: Router,
      private storage: Storage,
      private utilsService: UtilsService,
      private platform: Platform,
      private onOff: OnOffService,
      private dataPinPanService: DataPinPanService,
      private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.url = this.router.url;
  }

  ngOnInit() {
    this.urlQR = environment.URL_QR;
    this.smsBorder = this.smsInvalid;
    this.setupFormPage();
    this.createDevice();
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_PAGE.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_PAGE.ACTION,
      category: ENROLL_GA4_CONSTANTS.CREATE_PAGE.CATEGORY,
      step: ENROLL_GA4_CONSTANTS.CREATE_PAGE.STEP,
    };
    this.googleAnalyticsService.gtagPushEvent(ENROLL_GA4_CONSTANTS.CREATE_PAGE.EVENT, eventGA4);

    UtilsService.showRecaptchaBadge();
    this.addWidget(environment.WIDGET_ONB+ "/bundle.js");
    if(this.platform.is('desktop')){
      this.isfacialweb = true;
    }
    else if(this.platform.is('mobileweb')){
      this.isfacialweb = true;
    }
    else{
      this.isfacialweb = false;
    }
    this.platform.backButton.subscribeWithPriority(10, () => {
      document.addEventListener('backbutton', function (event) {
        event.preventDefault();
        event.stopPropagation();
      }, false);
    });
  }

  ngOnDestroy() {
    UtilsService.hideRecaptchaBadge();
  }

  /* INICIO_SQTE-138 - Corrección de validación casillas sms */
  onInput(index: number) {
    let value = ''
    switch(index) {
      case 0:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode1.value) ? this.otpCode1.value : '';
        value = this.otp[index];
        break;
      case 1:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode2.value) ? this.otpCode2.value : '';
        value = this.otp[index];
        break;
      case 2:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode3.value) ? this.otpCode3.value : '';
        value = this.otp[index];
        break;
      case 3:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode4.value) ? this.otpCode4.value : '';
        value = this.otp[index];
        break;
      case 4:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode5.value) ? this.otpCode5.value : '';
        value = this.otp[index];
        break;
      case 5:
        this.otp[index] = /^[0-9]+$/.test(this.otpCode6.value) ? this.otpCode6.value : '';
        value = this.otp[index];
        break;
      default:
    }
    if (value.length > 1) {
      this.otp[index] = value.slice(0, 1);
    }
    if (value.length === 1) {
      this.focusNextBox(index);
    }
  }
  onPasteV2(event: ClipboardEvent) {
    const pastedData = event.clipboardData.getData('text/plain');
    if (pastedData && pastedData.length === 6) {
      this.otp = pastedData.split('');
      this.otpCode1.patchValue(this.otp[0]);
      this.otpCode2.patchValue(this.otp[1]);
      this.otpCode3.patchValue(this.otp[2]);
      this.otpCode4.patchValue(this.otp[3]);
      this.otpCode5.patchValue(this.otp[4]);
      this.otpCode6.patchValue(this.otp[5]);
      this.smsActive[0] = true;
      this.smsActive[1] = true;
      this.smsActive[2] = true;
      this.smsActive[3] = true;
      this.smsActive[4] = true;
      this.smsActive[5] = true;
    }
  }
  onBackspace(index: number) {
    let value = ''
    switch(index) {
      case 0:
        this.otpCode1.reset();
        this.otp[index] = this.otpCode1.value;
        value = this.otp[index];
        break;
      case 1:
        this.otpCode2.reset();
        this.otp[index] = this.otpCode2.value;
        value = this.otp[index];
        break;
      case 2:
        this.otpCode3.reset();
        this.otp[index] = this.otpCode3.value;
        value = this.otp[index];
        break;
      case 3:
        this.otpCode4.reset();
        this.otp[index] = this.otpCode4.value;
        value = this.otp[index];
        break;
      case 4:
        this.otpCode5.reset();
        this.otp[index] = this.otpCode5.value;
        value = this.otp[index];
        break;
      case 5:
        this.otpCode6.reset();
        this.otp[index] = this.otpCode6.value;
        value = this.otp[index];
        break;
      default:
    }
    if (index > 0 && (value === '' || value === null)) {
      this.focusPrevBox(index);
    }
  }
  focusNextBox(index: number) {
    const nextIndex = index + 1;
    if (nextIndex <= this.otp.length && nextIndex !== 6) {
      const nextBox = document.getElementById(`box-${nextIndex}`);
      if (nextBox) {
        (nextBox as HTMLInputElement).focus();
      }
    }
  }
  focusPrevBox(index: number) {
    const prevIndex = index - 1;
    if (prevIndex >= 0) {
      const prevBox = document.getElementById(`box-${prevIndex}`);
      if (prevBox) {
        (prevBox as HTMLInputElement).focus();
      }
    }
  }
  /* FIN_SQTE-138 - Corrección de validación casillas sms */

  get loginRoute() { return `/${LOGIN_ROUTE}`; }

  get enrollmentRoute() { return `/${ENROLLMENT_ROUTE}`; }

  public setupFormPage() {
    this.validatingOtp = false;
    this.validateScreenTypeNONumber=false;
    this.validateScreenType= false;
    this.smsInvalid = false;
    this.pindisable = true;
    this.smsdisable = true;
    this.smsActive[0] = false;
    this.smsActive[1] = false;
    this.smsActive[2] = false;
    this.smsActive[3] = false;
    this.smsActive[4] = false;
    this.smsActive[5] = false;
    this.creatPass = true;
    this.dniSegment = DNI_SEGMENT;
    this.foreignCardSegment = FOREIGN_CARD_SEGMENT;
    this.actualGlobalStep = 1;
    this.stepMessage = '';
    this.maxTime = OTP_MAX_TIME;
    this.maxTimeString = String(OTP_MAX_TIME);
    this.hasEnrollmentErrors = false;
    this.hashCodeLabel = PASSWORD_LABEL;
    this.formLoading = false;
    this.passwordError = false;
    this.selectedTypeButton = false;
    this.totalSteps = 2;
    this.validateOTPorPIN = 1;
    this.validateType = 1;
    this.setActualStep(1);
    this.showTimerSection = false;
    this.mayorSeisCaracteres = false;
    this.soloNumeros = false;
    this.mayorSeisCaracteres = false;
    this.ambasConinciden = false;
    this.validacionClave = false;
    this.numerosContinuos = false;
    this.numerosListaNegra = false;
    this.verPasswordDos = false;
    this.verPasswordUno = false;
    this.aceptaTerminos = false;
    this.aceptaPolitica = false;
    this.successfullEnroll = false;
    this.facialdisable = true;
    this.CEFacial = true;
    this.enrollmentForm = this.formBuilder.group({
      DocumentNumber: new FormControl('', Validators.compose([Validators.required, Validators.nullValidator])),
      DocumentType: new FormControl('', Validators.compose([Validators.required, Validators.nullValidator])),
      OtpCode1: new FormControl('', Validators.compose([Validators.required])),
      OtpCode2: new FormControl('', Validators.compose([Validators.required])),
      OtpCode3: new FormControl('', Validators.compose([Validators.required])),
      OtpCode4: new FormControl('', Validators.compose([Validators.required])),
      OtpCode5: new FormControl('', Validators.compose([Validators.required])),
      OtpCode6: new FormControl('', Validators.compose([Validators.required])),
      Email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])),
      DataProtectionCheck: new FormControl('', Validators.compose([Validators.required])),
      TermsAndConditionsCheck: new FormControl('', Validators.compose([Validators.required])),
      CardNumber: new FormControl('', Validators.compose([Validators.required, Validators.nullValidator])),
    });

    this.pinForm = this.formBuilder.group({
      keyboardId: new FormControl('', Validators.compose([Validators.required])),
      fakePassword: new FormControl('', Validators.compose([Validators.required]))
    });

    this.passwordForm = this.formBuilder.group({
      keyboardId: new FormControl('', Validators.compose([Validators.required])),
      fakePassword: new FormControl('', Validators.compose([Validators.required])),
      fakeConfirmationPassword: new FormControl('', Validators.compose([Validators.required])),
    });

    this.confirmPasswordForm = this.formBuilder.group({
      keyboardId: new FormControl('', Validators.compose([Validators.required])),
      fakePassword: new FormControl('', Validators.compose([Validators.required])),
      fakeConfirmationPassword: new FormControl('', Validators.compose([Validators.required])),
    });

    this.isDataLoaded = {
      isLoaded: false,
      hasErrors: false
    };
    this.isKeyboardLoaded = {
      isLoaded: false,
      hasErrors: false
    };
    this.enrollmentAvailable = {
      isLoaded: true,
      hasErrors: false
    };

    this.bottonAvailableSMS = {
      isLoaded: false,
      hasErrors: false
    };

    this.bottonAvailableFac = {
      isLoaded: false,
      hasErrors: false
    };

    this.bottonAvailablePin = {
      isLoaded: false,
      hasErrors: false
    };

    this.pinicon = {
      isLoaded: true,
      hasErrors: false
    };
    this.changeCredentialType(this.dniSegment.value);
  }

  public async changeCredentialType(selectedType: string) {
    this.enrollmentForm.reset();
    if (selectedType === this.dniSegment.value) {
      this.documentNumberLength = DNI_LENGTH;
      this.documentNumberPlaceholder = '12345678';
    } else {
      this.documentNumberLength = FOREIGN_CARD_LENGTH;
      this.documentNumberPlaceholder = '123456789';
    }
    this.documentType.patchValue(selectedType);
  }

  public async checkEnrollment() {
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.ACTION,
      category:  'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEvent(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.EVENT, eventGA4);
    this.validatingPin = false;
    this.hasEnrollmentErrors = false;
    if (!this.documentType.valid || !this.documentNumber.valid) {
      return;
    } else {
      this.enrollmentAvailable.isLoaded = false;
      try {
        this.utilsService.addEventToTagManagerAndFirebase(
            'Verificación de enrolamiento vigente',
            'Enrolamiento',
            'Ingreso de número de documento'
        );

        this.hasEnrollment = await this.checkUserEnrollment();
        if (!this.hasEnrollmentErrors) {
          this.validateOTPorPIN = 0;
          this.selectTypeStep = 0;
          await this.setSmsStep();
        }
      } catch (err) {
        this.enrollmentAvailable.isLoaded = true;
        this.GENERIC_MODAL();
      }
    }
  }

  public async setSmsStep() {
    if(this.hasEnrollment==0){
      this.formType = "password";
      this.formType = 'password';
      this.firstDesktopStepMessage = FIRST_PASSWORD_RESET_STEP_DESCRIPTION;
      this.secondDesktopStepMessage = SECOND_PASSWORD_RESET_STEP_DESCRIPTION;
      this.finalButtonText = 'Cambiar clave';
      this.globalStepTitle = 'Recuperar clave';
    }
    else{
      this.formType = "enrollment";
      this.formType = 'enrollment';
      this.firstDesktopStepMessage = FIRST_ENROLLMENT_STEP_DESCRIPTION;
      this.secondDesktopStepMessage = SECOND_ENROLLMENT_STEP_DESCRIPTION;
      this.finalButtonText = 'Crear clave';
      this.globalStepTitle = 'Crear clave';
    }
    this.ValidateEnrollment();
  }

  public async checkUserProducts() {
    try {
      const products = await this.productsService.hasActiveProducts(await this.getAccountDocumentData());
      if (products.code !== 1) { throw { code: CLIENT_WITHOUT_CARD }; }
      return true;
    } catch (errBody) {
      let code = 0;
      try{
        code = JSON.parse(errBody.error.messages[0]).Code;
      }catch(e){
        try{
          code = JSON.parse(errBody.messages[0]).Code;
        }catch(e2){
          try {
            code = errBody.error.code || errBody.code;
          } catch (e) { console.error('Cannot parse error code'); }
        }
      }
      switch (code) {
        case CLIENT_WITHOUT_CARD:
          const eventGA4 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.STEP,
            subcategory:this.globalStepTitle,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.EVENT, eventGA4);
          await this.showNotRipleyUserModal();
          break;
        case GET_RIPLEY_PRODUCTS_ERROR:
          const eventGA41 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.STEP,
            subcategory:this.globalStepTitle,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.EVENT, eventGA41);
          this.GENERIC_MODAL();
          break;
        default:
          const eventGA42 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.STEP,
            subcategory:this.globalStepTitle,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_USER_SERVER.EVENT, eventGA42);
          this.GENERIC_MODAL();
          break;
      }
      return false;
    }
  }

  public async checkUserEnrollment() {
    try {
      const identifier = await this.storage.get(SAVED_IDENTIFIER_KEY);
      const dataPerson = await this.getAccountDocumentData();
      const enrollmentStatus = await this.enrollmentService.checkUserEnrollment(dataPerson, identifier);
      this.storage.set(ENROLL_DOCUMENT_NUMBER, dataPerson.documentNumber);
      this.validateType = 1;
      return enrollmentStatus.code;

    } catch (errBody) {
      this.enrollmentAvailable.isLoaded = true;
      this.hasEnrollmentErrors = true;
      let code = 0;
      try{
        code = JSON.parse(errBody.error.messages[0]).Code;
      }catch(e){
        try{
          code = JSON.parse(errBody.messages[0]).Code;
        }catch(e2){
          try {
            code = errBody.error.code || errBody.code;
          } catch (e) { console.error('Cannot parse error code'); }
        }
      }
      switch (code) {
        case USER_WITHOUT_PRODUCTS:
          const eventGA4 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.ACTION,
            category:  'enrolamiento',
            subcategory:this.globalStepTitle,
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_USER.STEP,
            step_error: 'error-no cliente',
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT.EVENT, eventGA4);
          await this.showNotRipleyUserModal();
          break;
        case GET_RIPLEY_PRODUCTS_ERROR:
        case USER_ALREADY_ENROLLED:
          await this.showAlreadyEnrolledModal();
          break;
        case USER_ALREADY_ENROLLED_BLOCK:
          await this.showAlreadyEnrolledBlockModal();
          break;
        default:
          await this.GENERIC_MODAL();
          break;
      }
      return false;
    }
  }

  public initEnroll(){
    this.router.navigateByUrl(`/${ENROLLMENT_ROUTE}`);
    this.utilsService.addEventToTagManagerAndFirebase(ENROLL_REGISTER.event, ENROLL_REGISTER.variable, ENROLL_REGISTER.action);
  }


  public navigatePasswordReset() {

    this.router.navigateByUrl(`/${PASSWORD_RESET_ROUTE}`);
  }

  public async validateTypeDni(){
    const identifier = await this.storage.get(SAVED_IDENTIFIER_KEY);
    const dataPerson = await this.getAccountDocumentData();
    const enrollmentStatus = await this.enrollmentService.checkUserEnrollment(dataPerson, identifier);

    if (enrollmentStatus.code == 0){
      this.initEnroll();

    }
    else{
      this.navigatePasswordReset();
    }
  }

  public async getUserContactInformation() {
    const formType = this.formType;
    switch (formType) {
      case PASSWORD_RESET_FORM:
        this.contactInformation = await this.accountService.getContactDataFromFISA(await this.getAccountDocumentData());
        break;
      case ENROLLMENT_FORM:
        this.contactInformation = await this.accountService.getContactDetail(await this.getAccountDocumentData());
        break;
    }
    this.email.patchValue(this.contactInformation.mail);
    this.idSession = this.contactInformation.idSession;
  }

  public async ValidateEnrollment() {
    try {
      this.otpCode1.patchValue('');
      this.otpCode2.patchValue('');
      this.otpCode3.patchValue('');
      this.otpCode4.patchValue('');
      this.otpCode5.patchValue('');
      this.otpCode6.patchValue('');
      await this.getUserContactInformation();

      var result = await this.getOnOff();
      if (result){
        this.sendDataTraceability(null, null, null, null, 'STEP_SEARCH_DOCUMENT'); // STEP_SEARCH_DOCUMENT
        this.screenbottonview();
        this.ShowType();
        this.selectedTypeButton= true;
      } else{
        this.enrollmentAvailable.isLoaded = true;
        this.NOVALIDATE_MODAL();
      }


    } catch (err) {
      this.sendDataTraceability(null, null, null, err.errorMessage, 'STEP_SEARCH_DOCUMENT'); // STEP_SEARCH_DOCUMENT
      this.GENERIC_MODAL();
    }
  }

  showValidateCE(){
    this.CEFacial = false;
    this.ShowType();
  }

  CallBackSendSMS(that) {
    that.bottonAvailableSMS.isLoaded = false;
    that.validateScreenType = false;
    that.selectTypeStep = 1;
    that.validateOTPorPIN = 1;
    that.setActualStep(2);
  }

  public async ResendOtp(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_RESEND_SMS.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_RESEND_SMS.ACTION,
      category:ENROLL_GA4_CONSTANTS.CREATE_BOTTON_RESEND_SMS.CATEGORY,
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_RESEND_SMS.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_RESEND_SMS.EVENT, eventGA4);
    this.enabledResendOTP = false;
    this.smsEnable();
    this.smsCleanValue();
    this.smsInvalid = false;
    this.SendOTP(function(that){

    });
  }

  public async SendOTP(callback){
    let processType = '';
    const formType = this.formType;
    switch (formType) {
      case ENROLLMENT_FORM:
        processType = '0';
        break;
      case PASSWORD_RESET_FORM:
        processType = '1';
        break;
      default:
        break;
    }
    const otpInformation: IOtpEnrollmentInformation = {
      sessionId: this.idSession,
      processType,
    };
    this.utilsService.addEventToTagManagerAndFirebase('Envío de código', 'Enrolamiento', 'click', null);
    const otpResponse = await this.validationService.sendOTPEnrollment(otpInformation);
    if (otpResponse.code === SEND_OTP_SUCCESSFUL) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_SMS_PAGE.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_SMS_PAGE.ACTION,
        category: ENROLL_GA4_CONSTANTS.CREATE_SMS_PAGE.CATEGORY,
        step: ENROLL_GA4_CONSTANTS.CREATE_SMS_PAGE.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_SMS_PAGE.EVENT, eventGA4);
      this.smsCleanValue();
      this.smsEnable();
      this.enabledResendOTP = false;
      this.maxTime = OTP_MAX_TIME;
      this.setOtpTimer();
      callback(this);
    } else {
      this.enabledResendOTP = false;
      this.showIntentsModal();
      this.bottonAvailableSMS.isLoaded = false;
      this.pindisable = true;
      this.smsdisable = true;
      this.facialdisable = true;
    }
  }

  async showIntentsModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );

    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_SMS_INTENTS_INVALID
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      }
    });
  }

  async showTimeInvalidModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );

    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_SMS_TIME_INVALID
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        const eventGA4 = {
          product: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_CONT.PRODUCT,
          action: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_CONT.ACTION,
          category: 'enrolamiento',
          step: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_CONT.STEP,
          subcategory:this.globalStepTitle,
          auth_method: this.selector,
        };
        this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_CONT.EVENT, eventGA4);
        this.smsEnable();
        this.smsCleanValue();
        this.smsInvalid = false;
        this.smsActive[0]=false;
        this.smsActive[1]=false;
        this.smsActive[2]=false;
        this.smsActive[3]=false;
        this.smsActive[4]=false;
        this.smsActive[5]=false;
        return
      }
      else if (button.data === 'secondaryButtonPressed') {
        const eventGA4 = {
          product: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_METHOD.PRODUCT,
          action: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_METHOD.ACTION,
          category: 'enrolamiento',
          step: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_METHOD.STEP,
          subcategory:this.globalStepTitle,
          auth_method: this.selector,
        };
        this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_METHOD.EVENT, eventGA4);
        this.showTypeStep = true;
        this.validateScreenType= true;
        this.selectTypeStep= 0;
        this.showOtpStep= false;
        this.bottonAvailableSMS.isLoaded=false;
        this.pindisable = true;
        this.smsdisable = true;
        this.facialdisable = true;
        this.smsActive[0]=false;
        this.smsActive[1]=false;
        this.smsActive[2]=false;
        this.smsActive[3]=false;
        this.smsActive[4]=false;
        this.smsActive[5]=false;
      }
    });
  }

  async showDataInvalidModal() {

    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );

    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_SMS_DATA_ERROR
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        const eventGA4 = {
          product: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER_METHOD.PRODUCT,
          action: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER_METHOD.ACTION,
          category: 'enrolamiento',
          step: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER_METHOD.STEP,
          subcategory:this.globalStepTitle,
          auth_method: this.selector,
        };
        this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER_METHOD.EVENT, eventGA4);
        this.pindisable = true;
        this.smsdisable = true;
        this.showTypeStep = true;
        this.validateScreenType= true;
        this.selectTypeStep= 0;
        this.showOtpStep= false;
        this.bottonAvailableSMS.isLoaded=false;
        this.facialdisable = true;
        this.smsActive[0]=false;
        this.smsActive[1]=false;
        this.smsActive[2]=false;
        this.smsActive[3]=false;
        this.smsActive[4]=false;
        this.smsActive[5]=false;
      }
    });
  }

  public async validateOtp() {
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_SMS.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_SMS.ACTION,
      category:ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_SMS.CATEGORY,
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_SMS.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_SMS.EVENT, eventGA4);
    try {
      this.utilsService.addEventToTagManagerAndFirebase(ENROLL_CONFIRM_SMS.event, ENROLL_CONFIRM_SMS.variable, ENROLL_CONFIRM_SMS.action);
      if (!this.isOtpStepValid) {

        return;
      }
      this.validatingOtp = true;
      const otp: IOtpValidateInformation = {
        sessionId: this.idSession,
        pin: this.CodeSMS,
        monitorInputFront: {
          url : window.location.host,
          device : this.deviceService.getWildField1(),
          firmware : this.deviceService.getPlatformName(),
          docName : window.location.pathname,
          cpuAbi : '',
          finger : '0',
          mobileNumber : this.contactInformation.mobileNumber,
          ip : (await (this.deviceService.getIP())).ip,
          navegadorIdenticator: this.deviceService.getNavegadorIdentifier(), //window.navigator.userAgent,
          deviceVersion: this.deviceService.getDeviceVersion(),
          deviceId: this.deviceService.getDeviceId(),
          deviceName: this.deviceService.getDeviceManufacturer(),
          deviceModel: this.deviceService.getDevice(),
          identifier: await this.storage.get(SAVED_IDENTIFIER_KEY),
          imei: this.deviceService.getImei(),
          numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
          memoryRam: this.deviceService.getNumberMemoryRam(),
          videoCardVendor: this.deviceService.getVideoCardVendor(),
          videoCardRenderer: this.deviceService.getVideoCardRenderer(),
          country: await this.deviceService.getCountry(),
          city: await this.deviceService.getCity(),
          isp: await this.deviceService.getISP(),
          macAddress: this.deviceService.getMacAddress(),
          navegadorVersion: this.deviceService.getNavegadorVersion(),
          navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
          engineName: this.deviceService.getEngineName(),
          engineVersion: this.deviceService.getEngineVersion()
        }
      };
      this.utilsService.addEventToTagManagerAndFirebase('Validación de código', 'Enrolamiento', 'click', null);
      const otpValidation = await this.validationService.validateOTP(otp);
      switch (otpValidation.code) {
        case OTP_VALIDATION_SUCCESSFUL:
          this.smsInvalid = false;
          clearTimeout(this.timer);
          this.originEnrollment = "SMS";
          this.sendDataTraceability(null, null, null, null, 'STEP_VALIDATE_CODE'); // STEP_VALIDATE_CODE
          return await this.createPassword();
        case OTP_VALIDATION_ERROR:
          this.smsInvalid = true;
          return this.validatingOtp = false;
        case undefined:
          return this.GENERIC_MODAL();
      }
    }
    catch (err) {
      this.sendDataTraceability(null, null, null, err.errorMessage, 'STEP_VALIDATE_CODE'); // STEP_VALIDATE_CODE
      this.validatingOtp = false;
      this.GENERIC_MODAL();
    }
  }

  onClassInputSMS(type){
    if (type == 0){
      return this.smsInvalid?'texto-numero txtleft input-error-container INPUT-SMS':!this.smsActive[type]?'texto-numero inactive txtleft 2':'texto-numero active txtleft 2';
    }else{
      return this.smsInvalid?'texto-numero input-error-container INPUT-SMS':!this.smsActive[type]?'texto-numero inactive':'texto-numero active 2';
    }
  }

  public async showValidateNoNumber(){
    this.bottonAvailablePin.isLoaded=false;
    this.bottonAvailableFac.isLoaded=false;
    this.validateScreenTypeNONumber = true;
    this.setActualStep(2);
  }

  public async ShowPinPan(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_PINPAN.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_PINPAN.ACTION,
      category:   'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_PINPAN.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_PINPAN.EVENT, eventGA4);
    this.facialdisable = false;
    this.smsdisable = false;
    this.pindisable = false;
    this.bottonAvailablePin.isLoaded=true;

    try {
      this.publicKeyPEM = await this.dataPinPanService.getNewPublicKey(this.documentNumber.value);
    } catch (err) {
      return this.GENERIC_MODAL();
    }

    this.pinicon.isLoaded=false;
    this.validateOTPorPIN = 2;
    this.setActualStep(2);
    this.showTypeStep = false;
    this.validateScreenType = false;
    this.showTypeStepNoNumber = false;
    this.validateScreenTypeNONumber = false;
    const eventGA41 = {
      auth_method:this.selector,
      product: ENROLL_GA4_CONSTANTS.CREATE_PINPAN_PAGE.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_PINPAN_PAGE.ACTION,
      category: 'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_PINPAN_PAGE.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_PINPAN_PAGE.EVENT, eventGA41);

  }

  public async ShowType(){
    this.bottonAvailableSMS.isLoaded = false;
    this.bottonAvailablePin.isLoaded = false;
    this.bottonAvailableFac.isLoaded = false;
    this.validateScreenType = true;
    this.setActualStep(2);
  }

  async showPinPanTimeInvalidModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_PINPAN_TIME_INVALID
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          this.enrollmentAvailable.isLoaded = true;
          break;
        case 'secondaryButtonPressed':
          return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      }
    });
  }

  public changeFakePin($event: any) {
    if ($event.target.value.length === 4) {
      this.pinButtonDisabled = false;
    } else {
      this.pinButtonDisabled = true;
    }
  }

  public async validatePin() {
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_PINPAN.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_PINPAN.ACTION,
      category:   'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_PINPAN.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CONT_PINPAN.EVENT, eventGA4);
    try {
      if (!this.isPinCodeValid) {
        return;
      }

      this.validatingPin = true;
      await this.getTokenizedKeyboard('pinForm');

      const pinPanData: IPinPanData = {
        documentNumber: this.documentNumber.value,
        pan: this.cardNumber.value,
        pinKeyboardId: this.keyboardId('pinForm').value,
        pinHashCodes: this.getPasswordHash('pinForm', VALIDATE_PIN_MAX_LENGTH)
      }
      this.dataPinPromise = this.dataPinPanService.encryptPublicKey(pinPanData, this.publicKeyPEM);
      const [isPrivateKeySetted, dataPinEncrypted] = await this.dataPinPromise;

      if (!isPrivateKeySetted) {
        return;
      }

      const pin: IPinPanAccount = {
        Channel : this.utilsService.getChannelCode(),
        DocumentType : this.documentType.value,
        DocumentNumber:this.documentNumber.value,
        CardNumber: this.cardNumber.value,
        CardType: "2",
        password4: {
          keyboardId: undefined,
          hashCodes: undefined
        },
        PinPanEncrypt: dataPinEncrypted,
        monitorInputFront: {
          url : window.location.host,
          device : this.deviceService.getWildField1(),
          firmware : this.deviceService.getPlatformName(),
          docName : window.location.pathname,
          cpuAbi : '',
          finger : '0',
          mobileNumber : '',
          ip : (await (this.deviceService.getIP())).ip,
          navegadorIdenticator: this.deviceService.getNavegadorIdentifier(), //window.navigator.userAgent,
          deviceVersion: this.deviceService.getDeviceVersion(),
          deviceId: this.deviceService.getDeviceId(),
          deviceName: this.deviceService.getDeviceManufacturer(),
          deviceModel: this.deviceService.getDevice(),
          imei: this.deviceService.getImei(),
          numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
          memoryRam: this.deviceService.getNumberMemoryRam(),
          videoCardVendor: this.deviceService.getVideoCardVendor(),
          videoCardRenderer: this.deviceService.getVideoCardRenderer(),
          country: await this.deviceService.getCountry(),
          city: await this.deviceService.getCity(),
          isp: await this.deviceService.getISP(),
          macAddress: this.deviceService.getMacAddress(),
          navegadorVersion: this.deviceService.getNavegadorVersion(),
          navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
          engineName: this.deviceService.getEngineName(),
          engineVersion: this.deviceService.getEngineVersion(),
          isEnrolled : this.hasEnrollment == 0 ? true: false
        }
      };

      const identifier = await this.storage.get(SAVED_IDENTIFIER_KEY);
      await this.enrollmentService.checkUserEnrollmentPinPan(pin, identifier);
      this.originEnrollment = "PIN+PAN";
      this.sendDataTraceability(null, null, null, null, 'STEP_VALIDATE_PIN'); // STEP_VALIDATE_CODE
      //this.clickpinsms = true;
      //this.clickpin = true;
      return await this.createPassword();
    }  catch (err) {
      this.validatingPin = false;
      let code = 0;
      let errBodyJson = "";
      try{
        errBodyJson = JSON.parse(err.error.messages[0]).Message;
        code = JSON.parse(err.error.messages[0]).Code;
      }catch(e){
        try {
          errBodyJson = JSON.parse(err.messages[0]).Message;
          code = JSON.parse(err.messages[0]).Code;
        }catch(e2){
        try {
          code = err.error.code || err.code;
          errBodyJson = err.error.message || err.message;
        } catch (e) { console.error('Cannot parse error code'); }
        }
      }
      switch (code) {
        case 703:
          const eventGA4 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.ACTION,
            category:   'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.STEP,
            subcategory:this.globalStepTitle,
            step_error:  ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.STEP_ERROR,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.EVENT, eventGA4);
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          await this.showInvalidPanModal();
          break;
        case 705:
          const eventGA41 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.STEP,
            subcategory:this.globalStepTitle,
            step_error: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.STEP_ERROR,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_INCORRECT.EVENT, eventGA41);
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          await this.showInvalidPanModal();
          break;
        case 706:
          const eventGA42 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_TIME_HERE.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_TIME_HERE.ACTION,
            category:   'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_TIME_HERE.STEP,
            subcategory:this.globalStepTitle,
            step_error:ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_TIME_HERE.STEP_ERROR, 
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_TIME_HERE.EVENT, eventGA42);
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          await this.showPinPanTimeInvalidModal();
          break;
        case 709:
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          await this.showInvalidCard();
          break;
        case 713:
          const eventGA43 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_BLOCK.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_BLOCK.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_BLOCK.STEP,
            subcategory:this.globalStepTitle,
            step_error:ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_BLOCK.STEP_ERROR,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_BLOCK.EVENT, eventGA43);
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          await this.showBlockPinModal();
          break;
        default:
          const eventGA44 = {
            product: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_SERVER.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_SERVER.ACTION,
            category:  'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_SERVER.STEP,
            subcategory:this.globalStepTitle,
            step_error: ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_SERVER.STEP_ERROR,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_PIN_SERVER.EVENT, eventGA44);
          this.validatingPin = false;
          //this.clickpinsms = true;
          //this.clickpin = true;
          break;
      }
      this.sendDataTraceability(null, null, code, errBodyJson, 'STEP_VALIDATE_PIN');
      if (this.modalCtrl.getTop() === undefined) {
        this.GENERIC_MODAL();
      }
    }
    this.bottonAvailablePin.isLoaded=true;
  }

  public async createPassword() {
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_6DIGITS_PAGE.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_6DIGITS_PAGE.ACTION,
      category:   'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_6DIGITS_PAGE.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_6DIGITS_PAGE.EVENT, eventGA4);
    this.actualGlobalStep = 2;
    this.setActualStep(3);

  }

  public async submitSelectedForm(this_) {
    const eventGA4 = {
      product:  this.finalButtonText,
      action: ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CONFIRM_6DIGITS.ACTION,
      category:  'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CONFIRM_6DIGITS.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CONFIRM_6DIGITS.EVENT, eventGA4);
    return this.formType === ENROLLMENT_FORM ? this.enrollUser() : this.changePassword();
  }

  public async enrollUser() {
    try {
      if (!this.isPasswordStepValid) {
        return;
      }
      this.formLoading = true;
      const emailField = this.emailUnchanged ? '' : this.email.value;
      const enrollmentInformation: IAccountCreate = {
        SessionId: this.idSession,
        password6: {
          keyboardId: this.keyboardId('passwordForm').value,
          hashCodes: this.getPasswordHash('passwordForm', LOGIN_PASSWORD_MAX_LENGTH)
        },
        password6Compare: {
          keyboardId: this.keyboardId('confirmPasswordForm').value,
          hashCodes: this.getPasswordHash('confirmPasswordForm', LOGIN_PASSWORD_MAX_LENGTH)
        },
        Email: emailField,
        isProtectedPersonal: Number(this.dataProtectionCheck.value),
        isTermCondition: Number(this.termsAndConditionsCheck.value),
        //typeEnrollment: SMS_ENROLLMENT_TYPE,
        originEnrollment:this.originEnrollment,
        channel: CHANNEL,
        monitorInputFront: {
          url : window.location.host,
          device : this.deviceService.getWildField1(),
          firmware : this.deviceService.getPlatformName(),
          docName : window.location.pathname,
          cpuAbi : '',
          finger : '0',
          mobileNumber : this.contactInformation.mobileNumber,
          ip : (await (this.deviceService.getIP())).ip,
          navegadorIdenticator: this.deviceService.getNavegadorIdentifier(), //window.navigator.userAgent,
          deviceVersion: this.deviceService.getDeviceVersion(),
          deviceId: this.deviceService.getDeviceId(),
          deviceName: this.deviceService.getDeviceManufacturer(),
          deviceModel: this.deviceService.getDevice(),
          imei: this.deviceService.getImei(),
          numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
          memoryRam: this.deviceService.getNumberMemoryRam(),
          videoCardVendor: this.deviceService.getVideoCardVendor(),
          videoCardRenderer: this.deviceService.getVideoCardRenderer(),
          country: await this.deviceService.getCountry(),
          city: await this.deviceService.getCity(),
          isp: await this.deviceService.getISP(),
          macAddress: this.deviceService.getMacAddress(),
          navegadorVersion: this.deviceService.getNavegadorVersion(),
          navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
          engineName: this.deviceService.getEngineName(),
          engineVersion: this.deviceService.getEngineVersion(),
          isEnrolled : this.hasEnrollment == 0 ? true: false
        }
      };
      const userEnrollment = await this.enrollmentService.enrollUser(enrollmentInformation, this.identifier);
      if (userEnrollment.code === USER_CREATION_SUCCESSFUL) {
        this.sendDataTraceability('product', 'product_status', null, null, 'STEP_REGISTER_PASSWORD'); // STEP_REGISTER_PASSWORD
        this.successEnroll();
      } else {
        this.sendDataTraceability(null, null, null, 'error al registrar Cliente', 'STEP_REGISTER_PASSWORD'); // STEP_REGISTER_PASSWORD
        this.GENERIC_MODAL();
      }
      this.storage.remove(ENROLL_DOCUMENT_NUMBER);

    } catch (errBody) {
      try {
        let code = 0;
        let errBodyJson = "";
        try{
          errBodyJson = JSON.parse(errBody.error.messages[0]).Message;
          code = JSON.parse(errBody.error.messages[0]).Code;
        }catch(e){
          try{
            errBodyJson = JSON.parse(errBody.messages[0]).Message;
            code = JSON.parse(errBody.messages[0]).Code;
          }catch(e2){
            try {
              code = errBody.error.code || errBody.code;
              errBodyJson = errBody.error.message || errBody.message;
            } catch (e) { console.error('Cannot parse error code'); }
          }
        }
        this.sendDataTraceability(null, null, code, errBodyJson, 'STEP_REGISTER_PASSWORD'); // STEP_REGISTER_PASSWORD
        if (code == 620){
          await this.showModalEndSession();
        } else if(code == 604)  {
          await this.showModalBackList()
        } else if (code == USER_ALREADY_ENROLLED){
          await this.showAlreadyEnrolledModal();
        } else if (ENROLLMENT_ERROR_MESSAGES[code]) {
          const alertMessage = await this.alertService.openErrorAlert(this.url, false, true, 'Error', ENROLLMENT_ERROR_MESSAGES[code]);
          if (alertMessage) { alertMessage.present(); }
        } else {
          this.GENERIC_MODAL();
        }
      } catch (e) {
        this.lastStepErrorHandler(errBody);
      }
    }
    this.formLoading = false;
  }

  public async changePassword() {
    try {
      if (!this.isPasswordStepValid) {
        return;
      }      
      this.formLoading = true;
      const emailField = this.emailUnchanged ? '' : this.email.value;
      const enrollmentInformation: IPasswordChange = {
        idSession: this.idSession,
        password6: {
          keyboardId: this.keyboardId('passwordForm').value,
          hashCodes: this.getPasswordHash('passwordForm', LOGIN_PASSWORD_MAX_LENGTH)
        },
        password6Compare: {
          keyboardId: this.keyboardId('confirmPasswordForm').value,
          hashCodes: this.getPasswordHash('confirmPasswordForm', LOGIN_PASSWORD_MAX_LENGTH)
        },
        typeEnrollment: SMS_CHANGE_PASSWORD_TYPE,
        originEnrollment:this.originEnrollment,
        channel: CHANNEL,
        validationId: 1,
        Email: emailField,
        monitorInputFront: {
          url : window.location.host,
          device : this.deviceService.getWildField1(),
          firmware : this.deviceService.getPlatformName(),
          docName : window.location.pathname,
          cpuAbi : '',
          finger : '0',
          mobileNumber : this.contactInformation.mobileNumber,
          ip : (await (this.deviceService.getIP())).ip,
          navegadorIdenticator: this.deviceService.getNavegadorIdentifier(), //window.navigator.userAgent,
          deviceVersion: this.deviceService.getDeviceVersion(),
          deviceId: this.deviceService.getDeviceId(),
          deviceName: this.deviceService.getDeviceManufacturer(),
          deviceModel: this.deviceService.getDevice(),
          imei: this.deviceService.getImei(),
          numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
          memoryRam: this.deviceService.getNumberMemoryRam(),
          videoCardVendor: this.deviceService.getVideoCardVendor(),
          videoCardRenderer: this.deviceService.getVideoCardRenderer(),
          country: await this.deviceService.getCountry(),
          city: await this.deviceService.getCity(),
          isp: await this.deviceService.getISP(),
          macAddress: this.deviceService.getMacAddress(),
          navegadorVersion: this.deviceService.getNavegadorVersion(),
          navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
          engineName: this.deviceService.getEngineName(),
          engineVersion: this.deviceService.getEngineVersion(),
          isEnrolled : this.hasEnrollment == 0 ? true: false
        }
      };
      this.utilsService.addEventToTagManagerAndFirebase('Envío de nueva contraseña', 'Cambio de contraseña', 'click', null);
      const changePassword = await this.accountService.changePassword(enrollmentInformation, this.identifier);
      if (changePassword.code === USER_CREATION_SUCCESSFUL) {
        this.successEnroll();
      } else {
        await this.openGenericError();
        this.formLoading = false;
      }
      this.storage.remove(ENROLL_DOCUMENT_NUMBER);
    } catch (errBody) {
      try {
        let code = 0;
        let errBodyJson = "";
        try{
          errBodyJson = JSON.parse(errBody.error.messages[0]).Message;
          code = JSON.parse(errBody.error.messages[0]).Code;
        }catch(e){
          try{
            errBodyJson = JSON.parse(errBody.messages[0]).Message;
            code = JSON.parse(errBody.messages[0]).Code;
          }catch(e2){
            try {
              code = errBody.error.code || errBody.code;
              errBodyJson = errBody.error.message || errBody.message;
            } catch (e) { console.error('Cannot parse error code'); }
          }
        }
        this.sendDataTraceability(null, null, code, errBodyJson, 'STEP_CHANGE_PASSWORD'); // STEP_REGISTER_PASSWORD

        if (code == 620){
          await this.showModalEndSession();
        } else if(code == 604)  {
          await this.showModalBackList()
        } else if (ENROLLMENT_ERROR_MESSAGES[code]) {
          const alertMessage = await this.alertService.openErrorAlert(this.url, false, true, 'Error', ENROLLMENT_ERROR_MESSAGES[code]);
          if (alertMessage) { alertMessage.present(); }
        } else {
          this.openGenericError();
        }
      } catch (e) {
        this.lastStepErrorHandler(errBody);
      }
    }
    this.formLoading = false;
  }

  get documentType() { return this.enrollmentForm.controls.DocumentType; }
  get documentNumber() { return this.enrollmentForm.controls.DocumentNumber; }
  get otpCode1() { return this.enrollmentForm.controls.OtpCode1; }
  get otpCode2() { return this.enrollmentForm.controls.OtpCode2; }
  get otpCode3() { return this.enrollmentForm.controls.OtpCode3; }
  get otpCode4() { return this.enrollmentForm.controls.OtpCode4; }
  get otpCode5() { return this.enrollmentForm.controls.OtpCode5; }
  get otpCode6() { return this.enrollmentForm.controls.OtpCode6; }
  get cardNumber() {return this.enrollmentForm.controls.CardNumber;}
  get CodeSMS() { return this.otp.map(x => x.toString()).join('') }
  get pinCode() { return this.fakePassword('pinForm');}
  get email() { return this.enrollmentForm.controls.Email; }
  get dataProtectionCheck() { return this.enrollmentForm.controls.DataProtectionCheck; }
  get termsAndConditionsCheck() { return this.enrollmentForm.controls.TermsAndConditionsCheck; }
  get isOtpStepValid() { return (this.otpCode1.valid && this.otpCode2.valid && this.otpCode3.valid && this.otpCode4.valid && this.otpCode5.valid && this.otpCode6.valid) && this.maxTime !== 0; }
  get isPinCodeValid() { return this.fakePassword('pinForm').valid && this.cardNumber.valid; }
  get maskedNumber() { return '*** *** ' + this.contactInformation.mobileNumber.slice(-LAST_DIGITS_LENGTH); }
  get isDocumentStepValid() { return (this.documentType.valid && this.documentNumber.valid); }
  get globalStepDescription() {
    switch (this.actualGlobalStep) {
      case 1:
        return this.firstDesktopStepMessage;
      case 2:
        return this.isMobileWidth ? this.firstDesktopStepMessage : this.secondDesktopStepMessage;
      case 3:
        return this.secondDesktopStepMessage;
    }
  }

  get emailUnchanged() {
    return this.email.value && this.email.untouched && this.contactInformation.mail === this.email.value;
  }

  get isPasswordStepValid() {
    const emailCheck = this.email.valid || this.emailUnchanged;
    return this.fakePassword('passwordForm').valid && this.fakePassword('confirmPasswordForm').valid && (emailCheck && this.aceptaTerminos && this.ambasConinciden && this.numerosContinuos);
  }

  get isEnrollmentForm() {
    return this.formType === ENROLLMENT_FORM;
  }

  get showTimerLoading() {
    return this.showTimerSection && this.maxTime === OTP_MAX_TIME;
  }

  get isFirstPasswordKeyboard() {
    return this.currentKeyboard === 'passwordForm';
  }

  public keyboardId(currentForm: string) { return this[currentForm].controls.keyboardId; }
  public fakePassword(currentForm: string) { return this[currentForm].controls.fakePassword; }
  public getKeyboardForm(currentForm: string) { return this[currentForm]; }

  public closeKeyboard() {
    this.errorMessage = '';
    this.isKeyboardLoaded.hasErrors = false;
  }

  public changeFakePassword($event: any) {
    if ($event.target.value.length === 6 && this["confirmPasswordForm"].controls['fakePassword'].value.length === 6) {
      if($event.target.value != this["confirmPasswordForm"].controls['fakePassword'].value){
        this.passwordError = true;
      }
    } else {
      this.passwordError = false;
    }
  }

  public changeFakePasswordCompare($event: any) {
    if ($event.target.value.length === 6 && this["passwordForm"].controls['fakePassword'].value.length === 6) {
      if(this["passwordForm"].controls['fakePassword'].value != $event.target.value){
        this.passwordError = true;
      }
    } else {
      this.passwordError = false;
    }
  }

  public PasswordConsecutiveNumber($event:any) {
    if($event.target.value.length === 6 && $event.target.value  ){
    }
  }

  public async focusKeyboard(currentForm: string) {
    this.getTokenizedKeyboard('passwordForm');
  }

  public async focusKeyboardConfirm(currentForm: string) {
    this.getTokenizedKeyboard('confirmPasswordForm');
  }

  public async getTokenizedKeyboard(currentForm: string) {
    this.isKeyboardLoaded.isLoaded = false;
    this.isKeyboardLoaded.hasErrors = false;
    try {
      this.tokenizedKeyboard = await this.deviceService.getTokenizedKeyboard(this.identifier);
      this.keyboardId(currentForm).patchValue('' + this.tokenizedKeyboard.id);
    } catch (err) {
      this.isKeyboardLoaded.hasErrors = true;
      const alertMessage = await this.alertService.openErrorAlert(this.url, true);
      if (alertMessage) {
        alertMessage.present();
        await alertMessage.onDidDismiss();
      }
    }
    this.isKeyboardLoaded.isLoaded = true;
  }

  public async createDevice(force = false) {
    this.isDataLoaded.isLoaded = false;
    this.isDataLoaded.hasErrors = false;
    try {
      const savedIdentifier = await this.storage.get(SAVED_IDENTIFIER_KEY);
      this.identifier = (savedIdentifier && !force) ? savedIdentifier : await this.deviceService.createIdentifier();
      this.storage.set(SAVED_IDENTIFIER_KEY, this.identifier);

    } catch (err) {
      this.isDataLoaded.hasErrors = true;
      const alertMessage = await this.alertService.openErrorAlert(this.url, true);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.createDevice(); }
      }
    }
    this.isDataLoaded.isLoaded = true;
  }

  public cleanPassword(currentForm: string) {
    for (let index = 1; index <= LOGIN_PASSWORD_MAX_LENGTH; index++) {
      this[currentForm].patchValue({
        [`${this.hashCodeLabel}${index}`]: '',
      });
    }
    this.fakePassword(currentForm).setValue('');
  }

  async showAlreadyEnrolledModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );

    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ALREADY_ENROLLED_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      } else if (button.data === 'secondaryButtonPressed') {
        return this.router.navigateByUrl(`/${PASSWORD_RESET_ROUTE}`);
      }
    });
  }

  async showAlreadyEnrolledBlockModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const modal = await this.modalCtrl.create({
      component:EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ALREADY_ENROLLED_BLOCK_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      } else if (button.data === 'secondaryButtonPressed') {
        return this.router.navigateByUrl(`/${PASSWORD_RESET_ROUTE}`);
      }
    });
  }

  async showNotRipleyUserModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_NO_PRODUCTS.info1;
    informAditional[1] = ENROLL_MODAL_NO_PRODUCTS.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_NO_PRODUCTS.event,
        ENROLL_MODAL_NO_PRODUCTS.variable,
        ENROLL_MODAL_NO_PRODUCTS.action,
        informAditional
    );
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: NOT_RIPLEY_USER_PROPS
    });
    await modal.present();
    modal.onDidDismiss()
        .then((button) => button.data === 'primaryButtonPressed' && this.openRipleyBankSite(null, RIPLEY_BANK_CARD_SITE));
  }

  async showModalEndSession() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_SESSION_EXPIRED_PROPS,
      backdropDismiss: false
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        window.location.reload();
      }
    });
  }

  async showModalBackList() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ENROLLMENT_BACKLIST_PASSWORD
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      // if (button.data === 'primaryButtonPressed') {
      //  return this.router.navigateByUrl(`/${ENROLLMENT_ROUTE}`);
      // }
    });
  }

  async showBlockPinModal() {
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ACCESS_BLOCK_PINPAN_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if (button.data === 'primaryButtonPressed') {
        return this.router.navigateByUrl(`/${LOGIN_ROUTE}`)
      }
    });
  }

  async showInvalidPinModal() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: ACCESS_INVALID_PINPAN_PROPS
    });
    await modal.present();
  }

  private async showInvalidPanModal() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: NODATES_PINPAN_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          break;
        case 'secondaryButtonPressed':
          return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      }
    });
  }

  private async showInvalidCard() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: NODATES_CARD
    });
    await modal.present();
  }

  public taggeoPan($event: any) {
    if ($event.target.value.length === 16 ) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PAN.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PAN.ACTION,
        category: 'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PAN.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_INPUT_PAN.EVENT, eventGA4);
    }
  }

  public taggeoPin($event: any) {
    if ($event.target.value.length == 4 ) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PIN.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PIN.ACTION,
        category:  'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_INPUT_PIN.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_INPUT_PIN.EVENT, eventGA4);
    }
  }

  public taggeoEmail($event: any) {
    if ($event.target.value.length > 0  ) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_INPUT_EMAIL_6DIGITS.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_INPUT_EMAIL_6DIGITS.ACTION,
        category:  'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_INPUT_EMAIL_6DIGITS.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_INPUT_EMAIL_6DIGITS.EVENT, eventGA4);

    }
  }


  public taggeoDni($event: any) {
    if($event.target.value.length === 8){
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_INPUT.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_INPUT.ACTION,
        category: 'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_INPUT.STEP,
        subcategory:this.globalStepTitle,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_INPUT.EVENT, eventGA4);
    }
  }

  isThisStepActive(step: number) {
    return this.actualStep === step;
  }

  private updateGlobalCount() {
    if (this.isMobileWidth) {
      this.totalSteps = 3;
      this.actualGlobalStep = this.actualStep;
    } else {
      this.totalSteps = 2;
      this.actualGlobalStep = this.actualStep === 3 ? 2 : 1;
    }
  }

  get isMobileWidth() {
    return window.innerWidth < MOBILE_STEPS_WIDTH;
  }

  setShowStep(step: number) {
    if (!this.isMobileWidth) {
      this.showTypeStepNoNumber = !this.isThisStepActive(3) && (this.validateScreenTypeNONumber);
      this.showTypeStep = !this.isThisStepActive(3) && (this.validateScreenType);
      this.showOtpStep = !this.isThisStepActive(3) && (this.validateOTPorPIN==1) && (this.selectTypeStep ==1) ;
      this.ShowBioStep =  !this.isThisStepActive(3) && (this.validateOTPorPIN==1) && (this.selectTypeStep ==2) ;
      this.showPinPanStep = !this.isThisStepActive(3) && (this.validateOTPorPIN===2);
      this.showDocumentStep =  !this.isThisStepActive(3);
    } else {
      switch (step) {
        case 1:
          this.showDocumentStep = !this.isThisStepActive(3) && this.isThisStepActive(1);
          this.showOtpStep = false;
          this.showPinPanStep = false;
          this.showTypeStep = false;
          this.ShowBioStep = false;
          this.showTypeStepNoNumber = false;
          break;
        case 2:
          this.showDocumentStep = false;
          this.showTypeStep = !this.isThisStepActive(3) && this.isThisStepActive(2) && (this.validateScreenType);
          this.showTypeStepNoNumber = !this.isThisStepActive(3) && this.isThisStepActive(2) && (this.validateScreenTypeNONumber);
          this.showOtpStep = !this.isThisStepActive(3) && this.isThisStepActive(2) && (this.validateOTPorPIN==3);
          this.showPinPanStep = !this.isThisStepActive(3) && this.isThisStepActive(2) && (this.validateOTPorPIN===2);
          this.ShowBioStep = !this.isThisStepActive(3) && this.isThisStepActive(2) && (this.validateOTPorPIN===4);;
          break;
        case 3:
          this.showTypeStep = false;
          this.showTypeStepNoNumber = false;
          this.showOtpStep = false;
          this.showPinPanStep = false;
          this.ShowBioStep = false;
      }
    }
  }

  async setDocumentToDownload(documentName: string) {
    try {
      const response = documentName === 'dataProtection' ?
          await this.enrollmentService.getEnrollmentDataProtection() :
          await this.enrollmentService.getEnrollmentTermsAndConditions();
      this.documentLink = response.value;
      this.downloadButton.nativeElement.click();
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, false, null, GET_DOCUMENT_ERROR);
      if (alertMessage) { alertMessage.present(); }
    }
  }

  openSelectedDocument() {
    if (this.documentLink) { window.open(this.documentLink, '_blank'); }
  }

  openRipleyBankSite(redirect = null, site = null) {

    if (redirect !== null && site === RIPLEY_BANK_PUBLIC_SITE_URL) {
      this.router.navigateByUrl(redirect);
    }
    if (site) {
      window.open(site);
    }
  }

  async showNotEnrolledUserModal() {
    await this.createPrimaryRedirectModal(SHOW_NOT_ENROLLED_USER_MODAL_PROP, 'form-modal enroll-modal small', `/${ENROLLMENT_ROUTE}`, false);
  }

  async showEnrollmentExitModal() {
    const eventGA42 = {
      product: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.ACTION,
      category:'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.EVENT, eventGA42);
    this.CANCEL_MODAL();
  }

  async ExitModalGA4_1() {
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC.ACTION,
      category:  'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC.STEP,
      subcategory:this.globalStepTitle,
      
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC.EVENT, eventGA4);
    this.CANCEL_MODAL();
    const eventGA42 = {
      product: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.ACTION,
      category: 'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.EVENT, eventGA42);
  }

 cancelpassw6(){
  const eventGA4 = {
    product: ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CANCEL_6DIGITS.PRODUCT,
    action: ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CANCEL_6DIGITS.ACTION,
    category:  'enrolamiento',
    step: ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CANCEL_6DIGITS.STEP,
    subcategory:this.globalStepTitle,
    auth_method: this.selector,
  };
  this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BUTTON_CANCEL_6DIGITS.EVENT, eventGA4);

    this.showEnrollmentExitModal();
  }

  cancelpinpan(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC_PINPAN.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC_PINPAN.ACTION,
      category:  'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC_PINPAN.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_CANC_PINPAN.EVENT, eventGA4);
    this.showEnrollmentExitModal();
  }

  async showNotPhoneModal() {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: NOT_PHONE_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      }
    });
  }

  async showItIsNotMyNumberModal() {
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_NOT_NUMBER.event,
        ENROLL_NOT_NUMBER.variable,
        ENROLL_NOT_NUMBER.action
    );
    const props = NOT_MY_NUMBER_PROPS;
    props.title = `¿El ${this.maskedNumber} no es tu número?`;
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: props
    });
    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_NOT_NUMBER.info1;
    informAditional[1] = ENROLL_MODAL_NOT_NUMBER.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_NOT_NUMBER.event,
        ENROLL_MODAL_NOT_NUMBER.variable,
        ENROLL_MODAL_NOT_NUMBER.action,
        informAditional
    );
    await modal.present();
    modal.onDidDismiss().then((button) => {
      return button.data === 'primaryButtonPressed' && this.openRipleyBankSite(null, RIPLEY_BANK_PUBLIC_SITE_URL);
    });
  }

  async showCreatedUsermodal(this_) {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'enrolled-modal',
      componentProps: CREATED_USER_PROPS
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          return this.pushGtmClickRiplayBank();
        case 'secondaryButtonPressed':
          return this.pushGtmClickRipleyUrl();
      }
    });
  }

  sendDataTraceability(product: string, productStatus: string, errorCode: number, errorMessage: string, step: string) {
    const trace: ITraceability = {
      traceId: UtilsService.getUUIDV4(), // Por definir
      channelType: CHANNEL,
      channelCode: this.utilsService.getChannelCode(),
      documentType: this.documentType.value,
      documentNumber: this.documentNumber.value,
      cellPhoneNumber: this.contactInformation == null ? '' : this.contactInformation.mobileNumber,
      email: this.contactInformation == null ? '' : this.contactInformation.mail,
      product: product, // Por definir
      productStatus: productStatus, // Por definir
      errorCode: errorCode, // Por definir
      errorMessage: errorMessage, // Por definir
      step: step, // Por definir
      registerDate: new Date() // Por definir
    };
    this.traceabilityService.traceablityEnrollment(trace);
  }

  async showPasswordChangedModal() {
    await this.createPrimaryRedirectModal(SHOW_PASSWORD_CHANGED_MODAL_PROP, 'form-modal enroll-modal small', `/${LOGIN_ROUTE}`, true);
  }

  async createPrimaryRedirectModal(componentProps: IComponentOneButton, cssClass: string, primaryRedirect: string, secondaryRedirect: boolean) {
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: componentProps
    });
    await modal.present();
    modal.onDidDismiss().then(async (button) => {
      return await this.externalFormAndRipleyBankRedirect(button.data, primaryRedirect);
    });
  }

  private async externalFormAndRipleyBankRedirect(pressedButton: string, primaryRedirect = null) {
    switch (pressedButton) {
      case 'primaryButtonPressed':
        if (primaryRedirect === `/${LOGIN_ROUTE}`) {
        }
        return await this.router.navigateByUrl(primaryRedirect);
    }
  }

  private async openGenericError() {
    const alertMessage = await this.alertService.openErrorAlert(this.url, true);
    if (alertMessage) { alertMessage.present(); }
  }

  private setActualStep(actualStep: number) {
    this.actualStep = actualStep;
    this.setShowStep(actualStep);
    this.updateGlobalCount();
  }

  private getPasswordHash(currentForm: string, length: number): string[] {
    const password = [];
    for (let index = 0; index < length; index++) {
      password.push(this.tokenizedKeyboard.keys.find(p => p.numericalValue === Number(this[currentForm]
          .controls['fakePassword'].value.charAt(index))).hashCode);
    }
    return password;
  }

  private setOtpTimer() {
    this.showTimerSection = true;
    if (this.timer) { clearTimeout(this.timer); }
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.utilsService.addEventToTagManagerAndFirebase(ENROLL_TIMEOUT_SMS.event, ENROLL_TIMEOUT_SMS.variable, ENROLL_TIMEOUT_SMS.action);
        this.showTimerSection = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.maxTimeString = String(this.maxTime).length==1 ? "0"+  String(this.maxTime) : String(this.maxTime);
        this.setOtpTimer();
      } else {
        this.maxTimeString = "00";
        this.smsDisable();
        this.showTimeInvalidModal();
        this.enabledResendOTP = true;
        const eventGA4 = {
          product: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_HERE.PRODUCT,
          action: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_HERE.ACTION,
          category:  'enrolamiento',
          step: ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_HERE.STEP,
          subcategory:this.globalStepTitle,
          auth_method: this.selector,
        };
        this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_SMS_TIME_HERE.EVENT, eventGA4);
      }
    }, MILLISECONDS_TO_SECOND);
  }

  private async getAccountDocumentData() {
    return {
      documentType: this.documentType.value,
      documentNumber: this.documentNumber.value,
      monitorInputFront: {
        url : window.location.host,
        device : this.deviceService.getWildField1(),
        firmware : this.deviceService.getPlatformName(),
        docName : window.location.pathname,
        cpuAbi : '',
        finger : '0',
        mobileNumber : '',
        ip : (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier(), //window.navigator.userAgent,
        deviceVersion: this.deviceService.getDeviceVersion(),
        deviceId: this.deviceService.getDeviceId(),
        deviceName: this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice(),
        imei: this.deviceService.getImei(),
        numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
        memoryRam: this.deviceService.getNumberMemoryRam(),
        videoCardVendor: this.deviceService.getVideoCardVendor(),
        videoCardRenderer: this.deviceService.getVideoCardRenderer(),
        country: await this.deviceService.getCountry(),
        city: await this.deviceService.getCity(),
        isp: await this.deviceService.getISP(),
        macAddress: this.deviceService.getMacAddress(),
        navegadorVersion: this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion()
      }
    } as IUserIdentification;
  }

  private reloadComponent() {
    window.location.reload();
  }

  private async lastStepErrorHandler(error) {
    if (error && error.hasOwnProperty('code')) {
      switch (error.code) {
        case PASSWORDS_DONT_MATCH: {
          this.passwordError = true;
          return;
        }
        case SESSION_EXPIRED: {
          this.reloadComponent();
          return;
        }
      }
    }
    const message = error && error.hasOwnProperty('errorMessage') ? error.errorMessage : null;
    const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, message);
    if (alertMessage) {
      alertMessage.present();
    }
  }

  public goTo(url) {
    return this.router.navigateByUrl(url, { replaceUrl: true });
  }

  checkTerminosCondiciones(event) {
    this.aceptaTerminos = event.detail.checked;
    if (event.detail.checked) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_TERMS_6DIGITS.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_TERMS_6DIGITS.ACTION,
        category:  'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_TERMS_6DIGITS.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_TERMS_6DIGITS.EVENT, eventGA4);
    }
  }

  checkPoliticas(event){
    this.aceptaPolitica = event.detail.checked;
    if (event.detail.checked) {
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_POLITICS_6DIGITS.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_POLITICS_6DIGITS.ACTION,
        category:  'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_POLITICS_6DIGITS.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POLITICS_6DIGITS.EVENT, eventGA4);
    }
  }

  public pushGtmClickRiplayBank() {
    this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
  }

  public pushGtmClickRipleyUrl() {
    this.openRipleyBankSite(`/${LOGIN_ROUTE}`, RIPLEY_BANK_PUBLIC_SITE_URL);
  }

  public ingresar(){
    this.goTo(this.loginRoute);
    const eventGA4 = {
      path_destino:ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_BOTTON.PATH_DESTINO,
      product: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_BOTTON.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_BOTTON.ACTION,
      category:'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_BOTTON.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_BOTTON.EVENT, eventGA4);

  }

  public goBack() {
    if(this.actualStep == 1){
      this.goTo(this.loginRoute);
      this.validateScreenTypeNONumber=false;
      this.validateScreenType= false;
      clearTimeout(this.timer);
      return;

    }
    else if(this.actualStep == 2){
      if(this.showTypeStep == true){
        this.setupFormPage();
        return;
      }
      if(this.showTypeStepNoNumber == true){
        this.setupFormPage();
        return;
      }
      if( this.selectTypeStep == 1){
        this.pindisable = true;
        this.facialdisable = true;
        this.smsdisable = true;
        this.smsInvalid = false;
        if(this.documentNumberLength == 9){
          this.showValidateCE();
          this.bottonAvailableSMS.isLoaded = false;
          this.bottonAvailablePin.isLoaded = false;
          this.screenbottonview();
          this.smsInvalid = false;
          clearTimeout(this.timer);
        }
        this.resetSMS();
        this.smsActive[0]=false;
        this.smsActive[1]=false;
        this.smsActive[2]=false;
        this.smsActive[3]=false;
        this.smsActive[4]=false;
        this.smsActive[5]=false;
        this.smsInvalid = false;
        this.showTypeStep = true;
        this.validateScreenType= true;
        this.validateOTPorPIN = 0;
        this.selectTypeStep = 0;
        this.bottonAvailableSMS.isLoaded = false;
        this.bottonAvailablePin.isLoaded = false;
        this.showOtpStep = false;
        clearTimeout(this.timer);
      }

      if (this.selectTypeStep == 2) {
        this.pindisable = true;
        this.facialdisable = true;
        this.smsdisable = true;
        this.showTypeStep = true;
        this.validateScreenType= true;
        this.selectTypeStep = 0;
        clearTimeout(this.timer);
        return
      }
      if( this.showPinPanStep == true){

        this.pindisable = true;
        this.facialdisable = true;
        this.smsdisable = true;
        if(this.documentNumberLength == 9){
          if(!this.contactInformation.mobileNumber){
            this.bottonAvailablePin.isLoaded = false;
            clearTimeout(this.timer);
            this.screenbottonview();
            this.ShowPinPan();
          }
          else{
            clearTimeout(this.timer);
            this.showValidateCE();
            this.bottonAvailableSMS.isLoaded = false;
            this.bottonAvailablePin.isLoaded = false;
            this.screenbottonview();
          }
        }
        if(!this.contactInformation.mobileNumber){
          this.showTypeStepNoNumber = true;
          this.validateScreenTypeNONumber=true;
          this.showPinPanStep = false;
          this.validateOTPorPIN = 0;
          this.bottonAvailableSMS.isLoaded = false;
          this.bottonAvailablePin.isLoaded = false;
          clearTimeout(this.timer);
        }
        else{
          this.showTypeStep = true;
          this.validateScreenType= true;
          this.showPinPanStep = false;
          this.validateOTPorPIN = 0;
          this.bottonAvailablePin.isLoaded=false;
          this.bottonAvailableSMS.isLoaded = false;
          clearTimeout(this.timer);
        }
      }
    }
    if(this.actualStep == 3){
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.ACTION,
        category:'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CANCEL_POP_RETURN.EVENT, eventGA4);
      return this.CANCEL_MODAL();
    }
  }

  ClickEnrollmentSMS(){
    this.selector = 'codigo sms';
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_SMS.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_SMS.ACTION,
        category: 'enrolamiento',
        step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_SMS.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_SMS.EVENT, eventGA4);
    this.smsEnable();
    this.smsDisable();
    this.smsActive[0]=false;
    this.smsActive[1]=false;
    this.smsActive[2]=false;
    this.smsActive[3]=false;
    this.smsActive[4]=false;
    this.smsActive[5]=false;
    this.smsInvalid = false;
    this.pindisable = false;
    this.facialdisable = false;
    this.smsdisable = false;
    this.bottonAvailableSMS.isLoaded = true;
    this.maxTimeString = "60";
    clearTimeout(this.timer);
    this.SendOTP(this.CallBackSendSMS);
    this.setActualStep(2);
    //this.ShowSms();

  }

  changeiconFacial(event){
    event.target.classList.remove('font-icon-card-purple');
  }

  verpassword(numero, event){
    if (numero === 2) {
      this.verPasswordDos = this.verPasswordDos ? false : true;
      if( this.verPasswordDos==true){
        event.target.classList.remove('content-icono-ver-c');
        event.target.classList.add('content-icono-nover-c');
      }
      else
      {
        event.target.classList.remove('content-icono-nover-c');
        event.target.classList.add('content-icono-ver-c');
      }
    }
    if (numero === 1) {
      this.verPasswordUno = this.verPasswordUno ? false : true;
      if( this.verPasswordUno==true){
        event.target.classList.remove('content-icono-ver-c');
        event.target.classList.add('content-icono-nover-c');
      }
      else
      {
        event.target.classList.remove('content-icono-nover-c');
        event.target.classList.add('content-icono-ver-c');
      }
    }
  }

  ValidateTaggueo(e){
    const largo = e.target.value;
    if(largo.length == 1){
      const eventGA4 = {
        product: ENROLL_GA4_CONSTANTS.CREATE_INPUT_SMS.PRODUCT,
        action: ENROLL_GA4_CONSTANTS.CREATE_INPUT_SMS.ACTION,
        category:  ENROLL_GA4_CONSTANTS.CREATE_INPUT_SMS.CATEGORY,
        step: ENROLL_GA4_CONSTANTS.CREATE_INPUT_SMS.STEP,
        subcategory:this.globalStepTitle,
        auth_method: this.selector,
      };
      this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_INPUT_SMS.EVENT, eventGA4);
    }
  }

  isAllDigits(key: string){
    return /^\d+$/.test(key);
  }

  minimunOfDifferentDigits(key: string, min: number){
    var distinctDigits = key.toString().split('').map(Number).filter((item, i, ar) => ar.indexOf(item) === i);

    if (distinctDigits.length < min){
      return false;
    }
    return true;
  }

  isConsecutive(key: string){
    if (key.length < 3){
      return true;
    }

    let keyConsecutive = key.substring(0, 1);
    let num = parseInt(keyConsecutive);

    for (let i = 1; i < key.length; i++)
    {
      num++;
      keyConsecutive += (num).toString();
    }

    if (keyConsecutive == key){
      return true;
    }

    keyConsecutive = key.substring(0, 1);
    num = parseInt(keyConsecutive);

    for (let i = 1; i < key.length; i++)
    {
      num--;
      keyConsecutive += (num).toString();
    }

    if (keyConsecutive == key){
      return true;
    }

    return false;
  }

  isAllConsecutivePairs(key: string){

  if (key.length != 6){
    return true;
  }

  let digitCompare = parseInt(key.substring(0, 2));
  let digit;

  for (let i = 1; i < key.length/2; i++)
  {
    digit = parseInt(key.substring(i * 2, (i * 2)+2));
    if((digitCompare == (digit - 1)) || (digitCompare == (digit + 1))) {
      return true;
    }
    digitCompare = digit;
  }

  return false;
}


  isAllTheSame(key: string){
    var firstValue = key.substring(0, 1);
    let value;

    for (let i = 0; i < key.length; i++)
    {
      value += firstValue;
    }

    if (value != key){
      return false;
    }

    return true;
  }

  changeValidateFn(e) {
    const valor =  e.target.value;
    const regex = /^[0-9]*(.)\1/;

    this.numerosContinuos = true;

    if (!this.isAllDigits(valor)){
      this.soloNumeros = false;
    }

    if (!this.minimunOfDifferentDigits(valor, 4)){
      this.numerosContinuos = false;
    }

    if (this.isConsecutive(valor)){
      this.numerosContinuos = false;
    }

    if (this.isAllConsecutivePairs(valor)){
      this.numerosContinuos = false;
    }

    if (this.isAllTheSame(valor)){
      this.numerosContinuos = false;
    }

    this.passwordUno = valor;
    if(valor.length  >= 6) {

      this.mayorSeisCaracteres = true;
    } else {
      this.mayorSeisCaracteres = false;
    }

    if( this.mayorSeisCaracteres) {
      this.soloNumeros = true;
    } else {

      this.soloNumeros = false;
    }
    if (this.mayorSeisCaracteres && this.passwordDos === this.passwordUno) {
      this.ambasConinciden = true;
    } else {
      this.ambasConinciden = false;
    }

    if (this.mayorSeisCaracteres && this.soloNumeros) {
      var str1 = new String( "123456,000000,111111,222222,333333,444444,555555,666666,777777,888888,999999,654321,123123,321321,121212,212121,112233,111222,111122,111112,131313,141414,151515,161616,171717,181818,191919,101010,332211,123412,123451,651234,502025" );
      var index = str1.indexOf(valor);
      if(index==-1){
        this.numerosListaNegra=true;
      }else{
        this.numerosListaNegra=false;
      }
    }

    if (this.ambasConinciden && this.numerosContinuos && this.numerosListaNegra && this.soloNumeros && this.mayorSeisCaracteres && this.aceptaTerminos) {
      this.validacionClave = true;
    }else{
      this.validacionClave = false;
    }
  }

  changeValidateCoincidenciaFn(e) {
    const valor =  e.target.value;
    this.passwordDos = valor;
    if (this.passwordDos === this.passwordUno) {
      this.ambasConinciden = true;
    } else {
      this.ambasConinciden = false;
    }
    this.actualizarEstadoBoton();
  }

  actualizarEstadoBoton(){
    if (this.ambasConinciden && this.numerosContinuos && this.numerosListaNegra && this.soloNumeros && this.mayorSeisCaracteres && this.aceptaTerminos){
      this.validacionClave = true;
    }else  this.validacionClave = false;
  }

  screenbottonview(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_SELECC_PAGE.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_SELECC_PAGE.ACTION,
      category:'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_SELECC_PAGE.STEP,
      subcategory:this.globalStepTitle,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_SELECC_PAGE.EVENT, eventGA4);
  }


  otroMetodo(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_METHOD_SMS.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_METHOD_SMS.ACTION,
      category: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_METHOD_SMS.CATEGORY,
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_METHOD_SMS.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_METHOD_SMS.EVENT, eventGA4);
    this.pindisable = true;
    this.smsdisable = true;
    this.facialdisable = true;
    this.smsActive[0]=false;
    this.smsActive[1]=false;
    this.smsActive[2]=false;
    this.smsActive[3]=false;
    this.smsActive[4]=false;
    this.smsActive[5]=false;
    if(this.documentNumberLength == 9){
      this.showValidateCE();
      this.screenbottonview();
      clearTimeout(this.timer);
    }
    this.resetSMS();
    this.smsInvalid = false;
    this.showTypeStep = true;
    this.validateScreenType= true;
    this.validateOTPorPIN = 0;
    this.selectTypeStep = 0;
    this.bottonAvailableSMS.isLoaded = false;
    this.showOtpStep = false;
    clearTimeout(this.timer);
  }

  noNumber(){
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_NONUMB_SMS.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_NONUMB_SMS.ACTION,
      category: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_NONUMB_SMS.CATEGORY,
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_NONUMB_SMS.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_NONUMB_SMS.EVENT, eventGA4);
  
  this.showDataInvalidModal();
  const eventGA42 = {
    product: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER.PRODUCT,
    action: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER.ACTION,
    category: 'enrolamiento',
    step: ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER.STEP,
    subcategory:this.globalStepTitle,
  };
  this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_POP_NO_NUMBER.EVENT, eventGA42);
  }


  successEnroll(){
    this.creatPass= false;
    this.successfullEnroll = true;
    this.actualStep = 3;
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_PAGE.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_PAGE.ACTION,
      category:'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_PAGE.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_SUCCESS_PAGE.EVENT, eventGA4);
  }

  async CANCEL_MODAL(){

    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const props = ENROLLMENT_EXIT_PROPS;
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: props
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          break;
        case 'secondaryButtonPressed':
          const eventGA4 = {
            path_destino:ENROLL_GA4_CONSTANTS.CANCEL_POP_YES.PATH_DESTINO,
            product: ENROLL_GA4_CONSTANTS.CANCEL_POP_YES.PRODUCT,
            action: ENROLL_GA4_CONSTANTS.CANCEL_POP_YES.ACTION,
            category:'enrolamiento',
            step: ENROLL_GA4_CONSTANTS.CANCEL_POP_YES.STEP,
            subcategory:this.globalStepTitle,
          };
          this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CANCEL_POP_YES.EVENT, eventGA4);
          return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);

      }
    });
  }

  async GENERIC_MODAL(){

    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const props = GENERIC_ERROR_ENROLL;
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: props
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          break;
        case 'secondaryButtonPressed':
          return this.router.navigateByUrl(`/${LOGIN_ROUTE}`);
      }
    });
  }

  async NOVALIDATE_MODAL(){

    const informAditional: any[] = [];
    informAditional[0] = ENROLL_MODAL_PRODUCTS_FOUND.info1;
    informAditional[1] = ENROLL_MODAL_PRODUCTS_FOUND.info2;
    this.utilsService.addEventToTagManagerAndFirebase(
        ENROLL_MODAL_PRODUCTS_FOUND.event,
        ENROLL_MODAL_PRODUCTS_FOUND.variable,
        ENROLL_MODAL_PRODUCTS_FOUND.action,
        informAditional
    );
    const props = ONOFF_ERROR_ENROLL;
    const modal = await this.modalCtrl.create({
      component: EnrollModalComponent,
      cssClass: 'form-modal enroll-modal small',
      componentProps: props
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      switch (button.data) {
        case 'primaryButtonPressed':
          break;
      }
    });
  }


  async getOnOff(){
    this.isOnBiometria = false;
    this.isOnPinPan = false;
    this.isOnSMS = false;
    this.isOnPinPanSMS = false;

    const onOffParams: IOnOffParams = {
      documentType: this.documentType.value == "C" ? "DNI" : "CE",
      processType: this.formType == "enrollment" ? "Enrollment" : "ChangePassword"
    };

    const response = await this.onOff.getOnOffInformation(onOffParams)

    if (response.biometria == 1){
      this.isOnBiometria = true;
    }
    if (response.pinPan == 1){
      this.isOnPinPan = true;
    }
    if (response.sms == 1 && this.contactInformation.mobileNumber != ""){
      this.isOnSMS = true;
    }
    if (response.smsPan == 1 && this.contactInformation.mobileNumber != ""){
      this.isOnPinPanSMS = true;
    }
    if(!this.isOnBiometria && !this.isOnPinPan && !this.isOnSMS && !this.isOnPinPanSMS){
      return false;
    }else{
      return true;
    }
  }



  public resetSMS(){
    this.otpCode1.reset();
    this.otpCode2.reset();
    this.otpCode3.reset();
    this.otpCode4.reset();
    this.otpCode5.reset();
    this.otpCode6.reset();
  }

  emailView(){
    if(this.globalStepTitle == 'Crear clave'){
      return true;
    }
    else {
      return  true;
    }
  }

  smsEnable(){
    this.enrollmentForm.controls["OtpCode1"].enable();
    this.enrollmentForm.controls["OtpCode2"].enable();
    this.enrollmentForm.controls["OtpCode3"].enable();
    this.enrollmentForm.controls["OtpCode4"].enable();
    this.enrollmentForm.controls["OtpCode5"].enable();
    this.enrollmentForm.controls["OtpCode6"].enable();
  }

  smsDisable(){
    this.enrollmentForm.controls["OtpCode1"].disable()
    this.enrollmentForm.controls["OtpCode2"].disable();
    this.enrollmentForm.controls["OtpCode3"].disable();
    this.enrollmentForm.controls["OtpCode4"].disable();
    this.enrollmentForm.controls["OtpCode5"].disable();
    this.enrollmentForm.controls["OtpCode6"].disable();
  }

  smsCleanValue(){
    this.enrollmentForm.controls["OtpCode1"].setValue("");
    this.enrollmentForm.controls["OtpCode2"].setValue("");
    this.enrollmentForm.controls["OtpCode3"].setValue("");
    this.enrollmentForm.controls["OtpCode4"].setValue("");
    this.enrollmentForm.controls["OtpCode5"].setValue("");
    this.enrollmentForm.controls["OtpCode6"].setValue("");
  }

  facialweb(){

  }

  public async ShowValidateBiometric() {
    try {
      this.originEnrollment = "BIOMETRIA";
      this.currentDocumentNumber = this.documentNumber.value;
      this.currentDocumentType = this.documentType.value;
      const bio: IBiometriaAccount = {
        userId: this.documentNumber.value,
        ip: (await (this.deviceService.getIP())).ip,
        deviceType: this.deviceService.getWildField1(),
        userType: "CI",
        event1: "InitPoliticas",
        localizacion: "00.00,00.00",
        userAgent: "xxxxxxxxx",
        country: "PERU",
        channel: "ENROLAMIENTO",
        operation: "RIPLEYENRC",
        os: "WEB",
        contextData: ""
      };
      const datatoken = await this.enrollmentService.checkUserBiometriaEnroll(bio);
      var that = this;
      this.widgetConfig = {
        widgetConfig: {
          header: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
          data: datatoken.data_token,
          signature: "X_8PP_o0A3Ez8i_wnlAjay4bz7lPna_0L0i_OCLkVT0",
          callback: function (result) {
            if (result.code == "00") {
              that.createPassword();
            } else {
              that.sendBiometricFailure(result.code);
              that.goBack();
            }
          }
        }
      };
      WidgetBR.widget.load(this.widgetConfig, document.getElementById("contenedor"));
    } catch (err) {
    }
  }
  public async sendBiometricFailure(biometricFailureCode?:string){
    const failure : IFailureMonitor={
      documentNumber: this.currentDocumentNumber,
      documentType: this.currentDocumentType,
      monitorInputFront: {
        biometricFailureCode: biometricFailureCode,
        url: window.location.host,
        device: this.deviceService.getWildField1(),
        firmware: this.deviceService.getPlatformName(),
        docName: window.location.pathname,
        cpuAbi: '',
        finger: '0',
        mobileNumber: '',
        ip: (await (this.deviceService.getIP())).ip,
        navegadorIdenticator: this.deviceService.getNavegadorIdentifier(),
        deviceVersion: this.deviceService.getDeviceVersion(),
        deviceId: this.deviceService.getDeviceId(),
        deviceName: this.deviceService.getDeviceManufacturer(),
        deviceModel: this.deviceService.getDevice(),
        imei: this.deviceService.getImei(),
        numberHardwareProcessor: this.deviceService.getNumberHardwareProcessor(),
        memoryRam: this.deviceService.getNumberMemoryRam(),
        videoCardVendor: this.deviceService.getVideoCardVendor(),
        videoCardRenderer: this.deviceService.getVideoCardRenderer(),
        country: await this.deviceService.getCountry(),
        city: await this.deviceService.getCity(),
        isp: await this.deviceService.getISP(),
        macAddress: this.deviceService.getMacAddress(),
        navegadorVersion: this.deviceService.getNavegadorVersion(),
        navegadorVersionMajor: this.deviceService.getNavegadorVersionMajor(),
        engineName: this.deviceService.getEngineName(),
        engineVersion: this.deviceService.getEngineVersion(),
        isEnrolled : this.hasEnrollment == 0 ? true: false
      }
    }
    await this.enrollmentService.biometriaEnrollFailureMonitor(failure);
  }
  addWidget(url: string) {
    try {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = false;
      document.body.appendChild(script);
    } catch (error) {

    }
  }
  async ClickEnrollmentFacial() {
    this.selector ="face id";
    //taggueo ga4 boton biometria
    const eventGA4 = {
      product: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_BIOMETRIA.PRODUCT,
      action: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_BIOMETRIA.ACTION,
      category:'enrolamiento',
      step: ENROLL_GA4_CONSTANTS.CREATE_BOTTON_BIOMETRIA.STEP,
      subcategory:this.globalStepTitle,
      auth_method: this.selector,
    };
    this.googleAnalyticsService.gtagPushEventWithId(ENROLL_GA4_CONSTANTS.CREATE_BOTTON_BIOMETRIA.EVENT, eventGA4);
    this.validateScreenType = false;
    this.validateScreenType = false;
    this.showOtpStep = false;
    this.pindisable = false;
    this.smsdisable = false;
    this.selectTypeStep = 2;
    this.validateOTPorPIN = 0;
    this.ShowBioStep = true;
    this.isMonitorBiometric = true;
    this.ShowValidateBiometric();
    this.setActualStep(2);

    //console.log(this.isNative);

    if(!this.isNative) {
      this.ShowValidateBiometric();
    }
    //console.log('clearTimeout')
    clearTimeout(this.timer);

  }
}
