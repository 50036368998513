export const PINNING_SSL_HANDSHAKE_ERROR_CODE = -2;

export const SERVER_NOT_FOUND_ERROR_CODE = -3;

export const TIMEOUT_ERROR_CODE = -4;

export const UNSOPOPPORTED_URL_ERROR_CODE = -5;

export const NO_CONNECTION_ERROR_CODE = -6;

export const GENERIC_PINNING_SSL_ERROR_MESSAGE = 'Existen problemas de comunicación segura con el servidor.';
