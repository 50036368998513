import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { R_PASS_INTRO_ROUTE } from '@common/constants/routes.constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-forgotten-rpass-modal',
  templateUrl: './forgotten-rpass-modal.component.html',
  styleUrls: ['./forgotten-rpass-modal.component.scss'],
})
export class ForgottenRpassModalComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {}

  public dismissModal() {
    return this.modalCtrl.dismiss();
  }
  public async goToActivationRpass() {
    await this.modalCtrl.dismiss();
    return this.router.navigateByUrl(R_PASS_INTRO_ROUTE, { state: { forgot: true } });
  }

}
