import { Component, Inject, Renderer2 } from '@angular/core';
import { environment } from '@environments/environment';
import * as FullStory from '@fullstory/browser';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { PdfService } from '@services/pdf/pdf.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { TAMPERED_DEVICE_PROPS } from '@common/constants/modal-props.constants';
import { HTTP } from '@ionic-native/http/ngx';
import {SwUpdateService} from '@services/sw-update/sw-update.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AcousticService } from './services/acoustic/acoustic.service';
import * as MCEPlugin from 'custom-plugins/co.acoustic.mobile.push.sdk/www/js/MCEPlugin';

declare const IRoot;
// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  statusBarBackgroundColor = '#F4F4F4';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private _renderer2: Renderer2, 
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private gtmService: GoogleTagManagerService,
    private inAppBrowser: InAppBrowser,
    private pdfService: PdfService,
    private notificationsService: NotificationsService,
    private modalCtrl: ModalController,
    private httpClient: HTTP,
    private swUpdateService: SwUpdateService,
    private router: Router,
    private acousticService: AcousticService,
  ) {
    this.detectTamperedDevices();
    this.initializeApp();
    this.initializeFullStory();
    this.swUpdateService.initializeSwUpdate();
  }

  public initializePinning() {
    this.platform.ready().then(async () => {
      await this.httpClient.setServerTrustMode('pinned').catch(
        () => console.error('Error al inicializar pinning')
      );
    });
  }

  public openLink = (link: string, target: string) => {
    return link.endsWith('.pdf') && this.pdfService.nativeAndroid ? this.pdfService.openPdf(link)
      : this.inAppBrowser.create(link, target, 'closebuttoncaption=Volver');
  }

  public initializeApp() {
    this.platform.ready().then((source) => {   
      this.addDynatrace();
      this.addGA4();
      this.addSynerise();
      if (source === 'cordova') {
        this.initializePinning();
        (window as any).nativeOpen = window.open;
        (window as any).open = this.openLink;
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        this.statusBar.backgroundColorByHexString(this.statusBarBackgroundColor);
        this.notificationsService.listenToTappedNotifications();
        this.notificationsService.grantPermission();
        this.initializeNotificationAcoustic();
      }
    });
  }

  public initializeFullStory() {
    FullStory.init({
      orgId: 'W494G',
      devMode: environment.ENV !== 'production'
    });
  }

  public tamperModalCheck = async () => {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'rooted-modal',
      componentProps: TAMPERED_DEVICE_PROPS
    });
    await modal.present();
  }

  public detectTamperedDevices() {
    this.platform.ready().then((source) => {
      if (source === 'cordova') {
        IRoot.isRooted(this.tamperSuccessCallback, this.tamperErrorCallback);
      }
    });
  }

  public tamperErrorCallback = async () => this.tamperModalCheck();

  public tamperSuccessCallback = async (tampered) => {
    if (tampered) {
      await this.tamperModalCheck();
    }
  }

  private addDynatrace() {
    const iosValidate = (this.platform.is('cordova') && this.platform.is('ios'));
    if (!iosValidate) {
      const scriptDtrace = this.doc.createElement('script');
      scriptDtrace.src = 'https://js-cdn.dynatrace.com/jstag/17b5f18726d/bf33417bmg/11d3750fb6d6587d_complete.js';
      scriptDtrace.crossOrigin = 'anonymous';
      const head = this.doc.getElementsByTagName('head')[0];
      head.appendChild(scriptDtrace);
    }
  }

  private addGA4() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.GA4_ID, { 'debug_mode': true });
      }
    });
    const scriptGA4 = this.doc.createElement('script');
    scriptGA4.id = 'ga4';
    scriptGA4.async = true;
    scriptGA4.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA4_ID;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(scriptGA4);
  }

  private addSynerise() {
    const scriptSynerise = this.doc.createElement('script');
    scriptSynerise.text = `
      function onSyneriseLoad() {
          SR.init({
              'trackerKey': '${environment.TRACKER_KEY}',
              'localStorageMode': true,
              'dynamicContent': {
                  'virtualPage': true
              }
          });
      }

      (function(s,y,n,e,r,i,se){s['SyneriseObjectNamespace']=r;s[r]=s[r]||[],
      s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n),
      se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se);
      z.onload=z.onreadystatechange=function(){var rdy=z.readyState;
      if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null;
      z.onreadystatechange=null;}};})(window,document,'script',
      'https://web.snrbox.com/synerise-javascript-sdk.min.js','SR', 'onSyneriseLoad');
    `;
    this._renderer2.appendChild(this.doc.body, scriptSynerise);

  }

  private initializeNotificationAcoustic() {
    if(this.platform.is('cordova') && this.platform.is('android')){
      MCEPlugin.setIcon('notification_icon')
    }
  }
}
