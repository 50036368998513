import { EXTEND_CREDIT_LINE_FAQS } from './faq-information.contants';
import { IDLE_EXIT_AFTER_WARNING_TIMEOUT } from './misc.constants';
import { MULTIPLE_SESSIONS_MESSAGE } from './auth.constants';
import { ElementRef } from '@angular/core';

let element: ElementRef;

export const FORCED_UPDATE_MODAL_PROPS = {
  title: 'Actualiza tu app',
  message: 'Por razones de seguridad, debes actualizar tu app.',
  primaryButtonText: 'Actualizar',
  icon: '',
  extraClass: 'title-bold'
};

export const RATE_APP_MODAL_PROPS = {
  title: '<br> Califica Banco Ripley App',
  message: (platform: string): string => {
    return `Si la aplicación te ha sido útil, tómate un
    <br> momento para evaluarnos en ${platform},
    <br> no te tomará más de un minuto.
    <br> ¡Gracias por la ayuda!.`
  },
  primaryButtonText: 'Ir a evaluar',
  secondaryButtonText: 'No, gracias.',
  icon: 'i-icon-banco-ripley',
  extraClass: 'title-bold'
};

export const RATE_DELAY = 10;

export const EXTEND_CREDIT_LINE_FAQS_MODAL_PROPS = {
  title: 'Preguntas frecuentes',
  information: EXTEND_CREDIT_LINE_FAQS,
};

export const EXTEND_CREDIT_LINE_CANCEL_PROGRESS_MODAL_PROPS = {
  title: '¿Estás seguro que deseas salir y no incrementar tu línea?',
  message: 'Perderás el progreso que llevas hasta ahora',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const TOOLTIP_RIPLEY_POINTS_DASHBOARD = {
  title: 'Puntos Go Categoría',
  message: '<div class="message-tooltip-ripley-points-dashboard">Son los puntos acumulados entre el 01 de enero y el 31 de diciembre de cada año que te permiten acceder a las categorías Plus, Silver y Gold. No se consideran los puntos adquiridos por bonos y/o campañas promocionales.</div>',
  primaryButtonText: 'Entendido',
  secondaryButtonText: '',
  icon: '',
  extraClass: 'title-tooltip-ripley-points-dashboard-categoria'
};

export const EFEX_CANCEL_PROGRESS_MODAL_PROPS = {
  title: '¿Estás seguro que deseas salir y no desembolsar tu Efectivo Express?',
  message: 'Perderás el progreso que llevas hasta ahora',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const EFEX_SMS_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación. ' +
    'Contáctanos al (01) 611-5757, un asesor te ayudará a finalizar el desembolso de tu Efectivo Express.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple',
  flow: 'EFEX_SMS'
};

export const EFEX_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple',
  dataString: '',
  flow: 'EFEX_FAILED'
};

export const EFEX_FAILED_TC_MODAL = {
  title: '',
  message: 'Los términos y condiciones no se encuentran disponibles, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_FAILED_AC_MODAL = {
  title: '',
  message: 'Los términos de autorización de cuenta no se encuentran disponibles, inténtalo nuevamente más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const EFEX_TCEA_MODAL_PROPS = {
  title: 'TCEA',
  message: 'La TCEA es calculada considerando el seguro de desgravamen porcentual sobre' +
  ' el saldo insoluto de la deuda según tarifario vigente. Tasas, Tarifas y Contratos en: ' +
  'www.bancoripley.com.pe, sección: Tasas, Tarifas y Contratos, seleccionando el producto contratado.',
};

export const CONTACT_WITH_CALL_CENTER_MODAL_PROPS = {
  message: 'Contáctate con nuestro RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a>' +
    ' o visítanos en la agencia de Banco Ripley más cercana para actualizar tu número.',
  primaryButtonText: 'Ir a bancoripley.com.pe',
};

export const IDLE_SESSION_WARNING_MODAL_PROPS = {
  title: 'Tu sesión está a punto de expirar',
  message: `Se cerrará la sesión en ${IDLE_EXIT_AFTER_WARNING_TIMEOUT} segundos`,
  primaryButtonText: 'Quedarme',
  secondaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const SIGNOUT_WARNING_MODAL_PROPS = {
  title: 'Estás a punto de cerrar sesión',
  message: '¿Estás seguro?',
  primaryButtonText: 'Sí',
  secondaryButtonText: 'No',
  icon: 'i-alert-purple'
};

export const TOOLTIP_RIPLEY_POINTS_DASHBOARD_CANJES = {
  title: 'Canjes',
  message: '<div class="message-tooltip-ripley-points-dashboard">Ahora tus canjes se visualizan en dos secciones. <strong>Disponibles:</strong> Canjes por utilizar / <strong>No Disponibles:</strong> Canjes vencidos o marcados como utilizado.</div>',
  primaryButtonText: 'Entendido',
  secondaryButtonText: '',
  icon: '',
  extraClass: 'title-tooltip-ripley-points-dashboard-canje'
};
export const TOOLTIP_RIPLEY_POINTS_DASHBOARD_CATALAGO = {
  title: 'Puntos Go',
  message: '<div class="message-tooltip-ripley-points-dashboard">Puntos disponibles: Puntos que puedes canjear por Gift Cards, Experiencias, Súper Canjes y Millas. Los acumulas por tus compras en Tiendas Ripley con Tarjeta Ripley y/o cualquier medio de pago; al usar tu Tarjeta Ripley en comercios asociados, desembolsar tus préstamos en Banco Ripley y/o al inscribirte en campañas promocionales. Tienen vigencia de 12 meses (excepto Categoría Gold con vigencia de 24 meses).</div>',
  primaryButtonText: 'Entendido',
  secondaryButtonText: '',
  icon: '',
  extraClass: 'title-tooltip-ripley-points-dashboard-canje'
};
export const INACTIVITY_CLOSED_SESSION_MODAL_PROPS = {
  title: 'Sesión finalizada',
  message: 'Por motivos de inactividad, hemos cerrado tu sesión. <br>Ingresa nuevamente para continuar.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple',
};

export const TOKEN_CLOSED_SESSION_MODAL_PROPS = {
  title: 'Lo sentimos',
  message: 'Su tiempo de sesión ha expirado. <br>Por favor vuelva a ingresar.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple',
};


export const TRANSFER_MODAL_OTP_PROPS = {
  transferFormValue: null,
  sessionId: '',
}

export const TRANSFER_ORIGIN_ACCOUNT_ERROR_GENERAL = {
  title: '',
  message: 'Los productos de tu cuenta no están habilitados para realizar transferencias a un contacto.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};

export const TRANSFER_ERROR_GENERAL = {
  title: '¡Lo sentimos!',
  message: 'Tu operación no pudo ser completada en este momento. Intenta nuevamente más tarde.',
  primaryButtonText: 'Aceptar',
  secondaryButtonText: "Ir al inicio",
  icon: 'i-alert-purple',
};

export const TRANSFER_FAILED_MODAL_GENERAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'Antes de realizar otra transferencia, revisa si el monto a traspasar ya fue descontado de tu cuenta.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const TRANSFER_ORIGIN_ERROR_ACCOUNT = {
  title: 'Lo sentimos',
  message: 'No es posible realizar la transferencia, tu cuenta se encuentra bloqueada. Te invitamos llamar a ' +
  'RipleyFono (01) 611 5757 para desbloquearla.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
}

export const TRANSFER_ERROR_INVALID_PHONE_NUMBER = {
  title: 'Número inválido',
  message: 'El número de celular ingresado no es válido, revisa los datos ingresado es inténtalo nuévamente',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};

export const TRANSFER_ERROR_NO_BANK_PHONE_NUMBER = {
  title: 'Número sin afiliación',
  message: 'El teléfono ingresado no puede recibir transferencias. Revisa si está correcto o intenta realizar otro tipo de transferencia.',
  primaryButtonText: 'Entendido',
  thirdButtonText: "Ir a transferencias",
  icon: 'i-alert-purple',
};

export const TRANSFER_ERROR_INSUFFICIENT_BALANCE = {
  title: 'Saldo insuficiente',
  message: 'El monto excede tu saldo disponible',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};
export const TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF = {
  title: 'Saldo insuficiente',
  message: 'El monto final excede tu saldo ya que se aplica el ITF del 0.005%',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};

export const TRANSFER_ERROR_INACTIVEACCOUNT_DESTINATION = {
  title: 'Lo sentimos',
  message: 'La cuenta de destino está Inactiva y no es posible realizar una transferencia.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};

export const TRANSFER_ERROR_ACCOUNT_DESTINATION = {
  title: 'Lo sentimos',
  message: 'La cuenta de destino es inválida y no es posible realizar una transferencia.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};
export const TRANSFER_ERROR_ACCOUNT_CURRENCY = {
  title: 'Lo sentimos',
  message: 'Operación inválida. El tipo de moneda entre las cuentas de origen y destino deben ser iguales.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
};

export const TRANSFER_CCI_ERROR_ACCOUNT = {
  title: 'Lo sentimos',
  message: 'No es posible transferir a la cuenta de destino. Intenta transferir a otra cuenta.',
  primaryButtonText: 'Entendido',
  icon: 'i-alert-purple',
}

export const TRANSFER_FORM_VALUE = {
  amount: 213.21,
  baseCurrency: "PEN",
  beneficiaryLastName: null,
  beneficiaryName: null,
  beneficiaryPhoneNumber: null,
  description: "kjghjgjhgjhg",
  destinationAccount: "1000000000",
  documentNumber: null,
  documentNumberBeneficiary: "54545454",
  documentType: "C",
  documentTypeBeneficiary: "C",
  email: "maeli12@hotmail.com",
  financialInstitution: null,
  lastName: null,
  name: null,
  originAccount: "4030149764",
  ownAccount: false,
  phoneNumber: null,
  sessionId:'N'
};


export const CONFIRM_TRANSFER_MODAL_PROPS = {
  phoneNumber: '0',
  sessionId: 'N',
  title: 'Confirma tu transferencia',
  message: '',
  primaryButtonText: 'Transferir',
  icon: 'i-confirm-modal',
  transferFormValue: {},
  voucher: {},
  currentType: '',
  currencySymbol: 'S/ ',
  ownAccount: true,
  finantialInstitution: '',
  currentTypeG4: ''
};

export const TRANSFERS_SCHEDULE_MODAL_PROPS = {
  title: 'Horarios de transferencias',
  shiftsSchedule: [],
  nonWorkingDayMessage: 'Las transferencias realizadas los días sábados, domingos y/o feriados' +
    ' se procesarán al siguiente día útil.',
  primaryButtonText: 'Volver',
  icon: 'i-question',
};

export const TRANSFERS_SCHEDULE_MODAL_PROPS_OLD = {
  title: 'Horarios de transferencias',
  shiftsSchedule: [],
  nonWorkingDayMessage: 'Las transferencias realizadas los días sábados, domingos y/o feriados' +
    ' se procesarán al siguiente día útil.',
  primaryButtonText: 'Aceptar',
  icon: 'i-question',
};

export const MULTIPLE_SESSIONS_MODAL_PROPS = {
  title: MULTIPLE_SESSIONS_MESSAGE,
  message: 'Asegúrate cerrar sesión en todas las ventanas o dispositivos antes de volver a ingresar.' +
  ' Si crees que una persona no autorizada accedió a tu cuenta, comunícate ' +
  'al RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a> .',
  secondaryButtonText: 'Cerrar la Sesión',
  extraClass1: 'direction-button',
  extraClass2: 'close-sesion',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple',
  closeSesion: true,
};

export const ERROR_DESCONEXION_MAIN = {
  title: 'Error',
  message: 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Volver',
  icon: 'i-alert-purple',
};

export const SAVINGS_MOVEMENTS_MODAL_PROPS = {
  title: 'Constancia de movimiento',
  primaryButtonText: 'Volver',
  icon: ''
};

export const PAYMENT_CREDITS_MODAL_PROPS = {
  title: 'Crédito de consumo',
  primaryButtonText: 'Pagar préstamo',
  secondaryButtonText: 'Volver',
  icon: ''
};

export const PRODUCTS_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos cargar alguno de tus productos, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const TRANFER_CTS_FAILED_MODAL_PROPS = {
  title: 'No es posible realizar la transferencia a esta cuenta',
  message: 'Una cuenta CTS no es válida como cuenta destino.' +
    ' Por favor, ingresa otra cuenta de tu preferencia para continuar la operación.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};


export const ALREADY_ENROLLED_PROPS = {
  title: '¡Ya te encuentras registrado!',
  message: 'Te invitamos a iniciar sesión con tu número de documento y Clave de ingreso',
  primaryButtonText: 'Ingresar',
  secondaryButtonText: 'Olvidé mi clave',
  icon: 'i-thumb-up'
};

export const ALREADY_ENROLLED_BLOCK_PROPS = {
  title: '¡Tu Cuenta se encuentra bloqueada!',
  message: 'Si necesitas ingresar con urgencia contáctate con nuestro RipleyFono (01) 6115757',
  primaryButtonText: 'Ir a Login',
  icon: 'i-lock-purple'
};

export const NOT_RIPLEY_USER_PROPS = {
  title: '¡Ups! Aún no eres parte de Banco Ripley',
  message: 'Entérate si tienes una tarjeta de crédito Ripley preaprobada.',
  primaryButtonText: 'Ver si tengo una tarjeta',
  icon: 'i-alert-purple'
};

export const ENROLLMENT_SESSION_EXPIRED_PROPS = {
  title: 'La sesión ha caducado',
  message: 'Vuelve a iniciar el proceso',
  primaryButtonText: 'Ir a Inicio',
  icon: 'icon-time-invalid'
};

export const ENROLLMENT_BACKLIST_PASSWORD = {
  message: 'La clave ingresada debe ser mas segura. No usar claves como 111111, 332211, 651234, 141414, etc.',
  primaryButtonText: 'Aceptar',
  icon: 'i-lock-purple'
};

export const NOT_PHONE_PROPS = {
  title: 'Tus datos no se encuentran registrados.',
  message: 'Por favor regístrate a través de la App Banco Ripley Perú',
  primaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const ENROLLMENT_EXIT_PROPS = {
  title: '',
  message: 'Perderás el progreso que llevas hasta ahora.',
  primaryButtonText: 'Cancelar',
  secondaryButtonText: ' Sí, estoy seguro',
  icon: 'i-alert-purple'
};

export const CREATED_USER_PROPS = {
  title: '',
  message: '<b>¡Felicidades!</b><br>Ahora podrás realizar tus operaciones de manera más fácil y en cualquier lugar.',
  primaryButtonText: 'Ingresar',
  secondaryButtonText: 'Ir a bancoripley.com.pe',
  icon: 'i-happy-face'
};

export const NOT_MY_NUMBER_PROPS = {
  title: '¿No es tu número?',
  message: 'Contáctate con nuestro RipleyFono <a class="modal-link" href="tel:016115757">(01) 611 5757</a>' +
    ' o visítanos en la agencia de Banco Ripley más cercana para actualizar tu número.',
  primaryButtonText: 'Ir a bancoripley.com.pe',
};

export const OPEN_ACCOUNT_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos realizar la apertura de la cuenta, intenta más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const OPEN_ACCOUNT_CANCELED_RPASS_PROPS = {
  title: 'Autenticación cancelada',
  message: 'En estos momentos no se ha podido autenticar por RPASS, intentalo más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const OPEN_ACCOUNT_FAILED_RPASS_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no se ha podido autenticar por RPASS, intentalo más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple'
};

export const TAMPERED_DEVICE_PROPS = {
  title: 'Lo sentimos',
  message: 'Tu dispositivo se encuentra inhabilitado para hacer uso de esta aplicación, debido a que ha sido alterado' +
    ' para obtener privilegios de administrador',
};

export const LOAN_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos atender tu solicitud, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Aceptar',
  secondaryButtonText: 'Reintentar',
  icon: 'i-alert-purple'
};

export const RECAPTCHA_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Error en el recaptcha, asegúrese de ser un humano.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const TIME_OUT_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Su tiempo en la plataforma web ha caducado.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const DATA_WRONG_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Los datos son incorrectos.',
  primaryButtonText: 'Aceptar',
  icon: 'i-alert-purple'
};

export const SERVICE_ERROR_MODAL_PROPS = {
  title: '¡Ups!',
  message: 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente más tarde',
  primaryButtonText: 'Salir',
  icon: 'i-alert-purple'
};

export const LOAN_LEAD_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos registrar tu solicitud, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple',
  dataString: '',
  flow: 'LOAN_LEAD_FAILED',
};

export const EFEX_SEF_FAILED_POLICY_MODAL = {
  title: '',
  message: 'Las políticas de privacidad no se encuentran disponibles, inténtalo nuevamente más tarde.',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple',
  flow: 'EFEX_SEF_FAILED'
};

export const ENROLLMENT_PINPAN_TIME_INVALID = {
  title: '¿Sigues ahí?',
  message: 'Se agotó el tiempo de espera. Puedes continuar intentando nuevamente o cambiar de método de autenticación.',
  primaryButtonText: 'Reintentar',
  secondaryButtonText: 'Intentar con otro método ',
  icon: 'icon-time-invalid'
};

export const OPEN_SIMPLE_ACCOUNT_FAILED_MODAL_PROPS = {
  title: 'Ocurrió un error',
  message: 'En estos momentos no podemos realizar la Apertura cuenta simple, intenta más tarde',
  primaryButtonText: 'Cerrar',
  icon: 'i-alert-purple',
  flow: 'OPEN_SIMPLE_ACCOUNT_FAILED'
};

export const ONOFF_ERROR_ENROLL = {
  title: '¡Lo sentimos!',
  message: 'No se encuentra método de autenticación disponible',
  primaryButtonText: 'Reintentar',
  icon: 'i-triangle-error'
};

export const ONOFF_BLOCK_CARD_PROPS ={
  title: 'Tarjeta bloqueada',
  message: '',
  primaryButtonText: 'Entendido',
  icon: 'i-info-purple-1',
  showCloseButton: false
};

export const SEF_AUTO_TCEA_MODAL_PROPS = {
    extraTitleClass: 'extraTitleClass',
    title: 'Tasa de Costo Efectivo Anual',
    message: 'La TCEA se calcula considerando el seguro de desgravamen porcentual sobre el saldo insoluto' +
        ' de la deuda según tarifario vigente. Tasas, Tarifas y Contratos en: www.bancoripley.com.pe, sección:' +
        ' Tasas, Tarifas y Contratos, seleccionando el producto contratado.',
};

export const EFEX_AUTO_TCEA_MODAL_PROPS = {
    extraTitleClass: 'extraTitleClass',
    title: 'Tasa de Costo Efectivo Anual',
    message: 'La TCEA es calculada considerando el seguro de desgravamen porcentual sobre el saldo insoluto' +
        ' de la deuda según tarifario vigente. Tasas, Tarifas y Contratos en: www.bancoripley.com.pe, sección:' +
        ' Tasas, Tarifas y Contratos, seleccionando el producto contratado.',
};

export const GRACE_PERIOD_MODAL_PROPS = {
  extraTitleClass: 'extraTitleClass',
  title: 'Periodo de Gracia',
  message: 'Al aceptar el periodo de gracia, está aceptando postergar su primera fecha de pago hasta en '+
  '2 meses, además se cobrarán los intereses desde la fecha de desembolso.',
};

export const PEP_MODAL_PROPS = {
  extraTitleClass: 'extraTitleClass',
  title: 'PEP',
  message: 'Persona Expuesta Políticamente (Resolución SBS4349-2016).',
};

export const SECURES_MODAL_PROPS = {
  extraTitleClass: 'extraTitleClass',
  title: 'Seguros',
  message: 'El seguro de desgravamen se aplica de manera mensual y porcentual sobre el saldo insoluto de'+
  ' la deuda. Desgravamen ',
  aditional: '% y Desgravamen con devolución ',
  aditional2: '%'
};

export const SEF_EFEX_AUTO_TEA_MODAL_PROPS = {
  extraTitleClass: 'extraTitleClass',
  title: 'Tasa Efectiva Anual',
  message: '',
};

export const LEAVE_OFFER_MODAL_PROPS = {
  title: '',
  message: '¿Estás seguro que deseas salir del proceso de desembolso?',
  primaryButtonText: 'Sí, salir',
  secondaryButtonText: 'Cancelar',
  icon: 'i-alert-purple',
  amount: 0,
  fee: 0,
  product: '',
  ensuranceType: ''
};

export const OFFER_EFEX_INACTIVE_MODAL_PROPS = {
  title: 'El desembolso no puede ser procesado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación.' +
  ' Te invitamos a realizar el desembolso de tu Efectivo Express a partir de las 12:00 a.m.',
  primaryButtonText: 'De acuerdo',
  icon: 'i-alert-purple',
  extraClass: 'title-purple',
  dataString: '',
  flow: 'OFFER_EFEX_INACTIVE'
};

export const DYNAMIC_MESSAGE_MODAL_PROPS = {
  title: 'El desembolso no puede ser procesado',
  primaryButtonText: 'De acuerdo',
  icon: 'i-alert-purple',
  extraClass: 'title-purple'
};

export const OFFER_SEF_INACTIVE_MODAL_PROPS = {
  title: 'El desembolso no puede ser procesado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación.' +
  ' Te invitamos a realizar el desembolso de tu Súper Efectivo a partir de las 02:30 hrs.',
  primaryButtonText: 'De acuerdo',
  icon: 'i-alert-purple',
  extraClass: 'title-purple',
  dataString: '',
  flow: 'OFFER_SEF_INACTIVE'
};

export const DISBURSEMENT_FAILED_WEB_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: '',
  content: 'Lo sentimos, en este momento no podemos realizar la operación. Contáctanos al ' +
  '<b>(01) 611-5757</b>, un asesor te ayudará a finalizar el desembolso de tu ',
  primaryButton: 'Cerrar',
  dataString: '',
  flow: 'DISBURSEMENT_FAILED_WEB'
};

export const DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: '',
  content: 'Lo sentimos, en este momento no podemos realizar la operación. Contáctanos al ' +
  '<b>(01) 611-5757</b>, un asesor te ayudará a finalizar el desembolso de tu ',
  primaryButton: 'Llamar ahora',
  secondaryButton: 'Cerrar',
  dataString: '',
  flow: 'DISBURSEMENT_FAILED_MOBILE'
};

export const DISBURSEMENT_FAILED_POLICIES_RSAT = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, hubo un inconveniente con tu solicitud de Súper Efectivo en canal digital. ' +
  'Puedes acercarte a la agencia más cercana para consultar sobre tu oferta.',
  content: '',
  primaryButton: 'Cerrar',
  secondaryButton: '',
  dataString: '',
  flow: 'DISBURSEMENT_FAILED_POLICIES_RSAT'
};

export const DISBURSEMENT_FAILED_POLICIES_T24 = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, por el momento no podemos atender tu solicitud de Súper Efectivo.',
  content: '',
  primaryButton: 'Cerrar',
  secondaryButton: '',
  dataString: '',
  flow: 'DISBURSEMENT_FAILED_POLICIES_T24'
};

export const AMOUNT_LIMIT_MODAL_PROPS = {
  title: 'El monto excede lo permitido',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: 'i-alert-purple',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar'
};

export const END_TO_END_OFF_MODAL_PROPS = {
  title: '¡Ups!, parece que algo salió mal',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: '',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar',
};

export const RESCHEDULING_CANCEL_MODAL = {
  title: '¿Estás seguro que deseas salir del proceso de reprogramación de deuda?',
  primaryButtonText: 'Sí, salir',
  secondaryButtonText: 'Cancelar',
  icon: 'paddingOff'
};

export const RESCHEDULING_TOTAL_DEBT_MODAL = {
  title: '',
  message: 'Deuda total al día de hoy, no considera las operaciones pendientes de procesar.',
};

export const ON_OFF_CANCEL_MODAL = {
  message: 'No has guardado los cambios que has realizado. ¿Estás seguro que deseas salir sin aplicar los cambios?',
  primaryButtonName: 'Guardar cambios',
  secondaryButtonName: 'Salir',
  acceptProcessAction: 0,
  cancelProcessAction: 0,
  icon:'i-alert-purple',
};

export const ON_OFF_CANCEL_MODAL_NO_SAVE = {
  message: '¿Estás seguro que deseas salir del proceso?',
  secondaryButtonName: 'Salir',
  acceptProcessAction: 0,
  cancelProcessAction: 0,
  icon:'i-alert-purple',
};


export const TEM_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa efectiva mensual.',
};

export const TEA_OPTION = 'tea';

export const TEM_OPTION = 'tem';

export const TCEA_OPTION = 'tcea';

export const TEA_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa efectiva anual.',
};

export const TCEA_TOOLTIP_MOBILE = {
  title: '',
  message: 'Tasa de costo efectivo anual.',
};

export const GENERIC_ERROR_PROPS = {
  title: 'Ocurrió algo inesperado',
  message: 'Lo sentimos, en este momento no podemos realizar la operación. ' +
  'Contáctanos al (01) 611-5757, un asesor te ayudará a finalizar el proceso de reprogramación.',
  primaryButtonText: 'Cerrar',
  icon: ''
};
// * RPASS Modal Props
export const MULTIPLE_SESSIONS_LOGIN_RPASS = {
  icon: 'i-alert-purple',
  title: 'Ya tienes una sesión activa',
  message: 'Cierra sesión en los otros dispositivos antes de volver a ingresar. Si crees que alguien accedió a tu cuenta, comunícate <br>al <a class="modal-link" href="tel:016115757">(01) 611 5757</a>.',
  primaryButtonText: 'Entendido',
  secondaryButtonText: '',
}

export const LOCKED_USER_BY_ADMIN_LOGIN_RPASS = {
  icon: 'i-alert-purple',
  title: 'Acceso temporalmente bloqueado!',
  message: 'Para activar tu R Pass debes tener tu cuenta al día.',
  primaryButtonText: 'Pagar deuda',
  secondaryButtonText: '',
}

export const ENROLLED_LOCKED_LOGIN_RPASS = {
  icon: 'i-alert-purple',
  title: '¡Lo sentimos!',
  message: 'Tu clave está bloqueada, para obtener tu clave haz click en Recuperar clave.',
  primaryButtonText: 'Recuperar clave',
  secondaryButtonText: 'Salir',
}

export const GENERIC_ERROR_LOGIN_RPASS = {
  icon: 'i-alert-purple',
  title: '¡Lo sentimos!',
  message: 'En estos momentos no podemos atender tu solicitud',
  primaryButtonText: 'Volver al inicio',
  secondaryButtonText: '',
}

export const GENERIC_ERROR_RPASS = {
  icon: 'i-alert-purple',
  title: '¡Lo sentimos!',
  message: 'No fue posible procesar tu solicitud. Por favor, vuelve a intentarlo más tarde.',
  primaryButtonText: 'Entendido',
}

export const ERROR_GET_MESSAGE = {
  icon: 'i-alert',
  title: '',
  message: 'Ha ocurrido un error al cargar los detalles de la transacción.'
};

export const ERROR_AUTHORIZE_TRANSACTION = {
  icon: 'i-alert',
  title: '',
  message: 'No se ha podido completar la autorización.'
};

export const ERROR_REJECT_TRANSACTION = {
  icon: 'i-alert',
  title: '',
  message: 'Ha ocurrido un error al rechazar la transacción.'
};

export const CONFIRM_REJECT_TRANSACTION_RPASS = {
  title: '¿Quieres rechazar esta transacción?',
  message: 'Al hacerlo perderás los datos de la operación en curso'
};

export const BIOMETRIC_RPASS_AUTH_ERROR_MODAL_PROPS = {
  valorBIOMETRIC: {
    '-111': {
      icon: 'i-alert-purple',
      title: 'Transacción rechazada',
      message: 'La huella ingresada es incorrecta.',
      primaryButtonText: "Volver al inicio",
    },
    '-112': {
      icon: 'i-alert-purple',
      title: 'Transacción rechazada',
      message: 'La huella esta deshabilitada',
      primaryButtonText: "Volver al inicio",
    }
  },
  valorFINGER: {
    '-102': {
      icon: 'i-alert-purple',
      title: 'Transacción rechazada',
      message: 'La huella ingresada es incorrecta.',
      primaryButtonText: "Volver al inicio",
    },
    '-111': {
      icon: 'i-alert-purple',
      title: 'Transacción rechazada',
      message: 'La huella esta deshabilitada',
      primaryButtonText: "Volver al inicio",
    }
  },
  valorFACE: {
    '-111': {
      icon: 'i-alert-purple',
      title: 'Transacción rechazada',
      message: 'El FaceID esta deshabilitado',
      primaryButtonText: "Volver al inicio",
    }
  }
}

export const PIN_RPASS_AUTH_ERROR_MODAL_PROPS = {
  icon: 'i-alert-purple',
  title: 'Transacción rechazada',
  message: 'El R Pass ingresado es incorrecto.',
  primaryButtonText: "Volver al inicio",
}

export const DEFAULT_RPASS_AUTH_ERROR_MODAL_PROPS = {
  icon: 'i-alert-purple',
  title: 'Transacción rechazada',
  message: 'Ha ocurrido un error al validar el R Pass.',
  primaryButtonText: "Volver al inicio",
}

export const FINAL_MODAL_TEXT = {
  authorized: {
    icon: 'i-check-purple',
    title: '¡Listo! Transacción autorizada',
    message: 'Regresa a la página donde iniciaste el proceso',
    primaryButton: 'Cerrar',
    secondaryButton: ''
  },
  rejected: {
    icon: 'i-check-purple',
    title: '¡Listo! Transacción rechazada',
    message: 'Podrás visualizarla en el historial de transacciones',
    primaryButton: 'Volver al inicio',
    secondaryButton: 'Ver historial'
  },
};
// * END RPASS Modal Props

export const SWORN_DECLARATION_MODAL_PROPS = {
  title: 'Parece que algo salió mal',
  message: 'No te preocupes. Solicita el apoyo de uno de <b>nuestros asesores ' +
  'especializados</b> para obtener tu préstamo.',
  icon: 'i-alert-purple',
  primaryButtonText: 'Quiero que me llamen',
  secondaryButtonText: 'Cancelar',
};


//pin + pan


//acceso invalido error de pin
export const ACCESS_INVALID_PINPAN_PROPS = {
  title: 'Acceso Invalido',
  message: 'Lo sentimos, la operación no puedo ser realizada',
  primaryButtonText: 'aceptar',
  //secondaryButtonText: 'Reconocimiento facial',
  icon: 'i-lock-purple'
};



//acceso bloquedo al 4 intento
export const ACCESS_BLOCK_PINPAN_PROPS = {
  title: 'Acceso Bloqueado',
  message: 'Se excedieron los intentos permitidos y por seguridad se ha bloqueado el acceso.Para desbloquear contáctanos al Ripleyfono: 01 611 5757',
  primaryButtonText: 'Salir',
  //secondaryButtonText: 'Reconocimiento Facial',
  icon: 'i-lock-purple'
};


//datos invalidos error de pan
export const NODATES_PINPAN_PROPS = {

  message: 'Los datos ingresados no son válidos',
  primaryButtonText: 'Aceptar',
  //secondaryButtonText: 'Reconocimiento Facial',
  icon: 'i-lock-purple'
};


//CUANDO LA TARJETA NO ES VALIDO
export const NODATES_CARD = {

  message: 'Documento de Identidad no registra Tarjeta Activa',
  primaryButtonText: 'Aceptar',
  //secondaryButtonText: 'Reconocimiento Facial',
  icon: 'i-lock-purple'
};


export const FAILED_LOAD_PAYMENT_MODAL_PROPS = {
  title: 'Lo sentimos',
  message: 'Algo ocurrió mal. Inténtalo nuevamente.',
  primaryButtonText: 'Reintentar',
  icon: 'i-sad'
};



export const ENROLLMENT_SMS_DATA_ERROR= {
  title: '¿Tus datos no son correctos?',
  message: 'Dirigete a la agencia mas cercana y actualiza tus datos. De lo contrario puedes continuar con otro método de autorización.',
  primaryButtonText: 'Intentar con otro método',
  icon: 'icon-data-error'
};


export const ENROLLMENT_SMS_INTENTS_INVALID = {
  title: '¡Lo sentimos!',
  message: 'Has sobrepasado el límite de intentos.',
  primaryButtonText: 'volver al inicio',
  icon: 'icon-error-sms'
};


export const ENROLLMENT_SMS_TIME_INVALID = {
  title: '¿Sigues ahí?',
  message: 'Se agotó el tiempo de espera. Puedes continuar la creación de tu clave de ingreso o cambiar de método de autorización.',
  primaryButtonText: 'Continuar con OTP SMS',
  secondaryButtonText: 'Intentar con otro método ',
  icon: 'icon-time-invalid'
};


export const GENERIC_ERROR_ENROLL = {
  title: '¡Lo sentimos!',
  message: 'No se puede realizar tu solicitud. Por favor llámanos al RipleyFono para más información ',
  primaryButtonText: 'Reintentar',
  secondaryButtonText: 'Salir',
  icon: 'i-alert-purple'
}

export const GRACE_PERIOD_TOOLTIP = {
  title: '',
  message: 'Al aceptar el periodo de gracia, está aceptando postergar su primera fecha de pago hasta en 2 meses, además se cobrarán los intereses desde la fecha de desembolso.',
};

export const PEP_TOOLTIP = {
  title: '',
  message: 'Persona Expuesta Políticamente (Resolución SBS4349-2016)',
};

export const SECURE_TOOLTIP = {
  title: '',
  message: 'El seguro de desgravamen se aplica de manera mensual y porcentual sobre el saldo insoluto de la deuda. Desgravamen ',
  complemento1: '%ㅤㅤㅤㅤㅤ Desgravamen con devolución ',
  complemento2: '%',
};

export const NODEBT_PROPS = {
  newTitle: '¡Estás al día en tus pagos!',
  newMessage: 'Tu siguiente estado de cuenta estará disponible al finalizar tu ciclo de facturación',
  title: 'No tienes deuda',
  message: '¡Felicitaciones por ser un cliente puntual!'
}