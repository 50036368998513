import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPromotionals } from '@common/interfaces/efex.interface';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GENERIC_TAG_BTN_CLICK } from '@common/constants/tag/tag-generic.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-promotionals',
  templateUrl: './promotionals.component.html',
  styleUrls: ['./promotionals.component.scss'],
})
export class PromotionalsComponent {

  @Input() promotionalsList: IPromotionals[];
  @Output() selectedPromotional: EventEmitter<IPromotionals>;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {
    this.selectedPromotional = new EventEmitter();
   }

  public promotionalClick(promotional: IPromotionals) {
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_BTN_EFEX.product = promotional.amount.toString();
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_BTN_EFEX.step = 'step seleccionar monto';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_BTN_EFEX.amount = promotional.amount;
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_BTN_EFEX.fee = promotional.months;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_BTN_EFEX);	

    this.selectedPromotional.emit(promotional);
  }
}
