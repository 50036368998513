"use strict";

/**
 Handler Banco Ripley for Acoustic MCE Cordova Plugin
 */

var HandlerBRFCMAcoustic;
(function (HandlerBRFCMAcoustic) {

    HandlerBRFCMAcoustic.callbackNotificationToMceFcm = function (notification, senderId, successCallback, errorCallback) {
        cordova.exec(successCallback, errorCallback, 'HandlerBRFCMAcoustic', 'callbackNotificationToMceFcm', [JSON.stringify(notification), senderId])
    }

})(HandlerBRFCMAcoustic || (HandlerBRFCMAcoustic = {}));
module.exports = HandlerBRFCMAcoustic;
