// EFEX IPROSPECT
// STEP 1
export const EFEX_S1_AMOUNT_OPTION_SELECTED = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_1',
  event_label: 'seleccionar_opcion_monto'
};

export const EFEX_S1_SHOW_FEEDBACK_MODAL = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_1.2',
  event_label: 'no_te_convence_ninguno'
};

// STEP 2
export const EFEX_S2_NEXT_STEP = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_2.4',
  event_label: 'continuar_venta'
};

export const EFEX_S2_CANCEL = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_2.5',
  event_label: 'cancelar_venta'
};

export const EFEX_S2_CANCEL_MODAL_PRIMARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_2.6',
  event_label: 'cancelar'
};

export const EFEX_S2_CANCEL_MODAL_SECONDARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_2.6',
  event_label: 'si_estoy_seguro'
};

// STEP 3
export const EFEX_S3_EDIT_EMAIL = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3',
  event_label: 'ingresar_mail'
};

export const EFEX_S3_PRIVACY_POLICY = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.1',
  event_label: 'aceptar_politica_privacidad'
};

export const EFEX_S3_AUTHORIZE_ACCOUNT_OPENING = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.2',
  event_label: 'autorizo_a_bancoripley'
};

export const EFEX_S3_NEXT_STEP = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.3',
  event_label: 'continuar_cuenta_destino'
};

export const EFEX_S3_CANCEL = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.4',
  event_label: 'cancelar_cuenta_destino'
};

export const EFEX_S3_CANCEL_MODAL_PRIMARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.5',
  event_label: 'cancelar'
};

export const EFEX_S3_CANCEL_MODAL_SECONDARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_3.5',
  event_label: 'si_estoy_seguro'
};

// STEP 4
export const EFEX_S4_SMS = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4',
  event_label: 'ingresar_codigo_sms'
};

export const EFEX_S4_SMS_CORRECT = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4',
  event_label: 'sms_correcto'
};

export const EFEX_S4_SMS_INCORRECT = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4',
  event_label: 'sms_incorrecto'
};

export const EFEX_S4_NEXT = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4.1',
  event_label: 'abonar_efectivo_express'
};

export const EFEX_S4_CANCEL = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4.2',
  event_label: 'cancelar_sms'
};

export const EFEX_S4_CANCEL_MODAL_PRIMARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4.3',
  event_label: 'cancelar'
};

export const EFEX_S4_CANCEL_MODAL_SECONDARY_BUTTON = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_4.3',
  event_label: 'si_estoy_seguro'
};

// Success page
export const EFEX_SUCCESS = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_5',
  event_label: 'exito_efectivo_express'
};

export const EFEX_GO_TO_HOME = {
  event: 'ofertasParaTi_event',
  event_category: 'flujoVenta',
  event_action: 'step_5.1',
  event_label: 'volver_al_inicio_venta'
};

// EFEX
// STEP 1

export const EFEX_AMOUNT_SELECTED = {
  variable: 'EFEX',
  event: 'Paso_1-Establecer_Monto',
  action: 'click'
};

export const EFEX_AMOUNT = {
  variable: 'EFEX',
  event: 'Paso_1-Establecer_Monto',
  action: 'click'
};

export const EFEX_BENEFITS = {
  variable: 'EFEX',
  event: 'Paso_1-Ver_beneficios',
  action: 'click'
};
export const EFEX_FEEDBACK = {
  variable: 'EFEX',
  event: 'Paso_1-No_te_convence?',
  action: 'click'
};
export const EFEX_SEND_FEEDBACK = {
  variable: 'EFEX',
  event: 'Paso_1-No_te_convence?_enviar',
  action: 'click'
};

export const EFEX_CANCEL_FEEDBACK = {
  variable: 'EFEX',
  event: 'Paso_1-No_te_convence?_cancelar',
  action: 'click'
};

// STEP 2
export const EFEX_SEND_DETAIL = {
  variable: 'EFEX',
  event: 'Paso_2-Detalle_enviar',
  action:  'click'
};

export const EFEX_CANCEL_DETAIL = {
  variable: 'EFEX',
  event: 'Paso_2-Detalle_cancelar',
  action: 'click'
};


// STEP 3
export const EFEX_SEND_ACCOUNT = {
  variable: 'EFEX',
  event: 'Paso_3-Cuenta_destino_enviar',
    action: 'click'
};

export const EFEX_CANCEL_ACCOUNT = {
  variable: 'EFEX',
  event: 'Paso_3-Cuenta_destino_cancelar',
  action: 'click'
};

// STEP 4
export const EFEX_SEND_PAYMENT = {
  variable: 'EFEX',
  event: 'Paso_4-Abonar_Enviar',
    action:  'click'
};
export const EFEX_CANCEL_PAYMENT = {
  variable: 'EFEX',
  event: 'Paso_4-Abonar_Cancelar',
  action: 'click'
};
export const EFEX_TIMEOUT = {
  variable: 'EFEX',
  event: 'Paso_4-TimeoutSMS',
  action:  'click'
};

export const EFEX_NOT_NUMBER = {
  variable: 'EFEX',
  event: 'Paso_4-No_Es_Tu_Numero',
  action: 'click'
};

