import { Component, Input } from '@angular/core';
import { ITableData } from '@common/interfaces/default.interface';
import { ModalController } from '@ionic/angular';
import { PAYMENT_CREDITS_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { CreditInfoModalComponent } from '@components/credits/credit-modal/credit-info-modal.component';
import { ILoanDataResponse, IQuotaPendingRequest, IQuotaPendingResponse } from '@common/interfaces/loan.interface';
import { LoanService } from '@services/loan/loan.service';
import { DeviceService } from '@services/device/device.service';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';

@Component({
  selector: 'app-credit-table',
  templateUrl: './credit-table.component.html',
  styleUrls: ['./credit-table.component.scss'],
})
export class CreditTableComponent {

  @Input() loanProducts: ILoanDataResponse;
  @Input() tableHeader: ITableData[];
  @Input() tableData: ITableData[][];
  @Input() emptyMessage: string;
  @Input() account: string;
  paymentInformation: IQuotaPendingResponse;
  isDataLoading: boolean;
  peruvianPipe: PeruvianCurrencyPipe;


  constructor(
    private loanService: LoanService,
    private deviceService: DeviceService,
    private modalCtrl: ModalController,
  ) {
    this.tableData = [[]];
    this.isDataLoading = false;

  }


  public getColorMovement(amount: string) {
    return amount[0] === '-' ? 'out-color' : 'in-color';
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  public async getClientIP() {
    return (await this.deviceService.getIP()).ip;
  }

  public async showMovementDetailModal(index: number) {

    this.isDataLoading = true;

    const clientIp = await this.getClientIP();

    const requestLoanDetail: IQuotaPendingRequest = {
      clientIp,
      identification: this.loanProducts.loansList[index].identification,
      accountNumber: this.loanProducts.loansList[index].accountNumber,
      sessionId: this.loanProducts.sessionId,
    };

    try {

      this.paymentInformation = await this.loanService.getQuotaPending(requestLoanDetail);
      const props = {
        title: this.loanProducts.loansList[index].description, // PAYMENT_CREDITS_MODAL_PROPS.title,
        primaryButtonText: PAYMENT_CREDITS_MODAL_PROPS.primaryButtonText,
        secondaryButtonText: PAYMENT_CREDITS_MODAL_PROPS.secondaryButtonText,
        quotaDetail: this.paymentInformation,
        dateLoanStart: this.loanProducts.loansList[index].expiredDate, // fecha de fin de contrato
        loanDisbursementDate: this.loanProducts.loansList[index].loanDisbursementDate, // Fecha de desembolso
        tcea: this.loanProducts.loansList[index].tcea, // TCA
        totalLoanAmount: this.loanProducts.loansList[index].totalLoanAmount,
        account: this.account,
        paiddueamount: this.loanProducts.loansList[index].paidDueAmount, // Cuotas pagadas
        paymentDueDate: this.loanProducts.loansList[index].paymentDueDate, // Fecha de vencimiento de siguiente cuota
        capitalAmount: this.loanProducts.loansList[index].capitalAmount, // deuda vigente
        loanNumber: this.loanProducts.loansList[index].loanNumber, // numero de prestamo
        paymentTime: this.loanProducts.loansList[index].paymentTime,
        totalQuotesAmount: this.loanProducts.loansList[index].paymentTime,
        currencySymbol: this.loanProducts.loansList[index].currencySymbol,
        quotaPending: this.paymentInformation.numberOfDuesPending, // Número de cuotas vencidas pendientes de pago
        status: this.loanProducts.loansList[index].status,

      };
      const modal = await this.modalCtrl.create({
        component: CreditInfoModalComponent,
        cssClass: 'credit-consum-modal',
        componentProps: props,
      });

      await modal.present();

    } catch (error) {

    } finally {
      this.isDataLoading = false;
    }

  }

  get isLargeTable() { return this.tableHeader.length > 3; }

}
