import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { NotificationsService } from '@services/notifications/notifications.service';
import { INotificationInformation } from '@common/interfaces/notifications.interface';
import { UtilsService } from '@services/utils/utils';
import {
  EXTERNAL_NOTIFICATION_TYPE, INTERNAL_NOTIFICATION_TYPE,
  NOTIFICATIONS_APP_REDIRECTIONS
} from '@common/constants/notifications.constants';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-notifications-feed',
  templateUrl: './notifications-feed.component.html',
  styleUrls: ['./notifications-feed.component.scss'],
})
export class NotificationsFeedComponent implements OnDestroy {

  public subscription: Subscription;
  public notificationList: INotificationInformation[];
  public isSessionLess: boolean;
  public isMobile: boolean;
  public readonly defaultNotificationImage: string;

  constructor(
    private notificationsService: NotificationsService,
    private modalController: ModalController,
    private platform: Platform,
    private utilsService: UtilsService,
    private router: Router,
    private menu: MenuController,
    private storage: Storage,
  ) {
    this.defaultNotificationImage = '../../assets/img/app_icon.png';
    this.subscription = new Subscription();
    this.listenToNotificationUpdate();
    this.isMobile = this.platform.is('mobile') || this.platform.is('mobileweb');
  }

  public dismissModal(): void {
    this.modalController.dismiss();
  }

  public async openNotification(notification: INotificationInformation) {
    this.notificationsService.markNotificationAsRead(notification);
    const { path, type } = notification.content.inboxContent;
    if (path) {
      if (type === EXTERNAL_NOTIFICATION_TYPE) {
        this.utilsService.openRedirectPath(path);
      } else if (type === INTERNAL_NOTIFICATION_TYPE) {
        const routePath = NOTIFICATIONS_APP_REDIRECTIONS[path] || 'home';
        this.utilsService.addEventToTagManagerAndFirebase('Notificaciones', 'open', 'Abrir_Notificacion');
        this.storage.set('origin', 'PUSH-NOTIFICATION');
        if (path === 'rpassAuth' && !notification.isRead) {
          this.router.navigate([NOTIFICATIONS_APP_REDIRECTIONS[path], { extraData: notification.content.extraData }]);
        } else if (path !== 'rpassAuth') {
          await this.router.navigateByUrl(routePath)
            .catch(() => this.navigationErrorAlert());
        }
        this.menu.close();
        this.dismissModal();
      } else {
        this.navigationErrorAlert();
      }
    }
  }

  public deleteNotification(notification: INotificationInformation) {
    this.notificationsService.deleteNotification(notification);
  }

  public navigationErrorAlert() {
    this.utilsService.showError('Hubo un error al intentar abrir la notificación');
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private listenToNotificationUpdate(): void {
    this.subscription.add(this.notificationsService.notificationList.subscribe((notificationList) => {
      this.notificationList = notificationList
        .filter((notification) => notification.expirationDate ? notification.expirationDate.toDate() > new Date() : true);
    }));
  }
}
