import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutOffDisclaimerComponent } from './cut-off-disclaimer/cut-off-disclaimer.component';
import { ComponentsModule } from '@components/components.module';

@NgModule({
  declarations: [CutOffDisclaimerComponent],
  imports: [
    ComponentsModule,
    CommonModule
  ],
  exports:[
    CutOffDisclaimerComponent
  ]
})
export class PayRipleyModule { }
