import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RECAPTCHA_ERROR_CODE } from '@common/constants/error.constants';
import { EMAIL_REGEX } from '@common/constants/misc.constants';
import {
  RECAPTCHA_MODAL_PROPS,
  RESCHEDULING_CANCEL_MODAL,
  RESCHEDULING_TOTAL_DEBT_MODAL,
} from '@common/constants/modal-props.constants';
import {
  ID_QUOTA_NOT_FOUND,
  ID_QUOTA_NOT_FOUND_PROPS,
  RESCHEDULING_GENERAL_ERROR_PROPS,
  RESCHEDULING_SCHEDULE,
  RESCHEDULING_SCHEDULE_PROPS,
  SESSION_DOESNT_EXIST,
  SESSION_DOESNT_EXIST_PROPS,
} from '@common/constants/rescheduling.constans';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { GTM_STEPS } from '@common/constants/tag/tag-rescheduling.constanst';
import {
  IQuotas,
  IReschedulingConsultResponse,
  IReschedulingSimulationRequest,
  IReschedulingSimulationResponse
} from '@common/interfaces/rescheduling';
import { TceaComponent } from '@components/efex/modals/tcea/tcea.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { ReschedulingService } from '@services/rescheduling/rescheduling.service';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
})
export class SimulatorComponent implements OnInit {

  quotasView = false;
  periodView = false;
  isLoading = true;
  isSimulateLoading = false;
  emailActive: boolean;
  quotas: IQuotas[];
  quotaSelected: string;
  public email: string;
  @Input() reschedulingData: IReschedulingConsultResponse;
  @Output() simulatorValidation: EventEmitter<IReschedulingSimulationResponse>;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private service: ReschedulingService,
    private userService: UserService,
    private gtmService: GoogleTagManagerService
    ) {
      this.simulatorValidation = new EventEmitter();
    }

  ngOnInit() {
    this.quotas = this.reschedulingData.quotas;
    this.quotaSelected = this.quotas[0].value;
    this.gtmService.pushTagV2(GTM_STEPS.SHOW_STEP_ONE);
    if (this.userService.userInformation.email) {
      this.email = this.userService.userInformation.email;
    } else {
      this.emailActive = true;
    }
    this.isLoading = false;
  }

  async showCancelModal() {
    this.gtmService.pushTagV2(GTM_STEPS.STEP_ONE_CLICK_CANCEL);
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal cancel-reprogramming',
      componentProps: RESCHEDULING_CANCEL_MODAL,
    });
    await modal.present();
    this.gtmService.pushTagV2(GTM_STEPS.SHOW_CANCEL_MODAL);
    const option = await modal.onDidDismiss();
    switch (option.data) {
      case 'primaryButtonPressed':
        this.gtmService.pushTagV2(GTM_STEPS.CANCEL_MODAL_CLICK_CONFIRM);
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
      case 'secondaryButtonPressed':
        this.gtmService.pushTagV2(GTM_STEPS.CANCEL_MODAL_CLICK_CANCEL);
        break;
    }
  }

  async showTotalDebtModalMobile() {
    const modal = await this.modalCtrl.create({
      component: TceaComponent,
      cssClass: 'form-modal',
      componentProps: RESCHEDULING_TOTAL_DEBT_MODAL,
    });
    await modal.present();
  }

  quotasSelected(value) {
    this.quotaSelected = value;
    if (value === this.quotas[this.quotas.length - 1].value) {
      this.gtmService.pushTagV2(GTM_STEPS.SELECT_LAST_QUOTA);
    }
  }

  public editemail() {
    this.email = '';
    this.emailActive = true;
  }

  get emailValid(): boolean {
    return (!this.emailActive && !this.email) ? true :
      (this.email && (!this.email.includes('*')) ? EMAIL_REGEX.test(this.email.toLowerCase()) : true);
  }

  async simulationCommand() {
    try {
      this.isSimulateLoading = true;
      const question = this.quotas.filter((m) => m.value === this.quotaSelected);
      const request: IReschedulingSimulationRequest = {
        reschedulingKey: this.reschedulingData.reschedulingKey,
        codeQuota: question[0].code,
        email: this.email,
        debtAmount: this.reschedulingData.debtAmount
      };
      this.gtmService.pushTagV2(GTM_STEPS.STEP_ONE_CLICK_SIMULATE);
      const response = await this.service.simulateRescheduling(request);
      this.simulatorValidation.emit(response);
    } catch (error) {
      this.showErrorModal(error);
    } finally {
      this.isSimulateLoading = false;
    }
  }

  async showErrorModal(e) {
    let props;
    switch (e.code) {
      case RECAPTCHA_ERROR_CODE:
        props = RECAPTCHA_MODAL_PROPS;
        break;
      case SESSION_DOESNT_EXIST:
        props = SESSION_DOESNT_EXIST_PROPS;
        break;
      case ID_QUOTA_NOT_FOUND:
        props = ID_QUOTA_NOT_FOUND_PROPS;
        break;
      case RESCHEDULING_SCHEDULE:
        props = RESCHEDULING_SCHEDULE_PROPS;
        break;
      default:
        props = RESCHEDULING_GENERAL_ERROR_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props
    });
    await modal.present();
    await modal.onDidDismiss().then(() => {
      if (e.code == RESCHEDULING_SCHEDULE) {
        return this.router.navigate([HOME_ROUTE], { replaceUrl: true });
      }
    });
  }
}

