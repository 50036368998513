import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HTTP } from '@ionic-native/http/ngx';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Platform } from '@ionic/angular';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { IErrorResponse } from '@common/interfaces/error-response.interface';
import {
  GENERIC_PINNING_SSL_ERROR_MESSAGE,
  PINNING_SSL_HANDSHAKE_ERROR_CODE
} from '@common/constants/ssl-pinning.constants';
import { HttpStatusCode } from '@common/constants/httpStatusCode';

declare type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class SslPinningInterceptor implements HttpInterceptor {

  private URL_REGEX: RegExp = new RegExp('.*bancoripley\.com\.pe.*');

  constructor(
    private advancedHttp: HTTP,
    private platform: Platform,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.platform.is('cordova') && this.URL_REGEX.test(request.url) && environment.SSL_PINNING_ENABLED) {
      return fromPromise(this.handleNativeHttp(request)).pipe(
        catchError((err) => {
          return this.handleError(err);
        }));
    } else {
      return next.handle(request).pipe(
        catchError((err) => {
          return throwError(err);
        }));
    }
  }

  private async handleNativeHttp(request: HttpRequest<any>): Promise<HttpResponse<any>> {
    if (this.platform.is('cordova')) {
      await this.platform.ready();
      const method = request.method.toLowerCase() as HttpMethod;
      const headers = this.convertHeaders(request.headers);
      const data = request.body ? request.body : {};

      if (method === 'post' || method === 'put') {
        this.advancedHttp.setDataSerializer('utf8');
      }

      try {
        const pinnedResponse = await this.advancedHttp.sendRequest(request.url,
          {
            method,
            data,
            headers,
          });

        let body;
        try {
          body = JSON.parse(pinnedResponse.data);
        } catch (error) {
          body = { response: pinnedResponse.data };
        }

        const response = new HttpResponse({
          body,
          status: pinnedResponse.status,
          headers: new HttpHeaders(pinnedResponse.headers),
          url: pinnedResponse.url,
        });

        return Promise.resolve(response);
      } catch (error) {
        const response = new HttpErrorResponse({
          error: error.error,
          status: error.status,
          headers: error.headers,
          url: error.url,
        });

        return Promise.reject(response);
      }
    }
  }

  private handleError(error: any) {
    if (error.status) {
      let newError: IErrorResponse;
      switch (error.status) {
        case PINNING_SSL_HANDSHAKE_ERROR_CODE:
          console.error(GENERIC_PINNING_SSL_ERROR_MESSAGE);
          newError =  {
            code: HttpStatusCode.UNAUTHORIZED.toString(),
            errorMessage: GENERIC_PINNING_SSL_ERROR_MESSAGE,
          };
          return throwError(newError);
        default:
          let response;
          try {
            response = JSON.parse(error.error);
          } catch (error) {
            response = error.error;
          }
          return throwError(response);
      }
    } else {
      return throwError(error);
    }
  }

  private convertHeaders(headers: HttpHeaders) {
    const headersDict = {};
    const keys = headers.keys();
    keys.forEach((header) => {
      headersDict[header] = headers.get(header);
    });
    return headersDict;
  }
}
