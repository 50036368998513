import { environment } from '@environments/environment';
import {
  FINANCIAL_INSTITUTION_MOCK,
  SEND_OTP_MOCK,
  SHIFTS_SCHEDULE_MOCK,
  TRANSFER_MOCK,
  MAX_TRANSFER_AMOUT_MOCK,
  SEND_WIDGET_MOCK,
  GET_WIDGET_TOKEN_INTEROP_MOCK,
  BANK_DIRECTORY_MOCK
} from '@common/mocks/transfer.mocks';
import { HttpService } from '@services/http/http.service';
import {
  IFinancialInstitution,
  ITransferOTPRequest,
  ITransferOTPResponse,
  ITransferRequest,
  ITransferResendOTPRequest,
  ITransferResponse,
  ITransferShiftSchedule,
  IMaxTransferAmount,
  ITransferRequestValidateToken,
  ITransferValidateWidgetResponse,
  ITransferGetTokenWidgetResponse,
  ITransferRequestBetweenAccountToken,
  ITransferRequestThirdAccountToken,
  ITransferRequestInterbankAccountToken,
  IFinancialEntityInfoOutRetrieveInput,
  IResultFinancialEntityInfoResponse,
  ITransferInteropFinantialInstRequest,
  ITransferInteropGetWidgetRequest,
  ITransferGetTokenWidgetInteropResponse,
  ITransferInteropFinantialInstResponse
} from '@common/interfaces/transfers.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(
    private http: HttpService,
  ) { }

  public transferBetweenMyAccounts(params: ITransferRequest): Promise<ITransferResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/transferbetweenmyaccounts';  
    return this.http.post(url, params, TRANSFER_MOCK).toPromise();
  }

  public getWidgetTokenBetweenMyAccounts(params: ITransferRequestBetweenAccountToken): Promise<ITransferGetTokenWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/transferbetweenmyaccounts/get-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public getWidgetTokenThirdParties(params: ITransferRequestThirdAccountToken): Promise<ITransferGetTokenWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/transfertothirdparties/get-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public getWidgetTokenInterbankTransfer(params: ITransferRequestInterbankAccountToken): Promise<ITransferGetTokenWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/interbanktransfer/get-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public getWidgetTokenImmediateInterbankTransfer(params: ITransferRequestInterbankAccountToken): Promise<ITransferGetTokenWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/immediate-interbanktransfer/get-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }  
  
  public validateWidgetTokenBetweenMyAccounts(params: ITransferRequestValidateToken): Promise<ITransferValidateWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/transferbetweenmyaccounts/validate-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public validateWidgetTokenTokenThirdParties(params: ITransferRequestValidateToken): Promise<ITransferValidateWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/transfertothirdparties/validate-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public validateWidgetTokenInterbankTransfer(params: ITransferRequestValidateToken): Promise<ITransferValidateWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/interbanktransfer/validate-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public validateWidgetTokenInmediateInterbankTransfer(params: ITransferRequestValidateToken): Promise<ITransferValidateWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/immediate-interbanktransfer/validate-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

  public sendOTPThirdParties(params: ITransferRequest): Promise<ITransferOTPResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/transfertothirdparties/send';
    return this.http.post(url, params, SEND_OTP_MOCK).toPromise();
  }

  public transferToThirdParties(params: ITransferOTPRequest): Promise<ITransferResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/transfertothirdparties/validate';
    return this.http.post(url, params, TRANSFER_MOCK).toPromise();
  }

  public resendOTPThirdParties(params: ITransferResendOTPRequest): Promise<ITransferOTPResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/transfertothirdparties/resend';
    return this.http.post(url, params, SEND_OTP_MOCK).toPromise();
  }

  public sendOTPOtherBanks(params: ITransferRequest): Promise<ITransferOTPResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interbanktransfer/send';
    return this.http.post(url, params, SEND_OTP_MOCK).toPromise();
  }

  public transferToOtherBanks(params: ITransferOTPRequest): Promise<ITransferResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interbanktransfer/validate';
    return this.http.post(url, params, TRANSFER_MOCK).toPromise();
  }

  public resendOTPOtherBanks(params: ITransferResendOTPRequest): Promise<ITransferOTPResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interbanktransfer/resend';
    return this.http.post(url, params, SEND_OTP_MOCK).toPromise();
  }

  public getFinancialInstitution(code: string): Promise<IFinancialInstitution> {
    const url = environment.BASE_BACKEND_WORKER + `/parameterhbk/bank/${code}`;
    return this.http.get(url, FINANCIAL_INSTITUTION_MOCK).toPromise();
  }

  public getShiftsSchedule(): Promise<ITransferShiftSchedule[]> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/transferSchedule/001';
    return this.http.get(url, SHIFTS_SCHEDULE_MOCK).toPromise();
  }

  public getShiftsScheduleWeb(): Promise<ITransferShiftSchedule[]> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/transferSchedule/005';
    return this.http.get(url, SHIFTS_SCHEDULE_MOCK).toPromise();
  }

  public getMaxTransferAmount(code: string): Promise<IMaxTransferAmount> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/' + code;
    return this.http.get(url, MAX_TRANSFER_AMOUT_MOCK).toPromise();
  }

  public getFinancialEntityAndFees(params: IFinancialEntityInfoOutRetrieveInput): Promise<IResultFinancialEntityInfoResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/financial-entity-info';
    return this.http.post(url, params, TRANSFER_MOCK).toPromise();
  }

  //Interoperatibilidad

  public getbankDirectory(params: ITransferInteropFinantialInstRequest): Promise<ITransferInteropFinantialInstResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interoperability-interbanktransfer/get-directory-sweep';
    return this.http.post(url, params, BANK_DIRECTORY_MOCK).toPromise();
  }
  
  public getWidgetTokenInterop(params: ITransferInteropGetWidgetRequest): Promise<ITransferGetTokenWidgetInteropResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interoperability-interbanktransfer/get-widget-token';
    return this.http.post(url, params, GET_WIDGET_TOKEN_INTEROP_MOCK).toPromise();
  }

  public getWidgetTokenInteropV2(params: ITransferInteropGetWidgetRequest): Promise<ITransferGetTokenWidgetInteropResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/v2/interoperability-interbanktransfer/get-widget-token';
    return this.http.post(url, params, GET_WIDGET_TOKEN_INTEROP_MOCK).toPromise();
  }
  
  public validateWidgetTokenInterop(params: ITransferRequestValidateToken): Promise<ITransferValidateWidgetResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/cashmanagement/interoperability-interbanktransfer/validate-widget-token';
    return this.http.post(url, params, SEND_WIDGET_MOCK).toPromise();
  }

}
