export const BR_DESTINATION = 'Banco Ripley';

export const BR_DESTINATION_CODE = '005';

export const EFEX_REAL_NAME = 'Efectivo Express';

export const TYPE_IS_NOT_MY_NUMBER = 'IS_NOT_MY_NUMBER';

export const TYPE_SMS_NOT_SENT = 'SMS_NOT_SENT';

export const VIEW_SWORN_DECLARATION = 'VIEW_SWORN_DECLARATION';

export const EFEX_MAIN_VIEW = 'EFEX_MAIN_VIEW';

export const IS_NOT_MY_NUMBER = {
    title: '¿No es tu número?',
    label: 'No te procupes, contáctanos al <b>(01) 611-5757</b> para finalizar el desembolso de tu ',
    webButton: 'De acuerdo',
    primaryButton: 'Llamar ahora',
    secondaryButton: 'Cerrar'
};

export const SEND_MY_NUMBER = {
    title: 'Ingresa tu número',
    label: 'Y un asesor te ayudará con tu desembolso',
    webButton: 'Quiero que me contacten',
    primaryButton: 'Quiero que me contacten'
};

export const SMS_ERROR = {
    title: 'Tuvimos un problema con tu SMS',
    label: '!No te preocupes! En unos minutos un asesor te contacatá al ',
    label_end: ' para ayudarte con tu desembolso.',
    webButton: 'Cerrar'
};

export const SMS_NOT_SENT = {
    title: '¿Aún no llega el código?',
    label: 'No te preocupes, <b>un asesor se comunicará contigo</b> pronto al número ',
    label_end: ' para ayudarte a finalizar el desembolso de tu ',
    webButton: 'De acuerdo'
};

export const TOOLTIP_TCEA_EFEX = 'La TCEA es calculada considerando el seguro de desgravamen porcentual sobre' +
    ' el saldo insoluto de la deuda según tarifario vigente. Tasas, Tarifas y Contratos en: ' +
    'www.bancoripley.com.pe, sección: Tasas, Tarifas y Contratos, seleccionando el producto contratado.';
    
export const TOOLTIP_TEA = 'Tasa Efectiva Anual del préstamo.';

export const EFEX_SWORN_DECLARATION = 'Abre tu cuenta ahorro simple y obtén:';

export const EFEX_SWORN_DECLARATION_MOBILE = 'Abre tu cuenta ahorro simple y obtén:';

export const EFEX_SIMULATOR_MAX_ATTEMPS = 6;

export const TITLE_SMS_ATTEMPS_EXCEEDED = 'Se superó el número de intentos con el SMS';