import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IOffering, ILoan } from '@common/interfaces/offerings.interface';
import { OfferingsService } from '@services/offerings/offerings.service';
import { UtilsService } from '@services/utils/utils';
import { OFFERS_LOAN, LEAD_EXECUTION_CODE, NAME_SEF, NAME_EFEX, NAME_SEF_REENGANCHE, NAME_CDD, ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT } from '@common/constants/offerings.constants';
import { EFEX_ROUTE, SEF_ROUTE } from '@common/constants/routes.constants';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import { USER_INFO } from '@common/constants/auth.constants';
import { SUCCESS } from '@common/constants/error.constants';
import { EFEX, SCREEN_WIDTH, SEF } from '@common/constants/misc.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BTN_CLICK } from '@common/constants/tag/tag-generic.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { DYNAMIC_MESSAGE_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
})
export class OfferCardComponent {

  @Input() title: string;
  @Input() label: string;
  @Input() boldLabelClass: string;
  @Input() amount: string;
  @Input() buttonText: string;
  @Input() disclaimer: string;
  @Input() validity: string;
  @Input() showSaleBanner: boolean;
  @Input() route: string;
  @Input() routerStateInformation: IOffering;
  @Input() tagManagerActionLabel: string;
  @Input() icon: string;
  @Input() openModal: (param) => void;
  isLoading: boolean;
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private startLoans: OfferingsService,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private firebaseService: FirebaseService,
    private storage: StorageService,
  ) {
    this.isLoading = false;
  }

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }
  
  // CAMBIO_OFERTA
  async goToRoute() {
    this.isLoading = true;
    this.utilsService.addEventToTagManagerAndFirebase('Ofertas para ti', 'Click', this.tagManagerActionLabel);
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;

    if (this.routerStateInformation.nameProduct === NAME_SEF ||
      this.routerStateInformation.nameProduct === NAME_EFEX ||
      this.routerStateInformation.nameProduct === NAME_SEF_REENGANCHE){
      
      HOME_GA4_CONSTANTS.HOME_LOANS_BTN.product = this.buttonText.replace(/[^\w\s]/gi, '').toLocaleLowerCase();
      HOME_GA4_CONSTANTS.HOME_LOANS_BTN.sub_category = this.routerStateInformation.nameProduct === NAME_EFEX ? EFEX : SEF;
      this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.HOME_LOANS_BTN);
    }

    switch (this.routerStateInformation.nameProduct) {
      case NAME_SEF:
        // TAG VENTAS: SEF
        this.route = '';
        await this.checkStorage();
        if(await this.delayValidation()) return
        this.isLoading = false;
        this.firebaseService.registerStepSefEfex('sef modal',
        { origen: 'OfferCardComponent', dniSearchFormat });
        this.openModal(this.routerStateInformation);
        break;
      case NAME_EFEX:
        // TAG VENTAS: EFEX
        await this.checkStorage();
        this.isLoading = false;
        this.firebaseService.registerStepSefEfex('efex semi automatico',
        { origen: 'OfferCardComponent', dniSearchFormat });
        this.router.navigate([EFEX_ROUTE], { state: this.routerStateInformation });
        break;
      case NAME_SEF_REENGANCHE:
        // TAG VENTAS: SEF REENGANCHE
        this.route = '';
        await this.checkStorage();
        if(await this.delayValidation()) return
        this.isLoading = false;
        this.firebaseService.registerStepSefEfex('sef reenganche modal',
        { origen: 'OfferCardComponent', dniSearchFormat });
        this.openModal(this.routerStateInformation);
        break;
      case NAME_CDD:
        this.route = '';
        await this.checkStorage();
        this.isLoading = false;
        this.openModal(this.routerStateInformation);
        break;
      default:
        this.isLoading = false;
        this.router.navigate([this.route], { state: this.routerStateInformation });
        break;
    }
  }

  private async checkStorage() {
    const offers_loan = sessionStorage.getItem(OFFERS_LOAN);
    if (offers_loan === null || offers_loan === undefined) {
      await this.startLoan(this.routerStateInformation.codeProduct);
    }
  }

  public async delayValidation(){
    this.isLoading = false;
    if(this.routerStateInformation.isPopUpsDynamic){
      DYNAMIC_MESSAGE_MODAL_PROPS['message'] = this.routerStateInformation.messagePopUpsDynamic;
      const modal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal small',
        componentProps: DYNAMIC_MESSAGE_MODAL_PROPS,
      });
      await modal.present();
      return true;
    }
    return false;
  }
  
  private async startLoan(codePorduct: string) {
    sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.MENU);
    try {
      const loanRequest: ILoan = {
        codeProduct: codePorduct,
        reference: window.location.href,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        originLoanPwa: ORIGIN_LOAN_PWA_OPT.MENU
      };
      const result = await this.startLoans.startLoanRegister(loanRequest);
      sessionStorage.setItem(OFFERS_LOAN, 'true');
      sessionStorage.setItem(LEAD_EXECUTION_CODE, result.executionCode);
      if (result.code === SUCCESS) {
        await this.startLoans.getRuoOffersOnOff(); 
      }
    } catch (err) {}
  }
}
