import { Device } from "@ionic-native/device/ngx";

export class DeviceMock extends Device {
  cordova: string;
  model: string;
  platform: string;
  uuid: string;
  version: string;
  manufacturer: string;
  isVirtual: boolean;
  serial: string;
}

export const NEW_DEVICE_MOCK = {
  success: [2000],
  failures: [
    {
      code: 200,
      errorMessage: 'Error al crear el dispositivo'
    }
  ]
};
export const JSONIP_MOCK = {
  success: [
    {
      ip: '200.104.70.83',
      'geo-ip': 'https://getjsonip.com/#plus',
      'API Help': 'https://getjsonip.com/#docs'
    }
  ],
  failures: [
    {
      code: 200,
      errorMessage: ''
    }
  ]
};
export const IDENTIFIER_MOCK = {
  success: [1],
  failures: [
    {
      code: 200,
      errorMessage: ''
    }
  ]
};

export const UUID_DEVICE_MOCK = "1234-5678-9012-3456";

export const ANDROID_UA_MOCK = "Mozilla/5.0 (Linux; Android 11; SM-G991B) AppleWebKit/537.36 Chrome/95.0.4638.54 Mobile Safari/537.36";