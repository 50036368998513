import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sef-t24-modal',
  templateUrl: './sef-t24-modal.component.html',
  styleUrls: ['./sef-t24-modal.component.scss'],
})
export class SefT24ModalComponent {

  @Input() value: string;
  isLoading: boolean;
  
  constructor(
    private modalController: ModalController,
  ) {
    this.isLoading = false;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  closeModal() {
    this.modalController.dismiss('primaryButtonPressed');
  }

}
