import { PRETTY_RIPLEY_PHONE } from './misc.constants';

export const PRODUCT_NAMES = {
  '001': {
    name: 'SEF',
    prettyName: 'Súper Efectivo'
  },
  '005': {
    name: 'EFEX',
    prettyName: 'Efectivo Express'
  },
  '032': {
    name: 'SEF_REENGANCHE',
    prettyName: 'Renovación de Súper Efectivo'
  },
  '007': {
    name: 'CDD',
    prettyName: 'Consolidación de deuda'
  }
};

export const PRODUCT_NAMES_UPDATED = {
  'SEF': 'Préstamo Súper Efectivo',
  'EFEX': 'Efectivo Express',
  'SEF_REENGANCHE': 'Renovación de Súper Efectivo',
  'CDD': 'Consolidación de deuda'
};

export const BUTTON_TEXT = {
  '001': {
    name: 'SEF',
    text: '¡Lo quiero!',
    ripleyPhone: ''
  },
  '005': {
    name: 'EFEX',
    text: 'Acéptalo aquí',
    ripleyPhone: ''
  },
  '032': {
    name: 'SEF_REENGANCHE',
    text: '¡Lo quiero!'
  },
  '007': {
    name: 'CDD',
    text: 'Solicítalo al ',
    ripleyPhone: PRETTY_RIPLEY_PHONE
  }
};


export const BUTTON_TEXT_MOBILE = {
  '001': {
    name: 'SEF',
    text: '¡Lo quiero!'
  },
  '005': {
    name: 'EFEX',
    text: 'Acéptalo aquí'
  },
  '032': {
    name: 'SEF_REENGANCHE',
    text: '¡Lo quiero!'
  },
  '007': {
    name: 'CDD',
    text: '¡Solicítalo aquí!'
  }
};

export const LEAD_HOT = { widget: "1", detail: "2",account: "3" };

export const PRODUCT_SEF = '001';

export const PRODUCT_SEF_REENGANCHE = '032';

export const PRODUCT_EFEX = '005';

export const PRODUCT_CDD = '007';

export const OFFERS_LOAN = 'OFFERS_LOAN';

export const LEAD_EXECUTION_CODE = 'LEAD_EXECUTION_CODE';

export const NAME_SEF = 'SEF';

export const NAME_EFEX = 'EFEX';

export const NAME_SEF_REENGANCHE = 'SEF_REENGANCHE';

export const NAME_CDD = 'CDD';

export const ORIGIN_LOAN_PWA = 'ORIGIN_LOAN_PWA';

export const ORIGIN_LOAN_PWA_OPT = {
  MENU : 'MENU',
  POPUP : 'POPUP',
  BANNER : 'BANNER',
}