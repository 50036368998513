import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ITransferOTPRequest,
  ITransferRequest,
  ITransferResendOTPRequest,
  ITransferResponse,
  ITransferOTPResponse
} from '@common/interfaces/transfers.interface';
import { IUserInformation } from '@common/interfaces/user.interface';
import { IVoucherPage } from '@common/interfaces/voucher.interface';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import {
  OTP_CODE_LENGTH,
  TRANSFER_BETWEEN_MY_ACCOUNTS,
  TRANSFER_GA4_CONSTANTS,
  TRANSFER_OTHER_BANKS,
  TRANSFER_THIRD_PARTIES
} from '@common/constants/transfers.constants';
import { OTP_MAX_TIME } from '@common/constants/enrollment.constants';
import { Router } from '@angular/router';
import { TRANSFER_ROUTE, VOUCHER_ROUTE } from '@common/constants/routes.constants';
import { TransferService } from '@services/transfer/transfer.service';
import { UserService } from '@services/user/user.service';
import { TransferErrorModalComponent } from '@components/transfer-error-modal/transfer-error-modal.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EFEX_FAILED_MODAL_PROPS, TRANFER_CTS_FAILED_MODAL_PROPS, TRANSFER_ERROR_GENERAL, TRANSFER_ERROR_INSUFFICIENT_BALANCE, TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF } from '@common/constants/modal-props.constants';
import { UtilsService } from '@services/utils/utils';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss'],
})
export class TransferModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() primaryButtonText: string;
  @Input() icon: string;
  @Input() voucher: IVoucherPage;
  @Input() transferFormValue: any;
  @Input() currentType: string;
  @Input() customerComissionAmount: number | string;
  @Input() currencySymbol: string;
  @Input() sessionId: string;
  @Input() phoneNumber: string;
  @Input() ownAccount: boolean;
  @Input() finantialInstitution: string;
  @Input() currentTypeG4: string;
  isTransfering: boolean;
  url: string;
  userInformation: IUserInformation;
  otpCodeForm: FormGroup;
  maxTime: number;
  showTimer: boolean;
  sendingOtp: boolean;
  timer: NodeJS.Timeout;
  phoneNumberBeneficiary: string;
  errorModal: HTMLIonModalElement;

  constructor(
    private modalController: ModalController,
    private modalErrorCtrl: ModalController,
    private transferService: TransferService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.title = '';
    this.message = '';
    this.primaryButtonText = '';
    this.icon = 'i-confirm-modal';
    this.voucher = {} as IVoucherPage;
    //this.transferFormValue = {} as any;
    this.currentType = this.betweenMyAccounts;
    this.isTransfering = false;
    this.url = this.router.url;
    this.userInformation = this.userService.userInformation;
    this.maxTime = OTP_MAX_TIME;
    this.showTimer = true;
    this.sendingOtp = false;
    this.customerComissionAmount = 0;
    this.errorModal = null;
    this.ownAccount = true;
    }

  async ngOnInit() {
    this.createOtpCodeForm();
    this.setOtpTimer();
    this.sendingOtp = false;

    if(this.isCurrentType(this.betweenMyAccounts) || this.isCurrentType(this.thirdParties) ){
      TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.TAG.sub_category = this.currentTypeG4;
      this.googleAnalyticsService.gtagPushEvent(TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.event, TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.TAG);
    }else{
      TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.TAG1.sub_category = this.ownAccount?'interbancaria - cuenta propia':'interbancaria - otra cuenta';
      TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.TAG1.destination_bank = this.finantialInstitution.toLocaleLowerCase();
      this.googleAnalyticsService.gtagPushEvent(TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.event, TRANSFER_GA4_CONSTANTS.TRANSFER_MODAL_SMS_PGE.TAG1);
    }
   
   // if (!this.isCurrentType(this.betweenMyAccounts)) { await this.sendOTP(); }
  }

  ionViewWillEnter() {
    UtilsService.showRecaptchaBadge();
  }

  ionViewWillLeave() {
    UtilsService.hideRecaptchaBadge();
  }

  public get otpCodeLength(): number { return OTP_CODE_LENGTH; }
  public get betweenMyAccounts(): string { return TRANSFER_BETWEEN_MY_ACCOUNTS; }
  public get thirdParties(): string { return TRANSFER_THIRD_PARTIES; }
  public get otherBanks(): string { return TRANSFER_OTHER_BANKS; }

  private createOtpCodeForm() {
    this.otpCodeForm = this.formBuilder.group({
      otpCode: [
        { value: null, disabled: this.isCurrentType(this.betweenMyAccounts) },
        [Validators.required, Validators.minLength(this.otpCodeLength), Validators.maxLength(this.otpCodeLength)]
      ]
    });
  }

  public isCurrentType(type: string): boolean {
    return this.currentType === type;
  }

  public async executeTransfer() {
    if (this.otpCodeForm.invalid) { return; }
    this.isTransfering = true;
    //this.utilsService.addEventToTagManagerAndFirebase('Transferencias', 'click', 'Confirmar_transferencia');
    TRANSFER_GA4_CONSTANTS.TRANFER_MODAL_SMS_BTN.TAG.sub_category = this.currentTypeG4;
    this.googleAnalyticsService.gtagPushEvent(TRANSFER_GA4_CONSTANTS.TRANFER_MODAL_SMS_BTN.event, TRANSFER_GA4_CONSTANTS.TRANFER_MODAL_SMS_BTN.TAG);
    try {
      const transaction: ITransferResponse = await this.transferCall();
      if (transaction.code === '00') {
        this.voucher.card.footerRightSide[1].content = transaction.transactionalSerialNumber; 
        this.voucher.transferTypeDes = this.currentType;
        this.voucher.productType = "TRANSFER"      
        this.voucher.finantialInstitution = this.finantialInstitution;       
        this.modalController.dismiss('primaryButtonPressed');
        this.router.navigate([`${TRANSFER_ROUTE}/${VOUCHER_ROUTE}`], { replaceUrl: true, state: this.voucher });
      } else if (transaction.code && transaction.message) {
        this.openTransferFailedModal(transaction.message);
      } else {
        this.fillTransferVoucherError(transaction.message);
        this.modalController.dismiss('primaryButtonPressed');
        this.router.navigate([`${TRANSFER_ROUTE}/${VOUCHER_ROUTE}`], { replaceUrl: true, state: this.voucher });
      }

    } catch (err) {
      const alertMessage = null;
      
      try {
        if(err.error) {
          this.openTransferFailedModal(JSON.parse(err.error.messages[0]).Message);
        } else {
          this.openTransferFailedModal(JSON.parse(err.messages[0]).Message);
        }
      } catch (e) {
        this.openTransferGenericFailModal();
      } finally {
        if (alertMessage) { alertMessage.present(); }
      } 
    }
    this.isTransfering = false;
  }

  public async openTransferFailedModal(message: string) {
    TRANFER_CTS_FAILED_MODAL_PROPS.message = message;
    if (!this.errorModal) {
      this.errorModal = await this.modalController.create({
        component: GenericModalComponent,
        cssClass: 'form-modal',
        componentProps: TRANFER_CTS_FAILED_MODAL_PROPS
      });
      this.errorModal.onDidDismiss()
        .then(() => {
          this.dismissModal();
        });

      this.errorModal.present();

    }
  }

  public async openTransferGenericFailModal() {
    if (!this.errorModal) {
      this.errorModal = await this.modalController.create({
        component: GenericModalComponent,
        cssClass: 'form-modal',
        componentProps: EFEX_FAILED_MODAL_PROPS
      });
      this.errorModal.onDidDismiss()
        .then(() => {
          this.dismissModal();
        });

      this.errorModal.present();

    }
  }

  public async openTransferErrorModal(prop) {
    const modal = await this.modalErrorCtrl.create({
      component: TransferErrorModalComponent,
      componentProps: prop
    });
    await modal.present();
  } 


  private async transferCall() {
    switch (this.currentType) {
      case this.betweenMyAccounts: return await this.transferService.transferBetweenMyAccounts(this.transferFormValue);
      case this.thirdParties: return await this.transferService.transferToThirdParties(this.otpValidation);
      case this.otherBanks: return await this.transferService.transferToOtherBanks(this.otpValidation);
    }
  }

  private fillTransferVoucherError(errorMessage) {
    this.voucher.title = this.voucherTitleError;
    this.voucher.icon = 'i-alert-purple';
    this.voucher.wasUnsuccessful = true;
    this.voucher.card.header.staticText = '';
    this.voucher.card.header.staticText2 = '';
    this.voucher.card.header.staticText3 = '';
    this.voucher.card.subHeader.staticText = '';
    this.voucher.card.header.dynamicText2 = '';
    this.voucher.card.header.dynamicText3 = '';
    this.voucher.card.header.dynamicText = 'Lo sentimos';
    this.voucher.card.subHeader.dynamicText = 'No hemos podido realizar la transferencia';
    this.voucher.card.rightSide[1].content = '';
    this.voucher.card.rightSide[2].content = '';
    this.voucher.card.leftSide[1].content = 'Disculpa los inconvenientes';
    this.voucher.card.leftSide[2].content = 'Por favor, inténtalo nuevamente';
  }

  private get voucherTitleError(): string {
    return `<strong>Transferencia</strong> incompleta`;
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  get otpCode() { return this.otpCodeForm.controls.otpCode; }

 private get otpValidation(): ITransferOTPRequest {
     return {
      sessionId: this.sessionId,
      PIN: this.otpCode.value,
      transferRequest: this.transferFormValue
    };
  }

  private get otpResend(): ITransferResendOTPRequest {
    return { sessionId: this.sessionId };
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.utilsService.addEventToTagManagerAndFirebase('Transferencias', 'click', 'TimeoutSMS');
        this.showTimer = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.showTimer = true;
        this.setOtpTimer();
      } else {
        this.showTimer = false;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  get formattedComission(): string {
    return Number(this.customerComissionAmount).toFixed(2);
  }

  public async sendOTP(resend: boolean = false) {
    if (this.isCurrentType(this.betweenMyAccounts)) { return; }
    this.sendingOtp = true;
    try {
      if (!resend || !this.sessionId) {
        this.utilsService.addEventToTagManagerAndFirebase('Transferencias', 'click', 'Enviar_Codigo');
        const otpResponse: ITransferOTPResponse = await this.sendOtpCall();
        if (Number(otpResponse.code) === 1) {
          this.sessionId = otpResponse.sessionId;
          this.phoneNumber = otpResponse.phoneNumber;
          this.customerComissionAmount = otpResponse.customerComissionAmount;
        }else if(Number(otpResponse.code) === 736){
          this.modalController.dismiss(otpResponse);
          this.openTransferErrorModal(TRANSFER_ERROR_INSUFFICIENT_BALANCE);
        }else if(Number(otpResponse.code) === 737){
          this.modalController.dismiss(otpResponse);
          this.openTransferErrorModal(TRANSFER_ERROR_INSUFFICIENT_BALANCE_ITF);
        }
         else {
          this.modalController.dismiss();
          this.openTransferErrorModal(TRANSFER_ERROR_GENERAL);
        }

      } else {
        this.utilsService.addEventToTagManagerAndFirebase('Transferencias', 'click', 'Reenviar_Codigo');
        await this.resendOtpCall();
      }
      this.maxTime = OTP_MAX_TIME;
      this.showTimer = true;
    } catch (err) {
      let message = null;
      const alertMessage = null;
      try {
        message = JSON.parse(err.error.messages[0]).Message;
        await this.openTransferFailedModal(message);
      } catch (e) {
        this.openTransferGenericFailModal();
      } finally {
        if (alertMessage) { alertMessage.present(); }
      }
    }

    this.setOtpTimer();
    this.sendingOtp = false;
  }

  private async sendOtpCall() {
    switch (this.currentType) {
      case this.thirdParties: return await this.transferService.sendOTPThirdParties(this.transferFormValue);
      case this.otherBanks: return await this.transferService.sendOTPOtherBanks(this.transferFormValue);
    }
  }

  private async resendOtpCall() {
    switch (this.currentType) {
      case this.thirdParties: return await this.transferService.resendOTPThirdParties(this.otpResend);
      case this.otherBanks: return await this.transferService.resendOTPOtherBanks(this.otpResend);
    }
  }

  public prettifyMaskedPhoneNumber(maskedPhoneNumber: string = ''): string {
    return maskedPhoneNumber && maskedPhoneNumber.substr(2).replace(/(.{3})(.{3})(.{3})/gi, '$1 $2 $3');
  }
}
