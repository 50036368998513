export const GTM_STEPS_GENERAL = {
    event: 'reprogramacionDeuda_event',
    event_category: 'flujoReprogramacionDeuda',
};


export const GTM_STEPS = {
    SELECT_BANNER_RESCHEDULING: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_0',
        event_label: 'home_reprogramar_deuda_pidelo_aqui'
    },
    SHOW_QUESTIONS: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_1',
        event_label: 'despliega_preguntas_reprogramacion'
    },
    SELECT_FIRST_QUESTION: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_1.1',
        event_label: 'ultima_opcion_marcada_pregunta_1'
    },
    SELECT_SECOND_QUESTION: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_1.2',
        event_label: 'ultima_opcion_marcada_pregunta_2'
    },
    STEP_QUESTION_CLICK_CONTINUE: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_1.3',
        event_label: 'continuar_reprogramacion_en_preguntas'
    },
    STEP_QUESTION_CLICK_CANCEL: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_1.4',
        event_label: 'cancelar_reprogramacion_en_preguntas'
    },
    SHOW_MODAL_WITHOUT_SALARY: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_0.1',
        event_label: 'modal_marca_sin_ingreso_reprogramacion'
    },
    SHOW_STEP_ONE: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2',
        event_label: 'despliega_seccion_cuota'
    },
    SELECT_LAST_QUOTA: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.1',
        event_label: 'seleccion_ultima_cuota_reprogramacion'
    },
    STEP_ONE_CLICK_SIMULATE: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.2',
        event_label: 'simular_reprogramacion_en_cuota'
    },
    STEP_ONE_CLICK_CANCEL: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.3',
        event_label: 'cancelar_reprogramacion_en_cuota'
    },
    SHOW_CANCEL_MODAL: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.4',
        event_label: 'modal_salir_proceso_reprogramacion'
    },
    CANCEL_MODAL_CLICK_CANCEL: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.5',
        event_label: 'cancelar_en_modal_salir'
    },
    CANCEL_MODAL_CLICK_CONFIRM: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_2.6',
        event_label: 'modal_boton_si_salir'
    },
    SHOW_STEP_TWO: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_3',
        event_label: 'despliega_seccion_detalle_reprogramacion'
    },
    ACCEPT_TERMS_AND_CONDITIONS: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_3.1',
        event_label: 'acepta_condiciones_en_detalle_reprogramacion'
    },
    SHOW_SELECT_TYPE_OTP: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_3.2',
        event_label: 'despliega_metodo_autentificacion_detalle'
    },
    SHOW_USER_WITHOUT_DATA: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_4',
        event_label: 'despliega_informacion_no_hay_datos_cliente'
    },
    CLICK_GO_TO_HOME: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_4.1',
        event_label: 'ir_a_inicio_desde_autentificacion'
    },
    sms: {
        sendOtp: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.2',
            event_label: 'autentificacion_presiona_codigo_sms_repro'
        },
        enterOtpCode: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.4',
            event_label: 'ingresa_codigo_sms_en_autentificacion_repro'
        },
        changeOtpType: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.5',
            event_label: 'boton_cambiar_metodo_en_sms'
        },
        lastStepRescheduleButton: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.6',
            event_label: 'boton_reprogramar_en_autentificacion_sms'
        },
        lastStepCancelButton: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.7',
            event_label: 'cancelar_en_autentificacion_repro_sms'
        }
    },
    email: {
        sendOtp: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.3',
            event_label: 'autentificacion_presiona_codigo_correo_repro'
        },
        enterOtpCode: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.8',
            event_label: 'ingresa_codigo_mail_en_autentificacion_repro'
        },
        changeOtpType: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.9',
            event_label: 'boton_cambiar_metodo_en_mail'
        },
        lastStepRescheduleButton: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.10',
            event_label: 'boton_reprogramar_en_autentificacion_mail'
        },
        lastStepCancelButton: {
            ...GTM_STEPS_GENERAL,
            event_action: 'step_4.11',
            event_label: 'cancelar_en_autentificacion_repro_mail'
        }
    },
    SHOW_RESCHEDULING_VOUCHER: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_5',
        event_label: 'exito_reprogramacion_deuda'
    },
    GO_TO_HOME_VOUCHER: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_5.1',
        event_label: 'volver_a_inicio_desde_exito_reprogramacion'
    },
    GO_TO_MOVEMENTS_VOUCHER: {
        ...GTM_STEPS_GENERAL,
        event_action: 'step_5.2',
        event_label: 'ver_movimientos_desde_exito_reprogramacion'
    }
};

