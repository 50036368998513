import { ModalController } from '@ionic/angular';
import { EFEX_AUTO_ROUTE } from '@common/constants/routes.constants';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IPreRegisterSave } from '@common/interfaces/open-account.interface';
import { ILoan, IOffering, IOfferState } from '@common/interfaces/offerings.interface';
import { LEAD_EXECUTION_CODE, NAME_EFEX, NAME_SEF, NAME_SEF_REENGANCHE, OFFERS_LOAN, ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT, PRODUCT_EFEX, PRODUCT_SEF } from '@common/constants/offerings.constants';
import {
  DYNAMIC_MESSAGE_MODAL_PROPS,
  END_TO_END_OFF_MODAL_PROPS, OFFER_EFEX_INACTIVE_MODAL_PROPS,
  OFFER_SEF_INACTIVE_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { OP_SHOW_BENEFITS } from '@common/constants/tag/tag-open-account.constants';
import { ILeadModalBody, ILoanLead } from '@common/interfaces/loan-lead.interface';
import { OfferingsService } from '@services/offerings/offerings.service';
import { LeadGenericModalComponent } from '@components/leads-modal/lead-generic-modal/lead-generic-modal.component';
import { SUCCESS } from '@common/constants/error.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { EFEX, SEF } from '@common/constants/misc.constants';
import { GENERIC_TAG_BTN_CLICK } from '@common/constants/tag/tag-generic.constants';

@Component({
  selector: 'app-offer-card-purple',
  templateUrl: './offer-card-purple.component.html',
  styleUrls: ['./offer-card-purple.component.scss'],
})
export class OfferCardPurpleComponent {

  @Input() title: string;
  @Input() label: string;
  @Input() boldLabelClass: string;
  @Input() amount: string;
  @Input() buttonText: string;
  @Input() disclaimer: string;
  @Input() validity: string;
  @Input() showSaleBanner: boolean;
  @Input() route: string;
  @Input() routerState?: any;
  @Input() stateArguments?: object;
  @Input() tagManagerActionLabel: string;
  @Input() icon: string;
  iPreRegisterSave: IPreRegisterSave;
  isLoading: boolean;

  constructor(private router: Router,
              private modalCtrl: ModalController,
              private googleAnalyticsService: GoogleAnalyticsService,
              private offeringService: OfferingsService) {
    this.isLoading = false;
  }

  async goToRoute() {
    if ((this.routerState as IOffering).nameProduct === NAME_SEF ||
        (this.routerState as IOffering).nameProduct === NAME_EFEX ||
        (this.routerState as IOffering).nameProduct === NAME_SEF_REENGANCHE){
      
      HOME_GA4_CONSTANTS.HOME_LOANS_BTN.product = this.buttonText.replace(/[^\w\s]/gi, '').toLocaleLowerCase();
      HOME_GA4_CONSTANTS.HOME_LOANS_BTN.sub_category = (this.routerState as IOffering).nameProduct === NAME_EFEX ? EFEX : SEF;
      this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.HOME_LOANS_BTN);
    }

    if (this.route === EFEX_AUTO_ROUTE ) {
      this.isLoading = true;
      await this.checkStorage();
      if(await this.delayValidation()) return
      this.isLoading = false;
      if (!(this.routerState as IOffering).endToEnd) {
        this.showEndToEndOffModal();
        return ;
      } else {
        if ((this.routerState as IOffering).offerInactive) {
          this.showOfferInactiveModal();
        } else {
          const stateArgs: IOfferState = {
            offeringData: this.routerState as IOffering,
            isEfexPromotional: true
          };
          this.router.navigate([this.route], { state: stateArgs });
        }
      }
    } else {
      if(await this.delayValidation()) return
      await this.router.navigate([this.route], { state: this.stateArguments });
      // this.router.navigate([this.route], { state: {isFromOffer: true} });
    }
  }

  public async showOfferInactiveModal() {
    let props = {};
    switch ((this.routerState as IOffering).codeProduct) {
      case PRODUCT_EFEX:
        props = OFFER_EFEX_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF:
        props = OFFER_SEF_INACTIVE_MODAL_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public async showEndToEndOffModal() {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const loanLead: ILoanLead = {
        codeProduct: PRODUCT_EFEX,
        executionCode,
        reference: window.location.href,
        callCenterReason: ''
      };

      const leadModalBody: ILeadModalBody = END_TO_END_OFF_MODAL_PROPS;
      const props = {
        leadModalBody,
        loanLeadData: loanLead
      };
      const modal = await this.modalCtrl.create({
        component: LeadGenericModalComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
    }
  }

  private async checkStorage() {
    const offers_loan = sessionStorage.getItem(OFFERS_LOAN);
    if (offers_loan === null || offers_loan === undefined) {
      await this.startLoan();
    }
  }

  public async delayValidation(){
    this.isLoading = false;
    if((this.routerState as IOffering).isPopUpsDynamic){
      DYNAMIC_MESSAGE_MODAL_PROPS['message'] = (this.routerState as IOffering).messagePopUpsDynamic;
      const modal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal small',
        componentProps: DYNAMIC_MESSAGE_MODAL_PROPS,
      });
      await modal.present();
      return true;
    }
    return false;
  }

  private async startLoan() {
    sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.MENU);
    try {
      const loanRequest: ILoan = {
        codeProduct: PRODUCT_EFEX,
        reference: window.location.href,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        isFeedback: false,
        originLoanPwa: ORIGIN_LOAN_PWA_OPT.MENU
      };
      const result = await this.offeringService.startLoanRegister(loanRequest);
      sessionStorage.setItem(OFFERS_LOAN, 'true');
      sessionStorage.setItem(LEAD_EXECUTION_CODE, result.executionCode);
    } catch (err) {}
  }
}
