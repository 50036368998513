import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { RIPLEY_PHONE, SCREEN_WIDTH } from '@common/constants/misc.constants';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';
import { ILoan } from '@common/interfaces/offerings.interface';
import { ModalController } from '@ionic/angular';
import { OfferingsService } from '@services/offerings/offerings.service';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import {
  EFEX_NA_FEEDBACK_MODAL_INIT, EFEX_NA_OPTION_SELECTED, EFEX_NA_SEND_FEEDBACK
} from '@common/constants/tag/tag-efex-automatic.constants';
import { ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT, PRODUCT_EFEX } from '@common/constants/offerings.constants';
import { DeviceService } from '@services/device/device.service';
import { EfexService } from '@services/efex/efex.service';
import { IEfexCualificationListIN, IEfexCualificationOptionsOut, IEfexCualificationQuestionsOut } from '@common/interfaces/efex.interface';
import { EFEX_QUALIFICATION_CODE } from '@common/constants/efex.constants';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EFEX_FAILED_MODAL_PROPS } from '@common/constants/modal-props.constants';

@Component({
  selector: 'app-efex-sef-feedback',
  templateUrl: './efex-sef-feedback.component.html',
  styleUrls: ['./efex-sef-feedback.component.scss'],
})
export class EfexSefFeedbackComponent implements OnInit {

  isOptionSelected: boolean;
  optionSelected: number;
  isOtherSelected: boolean;
  commentary: string;
  isErrorFeedback = false;
  errorFeedback = '';
  isLoading: boolean;
  isInit: boolean;
  questionBody: IEfexCualificationQuestionsOut;
  questions: IEfexCualificationOptionsOut[];
  @Input() codeProduct: string;
  @Input() haveBrAccount?: boolean;
  constructor(private viewController: ModalController,
              private saveFeedback: OfferingsService,
              private router: Router,
              private gtmService: GoogleTagManagerService,
              private deviceService: DeviceService,
              private efexService: EfexService,
              private modalCtrl: ModalController) {
    this.isOtherSelected = false;
    this.commentary = '';
    this.isOptionSelected = false;
    this.isLoading = false;
    this.isInit = true;
    this.questions = [];
  }

  ngOnInit() {
    this.getFeedbackQuestions();
    if (this.codeProduct === PRODUCT_EFEX ) {
      this.sendTag(EFEX_NA_FEEDBACK_MODAL_INIT, undefined);
    }
  }

  async getFeedbackQuestions() {
    try {
      const iEfexIn: IEfexCualificationListIN = {
        qualificationCode: EFEX_QUALIFICATION_CODE
      };
      const response = await this.efexService.FeedbackList(iEfexIn);
      this.questionBody = response.questions[0];
      this.questions = this.questionBody.options;
      this.isInit = false;
    } catch (error) {
      this.dismissModal();
      await this.openErrorAlert();
    }
  }

  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }
  get ripleyPhone() { return RIPLEY_PHONE; }

  private sendTag(tag_new_account, tag_existing_account) {
    if (!this.haveBrAccount) {
      if (tag_new_account !== undefined) { this.gtmService.pushTagV2(tag_new_account); }
    } else {
      if (tag_existing_account !== undefined) { this.gtmService.pushTagV2(tag_existing_account); }
    }
  }

  onChecked(event) {
    this.commentary = '';
    this.isErrorFeedback = false;
    this.errorFeedback = '';
    this.isOtherSelected = parseInt(event.detail.value) === 4 ? true : false;

    this.isOptionSelected = !this.isOtherSelected;
  }

  onComentary() {
    const commentary = this.commentary;
    this.isOptionSelected = commentary.trim().length > 0 ? true : false;
  }

  dismissModal() {
    this.viewController.dismiss();
  }

  async sendFeedback() {

    if (this.optionSelected !== undefined) {
      if (this.isOtherSelected && this.commentary.trim().length === 0) {
        this.isErrorFeedback = true;
        this.errorFeedback = 'Ingresa un comentario';
        return;
      }
      this.isLoading = true;
      const answer = document.getElementById('answer' + this.optionSelected).innerHTML;

      if (this.codeProduct === PRODUCT_EFEX) {
        const LABEL_DICT = {
          label: EFEX_NA_OPTION_SELECTED.event_label,
          option: answer
        };
        EFEX_NA_OPTION_SELECTED.event_label = JSON.stringify(LABEL_DICT);
        this.sendTag(EFEX_NA_OPTION_SELECTED, undefined);
        this.sendTag(EFEX_NA_SEND_FEEDBACK, undefined);
      }
      
      sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.POPUP);
      try {
        const loanRequest: ILoan = {
          codeProduct: this.codeProduct,
          reference: window.location.href,
          utmCampaing: '',
          utmContent: '',
          utmMedium: '',
          utmSource: '',
          descriptionQuestions: answer,
          detailInput: this.commentary,
          isFeedback : true,
          originLoanPwa: ORIGIN_LOAN_PWA_OPT.POPUP
        };

        await this.saveFeedback.startLoanRegister(loanRequest);
        this.isLoading = false;
        this.viewController.dismiss();
        return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
      } catch (err) {
        this.isLoading = false;
        this.viewController.dismiss();
      }
      this.viewController.dismiss();
    } else {
        this.isErrorFeedback = true;
        this.errorFeedback = 'Seleciona una opción';
    }
  }

  public openPhoneNumber(phoneNumber: string) {
    if (this.isMobile) {
      document.location.href = `tel:${phoneNumber}`;
    }
  }

  async openErrorAlert() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_FAILED_MODAL_PROPS,
    });
    await modal.present();
  }

}
