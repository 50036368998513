export const PLUS_BENEFITS = [
  {
    icon: 'i-money-bill-pink',
    content: 'Tasa de interés Efectiva Anual en soles.'
  },
  {
    icon: 'i-cost-zero',
    content: 'El costo de mantenimiento de cuenta queda exonerado para todos los clientes de Ahorro Plus hasta el 31/12/2023.'
  },
  {
    icon: 'i-money-exchange',
    content: 'Dos retiros gratuitos en cualquier cajero de la red MasterCard.'
  },
  {
    icon: 'i-credit-card',
    content: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.'
  },
  {
    icon: 'i-cellphone',
    content: 'Acceso a la Banca por Internet para realizar tus pagos y transferencias.'
  },
  {
    icon: 'i-question',
    content: 'Atención en horario extendido de lunes a domingo en las agencias.'
  }
];

export const SIMPLE_BENEFITS = [
  {
    icon: 'i-money-bill-pink',
    content: 'Cero costo de mantenimiento tengas el saldo que tengas.'
  },
  {
    icon: 'i-cost-zero',
    content: '10 retiros libres en cualquier cajero de la red MasterCard.'
  },
  {
    icon: 'i-money-exchange',
    content: 'Tarjeta de Débito para realizar compras en el Perú y en el extranjero.'
  },
  {
    icon: 'i-credit-card',
    content: 'Tasa de interés Efectiva Anual de 0.15% en soles y 0.10% en dólares.'
  },
  {
    icon: 'i-cellphone',
    content: 'Acceso a la Banca por Internet para realizar tus pagos y transferencias.'
  },
  {
    icon: 'i-question',
    content: 'Atención en horario extendido de lunes a domingo en las agencias.'
  }
];

export const CTS_BENEFITS = [
  {
    icon: 'i-money-bill-pink',
    content: 'Todas sus transacciones son gratuitas.'
  },
  {
    icon: 'i-cost-zero',
    content: 'Estado de cuenta totalmente gratis en mayo y noviembre.'
  },
  {
    icon: 'i-money-exchange',
    content: 'Disposición de fondos en todas las cajas de Tiendas Financieras de Banco Ripley o Centros de Tarjetas en Tiendas Ripley.'
  },
  {
    icon: 'i-credit-card',
    content: 'Cuenta exonerada del Impuesto a las Transacciones Financieras (ITF).'
  },
  {
    icon: 'i-credit-card',
    content: 'Tarjeta de Débito para disposición de efectivo de su disponible. No válido para compras.'
  }
];

export const LEGAL_URL = 'https://www.bancoripley.com.pe/pdf/migracion-numero-cuenta-banco-ripley.pdf';

export const SAVINGS_ACCOUNTS_TYPES = {
  SIMPLE: 'SIMPLE',
  PLUS: 'PLUS',
  CTS: 'CTS',
  TERMINOS: 'TCPROPAS'
};
