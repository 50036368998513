import { LoanLeadService } from './../../../services/loan-lead/loan-lead.service';
import { PRODUCT_SEF, PRODUCT_EFEX, LEAD_EXECUTION_CODE, ORIGIN_LOAN_PWA } from './../../../common/constants/offerings.constants';
import { SimulatorService } from '@services/simulator/simulator.service';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  EFEX_REAL_NAME, TITLE_SMS_ATTEMPS_EXCEEDED,
  TYPE_IS_NOT_MY_NUMBER, TYPE_SMS_NOT_SENT
} from '@common/constants/efex-automatic.constants';
import { OTP_LENGTH, OTP_MAX_TIME, SEND_OTP_SUCCESSFUL } from '@common/constants/enrollment.constants';
import { EFEX, MILLISECONDS_TO_SECOND, RIPLEY_PHONE, SCREEN_WIDTH, SEF } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { AlertService } from '@services/alert/alert.service';
import { Subscription } from 'rxjs';
import { AlternativeOfferComponent } from '../modals/alternative-offer/alternative-offer.component';
import { ISmsResponse } from '@common/interfaces/simulator.interface';
import { ERROR_CODE_SMS } from '@common/constants/open-account.constants';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';
import { SEF_REAL_NAME } from '@common/constants/sef-automatic.constants';
import { ILoanLead } from '@common/interfaces/loan-lead.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS, DISBURSEMENT_FAILED_WEB_MODAL_PROPS, LOAN_LEAD_FAILED_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import { LeadSuccessComponent } from '@components/leads-modal/lead-success/lead-success.component';
import { LeadExistModalComponent } from '@components/leads-modal/lead-exist-modal/lead-exist-modal.component';
import { DisbursementErrorComponent } from '../modals/disbursement-error/disbursement-error.component';
import { DeviceService } from '@services/device/device.service';
import { IEfexSefState } from '@common/interfaces/efex-sef-automatic.interface';
import { LEAD_EXIST_ERROR } from '@common/constants/efex.constants';
import { SUCCESS } from '@common/constants/error.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { GENERIC_TAG_BANCO_RIPLEY, GENERIC_TAG_BTN_CLICK, GENERIC_TAG_OTRO_BANCO, GENERIC_TAG_VIRTUAL_PAGE } from '@common/constants/tag/tag-generic.constants';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-efex-sef-sms-auth',
  templateUrl: './efex-sef-sms-auth.component.html',
  styleUrls: ['./efex-sef-sms-auth.component.scss'],
})
export class EfexSefSmsAuthComponent implements OnDestroy {

  @Input() efexSefState: IEfexSefState;
  @Input() productRealName: string;
  @Input() bankName: string = '';
  @Input() clientName: string;
  @Input() mobileNumber: string;
  @Input() checkingSMSMessage: boolean;
  @Input() isDestinationBrAccount: boolean;
  @Input() isNewSimpleAccount: boolean;
  @Output() otpCodeValidation: EventEmitter<boolean>;
  @Output() otpCodeValue: EventEmitter<string>;
  subscription: Subscription;
  otpCodeForm: FormGroup;
  smsCheckMessage = '';
  showTimerSection: boolean;
  maxTime: number;
  hideTimer: boolean;
  hideText: HideTextPipe;
  validatingOtp: boolean;
  url: string;
  timer: NodeJS.Timeout;
  errorTimes: number;
  isDisabled: boolean;
  lastCode = '';
  isSavingLead: boolean;

  constructor(private formBuilder: FormBuilder,
              private modalCtrl: ModalController,
              private simulatorService: SimulatorService,
              private alertService: AlertService,
              private router: Router,
              private googleAnalyticsService: GoogleAnalyticsService,
              private loanLeadService: LoanLeadService,
              private deviceService: DeviceService,
              private utilsService: UtilsService) {
    this.otpCodeValidation = new EventEmitter();
    this.otpCodeValue = new EventEmitter();
    this.hideText = new HideTextPipe();
    this.subscription = new Subscription();
    this.validatingOtp = false;
    this.isDisabled = false;
    this.isSavingLead = false;
    this.otpCodeForm =  this.formBuilder.group({
      otpCode: new FormControl('', Validators.compose([
        Validators.required, Validators.maxLength(OTP_LENGTH),
        Validators.required, Validators.minLength(OTP_LENGTH),
      ])),
    });
    this.subscription.add(this.otpCode.valueChanges.subscribe((code: string) => {
      if (this.lastCode !== code) {
        this.lastCode = code;
        if (code.length === OTP_LENGTH && !this.validatingOtp) {
          switch (this.productRealName) {
            case EFEX_REAL_NAME:
            case SEF_REAL_NAME:
            default:
              break;
          }

        } else if (code.length === 1 && !this.validatingOtp) {
          this.updateCheckMessage(4, '');
        }
      }
      this.emitOtp();
    }));
    this.sendSms();
  }

  ngOnInit(){
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.sub_category = this.productRealName == EFEX_REAL_NAME ? EFEX : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.ensurance_type = this.efexSefState.ensuranceType;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.amount = this.efexSefState.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.fee = this.efexSefState.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.type = this.bankName == '' ? GENERIC_TAG_BANCO_RIPLEY : GENERIC_TAG_OTRO_BANCO;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.destination_bank = this.bankName == '' ? '' : this.utilsService.TakeOffAccentMark(this.bankName.toLowerCase());
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS.auth_method = 'sms';
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_VIRTUAL_PAGE, HOME_GA4_CONSTANTS.HOME_LOANS_S5_LOAD_SMS);
  }

  get otpCode() {
    return this.otpCodeForm.controls.otpCode;
  }
  get showTimerLoading() {
    return this.showTimerSection && this.maxTime === OTP_MAX_TIME;
  }
  get isMobile() { return window.innerWidth < SCREEN_WIDTH.TABLET; }

  public getCodeProduct(offerType: string) {
    switch (offerType) {
      case SEF_REAL_NAME:
        return PRODUCT_SEF;
      case EFEX_REAL_NAME:
        return PRODUCT_EFEX;
    }
  }

  public showItIsNotMyNumberModal = async () => {
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.sub_category = this.productRealName == EFEX_REAL_NAME ? EFEX : SEF;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.ensurance_type = this.efexSefState.ensuranceType;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.amount = this.efexSefState.simulatorOption.amount;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.fee = this.efexSefState.simulatorOption.term;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.type = this.bankName == '' ? GENERIC_TAG_BANCO_RIPLEY : GENERIC_TAG_OTRO_BANCO;
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.destination_bank = this.bankName == '' ? '' : this.utilsService.TakeOffAccentMark(this.bankName.toLowerCase());
    HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS.auth_method = 'sms';
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_BTN_CLICK, HOME_GA4_CONSTANTS.HOME_LOANS_S5_NOTMYNUMBER_SMS);
    this.updateCheckMessage(3, '');

    const props = {
      productRealName: this.productRealName,
      messageType: TYPE_IS_NOT_MY_NUMBER,
      states: this.efexSefState,
      icon: 'i-cellphone',
      bankName: this.bankName,
      ensuranceType: this.efexSefState.ensuranceType,
    };

    const modal = await this.modalCtrl.create({
      component: AlternativeOfferComponent,
      cssClass: ['form-modal','short-form'],
      backdropDismiss: false,
      componentProps: props,
    });
    await modal.present();
    modal.onDidDismiss().then((button) => {
      if(button.data == 'cancel') {
        this.maxTime = OTP_MAX_TIME;
        this.showTimerSection = true;
        this.setOtpTimer();
      }
    });
  }

  public showSmsNotSentModal = async () => {
    this.updateCheckMessage(3, '');
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const codeProduct = this.getCodeProduct(this.productRealName);
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: 'NO-SMS',
        originLoanPwa: sessionStorage.getItem(ORIGIN_LOAN_PWA)
      };

      if (codeProduct === PRODUCT_SEF ||
        (codeProduct === PRODUCT_EFEX && !this.efexSefState.isEfexPromotional)) {
        loanLead.simulatorKey = this.efexSefState.keySimulator;
        loanLead.simulatorSelectedKey = this.efexSefState.loanSimulator.hashCode;
      } else {
        loanLead.simulatorKey = '';
        loanLead.simulatorSelectedKey = '';
      }

      try {
        const loanLeadResponse = await this.loanLeadService.registerLoanLead(loanLead);
        if (loanLeadResponse.code === SUCCESS) {
          const props = {
            productRealName: this.productRealName,
            messageType: TYPE_SMS_NOT_SENT,
            phoneNumber: this.mobileNumber,
            icon: 'i-alert-purple',
            bankName: this.bankName,
            ensuranceType: this.efexSefState.ensuranceType,
          };

          const modal = await this.modalCtrl.create({
            component: AlternativeOfferComponent,
            cssClass: ['form-modal','short-form'],
            backdropDismiss: false,
            componentProps: props,
          });
          await modal.present();
        } else if (loanLeadResponse.code === LEAD_EXIST_ERROR) {
          this.showExistLeadModal();
        } else {
          this.openErrorServiceModal();
        }
      } catch (err) {
        this.openErrorServiceModal();
      }
    }
  }

  private async saveLead() {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      this.isSavingLead = true;
      const codeProduct = this.getCodeProduct(this.productRealName);
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: '',
        originLoanPwa: sessionStorage.getItem(ORIGIN_LOAN_PWA)
      };

      if (codeProduct === PRODUCT_SEF ||
        (codeProduct === PRODUCT_EFEX && !this.efexSefState.isEfexPromotional)) {
        loanLead.simulatorKey = this.efexSefState.keySimulator;
        loanLead.simulatorSelectedKey = this.efexSefState.loanSimulator.hashCode;
      } else {
        loanLead.simulatorKey = '';
        loanLead.simulatorSelectedKey = '';
      }

      try {
        const loanLeadResponse = await this.loanLeadService.registerLoanLead(loanLead);
        this.isSavingLead = false;
        if (loanLeadResponse.code === SUCCESS) {
          this.showLeadSuccessModal();
        } else if (loanLeadResponse.code === LEAD_EXIST_ERROR) {
          this.showExistLeadModal();
        } else {
          this.openErrorServiceModal();
        }
      } catch (err) {
        this.isSavingLead = false;
        this.openErrorServiceModal();
      }
    }
  }

  public openPhoneNumber() {
    document.location.href = `tel:${RIPLEY_PHONE}`;
  }

  async openErrorServiceModal() {
    const productName = this.productRealName === SEF_REAL_NAME ? 'préstamo ' + this.productRealName + '.' : this.productRealName + '.';

    if (!this.isMobile) {
      const props = DISBURSEMENT_FAILED_WEB_MODAL_PROPS;
      props.message = props.content + productName + '.';
      const modal = await this.modalCtrl.create({
        component: DisbursementErrorComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
      modal.onDidDismiss().then(() => {
        return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
      });
    } else {
      const props_mb = DISBURSEMENT_FAILED_MOBILE_MODAL_PROPS;
      props_mb.message = props_mb.content + productName + '.';
      const modal = await this.modalCtrl.create({
        component: DisbursementErrorComponent,
        cssClass: 'form-modal',
        componentProps: props_mb,
      });
      await modal.present();
      modal.onDidDismiss().then((button) => {
        if (button.data === 'primaryButtonPressed' ) {
          this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
          this.openPhoneNumber();
          return ;
        } else {
          return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
        }
      });
    }
  }

  async showLeadSuccessModal() {
    const props = {
      showIcon: false,
      title: TITLE_SMS_ATTEMPS_EXCEEDED,
      productName: this.productRealName
    };

    const modal = await this.modalCtrl.create({
      component: LeadSuccessComponent,
      cssClass: 'form-modal',
      componentProps: props,
      backdropDismiss: false
    });
    await modal.present();
  }

  async showExistLeadModal() {
    const props = {
      productName: this.productRealName
    };

    const modal = await this.modalCtrl.create({
      component: LeadExistModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();

    modal.onDidDismiss().then(() => {
      return this.router.navigateByUrl(`/${CREDITS_ROUTE}`);
    });
  }

  public async showGenericErrorModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_LEAD_FAILED_MODAL_PROPS,
    });
    modal.present();
  }

  ngOnDestroy() {
    this.otpCode.setValue('');
    this.subscription.unsubscribe();
  }

  public emitOtp() {
    // this.showTimerSection = false;
    this.otpCodeValidation.emit(true);
    const otpValue = this.otpCode.value;
    this.otpCodeValue.emit(otpValue);
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.showTimerSection = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.hideTimer = false;
        this.setOtpTimer();
      } else {
        this.hideTimer = true;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  public async sendSms() {
    try {
      const otpResponse = await this.simulatorService.sendSmsToken();
      if (otpResponse.code === SUCCESS) {
          this.maxTime = OTP_MAX_TIME;
          this.showTimerSection = true;
          this.setOtpTimer();
      } else if (otpResponse.code === ERROR_CODE_SMS) {
        this.updateCheckMessage(3, otpResponse.message);
        this.saveLead();
      } else {
        this.sendError(otpResponse.message);
      }
    } catch (error) {
      this.openErrorServiceModal();
    }
  }

  private sendError(message: string) { throw new Error(message); }

  public async resendSms() {
    try {
      this.otpCodeForm.controls['otpCode'].setValue('');
      this.cleanCheckMessage();
      this.errorTimes = 0;
      this.sendSms();
    } catch (error) {
      if (error) {
        const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, error.errorMessage);
        if (alertMessage) {
          alertMessage.present();
          const response = await alertMessage.onDidDismiss();
          if (response) { await this.resendSms(); }
        }
      } else {
        this.openErrorServiceModal();
      }
    }
  }

  public updateCheckMessage(option: number, serviceMessage: string) {
    const message = document.getElementById('sms-check-message');
    const input = document.getElementById('sms-input');
    const control = this.otpCodeForm.get('otpCode');
    switch (option) {
      case 1: {
        this.smsCheckMessage = 'Código Correcto';
        message.style.color = '#008000';
        message.style.display = 'block';
        input.style.borderColor = '#008000';
        control.disable();
        break;
      }
      case 2: {
        this.smsCheckMessage = 'Código incorrecto';
        message.style.color = '#ff0000';
        message.style.display = 'block';
        input.style.borderColor = '#ff0000';
        this.showTimerSection = true;
        control.setValue('');
        break;
      }
      case 3: {
        this.smsCheckMessage = serviceMessage;
        message.style.color = '#ff0000';
        message.style.display = 'block';
        clearTimeout(this.timer);
        this.showTimerSection = false;
        this.hideTimer = true;
        break;
      }
      case 4: {
        this.smsCheckMessage = '';
        message.style.display = 'none';
        input.style.borderColor = '#D3D3D3';
        this.showTimerSection = true;
        break;
      }
    }
  }

  public cleanCheckMessage() {
    const message = document.getElementById('sms-check-message');
    const input = document.getElementById('sms-input');
    message.style.display = 'none';
    input.style.borderColor = '#D3D3D3';
  }

}
