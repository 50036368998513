
// STEP 1

export const IL_SET_AMOUNT = {
  variable: 'Incremento_de_linea',
  event: 'Paso_1-Establecer_monto',
  action: 'click'
};

// STEP 2
export const IL_SEND_MAIL_TC = {
  variable: 'Incremento_de_linea',
  event: 'Paso_2- Correo_Aceptar_Condiciones' ,
  action: 'click'
};

export const IL_TC_REVIEW = {
  variable: 'Incremento_de_linea',
  event: 'Paso_2-Ver_Terminos_y_Condiciones',
  action: 'click'
};

// STEP 3

export const IL_LINE_INCREMENT = {
  variable: 'Incremento_de_linea',
  event: 'Paso_3-Incrementar_Linea',
  action: 'click'
};
export const IL_TIMEOUT_SMS = {
  variable: 'Incremento_de_linea',
  event: 'Paso_3-TimeoutSMS',
  action:  'click'
};
export const IL_RESEND_SMS_CODE = {
  variable: 'Incremento_de_linea',
  event: 'Paso_3-Reenviar_Codigo',
  action: 'click'
};
export const IL_NOT_NUMBER = {
  variable: 'Incremento_de_linea',
  event: 'Paso_3-No_Es_Tu_Numero',
  action:  'click'
};




