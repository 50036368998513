import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { OTP_LENGTH, SEND_OTP_SUCCESSFUL } from '@common/constants/enrollment.constants';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { OTP_MAX_TIME } from '@common/constants/enrollment.constants';
import { UpdateInfoComponent } from '../modals/update-info/update-info.component';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { OpenAccountService } from '@services/open-account/open-account.service';
import { MILLISECONDS_TO_SECOND } from '@common/constants/misc.constants';
import { AlertService } from '@services/alert/alert.service';
import { IServiceResponse } from '@common/interfaces/efex.interface';
import { ERROR_CODE_SMS } from '@common/constants/open-account.constants';
import { CREDITS_ROUTE } from '@common/constants/routes.constants';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { AHORROPLUS_ISNOT_MY_NUMBER,
        AHORROPLUS_ENTER_CODE,
        AHORROPLUS_SMS_CODE_OK,
        AHORROPLUS_CODE_INVALID,
        AHORROPLUS_SOLICITUD_NEW_CODE } from '@common/constants/tag/tag-ahorroplus.constants';
import { OP_STEP3_CODE_ENTERED, OP_STEP3_NOT_MY_NUMBER, OP_STEP3_RESEND_CODE } from '@common/constants/tag/tag-open-account.constants';

@Component({
  selector: 'app-authorization-open-account',
  templateUrl: './authorization-open-account.component.html',
  styleUrls: ['./authorization-open-account.component.scss'],
})
export class AuthorizationOpenAccountComponent implements OnDestroy {

  @Input() mobileNumber: string;
  @Input() checkingSMSMessage: boolean;
  @Output() otpCodeValidation: EventEmitter<boolean>;
  @Output() otpCodeValue: EventEmitter<string>;
  subscription: Subscription;
  otpCodeForm: FormGroup;
  smsCheckMessage = '';
  showTimerSection: boolean;
  maxTime: number;
  hideTimer: boolean;
  hideText: HideTextPipe;
  validatingOtp: boolean;
  url: string;
  timer: NodeJS.Timeout;
  errorTimes: number;
  isDisabled: boolean;
  lastCode = '';

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private openAccountService: OpenAccountService,
    private alertService: AlertService,
    private router: Router,
    private gtmService: GoogleTagManagerService
    ) {
      this.otpCodeValidation = new EventEmitter();
      this.otpCodeValue = new EventEmitter();
      this.hideText = new HideTextPipe();
      this.subscription = new Subscription();
      this.validatingOtp = false;
      this.isDisabled = false;
      this.otpCodeForm =  this.formBuilder.group({
        otpCode: new FormControl('', Validators.compose([
          Validators.required, Validators.maxLength(OTP_LENGTH),
          Validators.required, Validators.minLength(OTP_LENGTH),
      ])),
    });
      this.subscription.add(this.otpCode.valueChanges.subscribe((code: string) => {
      if (this.lastCode !== code) {
        this.lastCode = code;
        if (code.length === OTP_LENGTH && !this.validatingOtp) {
          this.gtmService.pushTagV2(OP_STEP3_CODE_ENTERED);
          this.emitOtp();
        }
      }

    }));
  }

  get otpCode() {
    return this.otpCodeForm.controls.otpCode;
  }
  get showTimerLoading() {
    return this.showTimerSection && this.maxTime === OTP_MAX_TIME;
  }

  public showItIsNotMyNumberModal = async () => {
    this.gtmService.pushTagV2(OP_STEP3_NOT_MY_NUMBER);
    this.pushTagIsNotMyNumber();
    const modal = await this.modalCtrl.create({
      component: UpdateInfoComponent,
      cssClass: ['form-modal', 'modal-update-info'],
      backdropDismiss: false,
      componentProps: {},
    });
    await modal.present();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public emitOtp() {
    this.showTimerSection = false;
    this.otpCodeValidation.emit(true);
    const otpValue = this.otpCode.value;
    this.otpCodeValue.emit(otpValue);
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    // const MAX_TIME = 30;
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        // this.addEventToGoogleManager(EFEX_TIMEOUT.event, EFEX_TIMEOUT.variable , EFEX_TIMEOUT.action);
        this.showTimerSection = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        // if (this.maxTime === MAX_TIME) {
        //   this.showNotYetSection = true;
        // }
        this.hideTimer = false;
        this.setOtpTimer();
      } else {
        this.hideTimer = true;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  public async sendSms(isResend: boolean = false) {
    if (isResend) {
      // this.addEventToGoogleManager(this.tagManagerEventLabel, 'click', 'Reenviar_Codigo');
    }
    try {
      const otpResponse = await this.openAccountService.sendSms();
      if (otpResponse.code === SEND_OTP_SUCCESSFUL) {
          this.maxTime = OTP_MAX_TIME;
          this.showTimerSection = true;
          this.setOtpTimer();
      } else {
        this.sendError(otpResponse.message);
      }
    } catch (err) {
      const newError: IServiceResponse = {
        code: err.code,
        message: err.message,
        countNumber: err.countNumber,
        maxNumberAttemps: err.maxNumberAttemps
      };

      switch (newError.code) {
        case ERROR_CODE_SMS: {
            this.router.navigate([CREDITS_ROUTE], { replaceUrl: true });
            break;
        }
      }
    }
  }

  private sendError(message: string) { throw new Error(message); }

  public async resendSms() {
    try {
      this.gtmService.pushTagV2(OP_STEP3_RESEND_CODE);
      this.pushTagNewCode();
      this.otpCodeForm.controls['otpCode'].setValue('');
      this.cleanCheckMessage();
      this.errorTimes = 0;
      this.sendSms(true);
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, err.errorMessage);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.resendSms(); }
      }
    }
  }

  public updateCheckMessage(option: number, serviceMessage: string) {
    const message = document.getElementById('sms-check-message');
    const input = document.getElementById('sms-input');
    const control = this.otpCodeForm.get('otpCode');
    switch (option) {
      case 1: {
        this.smsCheckMessage = 'Código Correcto';
        message.style.color = '#008000';
        message.style.display = 'block';
        input.style.borderColor = '#008000';
        control.disable();
        this.pushGtmCodeValidSMS();
        break;
      }
      case 2: {
        this.smsCheckMessage = 'Código incorrecto';
        message.style.color = '#ff0000';
        message.style.display = 'block';
        input.style.borderColor = '#ff0000';
        this.showTimerSection = true;
        control.setValue('');
        this.pushGtmCodeInvalidSMS();
        break;
      }
      case 3: {
        this.smsCheckMessage = serviceMessage;
        message.style.color = '#ff0000';
        message.style.display = 'block';
        clearTimeout(this.timer);
        this.showTimerSection = true;
        this.hideTimer = true;
        break;
      }
    }
  }

  public cleanCheckMessage() {
    const message = document.getElementById('sms-check-message');
    message.style.display = 'none';
  }

  pushTagIsNotMyNumber() {
    this.gtmService.pushTagV2(AHORROPLUS_ISNOT_MY_NUMBER);

  }

  public pushGtmInputSMS(event) {
    const largo = event.target.value.length;
    if (Number(largo) == 6 ) {
          this.gtmService.pushTagV2(AHORROPLUS_ENTER_CODE);
    }
  }

  public pushGtmCodeValidSMS() {
     this.gtmService.pushTagV2(AHORROPLUS_SMS_CODE_OK);
  }

  public pushGtmCodeInvalidSMS() {
    this.gtmService.pushTagV2(AHORROPLUS_CODE_INVALID);
  }

  public pushTagNewCode() {
    this.gtmService.pushTagV2(AHORROPLUS_SOLICITUD_NEW_CODE);
  }
}
