import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUserInformation } from '@common/interfaces/user.interface';
import { UserService } from '@services/user/user.service';
import { GENERIC_TAG_END_FLOW, GENERIC_TAG_VIRTUAL_PAGE } from '@common/constants/tag/tag-generic.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { IOffering } from '@common/interfaces/offerings.interface';
import { PRODUCT_SEF } from '@common/constants/offerings.constants';

@Component({
  selector: 'app-offering-modal-sef-success',
  templateUrl: './offering-modal-sef-success.component.html',
  styleUrls: ['./offering-modal-sef-success.component.scss'],
})
export class OfferingModalSefSuccessComponent implements OnInit {

  userInformation: IUserInformation;

  @Input() offer: IOffering;
  @Input() productName: string;
  displayName: string;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.userInformation = this.userService.userInformation || {} as IUserInformation;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.product = 'listo';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.class = this.offer.codeProduct === PRODUCT_SEF ? 'solicitud - aprobacion' : 'solicitud - renovacion';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.step = 'step solicitud exitosa';
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.amount = this.offer.maximumAmount;
    HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP.fee = this.offer.maximumTimeMonth;
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_VIRTUAL_PAGE, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP);
    this.googleAnalyticsService.gtagPushEventWithId(GENERIC_TAG_END_FLOW, HOME_GA4_CONSTANTS.LOANS_SEMIAUTOMATICO_SEF_POPUP);

    if (this.offer.codeProduct === PRODUCT_SEF) this.displayName = "solicitud de tu préstamo Súper Efectivo";
    else this.displayName = "renovación de tu préstamo Súper Efectivo";
  }

}
