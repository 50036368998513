import { IEnvironment } from '@common/interfaces/environments.interface';

export const environment: IEnvironment = {
  ENV: 'development',
  BASE_BACKEND_WORKER: 'https://workersdev.dev.bancoripley.com.pe/homebanking-dev',
  TRANSFERS_ENABLED: true,
  CHANNEL: '85',
  USING_MOCKS: false,
  SERVICES_TIMEOUT: 60000,
  API_KEY: 'REMOTE',
  MAINTENANCE: false,
  MAINTENANCE_MESSAGE: '¡Volveremos pronto con mejores servicios para ti! Puedes realizar tus operaciones desde tu Banca Móvil.',
  MOCK_SUCCESS_RATE: 1,
  MOCK_DELAY_MILISECONDS: 1000,
  CLIENT_ID: 'appmobile',
  CLIENT_SECRET: '6ced5d11-a456-4e42-afaa-8de136cb2cf2',
  DNI_CODE: 'C',
  FOREIGN_CARD_CODE: '2',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyC7jk8hx0iodzOWReANTPSDPAx659E8urc',
    authDomain: 'hbk-dev.firebaseapp.com',
    databaseURL: 'https://hbk-dev.firebaseio.com',
    projectId: 'hbk-dev',
    storageBucket: 'hbk-dev.appspot.com',
    messagingSenderId: '54992857958',
    appId: '1:54992857958:web:02baf8b33fb631724236a7',
    vapidKey: 'BOOKToi7tg8g3NC7NlrUxYZ-t_noYVduUaD1KzeD7llwriyQjDTwIsmNL4QjpmccFJPcybGUtRVM9xVKPAVZeh4',
    measurementId: 'G-34YQ2CPM3Q'
  },
  PUSH_NOTIFICATIONS_API_PATH: '',
  PUBLIC_KEY_PEM: '-----BEGIN PUBLIC KEY-----\
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAolVf9xm6r4qyyii4YbwU\
  3brXh4//vE2bHhamh5l3X6+e0p6LNBj936p0ftVYxG8vTfGenDkVQiGnyzNI2ZP/\
  CUQ2S/Q71eiPn9bvKacVCnSyuxbCdy8lOl9xc3ZLxuPVnEeV7sNR1oke2YxtX3gt\
  kNlcxaqO+NaxIwLUFyXWwWh3rQ2TTibmBmeTm4Y6eXPM/Gj1uy4wKS8VnGbiVaW2\
  40++WPhpXeQQOAFi2eeACr/xhBrqa1nbpuMLRaX4k859S8QLjTAIIn7i9u/VOk4L\
  5WzLGiIQreDBY+4gvD28/OSPKgCUXmYZXnMYjNxqdixfVwACvq+0Cieoea9Q9XbZ\
  tQIDAQAB\
  -----END PUBLIC KEY-----',
  RECAPTCHA_SITE_KEY: '6LeXhccUAAAAADl4VkIiUmT4oZogNcC5aapxMjBu',
  PAYMENTS_CONFIG: {
    CHANNEL: 'web',
    ENDPOINT_JS: 'https://pocpaymentserve.s3.amazonaws.com/payform.min.js',
    ENDPOINT_CSS: 'https://pocpaymentserve.s3.amazonaws.com/payform.min.css',
    LANGUAGE: 'es',
    FONT: 'https://fonts.googleapis.com/css?family=Montserrat:600&display=swap'
  },
  SSL_PINNING_ENABLED: false,
  IP_PROVIDERS: [
    "https://jsonip.com",
  ],
  URL_QR: "../../../assets/img/qr-dev.png",
  GA4_ID: 'G-3DG44WF1HB',
  WIDGET_ONB: 'https://corpwidonbdev.bancoripley.cl',
  WIDGET_ONB_API: 'https://corpwidonbapidev.bancoripley.cl',
  WIDGET_AUTH: 'https://corpwidautapidev.bancoripley.cl',
  WIDGET_AUTH_V3: 'https://corpwidautdev.bancoripley.cl',
  JWT_SECRET_WID_AUTH_V3: 'H@McQfTjWnZq4t7w!z%C*F-JaNdRgUkX',
  URL_BANK: 'https://www.bancoripley.com.pe',
  WIDGET_CLIENT_ID: '0f32526f4056b4ad6f7e511c1ecdbce7',
  TRACKER_KEY: 'c73e2cba-4650-4418-834d-d6c4df2c9c00',
};
