import { Component, Input, ElementRef, HostListener } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { UtilsService } from "@services/utils/utils";
import {
  TIMER_UPDATE_BY_SEC,
  CHANGE_MINUTE_BREAK,
  CARD_RESTRICTED_ERROR,
  TRANSACTION_INFO,
  DEFAULT_DIGITAL_CARD_DATA,
  ENABLE_SHOPPING_PURCHARSE_ERROR,
  WIDGET_CODE_SUCCESSFUL,
  CARD_RESTRICTED_MESSAGE,
  DISABLE_ONLINE_SHOPPING_MESSAGE
} from "@common/constants/digital-card.constants";
import { DatacardService } from "@services/data-card/data-card.service";
import { AlertService } from "@services/alert/alert.service";
import { addSeconds, format } from "date-fns";
import { IDigitalCardData, IDigitalCardValidate, IElement, IDigitalCardMonitorInput } from "@common/interfaces/digital-card.interface";
import { IValidityResponse } from "@common/interfaces/widget.interface";
import { AuthWidgetV3Messages, AuthWidgetV3Status } from "@common/constants/digital-auth-form.constants";
import { UserService } from "@services/user/user.service";
import { IDetail, IMonitorRpassData} from "@common/interfaces/digital-auth.interface";
import { environment } from "@environments/environment";
import { DeviceService } from '@services/device/device.service';
import { VDT_CONSTANTS } from "@common/constants/tag/tag-visualizacion-tc.constans";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { ON_OF_ROUTE, R_PASS_INTRO_ROUTE } from "@common/constants/routes.constants";
import { GoogleAnalyticsService } from "@services/google-analytics/google-analytics.service";

@Component({
  selector: "app-digital-card-modal",
  templateUrl: "./digital-card-modal.component.html",
  styleUrls: ["./digital-card-modal.component.scss"],
})
export class DigitalCardModalComponent {
  @Input() public originalCardNumber = "";
  @Input() public keyCard = "";
  @Input() public uuidTransaction: string = '';
  @Input() public sessionId: string;
  @Input() public firstDisplayedElement: IElement = 'widget';

  public cardData: IDigitalCardData
  public showDataTimer: { min: number; sec: number };
  public timer: number;
  public showDataTimerStr: string;
  public intervalShowData: NodeJS.Timeout;
  public imageType: string;
  public cvvPosition: string;
  public label: string;
  public title: string;
  public token: string;
  public clientId: string = environment.WIDGET_CLIENT_ID;
  public transactionInfo = TRANSACTION_INFO;
  public responseDataCard?: IDigitalCardValidate;
  public validatedWidget: boolean = true;
  public typeAuth: string = 'SMS';
  public displayedElement: IElement = 'loading';
  public errorMessage: string = DISABLE_ONLINE_SHOPPING_MESSAGE;
  public showButton: boolean;
  public name = "";

  constructor(
    private alertService: AlertService,
    private datacardService: DatacardService,
    private modalController: ModalController,
    private platform: Platform,
    private userService: UserService,
    public deviceService: DeviceService,
    private widgetErrorClassName: ElementRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event) => {
      if ((event as any).url.startsWith('/rpass-auth') && this.firstDisplayedElement === 'widget') {
        this.dismissModal();
      }
    });
  }

  @HostListener('click', ['$event.target']) onClick(e: HTMLElement) {
    if(e) {
      const parentElement = e.parentElement;
      const parentClass = parentElement.classList;
      if (parentClass && (parentClass.contains('MuiButtonBase-root')||parentClass.contains('MuiGrid-root')))
      {
        const value = parentElement.textContent.toUpperCase();
        if (value.includes('SMS')) {
         // console.log('ENTRA AL SMS');
          this.typeAuth = 'SMS';
          this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.SMS_SELECTED.EVENT, 
            VDT_CONSTANTS.SMS_SELECTED.TAGS)
        } else if (value.includes('PASS')) {
          this.typeAuth = 'RPASS';
          //console.log('ENTRA AL RPASS');
          this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.RPASS_SELECTED.EVENT, 
            VDT_CONSTANTS.RPASS_SELECTED.TAGS)
        }
      }
    }
  }

  public ofuscateCardData() {
    this.cardData = {
      ...DEFAULT_DIGITAL_CARD_DATA,
      code: UtilsService.ofuscateCardNumber(this.originalCardNumber || this.datacardService.originalCardNumber)
    };
  }

  public async ionViewWillEnter(): Promise<void> {
    this.resetModal(this.firstDisplayedElement);
    if(this.displayedElement == 'authorized'){
      this.displayedElement = 'loading';
      this.validateWidget(
        this.datacardService.sessionId,
        this.uuidTransaction,
        this.datacardService.token
      );
      return;
    }
    if(this.displayedElement == 'widget'){
      this.datacardService.keyCard = this.keyCard;
      this.datacardService.originalCardNumber = this.originalCardNumber;
      await this.setPrivateKey();
    }
  }

  public resetModal(displayedElement: IElement) {
    this.name = this.userService.getFullName();
    this.showDataTimer = { min: 0, sec: 0 };
    this.showDataTimerStr = this.formattedTime(this.showDataTimer.sec);
    this.displayedElement = displayedElement;
    this.ofuscateCardData();
    UtilsService.showRecaptchaBadge();
   }

  public async ionViewWillLeave() {
    if (!this.validatedWidget)
    {
      const spanElement: HTMLElement = this.widgetErrorClassName.nativeElement.querySelector('.widget-auth-error-text-otp');
      if (spanElement)
      {
        this.typeAuth = 'SMS';
        await this.monitorViewCardFailure(
          this.sessionId,
          this.token
        );
      }
    }
    UtilsService.hideRecaptchaBadge();
    this.dismissModal();
  }

  public reset() {
    this.ofuscateCardData()
    this.displayedElement = 'loading';
    clearInterval(this.intervalShowData);
  }

  public dismissModal(close = false) {
    if(close){
      switch (this.displayedElement) {
        case 'authorized':
          this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.VDT_CLOSED_SUCCESSFULLY.EVENT,
            VDT_CONSTANTS.VDT_CLOSED_SUCCESSFULLY.TAGS)
          break;
        case 'disabled':
          if(this.errorMessage == DISABLE_ONLINE_SHOPPING_MESSAGE) {
            this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.VDT_CLOSED_PURCHARSE_ONLINE_OFF.EVENT,
              VDT_CONSTANTS.VDT_CLOSED_PURCHARSE_ONLINE_OFF.TAGS)
          }
          break;
        default:
          this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.MODAL_CLOSED.EVENT,
            VDT_CONSTANTS.MODAL_CLOSED.TAGS)
          break;
      }
    }
    this.reset();
    this.modalController.dismiss();
  }

  public async reloadWidget() {
    this.token = '';
    return await this.ionViewWillEnter();
  }

  public getTransactionData(): IDetail[] {
    return [
      { key: 'S.Canal', value: 'Homebanking' },
      { key: 'S.Operación', value: 'Visualización datos de tarjeta' },
      { key: 'flow', value: 'VDT' },
      { key: 'D.Fecha', value: new Date().toISOString() },
      { key: 'userId', value: this.userService.userInformation.documentNumber },
      { key: 'deviceType', value: this.platform.is('cordova') ? 'mobile' : 'desktop' },
      { key: 'VDT.name', value: this.name },
      { key: 'VDT.card', value: this.keyCard },
    ];
  }

  async verifyShowCard({ data }: IValidityResponse) {
    //console.log('verifyShowCard ->',data);
    if (data.status === AuthWidgetV3Status.success) {
      if (data.message === AuthWidgetV3Messages.initialized) {
        this.displayedElement = 'widget';
        this.validatedWidget = false;
        this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.MODAL_OPENED.EVENT, 
          VDT_CONSTANTS.MODAL_OPENED.TAGS)
      } else if (data.message === AuthWidgetV3Messages.authorized) {
        await this.validateWidget(
          this.sessionId,
          data.details.uuidTransaction,
          this.token
        );
      } else if (data.message === AuthWidgetV3Messages.activate_rpass) {
        this.router.navigateByUrl(R_PASS_INTRO_ROUTE);
        this.dismissModal();
      }
    } else if (data.status === AuthWidgetV3Status.failure) {
      if (data.message === AuthWidgetV3Messages.canceled) {
        await this.monitorViewCardFailure(
          this.sessionId,
          this.token
        );
        this.dismissModal();
      } else if (data.message === AuthWidgetV3Messages.rejected) {
        await this.monitorViewCardFailure(
          this.sessionId,
          this.token,
          data.details.uuidTransaction
        );
        this.displayedElement = 'rejected'
      }
    } else {
      this.displayedElement = 'failed';
    }
  }

  public async validateWidget(sessionId: string, uuidTransaction: string, authToken: string) {
    this.displayedElement = 'loading'
    await this.datacardService.validateWidget(
      {
        sessionId,
        uuidTransaction,
        authToken,
        keyCard: this.datacardService.keyCard,
        typeAuth: this.typeAuth
      }
    ).then((dataCard)=>{
      if (dataCard.code === WIDGET_CODE_SUCCESSFUL) {
        this.validatedWidget = true;
        this.getDataCard(dataCard);
        this.displayedElement = 'authorized';
        VDT_CONSTANTS.VDT_SUCCESSFUL.TAGS.step_part = this.typeAuth.toLowerCase();
        this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.VDT_SUCCESSFUL.EVENT,
          VDT_CONSTANTS.VDT_SUCCESSFUL.TAGS)
      } else if (dataCard.code === CARD_RESTRICTED_ERROR) {
        this.errorMessage = CARD_RESTRICTED_MESSAGE;
        this.displayedElement = 'disabled';
        this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.VDT_BLACK_LIST.EVENT, 
          VDT_CONSTANTS.VDT_BLACK_LIST.TAGS)
      } else {
        this.displayedElement = 'error';
      }
    }).catch(()=>{
      this.displayedElement = 'error'
    });
  }

  public async monitorViewCardFailure(sessionId: string, authToken: string, uuidTransaction: string = '') {
    try {
      let device =  this.deviceService.getWildField1()
      if(device ==='desktop'.toUpperCase()){
        await this.datacardService.monitorViewCardFailure(
          {
            sessionId,
            uuidTransaction,
            authToken,
            keyCard: this.keyCard,
            typeAuth: this.typeAuth
          }
        );
      }
    } catch (error) {
    }
  }

  public async setPrivateKey() {
    try {
      this.displayedElement = 'loading'
      if (!this.sessionId) {
        const data = await this.datacardService.checkVisualizationActivate(
          this.keyCard
        );
        this.sessionId = data.sessionId;
      }
      this.datacardService.sessionId = this.sessionId;
      await this.datacardService.getWidgetToken(
        this.sessionId,
        this.keyCard
      ).then((response)=> {
        if(response.code && response.code == ENABLE_SHOPPING_PURCHARSE_ERROR){
          this.displayedElement = 'disabled';
          this.showButton = true;
          this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.MODAL_ONLINE_PURCHARSE_OFF.EVENT, 
            VDT_CONSTANTS.MODAL_ONLINE_PURCHARSE_OFF.TAGS)
          return;
        }
        this.token = response.access_token;
        this.datacardService.token = response.access_token;
        this.displayedElement = 'widget'
      }).catch((error)=> {
        this.showError(error);
      })
    } catch (error) {
      this.showError(error);
    }
  }

  showError(error){
    if(error.code == ENABLE_SHOPPING_PURCHARSE_ERROR) {
      this.displayedElement = 'disabled';
      this.showButton = true;
      this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.MODAL_ONLINE_PURCHARSE_OFF.EVENT, 
        VDT_CONSTANTS.MODAL_ONLINE_PURCHARSE_OFF.TAGS)
      return;
    } else if (error.code == CARD_RESTRICTED_ERROR) {
      this.alertService.simpleMessageError(
        "Error",
        "La tarjeta se encuentra restringida para visualizar los datos"
      );
    } else {
      this.alertService.simpleMessageError(
        "Error",
        "Ocurrió un error al intentar obtener las tarjetas"
      );
    }
    this.displayedElement = 'error';
    this.dismissModal();
  }

  public async showDataCard() {
    await this.getDataCard(this.responseDataCard);
  }

  goToCardSettings(){
    this.modalController.dismiss();
    this.googleAnalyticsService.gtagPushEventWithId(VDT_CONSTANTS.GO_TO_SETTINGS.EVENT, 
      VDT_CONSTANTS.GO_TO_SETTINGS.TAGS);
    this.router.navigateByUrl(`/${ON_OF_ROUTE}`);
  }

  public async getDataCard(data: IDigitalCardValidate) {
    try {
      const decryptedCard = await this.datacardService.decryptCard(data);
      this.cardData.cvv = decryptedCard.cvv;
      this.cardData.code = this.encryptedKeyView(decryptedCard.code);
      this.cardData.expirationDate = this.expirationDateView(
        decryptedCard.expirationDate
      );
      this.showDataTimer.sec = data.maximumViewTime;
      this.showDataTimerStr = this.formattedTime(this.showDataTimer.sec);
      this.intervalShowData = setInterval(() => {
        if (this.showDataTimer.sec - 1 === -1) {
          this.showDataTimer.min -= 1;
          this.showDataTimer.sec = CHANGE_MINUTE_BREAK;
        } else {
          this.showDataTimer.sec -= 1;
        }
        if (this.showDataTimer.min <= 0 && this.showDataTimer.sec <= 0) {
          this.dismissModal();
        }
        this.showDataTimerStr = this.formattedTime(this.showDataTimer.sec);
      }, TIMER_UPDATE_BY_SEC);
      this.displayedElement = 'authorized'
    } catch (error) {
      this.displayedElement = 'error';
    }
  }

  public encryptedKeyView(code: string) {
    for (let i = 0; i < code.length; i += 5) {
      code =
        code.substring(0, i + 4) + " " + code.substring(i + 4, code.length);
    }
    return code;
  }

  public expirationDateView(date: string) {
    return date.substring(0, 2) + "/" + date.substring(2, 4);
  }

  public formattedTime(seconds: number) {
    return format(addSeconds(new Date(0), seconds), "mm:ss");
  }
}
