export const OP_OPEN_SAVING_ACCOUNT_PLUS = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.1',
    event_label: 'solicitar_cuentaplus_pwa'
} // correcto

export const OP_BENEFIT_SAVING_ACCOUNT_PLUS = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.13',
    event_label: 'vista_beneficioscuentaplus_pwa'
} // correcto

export const OP_BENEFIT_OPEN_SAVING_ACCOUNT_PLUS = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.14',
    event_label: 'abrircuenta_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_ABRIR_1 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.2',
    event_label: 'vista_paso1_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_CONTINUAR_1 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.3',
    event_label: 'continuar_paso1_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_CANCELAR_1 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.4',
    event_label: 'cancelar_paso1_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_ABRIR_2 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.5',
    event_label: 'vista_paso2_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_CONTINUAR_2 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.6',
    event_label: 'continuar_paso2_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_CANCELAR_2 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.7',
    event_label: 'cancelar_paso2_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_ABRIR_3 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.11',
    event_label: 'vista_confirmacion_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_PLUS_CONTINUAR_3 = {
    event: 'cuentaplus_PWA_event',
    event_category: 'flujoPLUS_PWA',
    event_action: 'step_1.12',
    event_label: 'ir_cuentaplus_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.1',
    event_label: 'solicitar_cuentavip_pwa'
} // correcto

export const OP_BENEFIT_SAVING_ACCOUNT_VIP = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.13',
    event_label: 'vista_beneficioscuentavip_pwa'
} // correcto

export const OP_BENEFIT_OPEN_SAVING_ACCOUNT_VIP = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.14',
    event_label: 'abrircuenta_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_ABRIR_1 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.2',
    event_label: 'vista_paso1_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CONTINUAR_1 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.3',
    event_label: 'continuar_paso1_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CANCELAR_1 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.4',
    event_label: 'cancelar_paso1_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_ABRIR_2 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.5',
    event_label: 'vista_paso2_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CONTINUAR_2 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.6',
    event_label: 'continuar_paso2_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CANCELAR_2 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.7',
    event_label: 'cancelar_paso2_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_ABRIR_3 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.8',
    event_label: 'vista_paso3_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CONTINUAR_3 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.9',
    event_label: 'continuar_paso3_cuentavip_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_VIP_CANCELAR_3 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.10',
    event_label: 'cancelar_paso3_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_ABRIR_4 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.11',
    event_label: 'vista_confirmacion_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_VIP_CONTINUAR_4 = {
    event: 'cuentaVIP_PWA_event',
    event_category: 'flujoVIP_PWA',
    event_action: 'step_1.12',
    event_label: 'ir_cuentavip_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.1',
    event_label: 'solicitar_cuentasueldo_pwa'
} // correcto

export const OP_BENEFIT_SAVING_ACCOUNT_SALARY = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.13',
    event_label: 'vista_beneficioscuentasueldo_pwa'
} // correcto

export const OP_BENEFIT_OPEN_SAVING_ACCOUNT_SALARY = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.14',
    event_label: 'abrircuenta_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_ABRIR_1 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.2',
    event_label: 'vista_paso1_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CONTINUAR_1 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.3',
    event_label: 'continuar_paso1_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CANCELAR_1 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.4',
    event_label: 'cancelar_paso1_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_ABRIR_2 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.5',
    event_label: 'vista_paso2_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CONTINUAR_2 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.6',
    event_label: 'continuar_paso2_cuentasueldo_pwa'
} // correcto

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CANCELAR_2 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.7',
    event_label: 'cancelar_paso2_cuentasueldo_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_SALARY_ABRIR_3 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.8',
    event_label: 'vista_paso3_cuentasueldo_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CONTINUAR_3 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.9',
    event_label: 'continuar_paso3_cuentasueldo_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CANCELAR_3 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.10',
    event_label: 'cancelar_paso3_cuentasueldo_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_SALARY_ABRIR_4 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.11',
    event_label: 'vista_confirmacion_cuentasueldo_pwa'
}

export const OP_OPEN_SAVING_ACCOUNT_SALARY_CONTINUAR_4 = {
    event: 'cuentasueldo_PWA_event',
    event_category: 'flujosueldo_PWA',
    event_action: 'step_1.12',
    event_label: 'ir_cuentasueldo_pwa'
}
