import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  RESCHEDULING_TOTAL_DEBT_MODAL,
  TCEA_OPTION,
  TCEA_TOOLTIP_MOBILE,
  TEA_OPTION,
  TEA_TOOLTIP_MOBILE,
  TEM_OPTION,
  TEM_TOOLTIP_MOBILE
} from '@common/constants/modal-props.constants';
import { PDF_REPRO } from '@common/constants/pdf.constants';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { GTM_STEPS } from '@common/constants/tag/tag-rescheduling.constanst';
import { IReschedulingSimulationResponse } from '@common/interfaces/rescheduling';
import { TceaComponent } from '@components/efex/modals/tcea/tcea.component';
import { ModalController } from '@ionic/angular';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { PdfService } from '@services/pdf/pdf.service';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'app-reprogramming-details',
  templateUrl: './reprogramming-details.component.html',
  styleUrls: ['./reprogramming-details.component.scss'],
})
export class ReprogrammingDetailsComponent implements OnInit {

  contactData: boolean;
  activeLastStep: boolean;
  emailOption: boolean;
  phoneOption: boolean;
  termsLink: string;
  @Input() data: IReschedulingSimulationResponse;
  @Output() simulateOutput: EventEmitter<number>;
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private pdfService: PdfService,
    private userService: UserService,
    private gtmService: GoogleTagManagerService
    ) {
      this.simulateOutput = new EventEmitter();
    }

  ngOnInit() {
    this.getUrls();
    this.gtmService.pushTagV2(GTM_STEPS.SHOW_STEP_TWO);
    this.emailOption = this.userService.userInformation.email ? true : false;
    this.phoneOption = this.userService.userInformation.mobileNumber  ? true : false;
    this.contactData = this.emailOption || this.phoneOption ? true : false;
  }

  async getUrls() {
    const urlPrivacy = await this.pdfService.getPdf(PDF_REPRO);
    this.termsLink = urlPrivacy.value;
  }

  async termsConditions() {
    window.open(this.termsLink);
  }

  simulateAgain() {
    this.simulateOutput.emit(2);
  }

  checkboxSelected(data) {
    this.activeLastStep = data.detail.checked;
    this.gtmService.pushTagV2(GTM_STEPS.ACCEPT_TERMS_AND_CONDITIONS);
    if (data.detail.checked) {
      this.gtmService.pushTagV2(GTM_STEPS.SHOW_SELECT_TYPE_OTP);
    }
  }

  async showTooltipMobile(value) {
    let props;
    switch (value) {
      case TEA_OPTION:
        props = TEA_TOOLTIP_MOBILE;
        break;
      case TEM_OPTION:
        props = TEM_TOOLTIP_MOBILE;
        break;
      case TCEA_OPTION:
        props = TCEA_TOOLTIP_MOBILE;
        break;
      default:
        props = RESCHEDULING_TOTAL_DEBT_MODAL;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: TceaComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  goToHome() {
    this.router.navigate([HOME_ROUTE], { replaceUrl: true });
    this.gtmService.pushTagV2(GTM_STEPS.CLICK_GO_TO_HOME);
  }
}
