import { ITransactionInfo } from "@common/interfaces/digital-auth.interface";
import { IDigitalCardData } from "@common/interfaces/digital-card.interface";

export const OTP_CODE_SUCCESSFUL = 0;

export const OTP_MAX_TIME = 60;

export const CHANGE_MINUTE_BREAK = 59;

export const TIMER_UPDATE_BY_SEC = 1000;

export const  CODE_DIGITS = 6;

// tslint:disable:max-line-length
export const DATA_CARD_PRIVATE_KEY_XML = `<?xml version="1.0" encoding="UTF-8"?>
<RSAParameters>
<Modulus>nwyrS05PcZdjs0nAatEtpxCEcKc3bSEqoegdEzu4i0gqWyT/IQZagXWRKHVEuVaBdCL9kdYZYtKajIsFLl9f5u9HhxnRSHHmpxAOiiQvSjXIdhX/KuwdiOttq0e9BwHptNyIqfP+OLLtQaxH4BbE5l2k1zUjxCMelu5zASvwChc=</Modulus>
<Exponent>AQAB</Exponent>
<P>5UCM5iyOKhl3KyJV6s487IsfD+quW/dsRZrszzcrDIGu36RmDbgR9z0DIb9jP/lxab2YjzPPxosJ1r3ivZ6Q1Q==</P>
<Q>sZtCxMT5nXyyIYum1AdrabenaurGKcmJb5B7ceSHJCVA1gsarrqTlVv3zUaiYVRIehK1QMKIA51bxWQxPY6FOw==</Q>
<DP>eQ3C2PACCbz0rjQtRhWtGgLEXjU/6JQX+hE6ppitBKed3b4DnY7q/bCb7MAQ+oEPIBTbcBKdzmswwNH33sTSGQ==</DP>
<DQ>Rh8pvmySrRY1cj8j7+S6CPREulbVRFxY0d52jqIOpTu8HIYn08LAUCEOdlKj+89+Cgxm2Zm0f9DaEdYcBtTFTw==</DQ>
<InverseQ>KQ4OsmKD1m28H9iworvU3NCS2psNP6HTQf17NSI/ZPHRBMgEE/yjVfmWBbS0Nx7dEfBGnYNTaSPeOfGP5iCh7g==</InverseQ>
<D>BFJ+0TQWmpqnnfayIPUATZr4Tmts6S1OkEnTiHNGB5r1c7akrEwU55G/WR8et/6u0+4jf/QWioX4pzsb7++tlnj5rGl58S1vtIs7s3v+nJSLeIM59fgAKgv+bv3eIoTxUJRGsIUO6PfQsH9yK7Kng3DoIre6LUiZoCuBgYCiu6k=</D>
</RSAParameters>`;

export const DECRYPT_BUFFER_SIZE = 16;

export const CARD_RESTRICTED_ERROR = 25;

export const ENABLE_SHOPPING_PURCHARSE_ERROR = 26;

export const CARD_NOT_AUTHORIZED = 'not-authorized';

export const CODE_SUCCESSFUL = 0;

export const WIDGET_CODE_SUCCESSFUL = 0;

export const DISABLE_ONLINE_SHOPPING_MESSAGE = 'Para visualizar los datos de tu tarjeta, recuerda tener activada la opción <strong>"Compras por internet”<strong>';

export const CARD_RESTRICTED_MESSAGE = 'Operación denegada. Te invitamos a contactarte con nosotros a través de RipleyFono (01) 611-5757';

export const TRANSACTION_INFO: ITransactionInfo = {
    operation: 'VDT',
    transactionType: 'VISUALIZACIONTARJETA',
    transactionName: 'VISUALIZACION_DATOS_TARJETA_01',
    subTypeTransaction: 'VISUALIZACION_DATOS_TARJETA_0',
};

export const TRANSACTION_INFO_SEF: ITransactionInfo = {
    operation: 'SEF', 
    transactionType: 'SEF',
    transactionName: 'SUPER_EFECTIVO',
    subTypeTransaction: 'SUPER_EFECTIVO',
};

export const TRANSACTION_INFO_EFEX: ITransactionInfo = {
    operation: 'EFEX', 
    transactionType: 'EFEX',
    transactionName: 'EFECTIVO_EXPRESS',
    subTypeTransaction: 'EFECTIVO_EXPRESS',
};

export const DEFAULT_DIGITAL_CARD_DATA: IDigitalCardData = {
    code: "",
    expirationDate: "XX/XX",
    cvv: "XXX"
};