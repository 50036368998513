import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-banner-sale',
  templateUrl: './banner-sale.component.html',
  styleUrls: ['./banner-sale.component.scss'],
})
export class BannerSaleComponent {

  @Input() strikingLabel: string;
  @Input() saleSubtitle: string;
  @Input() buttonLabel: string;
  @Input() backgroundClass: string;
  @Input() buttonClass: string;
  @Input() showSaleBanner: boolean;
  @Input() route: string;
  @Input() routerStateInformation: any;
  @Input() tagManagerActionLabel: string;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
  ) { }

  public goToRoute() {
    let URL = environment.URL_BANK;
    this.utilsService.addEventToTagManagerAndFirebase('Banner', 'Ofertas', this.tagManagerActionLabel);

    window.open(`${URL}/formularios/index.html?plantilla=reordenamiento-deuda-tarjeta-ripley`, "_blank");
    // this.router.navigate([this.route], { state: this.routerStateInformation });
  }

}
