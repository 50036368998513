import {
  ICustomerAgreementInput, ICustomerAgreementOutput, IDisbursementLoanInput,
  IDisbursementLoanOutput, IGenerateLoanData, ILoanCustomerData, IQuotesRequest, ISimulatorInput,
  ISimulatorOutput, ISmsResponse
} from './../../common/interfaces/simulator.interface';
import { Injectable } from '@angular/core';
import { HttpService } from '@services/http/http.service';
import { environment } from '@environments/environment';
import {
  COMMISIONS_MOCK, CUSTOMER_AGREEMENT_MOCK, CUSTOMER_DATA_LOAN_MOCK,
  DISBURSEMENT_MOCK, SIMULATOR_MOCK, SMS_DISBURSEMENT_MOCK
} from '@common/mocks/simulator.mocks';
import { IBank } from '@common/interfaces/bank.interface';
import { BANKS_MOCK } from '@common/mocks/bank.mocks';
import { ICommissionInput, ICommissionOutput } from '@common/interfaces/commision.interface';
import { IRegisterAccount, IRegisterAccountOutput } from '@common/interfaces/open-account.interface';
import { REGISTER_ACCOUNT_MOCK } from '@common/mocks/open-account.mocks';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import * as firebase from 'firebase/app';
import { USER_INFO } from '@common/constants/auth.constants';
import { DeviceService } from '@services/device/device.service';
import { IWidgetToken } from '@common/interfaces/digital-card.interface';
import { WIDGET_TOKEN_MOCK } from '@common/mocks/card.mocks';
import { UtilsService } from '@services/utils/utils';
import { ILoan, ILoanOutput } from '@common/interfaces/offerings.interface';
import { START_LOAN_MOCK } from '@common/mocks/offerings.mock';


@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  constructor(
    private http: HttpService,
    private firebaseService: FirebaseService,
    private storage: StorageService,
    private deviceService: DeviceService ) { }

  public async simulate(simulatorInput: ISimulatorInput): Promise<ISimulatorOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/simulatorV2';
    const responsePromise = this.http.post(url, simulatorInput, SIMULATOR_MOCK).toPromise();
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
    const hashToken = ''; // this.authService.currentToken.refreshToken;  TODO: hashear token con blake2
    return responsePromise.then(async (response) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          serviceName: 'simulate',
          token: hashToken,
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: simulatorInput,
          ...response
        },
        'simulationResponses');
      return response;
    });
  }

  public async GenerateLoan(data:IGenerateLoanData): Promise<IGenerateLoanData> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/generate-lead';
    return this.http.post(url, data, null).toPromise();
  }

  public async savePromotional(loanSaveParams: ILoan): Promise<ILoanOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/save-promotional';
    return this.http.post(url, loanSaveParams, START_LOAN_MOCK).toPromise();
  }
  
  public async customerDataLoan(): Promise<ILoanCustomerData> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/customer-dataV2';
    return this.http.post(url, null, CUSTOMER_DATA_LOAN_MOCK).toPromise();
  }

  public async getQuotes(data:IQuotesRequest): Promise<any[]> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/installments-by-amounts';
    return this.http.post(url, data, null).toPromise();
  }
  
  public async sendSmsToken(): Promise<ISmsResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/send-smsV2';
    return this.http.post(url, null, SMS_DISBURSEMENT_MOCK).toPromise();
  }

  public async getBanks(): Promise<IBank[]> {
    const url = environment.BASE_BACKEND_WORKER + '/parameterhbk/list-banks';
    return this.http.get(url, BANKS_MOCK).toPromise();
  }

  public async disbursementLoan(disbursementInput: IDisbursementLoanInput): Promise<IDisbursementLoanOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/disbursement-automaticV2';
    const responsePromise = this.http.post(url, disbursementInput, DISBURSEMENT_MOCK).toPromise();
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat = userInfo.documentType + '-' + userInfo.documentNumber;
    return responsePromise.then(async (response) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          serviceName: 'disbursementLoan',
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: disbursementInput,
          ...response
        },
        'voucherResponses');
      return response;
    }).catch((error) => {
      this.firebaseService.addSefEfexServiceResponse(
        {
          serviceName: 'disbursementLoan',
          failed: true,
          userId: dniSearchFormat,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          requestBody: disbursementInput,
          error: error.message
        },
        'voucherResponses');
      throw error;
    });
  }

  public async getCommissions(comissionInput: ICommissionInput): Promise<ICommissionOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/commissions-interbank';
    return this.http.post(url, comissionInput, COMMISIONS_MOCK).toPromise();
  }

  public async createAccount(createAccountInput: IRegisterAccount): Promise<IRegisterAccountOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/save-accountV2';
    return this.http.post(url, createAccountInput, REGISTER_ACCOUNT_MOCK).toPromise();
  }

  public async customerAgreement(customerAgreementInput: ICustomerAgreementInput): Promise<ICustomerAgreementOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/customer-agreement';
    return this.http.post(url, customerAgreementInput, CUSTOMER_AGREEMENT_MOCK).toPromise();
  }

  public async getWidgetToken(sessionId: string, productCode: string): Promise<IWidgetToken> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/widgettoken';
    const response: IWidgetToken = await this.http.post(url, { sessionId, productCode }, WIDGET_TOKEN_MOCK).toPromise();
    return response;
  }

  private async getClientIP() {
    return (await this.deviceService.getIP()).ip;
  }

  private async getDeviceId() {
    return UtilsService.getIdentifier();
  }


}
