import { Injectable } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { FirebaseService } from '@services/firebase/firebase.service';
import { Storage } from '@ionic/storage';
import { environment } from '@environments/environment';
import { USER_INFO } from '@common/constants/auth.constants';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { CREDIT_CARD, CTS_ACCOUNT, PLUS_ACCOUNT, SIMPLE_ACCOUNT } from '@common/constants/welcomepack.constants';
import { ProductService } from '@services/product/product.service';
import { SavingsService } from '@services/savings/savings.service';
import { UserService } from '@services/user/user.service';
import { RewardPointsService } from '@services/reward-points/reward-points.service';
import { CUSTOMER_TYPE_SEGMENT_GOLD, CUSTOMER_TYPE_SEGMENT_RIPLEY, CUSTOMER_TYPE_SEGMENT_SILVER } from '@common/constants/reward-points.constants';
import { PRODUCT_EFEX, PRODUCT_SEF } from '@common/constants/offerings.constants';

// Service ruoffer 2022
import { TypeRuoffers } from '../../common/types/ruoffers.types';
import { WelcomeCampaignComponent } from '@components/welcome-campaign/welcome-campaign.component';
import { IOffering } from '@common/interfaces/offerings.interface';


@Injectable()
export class CampaignEngineService {

  // welcome campaigns
  public welcomeCampaigns: Promise<firebase.firestore.QueryDocumentSnapshot[]>;
  public welcomeCampaignsUserData: Promise<firebase.firestore.DocumentData>;
  public welcomeCampaignsUserDataRef: any;
  public loadingWelcomeCampaigns = true;
  public newAccounts = [];
  public productErrorService = false;
  public goToViewCallback: any;  
  public welcomeModalComponent: any;

  // slider banner campaigns
  public sliderCampaigns: Promise<firebase.firestore.QueryDocumentSnapshot[]>;
  public sliderCampaignsUserData: Promise<firebase.firestore.DocumentData>;
  public sliderCampaignsUserDataRef: any;
  public loadingSliderCampaigns = true;
  public sliderCampaignToShow: any;

  public savingAccount = null; // PLUS y SIMPLE
  public savingAccountSet = false;
  public ctsAccount = null;  // CTS
  public ctsAccountSet = false;
  public creditCard = null;   // TC
  public creditCardSet = false;
  public hasExecutedOnce = false; //Flag para impedir múltiples ejecuciones ocasionadas por Promises del home.

  public subProductCode;
  public hasCreditCard = false;
  public hasCTS = false;
  public hasPLUS = false;
  public hasSIMPLE = false;

  constructor(
    private firebase: FirebaseService,
    private productService: ProductService,
    private savingsService: SavingsService,
    private userService: UserService,
    private platform: Platform,
    private modalController: ModalController,
    private storage: Storage,
    private rewardPointsService: RewardPointsService,
  ) {
  }

  /**
   * Show campaign methods
   */

  public async getWelcomeCampaignToShow(goToViewCallback, componentRef, ruooffer: TypeRuoffers[]) {
    const today = new Date();
    let userCampaigns = [];

    let responseSegment = await this.rewardPointsService.getSegmentDirection();
    const segmentDirection = responseSegment.retrieveSegmentStrategyCustomerResponse ? responseSegment.retrieveSegmentStrategyCustomerResponse.customer.type.description : CUSTOMER_TYPE_SEGMENT_RIPLEY;    

    await this.verifyProductServices();
    let campaigns: firebase.firestore.QueryDocumentSnapshot[];
    let campaignsUserData: firebase.firestore.DocumentData;
    const campaignsUserDataRef = this.welcomeCampaignsUserDataRef;

    if(this.welcomeCampaigns == undefined || this.welcomeCampaignsUserData == undefined) return;
    
    await Promise.all([
      this.welcomeCampaigns.then((docs) => { campaigns = docs; }),
      this.welcomeCampaignsUserData.then((snap) => { campaignsUserData = snap; }),
    ]);

    const notAssigned = this.notAssignedCampaigns(campaigns, campaignsUserData);
    for (const campaign of notAssigned) {
      campaignsUserData = await this.assignCampaigntoUser(campaignsUserDataRef, campaignsUserData, campaign.data());
    }
    for (const campaign of campaigns) {
      const campaignData = campaign.data();
      for (const userCampaignId of campaignsUserData.campaigns) {    
        if (
          campaign.id === userCampaignId &&
          campaignsUserData.counters[campaign.id] &&
          (campaignData.maxViews || Infinity) > campaignsUserData.counters[campaign.id].views &&
          (campaignData.maxGoals || Infinity) > campaignsUserData.counters[campaign.id].goals &&
          campaignData.filters.startDate.toDate() <= today &&
          campaignData.filters.endDate.toDate() >= today &&
          this.verifyDevice(campaignData) &&
          this.checkProductsConditions(campaignData) &&
          this.checkSegment(campaignData, segmentDirection) &&
          this.verifyAvSavAmounts(campaignData, ruooffer)
        ) {
          userCampaigns.push(campaign);
        }
      }
    }

    if (userCampaigns.length) {
      userCampaigns = userCampaigns.sort((firstElement, secondElement) => firstElement.data().priority - secondElement.data().priority);
      let userCampaignsData = [];
      for (const campaign of userCampaigns) {
        userCampaignsData.push(campaign.data());
      }

      await this.modalWelcomePack(
        userCampaignsData,
        campaignsUserDataRef,
        campaignsUserData,
        goToViewCallback,
        componentRef
      );
    }
    this.loadingWelcomeCampaigns = false;
  }

  //Metodo para setear variables locales, y cuando estén disponibles, ejecuta un método local para carga de banner sliders
  public setAndVerifyLocalData(creditCardData: any, ctsAccountData: any, savingAccount: any, ruooffer: TypeRuoffers[]) {    
    if(creditCardData) {            
      this.creditCard = creditCardData;
      this.creditCardSet = true;
    } else if(ctsAccountData) {      
      this.ctsAccount = ctsAccountData;
      this.ctsAccountSet = true;
    } else if(savingAccount) {      
      this.savingAccount = savingAccount;
      this.savingAccountSet = true;
    }
    if(this.creditCardSet && this.ctsAccountSet && this.savingAccountSet && !this.hasExecutedOnce) {
      this.hasExecutedOnce = true;
      this.getSliderCampaignToShow(ruooffer);
      this.getWelcomeCampaignToShow(this.goToViewCallback, this.welcomeModalComponent, ruooffer);
    }
  }

  public async getSliderCampaignToShow(ruooffer: TypeRuoffers[]) {

    this.loadSliderCampaignsConfig();
    const loginInfo = await this.storage.get(USER_INFO);
    this.loadSliderCampaignsUserData(loginInfo.documentType, loginInfo.documentNumber);

    let userSliderCampaigns = [];
    const today = new Date();
    await this.verifyProductServices();    
    
    let responseSegment = await this.rewardPointsService.getSegmentDirection();
    const segmentDirection = responseSegment.retrieveSegmentStrategyCustomerResponse ? responseSegment.retrieveSegmentStrategyCustomerResponse.customer.type.description : CUSTOMER_TYPE_SEGMENT_RIPLEY;    

    //const offers = await this.offeringsService.getRuoOffersOnOff();    
    const offers = ruooffer; 
    const sliderCampaignsUserDataRef = this.sliderCampaignsUserDataRef;    

    let sliderCampaignsSnapshot = await this.sliderCampaigns;
    let sliderCampaignsUserData = await this.sliderCampaignsUserData;
        
    sliderCampaignsSnapshot = sliderCampaignsSnapshot.filter(el => el.id != 'Campaña Prueba');
    const notAssigned = this.notAssignedCampaigns(sliderCampaignsSnapshot, sliderCampaignsUserData);
    for (const campaign of notAssigned) {
      sliderCampaignsUserData = await this.assignCampaigntoUser(
        sliderCampaignsUserDataRef, sliderCampaignsUserData, campaign.data());

    }        

    // filters for campaings assigend to user    
    for (const sliderCampaign of sliderCampaignsSnapshot) {
      const sliderCampaignData = sliderCampaign.data();
      for (const sliderCampaignUserId of sliderCampaignsUserData.campaigns) {
        if (
          sliderCampaign.id === sliderCampaignUserId &&
          sliderCampaignsUserData.counters[sliderCampaign.id] &&
          sliderCampaignsUserData.counters[sliderCampaign.id].views <= (sliderCampaignData.maxViews || Infinity) &&
          sliderCampaignsUserData.counters[sliderCampaign.id].goals <= (sliderCampaignData.maxGoals || Infinity) &&
          sliderCampaignData.filters.startDate.toDate() <= today &&
          sliderCampaignData.filters.endDate.toDate() >= today &&
          this.verifyDevice(sliderCampaignData) &&
          this.checkProductsConditions(sliderCampaignData) &&
          this.checkSegment(sliderCampaignData, segmentDirection) &&
          this.verifyAvSavAmounts(sliderCampaignData, offers)
        ) {
          userSliderCampaigns.push(sliderCampaign);
        }
      }
    }

    // get higher priority campaign and show it
    if (userSliderCampaigns.length) {
      userSliderCampaigns = userSliderCampaigns.sort((a, b) => a.data().priority - b.data().priority);
      const sliderCampaignToShow = userSliderCampaigns[0].data();
      if (sliderCampaignToShow.page === 'av' || sliderCampaignToShow.page === 'sav') {
        if (!sliderCampaignToShow.params) {
          sliderCampaignToShow.params = {};
        }
      }


      this.sliderCampaignToShow = sliderCampaignToShow;            
    }
  }


  public async modalWelcomePack(
    campaigns, campaignsUserDataRef, campaignsUserData, goToViewCallback, componentRef) {
    let userName;
    try {
      await this.userService.getUserInformation();
      userName = this.userService.userInformation.names;
    } catch {
      userName = '';
    }
    const userInfo = await this.storage.get(USER_INFO);
    const accounts = this.getCampaignsUserAccounts(campaignsUserData);
    const newAccounts = this.getCampaignsNewUserAccounts(campaignsUserData, userName);
    const user = {
      dNumber: userInfo.documentNumber,
      dType: userInfo.documentType,
      name: userName,
      userAccounts: accounts,
      newAccounts,
    };

    const firstCampaign = campaigns[0];
    const desktopMode =
      !!firstCampaign.filters.devices &&
      !!firstCampaign.filters.devices.desktop &&
      this.isDeviceAllowed(false, true);
    const desktopClass = desktopMode ? 'desktop-campaign-modal' : '';
    const offerModalClass = firstCampaign.offerModal && !desktopMode ? 'offer-modal' : '';
    const modal = await this.modalController.create({
      component: componentRef,
      cssClass: ['backdrop-modal', offerModalClass, desktopClass].join(' '),

      componentProps: {
        campaigns: campaigns,
        campaignsUserDataRef,
        campaignsUserData,
        user,
        desktopMode,
      },
      backdropDismiss: true,
    });

    await modal.present();
    modal.onWillDismiss().then(async (dismiss) => {
      if (!!dismiss.data) {
        goToViewCallback(dismiss.data.page, dismiss.data.params);
      }
    });
  }

  /**
   * Campaign assignation methods
   */

  public async assignCampaigntoUser(
    campaignsUserDataRef: firebase.firestore.DocumentReference, campaignsUserData, campaign
  ) {
    const data = {
      counters: {},
      campaigns: [...campaignsUserData.campaigns, campaign.id],
      documentNumber: campaignsUserDataRef.id.split('-')[1],
      documentType: campaignsUserDataRef.id.split('-')[0]
    };
    data.counters[campaign.id] = { views: 0, goals: 0 };
    await campaignsUserDataRef.set(data, { merge: true });

    // update campaignsUserData to avoid update object from firestore
    campaignsUserData.counters[campaign.id] = data.counters[campaign.id];
    campaignsUserData.campaigns.push(campaign.id);

    return campaignsUserData;
  }

  public notAssignedCampaigns(campaigns: any[], userData) {
    // for campaigns not assigned to user (allUsers flag)
    const notAssigned = campaigns.filter((c) =>
      c.data().filters.allUsers && (
        !userData.campaigns.find((campaign) => campaign === c.id) ||
        !Object.keys(userData.counters).find((campaign) => campaign === c.id)
      )
    );
    return notAssigned;
  }

  /**
   * Filter methods
   */

  public verifyDevice(campaign): boolean {
    return this.isDeviceAllowed(
      campaign.filters.devices ? campaign.filters.devices.mobile : undefined,
      campaign.filters.devices ? campaign.filters.devices.desktop : undefined
    );
  }

  public async verifyProductServices() {
    if (this.creditCard && this.creditCard.productOutput) {
      this.creditCard.productOutput.forEach(element => {
        this.subProductCode = element.productType === CREDIT_CARD ? element.subProductCode : '';
      });
    }
  }

  public checkProductsConditions(campaign): boolean {
    this.hasCreditCard = this.creditCard && this.creditCard.accounts.length > 0 ?
    this.creditCard.accounts.some(productType => productType.productType === CREDIT_CARD) : false;
    this.hasCTS = this.ctsAccount.some(element => element.benefits === CTS_ACCOUNT);
    this.hasSIMPLE = this.savingAccount.some(element => element.benefits === SIMPLE_ACCOUNT);
    this.hasPLUS = this.savingAccount.some(element => element.benefits === PLUS_ACCOUNT);
    if (this.productErrorService) { return false; }
    if (!!campaign.filters.activeVerify) {
      return campaign.filters.activeVerify;
    }
    if (!!campaign.filters.haveProductCondition && campaign.filters.haveProductCondition === 'or') {
      return (campaign.filters.forTC && this.hasCreditCard) ||
        (campaign.filters.forCTS && this.hasCTS) ||
        (campaign.filters.forSIMPLE && this.hasSIMPLE) ||
        (campaign.filters.forPLUS && this.hasPLUS);
    } else {
      return (!campaign.filters.forTC || (campaign.filters.forTC && this.hasCreditCard)) &&
        (!campaign.filters.forCTS || (campaign.filters.forCTS && this.hasCTS)) &&
        (!campaign.filters.forSIMPLE || (campaign.filters.forSIMPLE && this.hasSIMPLE)) &&
        (!campaign.filters.forPLUS || (campaign.filters.forPLUS && this.hasPLUS));
    }
  }

  checkSegment(campaign: any, segment: any): boolean {
    if(campaign.filters.segment) {

      if (segment) {
        return (campaign.filters.segmentRipley && segment.toLowerCase() === CUSTOMER_TYPE_SEGMENT_RIPLEY) ||
               (campaign.filters.segmentSilver && segment.toLowerCase() === CUSTOMER_TYPE_SEGMENT_SILVER) ||
               (campaign.filters.segmentGold && segment.toLowerCase() === CUSTOMER_TYPE_SEGMENT_GOLD);
      } else {
        return false;
      }
    } else return true;
  }

  verifyAvSavAmounts(campaign, offers: TypeRuoffers[]): boolean {
    if((campaign.filters.sef && campaign.filters.sef.active) || (campaign.filters.efex && campaign.filters.efex.active)) { 
      let sefFilterPassed = false;
      let efexFilterPassed = false;

      if(campaign.filters.sef && campaign.filters.sef.active) {
        const activeSefOffer = offers.find(el => el.codeProduct == PRODUCT_SEF);
        //Debe tener sef, y el rango de oferta sef debe estar contenido dentro del rango configurado en la campaña
        if(activeSefOffer && activeSefOffer.maximumAmount >= campaign.filters.sef.min && activeSefOffer.maximumAmount <= campaign.filters.sef.max) {
          sefFilterPassed = true;
        } else {
          sefFilterPassed = false;
        }
      } else {
        sefFilterPassed = true;
      }

      if(campaign.filters.efex && campaign.filters.efex.active) {
        const activeEfexOffer = offers.find(el => el.codeProduct == PRODUCT_EFEX);
        //Debe tener efex, y el rango de oferta sef debe estar contenido dentro del rango configurado en la campaña
        if(activeEfexOffer && activeEfexOffer.maximumAmount >= campaign.filters.efex.min && activeEfexOffer.maximumAmount <= campaign.filters.efex.max) {
          efexFilterPassed = true;
        } else {
          efexFilterPassed = false;
        }
      } else {
        efexFilterPassed = true;
      }

      return sefFilterPassed && efexFilterPassed;

    } else {
      return true;
    }
  }

  /**
   * Load config methods
   */

  public async loadWelcomeCampaignsConfig() {
    this.welcomeCampaigns = this.firebase.getFirebaseCollection('welcomeCampaigns')
      .where('showPWA', '==', true)
      .get().then((snap) => {
        return snap.docs;
      });
  }

  public loadSliderCampaignsConfig() {
    this.sliderCampaigns = this.firebase.getFirebaseCollection('sliderCampaigns')
      .where('showPWA', '==', true)
      .get().then((snap) => {
        return snap.docs;
      });
  }

  /**
   * Load user data methods
   */

  public async loadWelcomeCampaignsUserData(dType: string, dNumber: string) {
    this.welcomeCampaignsUserDataRef = this.firebase.getFirebaseCollection('welcomeCampaignUsers').doc(`${dType}-${dNumber}`);
    this.welcomeCampaignsUserData = this.welcomeCampaignsUserDataRef.get().then(async (snap) => {
      return !!snap.data() ? snap.data() :
        await this.initializeCampaignUserData(this.welcomeCampaignsUserDataRef, true, true);
    });
  }

  public async loadSliderCampaignsUserData(dType: string, dNumber: string) {
    this.sliderCampaignsUserDataRef = this.firebase.getFirebaseCollection('sliderCampaignUsers').doc(`${dType}-${dNumber}`);
    this.sliderCampaignsUserData = this.sliderCampaignsUserDataRef.get().then(async (snap) => {
      return snap.data() ? snap.data() :
        await this.initializeCampaignUserData(this.sliderCampaignsUserDataRef, true, true);
    });
  }

  /**
   * Initialize user register for campaign methods
   */

  public async initializeCampaignUserData(
    campaignsUserDataRef: firebase.firestore.DocumentReference, counters = false, useCards = false) {
    const data = {
      campaigns: [],
      documentNumber: campaignsUserDataRef.id.split('-')[1],
      documentType: campaignsUserDataRef.id.split('-')[0]
    };
    if (counters) { data['counters'] = {}; }
    if (useCards) { data['cards'] = {}; }
    await campaignsUserDataRef.set(data, { merge: true });
    return data;
  }

  /**
   * Utils and exclusive for some campaigns methods
   */

  public isDeviceAllowed(mobile = true, desktop = false) {
    if (environment.ENV === 'local') { return false; }
    switch (true) {
      case mobile && desktop:
        return true;
      case mobile:
        return this.platform.is('cordova') ? window.innerWidth < SCREEN_WIDTH.TABLET : !this.platform.is('desktop');
      case desktop:
        return this.platform.is('desktop') || this.platform.is('tablet');
      default:
        return false;
    }
  }

  public getCampaignsUserAccounts(campaignsUserData) {
    if (!!campaignsUserData.cards && campaignsUserData.cards.useCards) {
      return {
        hasCreditCard: !!campaignsUserData.cards.TC,
        hasSimpleAccount: !!campaignsUserData.cards.SIMPLE,
        hasPlusAccount: !!campaignsUserData.cards.PLUS,
        hasCtsAccount: !!campaignsUserData.cards.CTS,
        subProductCode: this.subProductCode
      };
    }
    return {
      hasCreditCard: this.hasCreditCard,
      hasSimpleAccount: this.hasSIMPLE,
      hasPlusAccount: this.hasPLUS,
      hasCtsAccount: this.hasCTS,
      subProductCode: this.subProductCode
    };
  }

  public getCampaignsNewUserAccounts(campaignsUserData, userName: string) {
    this.newAccounts = [];
    if (!!campaignsUserData.cards && campaignsUserData.cards.useCards) {
      switch (true) {
        case campaignsUserData.cards.TC:
          this.newAccounts.push({ type: CREDIT_CARD, pan: 'XXXX', name: userName, subProductCode: this.subProductCode });
          break;
        case campaignsUserData.cards.SIMPLE:
          this.newAccounts.push({ type: SIMPLE_ACCOUNT, pan: 'XXXX', name: userName });
          break;
        case campaignsUserData.cards.PLUS:
          this.newAccounts.push({ type: PLUS_ACCOUNT, pan: 'XXXX', name: userName });
          break;
        case campaignsUserData.cards.CTS:
          this.newAccounts.push({ type: CTS_ACCOUNT, pan: 'XXXX', name: userName });
      }
    }
    return this.newAccounts;
  }

  public async addCount(counter: 'views' | 'goals', campaign, campaignsUserData, campaignsUserDataRef, completeProcess = true) {
    const data = { counters: {} };
    data.counters[campaign.id] = { [counter]: campaignsUserData.counters[campaign.id][counter] + 1 };
    await campaignsUserDataRef.set(data, { merge: true });
    this.addCountCollection(counter, campaign, campaignsUserDataRef.id, data.counters[campaign.id][counter]);

    if(!completeProcess) return;

    const firebasePath = campaignsUserDataRef.path.split('Users/')[0] + 'UserViewsGoals';
    let campaingCollection = await this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).get();

    if (!campaingCollection.exists) {
      await this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
        count_users_with_goals: 0,
        count_users_with_views: 0,
        banner: campaignsUserDataRef.path.split('Users/')[0],
        id: campaignsUserDataRef.path.split('Users/')[0] + '-' + campaign.id,
        total_goals: 0,
        total_views: 0,
        total_users: 0
      }, { merge: true });
      campaingCollection = await this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).get();
    }

    const userId = `${campaignsUserData.documentType}-${campaignsUserData.documentNumber}`;
    const userCampaign = await this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).collection('stats')
      .doc(userId).get();

    if (!userCampaign.exists) {
      this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).collection('stats').doc(userId).
        set({
          uid: userId,
          views: 1,
          goals: 0,
        }, { merge: true });

      if (counter === 'views') {
        this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
          count_users_with_views: campaingCollection.data()['count_users_with_views'] + 1,
          total_views: campaingCollection.data()['total_views'] + 1,
          total_users: campaingCollection.data()['total_users'] + 1,
        }, { merge: true });
      } else {
        this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
          count_users_with_goals: campaingCollection.data()['count_users_with_goals'] + 1,
          total_goals: campaingCollection.data()['total_goals'] + 1,
          total_users: campaingCollection.data()['total_users'] + 1,
        }, { merge: true });
      }
    } else {
      const info = {};
      const lastCount = userCampaign.data()[counter];
      info[counter] = campaignsUserData.counters[campaign.id][counter] + 1;
      this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).collection('stats').doc(userId).
        set(info, { merge: true });
      if (counter === 'views') {
        this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
          total_views: campaingCollection.data()['total_views'] + 1,
        }, { merge: true });
      } else if (info['goals'] === 1 && lastCount === 0) {
        this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
          count_users_with_goals: campaingCollection.data()['count_users_with_goals'] + 1,
          total_goals: campaingCollection.data()['total_goals'] + 1,
        }, { merge: true });
      } else if (info['goals'] === lastCount + 1) {
        this.firebase.getFirebaseCollection(firebasePath).doc(campaign.id).set({
          total_goals: campaingCollection.data()['total_goals'] + 1,
        }, { merge: true });
        campaignsUserData.counters[campaign.id][counter] += 1;
      }
    }
  }

  public async addCountCollection(counter: 'views' | 'goals', campaign, uid, counterValue = -1) {
    if (!campaign.type || (campaign.type !== 'welcome' && campaign.type !== 'slider')) { return; }
    const campaigns: firebase.firestore.QueryDocumentSnapshot[] = campaign.type == 'welcome' ? await this.welcomeCampaigns : await this.sliderCampaigns;
    
    const campaignSnapshot = campaigns.find((c) => c.id === campaign.id);
    if (campaignSnapshot) {
      const docCollection = await campaignSnapshot.ref.collection(counter).doc(uid).get()
      const collectionData = docCollection.data()
      if (collectionData == undefined) {
        const currentDate = new Date();
        const formattedDate = this.getFormattedDateTime(currentDate);
        campaignSnapshot.ref.collection(counter).doc(uid).set({ times: counterValue, fecha: formattedDate }, { merge: true });
      }
      else {
        campaignSnapshot.ref.collection(counter).doc(uid).set({ times: counterValue }, { merge: true });
      }
    }
  }

  /**
   * Updates the sliderCampaignStats collection, adding +1 to the input parameter field. The method addCount is used to something similar with added logic, this function simplifies the process.
   * @campaignId Id of the campaign to update
   * @param field Use 'views' or 'goals'
   */
  public async updateCampaignStats(campaignId: string, field: string) {
    const sliderCampaignStats = await this.firebase.getFirebaseCollection('sliderCampaignsStats').doc(campaignId).get();
    const sliderCampaignStatsData = sliderCampaignStats.data();
    const actualFieldValue = sliderCampaignStatsData && sliderCampaignStatsData[field] ? sliderCampaignStatsData[field] : 0; 
            
    this.firebase.getFirebaseCollection('sliderCampaignsStats').doc(campaignId).set({
      [field]: actualFieldValue + 1,
    }, { merge: true });
  }

  resetServiceVariables() {
    //Mismos valores que arriba en las inicializaciones
    this.savingAccount = null;
    this.savingAccountSet = false;
    this.ctsAccount = null;
    this.ctsAccountSet = false;
    this.creditCard = null;
    this.creditCardSet = false;    
    this.hasExecutedOnce = false;
  
    this.subProductCode;
    this.hasCreditCard = false;
    this.hasCTS = false;
    this.hasPLUS = false;
    this.hasSIMPLE = false;   
  }

  getFormattedDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
