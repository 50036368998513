import { Component, Input } from '@angular/core';
import { ITableData } from '@common/interfaces/default.interface';
import { ModalController } from '@ionic/angular';
import { SAVINGS_MOVEMENTS_MODAL_PROPS } from '@common/constants/modal-props.constants';
import { IMovementSavingAccount } from '@common/interfaces/savings.interface';
import { SavingsModalComponent } from '@components/savings-modal/savings-modal.component';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';

@Component({
  selector: 'app-description-table',
  templateUrl: './description-table.component.html',
  styleUrls: ['./description-table.component.scss'],
})
export class DescriptionTableComponent {

  @Input() tableHeader: ITableData[];
  @Input() tableData: ITableData[][];
  @Input() emptyMessage: string;
  @Input() account: string;
  @Input() cardType: string;
  @Input() isAditional: boolean;

  constructor(
    private modalCtrl: ModalController,
  ) {
    this.tableData = [[]];
  }

  public getColorMovement(amount: string) {
    return amount[0] === '-' ? 'out-color' : 'in-color';
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  public async showMovementDetailModal(data: string) {
    const dataJson: IMovementSavingAccount = JSON.parse(data);
    const props = {
      title: SAVINGS_MOVEMENTS_MODAL_PROPS.title,
      primaryButtonText: SAVINGS_MOVEMENTS_MODAL_PROPS.primaryButtonText,
      movement: dataJson,
      account: this.account
    };
    const modal = await this.modalCtrl.create({
      component: SavingsModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  get isLargeTable() { return this.tableHeader.length > 3; }
}
