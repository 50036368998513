import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  OTP_LENGTH, OTP_MAX_TIME, SEND_OTP_SUCCESSFUL
} from '@common/constants/enrollment.constants';
import {
  MILLISECONDS_TO_SECOND, RIPLEY_BANK_PUBLIC_SITE_URL
} from '@common/constants/misc.constants';
import { CONTACT_WITH_CALL_CENTER_MODAL_PROPS } from '@common/constants/modal-props.constants';
import {
  IL_NOT_NUMBER, IL_RESEND_SMS_CODE, IL_TIMEOUT_SMS
} from '@common/constants/tag/tag-incrementolinea.constants';
import { IUserInformation } from '@common/interfaces/user.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { HideTextPipe } from '@pipes/hide-text-number/hide-text-number.pipe';
import { AlertService } from '@services/alert/alert.service';
import { OtpService } from '@services/otp/otp.service';
import { UserService } from '@services/user/user.service';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-sms-validation-input',
  templateUrl: './sms-validation-input.component.html',
  styleUrls: ['./sms-validation-input.component.scss'],
})
export class SmsValidationInputComponent implements OnDestroy {

  @Input() phoneNumber: string;
  @Input() cameFrom: string;
  @Input() tagManagerEventLabel: string;
  @Input() userInformation: IUserInformation;
  @Output() otpCodeValidation: EventEmitter<boolean>;
  @Output() otpCodeValue: EventEmitter<string>;
  otpCodeForm: FormGroup;
  maxTime: number;
  showTimerSection: boolean;
  hideTimer: boolean;
  hideText: HideTextPipe;
  subscription: Subscription;
  validatingOtp: boolean;
  url: string;
  timer: NodeJS.Timeout;

  constructor(
    private validationService: OtpService,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private userService: UserService,
  ) {
    this.otpCodeValidation = new EventEmitter();
    this.otpCodeValue = new EventEmitter();
    this.hideText = new HideTextPipe();
    this.subscription = new Subscription();
    this.maxTime = OTP_MAX_TIME;
    this.showTimerSection = false;
    this.hideTimer = false;
    this.validatingOtp = false;
    this.otpCodeForm =  this.formBuilder.group({
      otpCode: new FormControl('', Validators.compose([
        Validators.required, Validators.maxLength(OTP_LENGTH),
        Validators.required, Validators.minLength(OTP_LENGTH),
      ])),
    });
    this.subscription.add(this.otpCode.valueChanges.subscribe((code: string) => {
      if (code.length === OTP_LENGTH && !this.validatingOtp) {
        this.emitOtp();
      }
    }));
    this.url = this.router.url;
  }

  get otpCode() {
    return this.otpCodeForm.controls.otpCode;
  }

  get showTimerLoading() {
    return this.showTimerSection && this.maxTime === OTP_MAX_TIME;
  }

  public emitOtp() {
    this.validatingOtp = false;
    this.showTimerSection = false;
    this.otpCodeValidation.emit(true);
    const otpValue = this.otpCode.value;
    this.otpCodeValue.emit(otpValue);
  }

  private setOtpTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.maxTime <= 0) {
        this.utilsService.addEventToTagManagerAndFirebase(IL_TIMEOUT_SMS.event, IL_TIMEOUT_SMS.variable, IL_TIMEOUT_SMS.action);
        this.showTimerSection = false;
      }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.hideTimer = false;
        this.setOtpTimer();
      } else {
        this.hideTimer = true;
      }
    }, MILLISECONDS_TO_SECOND);
  }

  private sendError(message: string) { throw new Error(message); }

  private openRipleyBankSite() {
    window.open(RIPLEY_BANK_PUBLIC_SITE_URL);
  }

  public async showItIsNotMyNumberModal() {
    this.utilsService.addEventToTagManagerAndFirebase(IL_NOT_NUMBER.event, IL_NOT_NUMBER.variable, IL_NOT_NUMBER.action);

    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: {
        title: `El ${this.hideText.transform(this.userInformation.mobileNumber, 3)} no es tu número?`,
        ...CONTACT_WITH_CALL_CENTER_MODAL_PROPS
      }
    });
    await modal.present();
    const option = await modal.onDidDismiss();
    return option.data === 'primaryButtonPressed' && this.openRipleyBankSite();
  }

  public async resendOTP() {
    try {

      await this.userService.getContactData();
      this.sendOTP(true);
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, err.errorMessage);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.resendOTP(); }
      }
    }
  }

  public async sendOTP(isResend: boolean = false) {
    if (isResend) {
      this.utilsService.addEventToTagManagerAndFirebase(IL_RESEND_SMS_CODE.event, IL_RESEND_SMS_CODE.variable, IL_RESEND_SMS_CODE.action);
    }
    try {
      const otpResponse = await this.validationService.sendOTPIncrementLine();
      if (otpResponse.code === SEND_OTP_SUCCESSFUL) {
          this.maxTime = OTP_MAX_TIME;
          this.showTimerSection = true;
          this.setOtpTimer();
      } else {
        this.sendError(otpResponse.errorMessage);
      }
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, true, null, null, err.errorMessage);
      if (alertMessage) {
        alertMessage.present();
        const response = await alertMessage.onDidDismiss();
        if (response) { await this.sendOTP(); }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
