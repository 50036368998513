import { Component, OnInit, Input } from '@angular/core';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { ModalController } from '@ionic/angular';
import { PRODUCT_NAMES, PRODUCT_SEF, LEAD_EXECUTION_CODE, OFFERS_LOAN, PRODUCT_EFEX, NAME_SEF, PRODUCT_SEF_REENGANCHE, NAME_EFEX, ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT } from '@common/constants/offerings.constants';
import { Router } from '@angular/router';
import { EFEX_AUTO_ROUTE, EFEX_ROUTE, SEF_ROUTE } from '@common/constants/routes.constants';
import { ILoan, IOffering, IOfferState } from '@common/interfaces/offerings.interface';
import { OfferingsService } from '@services/offerings/offerings.service';
import { LoanLeadService } from '@services/loan-lead/loan-lead.service';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  DYNAMIC_MESSAGE_MODAL_PROPS,
  END_TO_END_OFF_MODAL_PROPS, LOAN_LEAD_FAILED_MODAL_PROPS,
  OFFER_EFEX_INACTIVE_MODAL_PROPS, OFFER_SEF_INACTIVE_MODAL_PROPS
} from '@common/constants/modal-props.constants';
import { LeadExistModalComponent } from '@components/leads-modal/lead-exist-modal/lead-exist-modal.component';
import { ILeadModalBody, ILoanLead } from '@common/interfaces/loan-lead.interface';
import { LeadGenericModalComponent } from '@components/leads-modal/lead-generic-modal/lead-generic-modal.component';
import { LEAD_EXIST_ERROR } from '@common/constants/efex.constants';
import { OfferingModalComponent } from '@components/offering-modal/offering-modal.component';
import { FirebaseService } from '@services/firebase/firebase.service';
import { StorageService } from '@services/storage/storage.service';
import { USER_INFO } from '@common/constants/auth.constants';
import { SUCCESS } from '@common/constants/error.constants';
import { HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-banner-sef-efex',
  templateUrl: './banner-sef-efex.component.html',
  styleUrls: ['./banner-sef-efex.component.scss'],
})
export class BannerSefEfexComponent implements OnInit {

  @Input() productName: string;
  @Input() productAmount: string;
  @Input() classToMultiple: string;
  @Input() isMultipleBanner: boolean;
  @Input() bannerOfferData: IOffering;
  isLoading: boolean;
  text: string;
  constructor(
      private modalCtrl: ModalController,
      private router: Router,
      private startLoans: OfferingsService,
      private loanLeadService: LoanLeadService,
      private firebaseService: FirebaseService,
      private storage: StorageService,
      private googleAnalyticsService: GoogleAnalyticsService,
      ) {
    this.isLoading = false;
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  // CAMBIO_BANNER
  async showOfferDetail() {
    this.isLoading = true;

    if(this.bannerOfferData.nameProduct === 'EFEX') {

      this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.EVENT, {
        path_destino: HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.PATH_DESTINO,
        product: HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.PRODUCT,
        action: HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.ACTION,
        category: HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.CATEGORY,
        sub_category: HOME_GA4_CONSTANTS.HOME_EFEX_BANNER.SUB_CATEGORY
      });
    }

    if(this.bannerOfferData.nameProduct === 'SEF') {

      this.googleAnalyticsService.gtagPushEventWithId(HOME_GA4_CONSTANTS.HOME_SEF_BANNER.EVENT, {
        path_destino: HOME_GA4_CONSTANTS.HOME_SEF_BANNER.PATH_DESTINO,
        product: HOME_GA4_CONSTANTS.HOME_SEF_BANNER.PRODUCT,
        action: HOME_GA4_CONSTANTS.HOME_SEF_BANNER.ACTION,
        category: HOME_GA4_CONSTANTS.HOME_SEF_BANNER.CATEGORY,
        sub_category: HOME_GA4_CONSTANTS.HOME_SEF_BANNER.SUB_CATEGORY
      });
    }

    await this.checkStorage();
    if(await this.delayValidation()) return
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    this.isLoading = false;

    // FLUJO SEMI-AUTOMATICO
    if (this.bannerOfferData.loansV1) {
      switch (this.bannerOfferData.nameProduct) {
        case 'SEF':
          this.firebaseService.registerStepSefEfex('sef modal', { origen: 'BannerSefEfexComponent', dniSearchFormat });
          this.showSefModal();
          return ;
        case 'EFEX':
          this.firebaseService.registerStepSefEfex('efex semi automatico',
          { origen: 'BannerSefEfexComponent', dniSearchFormat });
          return this.router.navigate([EFEX_ROUTE], { state: this.bannerOfferData });
      }
    }
    if(this.bannerOfferData.codeProduct == PRODUCT_SEF_REENGANCHE){
      this.firebaseService.registerStepSefEfex('sef reenganche modal', { origen: 'BannerSefEfexComponent', dniSearchFormat });
      this.showSefReengancheModal();
      return ;
    }

    // FLUJO AUTOMATICO
    if (!this.bannerOfferData.endToEnd) {
      this.showEndToEndOffModal(this.bannerOfferData.codeProduct);
      return ;
    }
    if (this.bannerOfferData.offerInactive) {
      this.showOfferInactiveModal();
    } else {
      const routerStateData: IOfferState = {
        offeringData: this.bannerOfferData,
        isEfexPromotional: false
      };
      switch (this.bannerOfferData.nameProduct) {
        case NAME_SEF:
          this.firebaseService.registerStepSefEfex('sef automatico', { origen: 'BannerSefEfexComponent', dniSearchFormat });
          this.router.navigate([SEF_ROUTE], { state: routerStateData });
          break;
        case NAME_EFEX:
          this.firebaseService.registerStepSefEfex('efex automatico',
          { origen: 'BannerSefEfexComponent', dniSearchFormat });
          this.router.navigate([EFEX_AUTO_ROUTE], { state: routerStateData});
          break;
      }
    }
  }

  public async delayValidation(){
    this.isLoading = false;
    if(this.bannerOfferData.isPopUpsDynamic){
      DYNAMIC_MESSAGE_MODAL_PROPS['message'] = this.bannerOfferData.messagePopUpsDynamic;
      const modal = await this.modalCtrl.create({
        component: GenericModalComponent,
        cssClass: 'form-modal small',
        componentProps: DYNAMIC_MESSAGE_MODAL_PROPS,
      });
      await modal.present();
      return true;
    }
    return false;
  }
  
  private async checkStorage() {
    const offers_loan = sessionStorage.getItem(OFFERS_LOAN);
    if (offers_loan === null || offers_loan === undefined) {
      await this.startLoan(this.bannerOfferData.codeProduct);
    }
  }

  public formattedTCA(offer: IOffering) {
    const timeMonth = Math.max(offer.minimumTimeMonth, offer.maximumTimeMonth);
    return 'TCEA* ' + Number(offer.rateTCA).toFixed(2) + '% a ' + timeMonth + ' cuotas.';
  }

  public getPrettyName(productCode: string) {
    return PRODUCT_NAMES[productCode].prettyName;
  }

  async showSefModal() {
    const props = {
      title: 'Detalles de tu ' + this.getPrettyName(this.bannerOfferData.codeProduct),
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: this.bannerOfferData,
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(this.bannerOfferData),
      disclaimer: this.bannerOfferData.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  async showSefReengancheModal() {
    const props = {
      title: 'Detalles de tu Renovación de Súper Efectivo',
      subtitle: 'Monto a desembolsar',
      primaryButtonText: 'Volver',
      offer: this.bannerOfferData,
      amountIcon: 'i-money-bill-pink',
      tca: this.formattedTCA(this.bannerOfferData),
      disclaimer: this.bannerOfferData.customerOfferReference
    };
    const modal = await this.modalCtrl.create({
      component: OfferingModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public openPhoneNumber(phoneNumber: string) {
    document.location.href = `tel:${phoneNumber}`;
  }

  private async startLoan(codePorduct: string) {
    sessionStorage.setItem(ORIGIN_LOAN_PWA, ORIGIN_LOAN_PWA_OPT.BANNER);
    try {
      const loanRequest: ILoan = {
        codeProduct: codePorduct,
        reference: window.location.href,
        utmCampaing: '',
        utmContent: '',
        utmMedium: '',
        utmSource: '',
        isFeedback: false,
        originLoanPwa: ORIGIN_LOAN_PWA_OPT.BANNER
      };
      const result = await this.startLoans.startLoanRegister(loanRequest);
      sessionStorage.setItem(LEAD_EXECUTION_CODE, result.executionCode);
      sessionStorage.setItem(OFFERS_LOAN, 'true');
    } catch (err) {}
  }

  private async saveLead() {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const codeProduct = this.bannerOfferData.codeProduct;
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: '',
        originLoanPwa: sessionStorage.getItem(ORIGIN_LOAN_PWA)
      };
      try {
        let lead = await this.loanLeadService.registerLoanLead(loanLead);
        if (lead.code === LEAD_EXIST_ERROR) this.openLeadExistModal();
      } catch (err) {
        this.openFailedModal();
      }
    }
  }

  public async openFailedModal() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: LOAN_LEAD_FAILED_MODAL_PROPS,
    });
    modal.present();
  }

  public async openLeadExistModal() {
    const props = {
      productName: this.bannerOfferData.productRealName
    };

    const modal = await this.modalCtrl.create({
      component: LeadExistModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    modal.present();
  }

  public async showOfferInactiveModal() {
    let props = {};
    switch (this.bannerOfferData.codeProduct) {
      case PRODUCT_EFEX:
        props = OFFER_EFEX_INACTIVE_MODAL_PROPS;
        break;
      case PRODUCT_SEF:
        props = OFFER_SEF_INACTIVE_MODAL_PROPS;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: props,
    });
    await modal.present();
  }

  public async showEndToEndOffModal(codeProduct: string) {
    const executionCode = sessionStorage.getItem(LEAD_EXECUTION_CODE);
    if (executionCode !== null && executionCode !== undefined) {
      const loanLead: ILoanLead = {
        codeProduct,
        executionCode,
        reference: window.location.href,
        callCenterReason: ''
      };

      const leadModalBody: ILeadModalBody = END_TO_END_OFF_MODAL_PROPS;
      const props = {
        leadModalBody,
        loanLeadData: loanLead
      };
      const modal = await this.modalCtrl.create({
        component: LeadGenericModalComponent,
        cssClass: 'form-modal',
        componentProps: props,
      });
      await modal.present();
    }
  }

  ngOnInit() { this.text = this.bannerOfferData.textBanner; }

}
