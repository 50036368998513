import {
  IFinancialInstitution,
  ITransferOTPResponse,
  ITransferResponse,
  ITransferShiftSchedule,
} from "@common/interfaces/transfers.interface";

export const TRANSFER_MOCK = {
  success: [],
  failures: [],
};
export const CONTACT_LIST_MOCK = {
  success: [],
  failures: [],
}
export const BANK_DIRECTORY_MOCK = {
  success: [],
  failures: []
}
export const GET_WIDGET_TOKEN_INTEROP_MOCK ={
  success: [],
  failures: []
}
export const SEND_WIDGET_MOCK = {
  success: [],
  failures: []
};

export const SEND_OTP_MOCK = {
  success: [],
  failures: [],
};

export const FINANCIAL_INSTITUTION_MOCK = {
  success: [],
  failures: [],
};

export const MAX_TRANSFER_AMOUT_MOCK = {
  success: [],
  failures: [],
};

export const SHIFTS_SCHEDULE_MOCK = {
  success: [],
  failures: [],
};
