import { Injectable } from '@angular/core';
import { TypeRuoffers } from '@common/types/ruoffers.types';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { SEF_EFEX_ON_OFF_DOCUMENT_V2 } from '@common/constants/firebase.constants';
import { ISefEfexOnOff, ISefEfexUser } from '@common/interfaces/firebase-on-off.interface';
import { FirebaseService } from '@services/firebase/firebase.service';
import { PRODUCT_EFEX, PRODUCT_SEF } from '@common/constants/offerings.constants';
import { UtilsService } from './../utils/utils';
import { USER_INFO } from '@common/constants/auth.constants';
import { StorageService } from '@services/storage/storage.service';
import * as firebase from 'firebase/app';
import { ILoan, ILoanOutput } from '@common/interfaces/offerings.interface';
import { shareReplay, take, tap } from 'rxjs/operators';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';

@Injectable({
  providedIn: 'root'
})
export class RuoffersService {

  private dataSouce$ = new BehaviorSubject<TypeRuoffers[]>([]);
  ruoOffer: TypeRuoffers[];

  ruoOffersLoginCache: Promise<TypeRuoffers[]>;

  constructor(
    private _http: HttpClient,
    private firebaseService: FirebaseService,
    private utilsService: UtilsService,
    private storage: StorageService,
    private navCheckerSrv: NavigationCheckerService
    ) { }

  get setRuoOffers(): Observable<TypeRuoffers[] | []> {
    return this.dataSouce$.asObservable();
  }

  getRuoOffers(): Observable<TypeRuoffers[] | []> {
    return this.setRuoOffers.pipe(take(1));
  }

  preloadRuoOffers(forceRequest?: boolean): Promise<TypeRuoffers[]> {    
    if(this.navCheckerSrv.InHome() && this.ruoOffersLoginCache && !forceRequest) {
      return this.ruoOffersLoginCache;
    }

    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/ruooffers';
    const request = this._http.get<TypeRuoffers[]>(url).pipe(shareReplay(1)).toPromise();   
    
    this.ruoOffersLoginCache = request;
    return this.ruoOffersLoginCache;

  }

  async refreshRuoOffers(): Promise<void> {
    try {
      const data = await this.preloadRuoOffers();
      const sefEfexParams = await this.firebaseService.getData(SEF_EFEX_ON_OFF_DOCUMENT_V2) as ISefEfexOnOff;
      if (!sefEfexParams) { 
        this.dataSouce$.next(data); 
        return;
      }
      if (sefEfexParams.overRideService) { 
        this.ruoOffer = this.overwriteParams(data, sefEfexParams); 
      }
      if(sefEfexParams.enableWhiteListWidget){
        this.ruoOffer = await this.whitelistValidationWidgetTin(this.ruoOffer,'widget');
      }
      if(sefEfexParams.enableWhiteListTin){
        this.ruoOffer = await this.whitelistValidationWidgetTin(this.ruoOffer,'tin');
      }
      if (!this.utilsService.isNative() && sefEfexParams.enableWhiteList) { 
        this.ruoOffer = await this.whitelistValidation(this.ruoOffer); 
      }
      if (this.utilsService.isNative() && sefEfexParams.enableWhiteListApp) { 
        this.ruoOffer = await this.whitelistValidation(this.ruoOffer); 
      }

      const ruofferProduct = (this.ruoOffer || data).map(offer => {
        const offerLoans = {...offer};
        if (offer.codeProduct === PRODUCT_EFEX) {
          offerLoans['loansV1'] = this.utilsService.isNative() ? Boolean(offer.loansV1 || !sefEfexParams.efexEnabledApp) : Boolean(offer.loansV1 || !sefEfexParams.efexEnabled);
          offerLoans.widgetEnabled = offer.widgetEnabled!=undefined ? Boolean(offer.widgetEnabled && sefEfexParams.widgetEnabled) : sefEfexParams.widgetEnabled;
          offerLoans.tinEnabled = offer.tinEnabled!=undefined ? Boolean(offer.tinEnabled && sefEfexParams.tinEnabled) : sefEfexParams.tinEnabled;
        } else if (offer.codeProduct === PRODUCT_SEF) {
          offerLoans['loansV1'] = this.utilsService.isNative() ? Boolean(offer.loansV1 || !sefEfexParams.sefEnabledApp) : Boolean(offer.loansV1 || !sefEfexParams.sefEnabled)
          offerLoans.widgetEnabled = offer.widgetEnabled!=undefined ? Boolean(offer.widgetEnabled && sefEfexParams.widgetEnabled) : sefEfexParams.widgetEnabled;
          offerLoans.tinEnabled = offer.tinEnabled!=undefined ? Boolean(offer.tinEnabled && sefEfexParams.tinEnabled) : sefEfexParams.tinEnabled;
        }
        return offerLoans;
      })
      this.dataSouce$.next(ruofferProduct); 
    } catch (error) {
      console.log('Error en ruoffers: ' + error)
    }

  }

  private overwriteParams(ruoOffer: TypeRuoffers[], sefEfexParams: ISefEfexOnOff): TypeRuoffers[] {
    const sef = sefEfexParams.sefAmount;
    const efex = sefEfexParams.efexAmount;
    const ruofferLoans = ruoOffer.map(offer => {
      const offerLoans = {...offer}
      if (offer.codeProduct === PRODUCT_EFEX) {
        offerLoans['loansV1'] = !this.betweenValues(offer.maximumAmount, efex.minAmount, efex.maxAmount);
        offerLoans.maximumAmountLimit = efex.maxAmount;
        offerLoans.minimumAmountLimit = efex.minAmount;
      } else if (offer.codeProduct === PRODUCT_SEF) {
        offerLoans['loansV1'] = !this.betweenValues(offer.maximumAmount, offer.minimumAmount, offer.maximumAmount);
        offerLoans.maximumAmountLimit = offer.maximumAmount;
        offerLoans.minimumAmountLimit = offer.minimumAmount;
      }
      return offerLoans;
    })
    return ruofferLoans;
  }

  private betweenValues(value: number, valueMin: number, valueMax: number): boolean {
    return value >= valueMin && value <= valueMax;
  }

  private async whitelistValidationWidgetTin(ruoOffer: TypeRuoffers[], type:string): Promise<TypeRuoffers[]> {
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    const dniEnabled = await this.firebaseService.getUsersSefEfex(dniSearchFormat) as ISefEfexUser;

    const ruofferLoans = ruoOffer.map(offer => {
      const offerLoans = {...offer}
      if (type == 'widget'){
        offerLoans.widgetEnabled = (!!dniEnabled && !!dniEnabled.widgetEnabled) ? dniEnabled.widgetEnabled : false;
      }
      else{
        offerLoans.tinEnabled = (!!dniEnabled && !!dniEnabled.tinEnabled) ? dniEnabled.tinEnabled : false;
      }
      return offerLoans;
    })
    return ruofferLoans;
  }
  
  private async whitelistValidation(ruoOffer: TypeRuoffers[]): Promise<TypeRuoffers[]> {
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    const dniEnabled = await this.firebaseService.getUsersSefEfex(dniSearchFormat) as ISefEfexUser;

    const ruofferLoans = ruoOffer.map(offer => {
      const offerLoans = {...offer}
      if (offer.codeProduct === PRODUCT_EFEX) {
        offerLoans['loansV1'] = !!dniEnabled ? Boolean(offer.loansV1 && !!dniEnabled || !dniEnabled.efexEnabled) : true;
      } else if (offer.codeProduct === PRODUCT_SEF) {
        offerLoans['loansV1'] = !!dniEnabled ? Boolean(offer.loansV1 && !!dniEnabled || !dniEnabled.sefEnabled) : true;
      }
      return offerLoans;
    })
    return ruofferLoans;
  }

  public async loadOfferService(ruoOffer: TypeRuoffers[]) {
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    const hashToken = ''; // this.authService.currentToken.refreshToken;  TODO: hashear token con blake2
    this.firebaseService.addSefEfexServiceResponse(
      {
        token: hashToken,
        userId: dniSearchFormat,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...ruoOffer
      },
      'offeringResponses');
  }

  public startLoanRegister(loanSaveParams: ILoan): Promise<ILoanOutput> {
    const url = environment.BASE_BACKEND_WORKER + '/financialsoffers/loans/pre-registerV2';
    return this._http.post<any>(url, loanSaveParams).toPromise();
  }

  public async loadCustomerOffersService(ruoOffer: TypeRuoffers[]) {
    const userInfo = await this.storage.get(USER_INFO);
    const dniSearchFormat =  userInfo.documentType + '-' + userInfo.documentNumber;
    const sefOffer = ruoOffer.find(offer => offer.codeProduct === PRODUCT_SEF );
    const efexOffer = ruoOffer.find(offer => offer.codeProduct === PRODUCT_EFEX );
    this.firebaseService.addSefEfexServiceResponse(
      {
        userId: dniSearchFormat,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        sefAmount: sefOffer?sefOffer.maximumAmount : null,
        efexAmount: efexOffer?efexOffer.maximumAmount: null,
      },
      'customerOffersResponses');
  }

}