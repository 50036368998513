import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { AHORROPLUS_OPEN_MODAL_RESPONSE_YES_SECOND_QUESTION } from '@common/constants/tag/tag-ahorroplus.constants';
import {AHORRO_ROUTE, RIPLEY_AGENCIES_ROUTE} from '@common/constants/routes.constants';
import { ACCOUNT_PLUS_CODE, TYPE_ACCOUNT_SELECTED } from '@common/constants/open-account.constants';
import { OP_MORE_INFORMATION_MODAL } from '@common/constants/tag/tag-open-account.constants';

@Component({
  selector: 'app-alternative-open-account',
  templateUrl: './alternative-open-account.component.html',
  styleUrls: ['./alternative-open-account.component.scss'],
})
export class AlternativeOpenAccountComponent implements OnInit {

  constructor(
    private viewController: ModalController,
    private router: Router,
    private gtmService: GoogleTagManagerService
    ) { }

  ngOnInit() {
    const typeAccount = sessionStorage.getItem(TYPE_ACCOUNT_SELECTED);
    if (typeAccount === ACCOUNT_PLUS_CODE) {
      this.gtmService.pushTagV2(OP_MORE_INFORMATION_MODAL);
    }
  }

  public redirectToOffers() {
    this.viewController.dismiss();
    return this.router.navigate([AHORRO_ROUTE], { replaceUrl: true });
  }

  ionViewDidEnter() {
    this.gtmService.pushTagV2(AHORROPLUS_OPEN_MODAL_RESPONSE_YES_SECOND_QUESTION);
  }
  public goToAgencies() {
    this.viewController.dismiss();
    return this.router.navigate([RIPLEY_AGENCIES_ROUTE], { replaceUrl: true });
  }

  dismissModal() {
    this.viewController.dismiss();
  }

}
