import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENROLL_DOCUMENT_NUMBER, USER_INFO } from '@common/constants/auth.constants';
import { FOOTER_GA4_CONSTANTS, HOME_GA4_CONSTANTS } from '@common/constants/digital-auth.constants';
import { Platform } from '@ionic/angular';
import { AnalyticsUtilsService } from  '@services/utils-analytics/utils-analytics.service';
import { Storage } from '@ionic/storage';

// tslint:disable-next-line:ban-types
declare const gtag: Function;

interface TAG {
  path: string
  user_id: string
  channel: string
  embedded: string
  status: string
  time: number
  path_destino: string
  product: string
  action: string
  category: string
  sub_category: string
}

interface ITagConstant {
  PATH_DESTINO?: string
  EVENT: string
  PRODUCT: string
  ACTION: string
  CATEGORY: string
  SUB_CATEGORY?: string
}

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {
  public channel: string;
  public user_id: string | boolean;
  public timeEventGA4: string = 'loadingTimeEventGA4';

  constructor(
    private analyticsService: AnalyticsUtilsService,
    private router: Router,
    private platform: Platform,
    private storage: Storage,
  ) {
   
   }

  public async gtagPushEventWithId(eventType: string, params: object) {
    if ( !this.channel ) {
      this.channel = this.setChannel();
    }

    gtag('event', `${eventType}`, {
      path: !params['path'] ? this.router.url : params['path'],
      user_id: await this.setUserId(),
      channel: this.channel,
      embedded: '0',
      status: '0',
      time: Date.now(),
      ...params
    });
  }

  public async gtagPushEvent(eventType: string, params: object) {
    if ( !this.channel ) {
      this.channel = this.setChannel();
    }

    gtag('event', `${eventType}`, {
      path: this.router.url,
      channel: this.channel,
      embedded: 0,
      status: 0,
      time: Date.now(),
      ...params
    });
  }

  public async getUserDni() {
    return await this.setUserId();
  }

  public setChannel() {
    let channel: string;
    if (this.platform.is('desktop')) {
      channel = 'desktop';
    } else if ( this.platform.is('android') && this.platform.is('mobileweb')) {
      channel = 'androidweb';
    } else if (this.platform.is('cordova') && this.platform.is('android')) {
      channel = 'androidapp';
    } else if (this.platform.is('ios') && this.platform.is('mobileweb')) {
      channel = 'iosWeb';
    } else if (this.platform.is('cordova') && this.platform.is('ios')) {
      channel = 'iosapp';
    } else {
      channel = 'desktop';
    }
    return channel;
  }

  private async setUserId() {
    const { documentNumber = 0 } = (await this.storage.get(USER_INFO)) || {}; 
    const enrollDocumentNumber = await this.storage.get(ENROLL_DOCUMENT_NUMBER);
    const encrypt = this.analyticsService.encryptData(enrollDocumentNumber ? enrollDocumentNumber : documentNumber);
    return encrypt;
  }

  public menuTag(path: string, datalayer?: any): void {
    const getValues = Object.values(HOME_GA4_CONSTANTS);
    const HOME_GA4 = (getValues as ITagConstant[]).find( item  => (datalayer === null ? item.PRODUCT !== 'actualiza tus datos' && item.PATH_DESTINO === path : item.PATH_DESTINO === path));
    if (HOME_GA4) {
      const { EVENT, ...rest } = HOME_GA4;
      const setObject = Object.keys(rest).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = rest[key]
        return accumulator;
      } , {});
     this.gtagPushEventWithId(EVENT, setObject);
    }
  }

  public footerTag(path: string): void {
    const getValues = Object.values(FOOTER_GA4_CONSTANTS);
    const FOOTER_GA4 = (getValues as ITagConstant[]).find( item  => (item.PATH_DESTINO === ('/'+ path)));
    if (FOOTER_GA4) {
      const { EVENT, ...rest } = FOOTER_GA4;
      const setObject = Object.keys(rest).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = rest[key]
        return accumulator;
      } , {});
     this.gtagPushEventWithId(EVENT, setObject);
    }
  }
}
