import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() route: string;
  @Input() testMode: boolean;
  @Input() primaryButton: string;
  @Input() secondaryButton: string;
  @Input() secondaryRoute: string;
  constructor(
    private viewController: ModalController,
  ) {
    this.title = '';
    this.message = '';
    this.route = '';
  }

  ngOnInit() {
  }

  public dismissModal() {
    this.viewController.dismiss();
  }

  public async goToRoute() {
    this.dismissModal();
    return location.pathname = this.route;
  }

  public async goToSecondaryRoute() {
    this.dismissModal();
    return location.pathname = this.secondaryRoute;
  }
}
