import {
  RIPLEY_POINTS_CONSOLIDATED_MOCK,
  RIPLEY_POINTS_PENDING_MOCK,
  RIPLEY_POINTS_SEGMENT_DIRECTION_MOCK
} from '@common/mocks/reward.mocks';
import {
  IConsolidatedRewardPoints, IPendingPoints,
  IRewardPoints, IRipleySegmentDirection
} from '@common/interfaces/reward-points.interface';
import { HttpService } from '@services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IProductCategory, IVoucherCencosudRequest, IVoucherCencosudResponse  } from '@common/interfaces/redeem-ripley-points.interface';
import { NavigationCheckerService } from '@services/navigation/navigation-checker.service';
import { shareReplay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { UtilsService } from '@services/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class RewardPointsService {
  private _code: any;
  private _category: IProductCategory;
  private _mainCategory: string;
  private _categoryList: IProductCategory[];
  public currentPoints: IConsolidatedRewardPoints[];
  public pendingPoints: IRewardPoints[];
  
  public segmentDirection: Promise<IRipleySegmentDirection>;  
  public segmentDirectionIntentos: number = 0;
  public consolidatedPoints: Promise<IConsolidatedRewardPoints[]>;

  private updateMisCanjes = new Subject<void>();
  updateMisCanjes$ = this.updateMisCanjes.asObservable();
/*
  private updatePuntos = new Subject<void>();
  updatePuntos$ = this.updatePuntos.asObservable();
  
  private updatePuntoHeader = new Subject<void>();
  updatePuntoHeaders$ = this.updatePuntoHeader.asObservable(); 
*/
  actualizarMisCanjes() {
    this.updateMisCanjes.next();
  }
  public availableRipleyPoints :number;
  public mostrarPuntos: boolean = false;
/*  
  actualizarPuntos(){
    this.updatePuntos.next();
  }
  actualizarPuntosHeader(){
    this.updatePuntoHeader.next();
  }
*/
  constructor(
    private http: HttpService,
    private navCheckerSrv: NavigationCheckerService,
    private utilsService: UtilsService
  ) {
    this.currentPoints = [];
    this.pendingPoints = [];
  }

  public getConsolidatedPointsRequest(): Promise<IConsolidatedRewardPoints[]> {
    if(this.navCheckerSrv.InHomeFromLogin() && this.consolidatedPoints) {
      return this.consolidatedPoints;
    }
    const url = environment.BASE_BACKEND_WORKER + '/hbk/pointsgo/ripley/consolidated';      
    const request = this.http.get(url, RIPLEY_POINTS_CONSOLIDATED_MOCK).pipe(shareReplay(1)).toPromise();
    
    if(this.navCheckerSrv.InLogin()){
      this.consolidatedPoints = request;
      return this.consolidatedPoints;
    } else {
      return request;
    }    
  }

  public async getConsolidatedPoints(): Promise<IConsolidatedRewardPoints[]> {        
    if (this.currentPoints.length === 0) {      
      const consolidatedPoints: IConsolidatedRewardPoints[] = await this.getConsolidatedPointsRequest();
      this.currentPoints = consolidatedPoints;
    }
    return this.currentPoints;
  }

  public async getPendingPoints(): Promise<IRewardPoints[]> {
    if (this.pendingPoints.length === 0) {
      const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/rewardpointsconsolidate';
      const pendingPointsResponse: IPendingPoints = await this.http.post(url, {}, RIPLEY_POINTS_PENDING_MOCK).toPromise();
      this.pendingPoints = pendingPointsResponse.RewardPointsList.RewardPoints;
    }
    return this.pendingPoints;
  }

  public async getSegmentDirection(): Promise<IRipleySegmentDirection> {  
    if(this.navCheckerSrv.InLogin()){
      this.segmentDirection = undefined;
      this.segmentDirectionIntentos = 0;
      localStorage.setItem("segmentDirectionStorage", undefined);
    }
    // console.log(this.segmentDirection);
    if(this.segmentDirection){
      console.log("SegmentDirection Memoria");
      return this.segmentDirection;
    } else {
      var segmentStorage = localStorage.getItem("segmentDirectionStorage");
      // console.log("segmentStorage: " + segmentStorage);
      if(segmentStorage != 'undefined' && segmentStorage != 'null') {
        console.log("SegmentDirection localStorage: ", segmentStorage);
        this.segmentDirection = JSON.parse(segmentStorage);
        return this.segmentDirection;
      }
      else {
        if (this.segmentDirectionIntentos < 2){
          this.segmentDirectionIntentos++;
          console.log("segmentDirectionIntentos: " + this.segmentDirectionIntentos);
          // console.log("getSegmentDirection CP: " + pageModule);
          const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/segmentdirection';
          const request = await this.http.post(url, {}, RIPLEY_POINTS_SEGMENT_DIRECTION_MOCK).pipe(shareReplay(1)).toPromise();
          this.segmentDirection = request;
          localStorage.setItem("segmentDirectionStorage", JSON.stringify(this.segmentDirection));
          console.log(this.segmentDirection);
          return this.segmentDirection;
        }        
        else {
          console.log("SegmentDirection Reintento Excedido");
          return this.segmentDirection
        }
      }
    }        
  }

  public async getVoucherCencosudPdf(params: IVoucherCencosudRequest): Promise<IVoucherCencosudResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/benefits/customer/getVoucherCencosudPdf';
    let headers = {} as HttpHeaders;
    headers = new HttpHeaders().set('Channel', this.utilsService.getChannelCode());
    return await this.http.post(url, params, RIPLEY_POINTS_PENDING_MOCK, headers).toPromise();
  }

  get code(): any {
    return this._code;
  }

  set code(code) {
    this._code = code;
  }

  get category(): IProductCategory {
    return this._category;
  }

  set category(category: IProductCategory) {
    this._category = category;
  }

  get mainCategory(): string {
    return this._mainCategory;
  }

  set mainCategory(mainCategory: string) {
    this._mainCategory = mainCategory;
  }

  get categoryList(): IProductCategory[] {
    return this._categoryList;
  }

  set categoryList(categoryList: IProductCategory[]) {
    this._categoryList = categoryList;
  }

  public resetVariables(full: boolean = true): void {
    this.currentPoints = [];
    this.pendingPoints = [];
    this.segmentDirection = undefined;
    localStorage.setItem("segmentDirectionStorage", undefined);
    this._code = null;
    this._category = null;
    if (full) {
      this._mainCategory = null;
    }
  }

}


