import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { IUserHashInformation } from '@common/interfaces/user.interface';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService } from '@services/alert/alert.service';
import { ERROR_INESPERADO } from '@common/constants/error.constants';
import { EMAIL_REGEX, EMAIL_REGEXB } from '@common/constants/misc.constants';
import { IHashInformation } from '@common/interfaces/general.interface';
import { IPromotionals } from '@common/interfaces/efex.interface';
import { Subscription } from 'rxjs';
import { EfexService } from '@services/efex/efex.service';
import { ModalController } from '@ionic/angular';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { EFEX_FAILED_TC_MODAL, EFEX_FAILED_AC_MODAL } from '@common/constants/modal-props.constants';
import { EFEX_S3_AUTHORIZE_ACCOUNT_OPENING, EFEX_S3_PRIVACY_POLICY } from '@common/constants/tag/tag-efex.constants';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';

@Component({
  selector: 'app-authorization-efex',
  templateUrl: './authorization-efex.component.html',
  styleUrls: ['./authorization-efex.component.scss'],
})
export class AuthorizationEfexComponent implements OnInit, OnDestroy {

  @Input() hashCodePromSelected: IPromotionals;
  @Input() info: IPromotionals;
  hashInformation: IHashInformation;
  data: IUserHashInformation;
  AuthorizationForm: FormGroup;
  termsAndConditionsLink: string;
  privacyPoliciesLink: string;
  url: string;
  emailPattern: RegExp;
  loading: boolean;
  isTerm: boolean;
  isTermAuth: boolean;
  emailActive: boolean;
  isError: boolean;
  subscription: Subscription = new Subscription();
  @Output() emailValue: EventEmitter<string> = new EventEmitter();
  @Output() isTermAccepted: EventEmitter<boolean> = new EventEmitter();
  @Output() isAuthConditions: EventEmitter<boolean> = new EventEmitter();
  @Output() userInformation: EventEmitter<IUserHashInformation> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private efexservice: EfexService,
    private modalCtrl: ModalController,
    private gtmService: GoogleTagManagerService,
  ) { }

  ngOnInit() {
    this.info = {} as IPromotionals;
    this.loading = false;
    this.emailActive = false;
    this.emailPattern =  EMAIL_REGEX;
    this.setupFormPage();
  }

  public async getUserInformation(hashCodeInfo: string) {
    const hash: IHashInformation = {
      hashCode: hashCodeInfo
    };
    try {
      this.loading = true;
      this.data = await this.userService.getDataProfile(hash);
      this.AuthorizationForm.controls.email.setValue(this.data.email);
      this.userInformation.emit(this.data);
      await this.getUrls();
      this.loading = false;
    } catch (err) {
      const alertMessage = await this.alertService.openErrorAlert(this.url, false, null, ERROR_INESPERADO);
      if (alertMessage) { alertMessage.present(); }
    }
  }

  async getUrls() {
    try {
      const urlTC = await this.efexservice.getExtensionTermsAndConditions();
      this.termsAndConditionsLink = urlTC.value;
      const urlAC = await this.efexservice.getExtensionAccountAuthorizationConditions();
      this.privacyPoliciesLink = urlAC.value;
      this.isError = false;
    } catch (error) {
      this.isError = true;
    }
  }

  public setupFormPage() {
    this.AuthorizationForm = this.formBuilder.group({
      email: new FormControl(''),
      termsAndConditions: new FormControl(false, Validators.compose([Validators.required, Validators.requiredTrue])),
      authConditions: new FormControl(false, Validators.compose([Validators.required, Validators.requiredTrue]))
    });
    this.subscription.add(this.email.valueChanges.subscribe(() => {
      if (this.email.valid) {
        this.emailValue.emit(this.email.value);
      } else {
        this.emailValue.emit(this.email.valid.toString());
      }
    }));

    this.subscription.add(this.termsAndConditions.valueChanges.subscribe(() => {
      this.isTerm =  this.termsAndConditions.value;
      this.isTermAccepted.emit(this.isTerm);
  }));

    this.subscription.add(this.termAuthConditions.valueChanges.subscribe(() => {
        this.isTermAuth =  this.termAuthConditions.value;
        this.isAuthConditions.emit(this.isTermAuth);
    }));
  }

  get email() { return this.AuthorizationForm.controls.email; }
  get termsAndConditions() { return this.AuthorizationForm.controls.termsAndConditions; }
  get termAuthConditions() { return this.AuthorizationForm.controls.authConditions; }

  sendTagTermsAndConditions() {
    if (this.isTerm) {
      this.gtmService.pushTagV2(EFEX_S3_PRIVACY_POLICY);
    }
  }

  sendTagAuthorize() {
    if (this.isTermAuth) {
      this.gtmService.pushTagV2(EFEX_S3_AUTHORIZE_ACCOUNT_OPENING);
    }
  }

  async setTermsAndConditions() {
    if (!this.isError && this.termsAndConditionsLink) {
      window.open(this.termsAndConditionsLink, '_blank');
    } else {
      await this.openErrorAlertTC();
      await this.getUrls();
    }
  }

  async goSimpleAccount() {
    if (!this.isError && this.privacyPoliciesLink) {
      window.open(this.privacyPoliciesLink, '_blank');
    } else {
      await this.openErrorAlertAC();
      await this.getUrls();
    }
  }

  public editemail() {
    this.emailActive = true;
    this.emailPattern =  EMAIL_REGEXB;
    this.AuthorizationForm.controls.email.setValue('');
  }

  async openErrorAlertTC() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_FAILED_TC_MODAL,
    });
    await modal.present();
  }

  async openErrorAlertAC() {
    const modal = await this.modalCtrl.create({
      component: GenericModalComponent,
      cssClass: 'form-modal',
      componentProps: EFEX_FAILED_AC_MODAL,
    });
    await modal.present();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
