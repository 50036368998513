import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LOTTIE_CONFIG } from '@common/constants/lottie-spinner.constants';
import {
  APP_ID, BIOMETRIC_FACTOR, COUNTRY, DEFAULT_WIDGET_CONFIG_DATA, DEVICE_ACTIVE, DEVICE_INACTIVE, DOCUMENT_TYPES
  , ENROLLMENT_KEY, FACE_ID_FACTOR, FACE_ID_TITLE, FACTOR_KEYS, FINGERPRINT_TITLE, FINGER_FACTOR
  , FLIP_DNI_ICON, INSTRUCTIONS_DNI_IMG, LOADING_PROPS, NOT_ENROLLED_FACTOR,
  PIN_FACTOR, PIN_TITLE, PREPARE_ENROLLMENT_STEPS, RPASS_FACTORS, RPASS_GA4_CONSTANTS, IDNOWSTEPS, IDNOW, DNI_TYPES, DNI_TYPES_FLOW, RPASS_INITIATIVES
} from '@common/constants/digital-auth.constants';
import { IRPassFactor } from '@common/interfaces/rpass.interface';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { ModalController } from '@ionic/angular';
import { BiometricService } from '@services/biometric/biometric.service';
import { CustomAlertsService } from '@services/custom-alerts/custom-alerts.service';
import { DeviceService } from '@services/device/device.service';
import { LoginService } from '@services/login/login.service';
import { PermissionDiagnosticService } from '@services/permission-diagnostics/permission-diagnostic.service';
import { RpassErrorHandlerService } from '@services/rpass-error-handler/rpass-error-handler.service';
import { UtilsService } from '@services/utils/utils';
import { WidgetService } from '@services/widget/widget.service';
import { IBiometriaAccount } from '@common/interfaces/account.interface';
import { DigitalAuthService } from '@services/digital-auth/digital-auth.service';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { DNI_SEGMENT } from '@common/constants/document-type.constants';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { FirebaseService } from '@services/firebase/firebase.service';
import { IdnowService } from '@services/idnow/idnow.service';

@Component({
  selector: 'app-digital-auth',
  templateUrl: './digital-auth.component.html',
  styleUrls: ['./digital-auth.component.scss'],
})
export class DigitalAuthComponent implements OnInit {
  @Input() enrollmentTypeFlows: any;
  @Input() isSessionLess: boolean = false;
  @Input() rut: string;
  @Input() public progressBarValue: number;
  @Output() public progressBarValueChange = new EventEmitter<any>();
  @Output() public widgetResult = new EventEmitter<{ data: any }>();
  public IdNowSteps: any = IDNOWSTEPS;
  public lottieConfig: object = LOTTIE_CONFIG;
  public lottieHeight: number = LOTTIE_CONFIG.sizes.small.height;
  public lottieWidth: number = LOTTIE_CONFIG.sizes.small.width;

  public preSteps: any = PREPARE_ENROLLMENT_STEPS;
  public step: string;
  public previousStep: any;
  public terms: any = { isChecked: false };
  public policy: any = { isChecked: false };
  public currentPrepareEnrollmentStep: number;
  public enrollmentStarted: boolean = false;
  public enrollmentClient: boolean = false;
  public documentTypes: any = DOCUMENT_TYPES;
  public documentInstructionsImgs = INSTRUCTIONS_DNI_IMG;
  public loading: boolean = false;
  public loadingProps: any = LOADING_PROPS.default;
  public documentType: string;
  public uuidDevice: string;
  public clientIp: string;
  public channelOs: string;
  public appId: string;
  public enrollmentType: string;
  public pathIdDNI: string;
  public pathIdLate: string;
  public promptAsked: boolean = false;
  public widgetSuccess: boolean = false;
  public isActivation: boolean;
  public pin: string[] = [];
  public repeatPin: string[] = [];
  public isInputValid: boolean;
  public pinStatus: boolean = false;
  public isPinFactorSelected: boolean = false;
  public rpassFactors: IRPassFactor[] = RPASS_FACTORS;
  public selectedFactor: IRPassFactor;
  public stateFactor: boolean = false;
  public factorNameKeySelected: string;
  public isFactorNotEnrolled: boolean;
  public rpassStatus = false;
  public promptAskedInit = false;
  public promptAskedOCR = false;
  public stepId: string;
  public progressBar: number = 0.2;
  public isGA4Active: boolean = false;
  public GA4Executed: boolean = false;
  public addGA4: boolean = false;
  public ga4EventAdd: boolean = false;

  private stepsSize: number;
  constructor(
    private modalController: ModalController,
    private widgetService: WidgetService,
    private utilsService: UtilsService,
    private router: Router,
    private loginService: LoginService,
    private deviceService: DeviceService,
    private permissionDiagnosticService: PermissionDiagnosticService,
    private customAlertsService: CustomAlertsService,
    private rpassErrorHandlerService: RpassErrorHandlerService,
    private biometricService: BiometricService,
    private digitalAuthService: DigitalAuthService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationsService: NotificationsService,
    private firebaseService: FirebaseService,
    private idnowService: IdnowService,
  ) { }

  async ngOnInit() {
    this.addGA4RPass();
    this.enrollmentStarted = true;
    this.currentPrepareEnrollmentStep = this.preSteps.dniSelecction;
    this.channelOs = this.utilsService.getDeviceOS().toUpperCase();
  }

  async ngAfterViewInit() {
    this.clientIp = (await this.deviceService.getIP()).ip;
    await this.startEnrollment();
  }

  public addGA4RPass() {
    if (this.enrollmentTypeFlows.CP === 'RIPLEYPIN_' || this.enrollmentTypeFlows.CPA === 'RIPLEYPIN2') {
      this.addGA4 = true;
    }
  }

  public ngAfterViewChecked() {
    if (this.step === this.IdNowSteps.PREPARE_RPASS) {
      if (this.addGA4 && !this.GA4Executed && !this.isPinFactorSelected) {
        const eventGA4 = {
          product: RPASS_GA4_CONSTANTS.SELECT_METHODS.PRODUCT,
          action: RPASS_GA4_CONSTANTS.SELECT_METHODS.ACTION,
          step: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP,
          step_part: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP_PART,
          category: RPASS_GA4_CONSTANTS.SELECT_METHODS.CATEGORY,
        };
        this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_METHODS.EVENT, eventGA4);
        this.GA4Executed = true;
        this.isGA4Active = false;
      } else if (this.addGA4 && !this.isGA4Active && this.isPinFactorSelected) {
        const eventGA4 = {
          product: RPASS_GA4_CONSTANTS.CREATE_PIN.PRODUCT,
          action: RPASS_GA4_CONSTANTS.CREATE_PIN.ACTION,
          step: RPASS_GA4_CONSTANTS.CREATE_PIN.STEP,
          step_part: RPASS_GA4_CONSTANTS.CREATE_PIN.STEP_PART,
          category: RPASS_GA4_CONSTANTS.CREATE_PIN.CATEGORY,
        };
        this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.CREATE_PIN.EVENT, eventGA4);
        this.isGA4Active = true;
      }
    }
  }

  public ngOnDestroy() {
    this.widgetService.finalAnalizeDNIFront = null;
    this.widgetService.finalAnalizeDNIBack = null;
    this.widgetService.retrieveZoom = null;
  }

  public async startEnrollment() {
    this.enrollmentClient = true;
    this.step = IDNOWSTEPS.DEVICE_STATUS;
    this.loadingProps = LOADING_PROPS.initEnrollment;
    this.loading = true;
    this.widgetService.rut = this.rut;
    this.widgetService.access_token = this.widgetService.jwToken;
    this.widgetService.country = 'RIPLEY PE';
    await this.updateOAuthToken();
    await this.getDeviceStatus();
  }

  public async methodValidity(response: { data }) {
    this.previousStep = response;
    if (!response) { return await this.loginService.logoutWithoutRedirection(); }
    let nextStep = response.data.evento;
    if (nextStep === IDNOWSTEPS.NOT_ENROLLMENT) {
      nextStep = IDNOWSTEPS.ENROLLMENT_BY_CONTEXT;
    }
    if (nextStep === IDNOWSTEPS.INITENROLLMENT && !this.promptAskedInit) {
      let enrollmentKey = this.findKey(response.data.contextData, IDNOW.FLOW_CODE);
      let isExist = false;
      if (this.enrollmentTypeFlows[DNI_TYPES.CP] == enrollmentKey || this.enrollmentTypeFlows[DNI_TYPES.CP] == DNI_TYPES_FLOW.CP) {
        isExist = true;
      } else {
        if (this.enrollmentTypeFlows[DNI_TYPES.CPA] == enrollmentKey || this.enrollmentTypeFlows[DNI_TYPES.CPA] == DNI_TYPES_FLOW.CPA) {
          isExist = true;
        }
      }
      if (isExist) {
        nextStep = IDNOWSTEPS.PREPARE_INITENROLLMENT;
      }
    }
    if ((nextStep === IDNOWSTEPS.RPASS) && !this.rpassStatus) {
      nextStep = IDNOWSTEPS.PREPARE_RPASS;
    }
    if ((nextStep === IDNOWSTEPS.BIOMETRIA || nextStep === IDNOWSTEPS.FACE_AUTH) && !this.promptAsked) {
      nextStep = IDNOWSTEPS.PREPARE_BIOMETRIA;
      const permit = await this.permissionDiagnosticService.askForCameraPermission();
      if (!permit) {
        this.promptAsked = false;
        nextStep = IDNOWSTEPS.UNHANDLED_ERROR;
      }
    }
    if ((nextStep === IDNOWSTEPS.OCR_DNI) && !this.promptAskedOCR) {
      const permit = await this.permissionDiagnosticService.askForCameraPermission();
      nextStep = IDNOWSTEPS.PREPARE_OCR_DNI;
      if (!permit) {
        this.promptAskedOCR = false;
        nextStep = IDNOWSTEPS.UNHANDLED_ERROR;
      }
    }

    switch (nextStep) {
      case IDNOWSTEPS.PREPARE_INITENROLLMENT:
        const eventGA4_init = {
          product: RPASS_GA4_CONSTANTS.SELECT_DNI.PRODUCT,
          action: RPASS_GA4_CONSTANTS.SELECT_DNI.ACTION,
          step: RPASS_GA4_CONSTANTS.SELECT_DNI.STEP,
          step_part: RPASS_GA4_CONSTANTS.SELECT_DNI.STEP_PART,
          category: RPASS_GA4_CONSTANTS.SELECT_DNI.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_DNI.EVENT, eventGA4_init);
        this.stepsSize = response.data.stepsSize ? response.data.stepsSize : 5;
        this.setEnrollment(response.data.contextData, IDNOW.FLOW_CODE);
        this.loading = false;
        break;
      case IDNOWSTEPS.INITENROLLMENT:
        await this.blockCurrentActiveRPass();
        this.setEnrollment(response.data.contextData, IDNOW.FLOW_CODE);
        await this.initEnrollmentRequest();
        await this.saveSecureDataActive(DEVICE_INACTIVE);
        break;
      case IDNOWSTEPS.ENROLLMENT_BY_CONTEXT:
        this.appId = this.findKey(response.data.contextData, IDNOW.APP_ID);
        this.widgetService.appId = this.appId ? this.appId : this.widgetService.appId;
        break;
      case IDNOWSTEPS.REGISTERPATH:
        await this.processInitEnrollment(response.data);
        break;
      case IDNOWSTEPS.PREPARE_OCR_DNI:
        let ga4EventAdded = false;
        if (!ga4EventAdded) {
          const eventGA4_dni = {
            product: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.PRODUCT,
            action: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.ACTION,
            step: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.STEP,
            step_part: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.STEP_PART,
            category: RPASS_GA4_CONSTANTS.SELECT_DNI.CATEGORY,
          };
          this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.EVENT, eventGA4_dni);
          ga4EventAdded = true;
        }
        this.stepsSize = response.data.stepsSize ? response.data.stepsSize : 5;
        this.setProgressBar(+response.data.sequenceNumber);
        this.stepId = response.data.stepId ? response.data.stepId : this.stepId;
        this.getStepFlow(response.data);
        this.loading = false;
        break;
      case IDNOWSTEPS.OCR_DNI:
        this.promptAskedOCR = true;
        if (!this.widgetService.finalAnalizeDNIFront) {
          try {
            await this.frontDniData();
            const eventGA4 = {
              product: RPASS_GA4_CONSTANTS.FRONT_DNI.PRODUCT,
              action: RPASS_GA4_CONSTANTS.FRONT_DNI.ACTION,
              step: RPASS_GA4_CONSTANTS.FRONT_DNI.STEP,
              step_part: RPASS_GA4_CONSTANTS.FRONT_DNI.STEP_PART,
              category: RPASS_GA4_CONSTANTS.FRONT_DNI.CATEGORY,
            };
            this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FRONT_DNI.EVENT, eventGA4);
          }
          catch (err) { break; }
        }
        if (this.widgetService.finalAnalizeDNIFront && !this.widgetService.finalAnalizeDNIBack) {
          try {
            await this.backDniData();
            const eventGA4 = {
              product: RPASS_GA4_CONSTANTS.BACK_DNI.PRODUCT,
              action: RPASS_GA4_CONSTANTS.BACK_DNI.ACTION,
              step: RPASS_GA4_CONSTANTS.BACK_DNI.STEP,
              step_part: RPASS_GA4_CONSTANTS.BACK_DNI.STEP_PART,
              category: RPASS_GA4_CONSTANTS.BACK_DNI.CATEGORY,
            };
            this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.BACK_DNI.EVENT, eventGA4);
          }
          catch (err) { break; }
        }
        break;
      case IDNOWSTEPS.PREPARE_BIOMETRIA:
        this.stepsSize = this.stepsSize ? this.stepsSize : response.data.stepsSize ? response.data.stepsSize : 4;
        this.setProgressBar(+response.data.sequenceNumber);
        this.stepId = response.data.stepId ? response.data.stepId : this.stepId;
        this.getStepFlow(response.data);
        this.promptAsked = false;
        this.loading = false;
        if (!this.ga4EventAdd) {
          const eventGA4 = {
            product: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.PRODUCT,
            action: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.ACTION,
            step: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.STEP,
            step_part: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.STEP_PART,
            category: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.CATEGORY,
          };
          this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.EVENT, eventGA4);
          this.ga4EventAdd = true;
        }
        break;
      case IDNOWSTEPS.FACE_AUTH:
      case IDNOWSTEPS.BIOMETRIA:
        const eventGA4_BIOMETRIA = {
          product: RPASS_GA4_CONSTANTS.MATCH_FACE.PRODUCT,
          action: RPASS_GA4_CONSTANTS.MATCH_FACE.ACTION,
          step: RPASS_GA4_CONSTANTS.MATCH_FACE.STEP,
          step_part: RPASS_GA4_CONSTANTS.MATCH_FACE.STEP_PART,
          category: RPASS_GA4_CONSTANTS.MATCH_FACE.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.MATCH_FACE.EVENT, eventGA4_BIOMETRIA);
        this.setProgressBar(+response.data.sequenceNumber);
        try {
          await this.retrieveZoom();
          const eventGA4_finalMatchFace = {
            product: RPASS_GA4_CONSTANTS.FINAL_MATCH_FACE.PRODUCT,
            action: RPASS_GA4_CONSTANTS.FINAL_MATCH_FACE.ACTION,
            step: RPASS_GA4_CONSTANTS.FINAL_MATCH_FACE.STEP,
            category: RPASS_GA4_CONSTANTS.FINAL_MATCH_FACE.CATEGORY,
          };
          this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINAL_MATCH_FACE.EVENT, eventGA4_finalMatchFace);
        }
        catch (err) { break; }
        break;
      case IDNOWSTEPS.PREPARE_RPASS:
        this.setProgressBar(+response.data.sequenceNumber);
        this.getStepFlow(response.data);
        this.isActivation = true;
        this.resetFactors();
        await this.enableAvailableFactors();
        this.loading = false;
        this.rpassStatus = false;
        break;
      case IDNOWSTEPS.RPASS:
        this.loading = true;
        break;
      case IDNOWSTEPS.FINISH_ENROLLEMENT:
        this.setProgressBar(+response.data.sequenceNumber);
        this.getStepFlow(response.data);
        this.loadingProps = LOADING_PROPS.mobileFinishEnrollment;
        this.loading = true;
        let contextData = [{
          nombreKey: FACTOR_KEYS.factor,
          valorKey: this.factorNameKeySelected
        }];
        response.data.contextData = contextData;
        await this.saveSecureData(response.data);
        if (this.isPinFactorSelected) {
          response.data.contextData[0].nombreKey = FACTOR_KEYS.pin;
          response.data.contextData[0].valorKey = this.pin.join("-");
          await this.saveSecureData(response.data);
        }
        await this.finishEnrollmentRequest();
        break;
      case IDNOWSTEPS.PROCESS_FINISHENROLLMENT: // Finish Enrolamiento
        await this.processFinishEnrollment(response.data);
        this.getStepFlow(response.data);
        if (this.isActivation) {
          await this.saveSecureDataActive(DEVICE_ACTIVE);
          const finalFactorEnrolled = { nombreKey: FACTOR_KEYS.factor, valorKey: this.factorNameKeySelected }
          response.data.contextData.push(finalFactorEnrolled);
          this.logFinalScreen();
          const currentTokenRpassData = {
            uuidDevice: this.uuidDevice,
            userAgent: window.navigator.userAgent.toLowerCase()
          };
          this.notificationsService.requestPermission(DNI_SEGMENT.value, this.widgetService.rut, currentTokenRpassData)
        }
        this.widgetSuccess = true;
        this.step = '';
        this.loading = false;
        this.widgetResult.emit(response.data)
        break;
      case IDNOWSTEPS.INVALID_REQUEST:
        await this.loginService.logoutWithoutRedirection();
        this.loading = false;
        await this.rpassErrorHandlerService.handleEvent(response);
        if (this.rpassErrorHandlerService.repeatEvent === 'DNI') {
          this.widgetService.finalAnalizeDNIFront = null;
          this.widgetService.finalAnalizeDNIBack = null;
          this.methodValidity({ data: { evento: IDNOWSTEPS.OCR_DNI } });
        } else if (this.rpassErrorHandlerService.repeatEvent === 'SELFIE') {
          this.widgetService.retrieveZoom = null;
          this.methodValidity({ data: { evento: IDNOWSTEPS.BIOMETRIA } });
        }
        break;
      case IDNOWSTEPS.UNHANDLED_ERROR:
        await this.loginService.logoutWithoutRedirection();
        this.loading = false;
        await this.rpassErrorHandlerService.handleEvent(response);
        break;
      case IDNOWSTEPS.FINISH_ENROLLMENT_CANCEL:
        this.router.navigateByUrl('/login', { replaceUrl: true });
        break;
      case IDNOWSTEPS.NOT_ENROLLMENT:
        await this.loginService.logoutWithoutRedirection();
        await this.rpassErrorHandlerService.handleEvent(response);
        this.widgetSuccess = false;
        this.step = '';
        this.enrollmentClient = false;
        this.loading = false;
        break;
      default:
        this.widgetSuccess = false;
        this.step = '';
        this.loading = false;
        await this.showModalError();
        break;
    }
    this.step = nextStep;
  }

  public async blockCurrentActiveRPass(): Promise<void> {
    const unformatDocumentNumber = this.widgetService.rut;
    try {
      await this.clearEnrollmentIdNow();
      const darDeBajaFeatureFlag = !!(await this.firebaseService.getInitiativeRpass(RPASS_INITIATIVES.DAR_DE_BAJA_RPASS)).enabled;
      if (!darDeBajaFeatureFlag) return;
      const currentTokenRpass = await this.firebaseService.getCurrentTokenRpass({ dType: DNI_SEGMENT.value, dNumber: unformatDocumentNumber });
      if (!currentTokenRpass) return;
      await this.idnowService.blockDevice(unformatDocumentNumber, currentTokenRpass.uuidDevice);
      await this.firebaseService.deleteCurrentTokenRpass({ dType: DNI_SEGMENT.value, dNumber: unformatDocumentNumber });
    } catch (err) {
      this.showModalError();
    }
  }

  public completeFlow() {
    this.widgetSuccess = true;
    this.enrollmentClient = true;
    this.loading = false;
  }

  public getStepFlow(response: any) {
    this.widgetService.stepId = response.stepId ? response.stepId : this.widgetService.stepId;
    this.widgetService.definitionWorkflow =
      response.definitionWorkflow ? response.definitionWorkflow : this.widgetService.definitionWorkflow;
    this.widgetService.sequenceNumber =
      response.sequenceNumber ? response.sequenceNumber : this.widgetService.sequenceNumber;
  }

  public setEnrollment(contextData: any, enrollment: any) {
    let enrollmentKey = this.findKey(contextData, enrollment);
    this.enrollmentType = enrollmentKey ? enrollmentKey : this.enrollmentTypeFlows[this.documentType];
    this.widgetService.enrollmentType = this.enrollmentType;
  }

  public logFinalScreen() {
    switch (FACTOR_KEYS[this.selectedFactor.type]) {
      case 'valorPIN':
        const eventGA4_logFinalScreen = {
          product: RPASS_GA4_CONSTANTS.FINAL_SCREEN.PRODUCT,
          action: RPASS_GA4_CONSTANTS.FINAL_SCREEN.ACTION,
          step: RPASS_GA4_CONSTANTS.FINAL_SCREEN.STEP,
          step_part: RPASS_GA4_CONSTANTS.FINAL_SCREEN.STEP_PART,
          category: RPASS_GA4_CONSTANTS.FINAL_SCREEN.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINAL_SCREEN.EVENT, eventGA4_logFinalScreen);
        break;
      case 'valorFINGER':
      case 'valorBIOMETRIC':
        const eventGA4_finger = {
          product: RPASS_GA4_CONSTANTS.FINAL_SCREEN.PRODUCT,
          action: RPASS_GA4_CONSTANTS.FINAL_SCREEN.ACTION,
          step: RPASS_GA4_CONSTANTS.FINAL_SCREEN.STEP,
          step_part: RPASS_GA4_CONSTANTS.FINAL_SCREEN_TOUCHID.STEP_PART,
          category: RPASS_GA4_CONSTANTS.FINAL_SCREEN_TOUCHID.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINAL_SCREEN.EVENT, eventGA4_finger);
        break;
      case 'valorFACE':
        const eventGA4_faceId = {
          product: RPASS_GA4_CONSTANTS.FINAL_SCREEN.PRODUCT,
          action: RPASS_GA4_CONSTANTS.FINAL_SCREEN.ACTION,
          step: RPASS_GA4_CONSTANTS.FINAL_SCREEN.STEP,
          step_part: RPASS_GA4_CONSTANTS.FINAL_SCREEN_FACEID.STEP_PART,
          category: RPASS_GA4_CONSTANTS.FINAL_SCREEN_FACEID.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINAL_SCREEN.EVENT, eventGA4_faceId);
        break;
      default:
        break;
    }
  }

  public selectMethod() {
    switch (FACTOR_KEYS[this.selectedFactor.type]) {
      case 'valorFINGER':
      case 'valorBIOMETRIC':
        const eventGA4_finger = {
          product: 'huella digital',
          action: RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.ACTION,
          step: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP,
          step_part: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP_PART,
          category: RPASS_GA4_CONSTANTS.SELECT_METHODS.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.EVENT, eventGA4_finger);
        break;
      case 'valorFACE':
        const eventGA4 = {
          product: 'face id',
          action: RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.ACTION,
          step: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP,
          step_part: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP_PART,
          category: RPASS_GA4_CONSTANTS.SELECT_METHODS.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.EVENT, eventGA4);
        break;
      default:
        break;
    }
  }

  public goBack(): void {
    if (!this.isPinFactorSelected) {
      const buttons = [
        {
          cssClass: 'button-accept-for-rpass', text: 'Continuar',
          handler: async () => await this.methodValidity(this.previousStep)
        },
        {
          cssClass: 'button-quit-for-rpass', text: 'Salir',
          handler: () => this.loginService.logoutWithoutRedirection().then(() => this.router.navigateByUrl("/login", { replaceUrl: true })
          ),
        }
      ];
      const template = {
        icon: false, title: '¿Quieres salir?',
        message: 'Si lo haces no podremos guardar tus datos y tendrás que volver a completarlos para crear tu R Pass.'
      };
      this.customAlertsService.promptForCustomAction(template, 'quit-br-pass', buttons);
    } else {
      this.resetFactors();
    }
  }

  public async enableAvailableFactors() {
    const type = await this.biometricService.availableHardwareRPass();
    this.rpassFactors.forEach((factor) => {
      switch (factor.title) {
        case FACE_ID_TITLE:
          if (this.channelOs === 'IOS') {
            factor.isSupports = true;
            if (type === FACE_ID_FACTOR) {
              factor.isAvailable = true;
              factor.type = type;
              this.isFactorNotEnrolled = false;
            } else if (type === NOT_ENROLLED_FACTOR) {
              this.isFactorNotEnrolled = true;
            }
          }
          break;
        case FINGERPRINT_TITLE:
          if (this.channelOs === 'IOS' || this.channelOs === 'ANDROID') {
            factor.isSupports = true;
            if (type === FINGER_FACTOR || type === BIOMETRIC_FACTOR) {
              factor.isAvailable = true;
              factor.type = type;
              this.isFactorNotEnrolled = false;
            } else if (type === NOT_ENROLLED_FACTOR) {
              this.isFactorNotEnrolled = true;
            }
          }
          break;
        case PIN_TITLE:
          factor.isSupports = true;
          factor.isAvailable = true;
          factor.type = PIN_FACTOR;
          break;
      }
    });
  }

  public async selectFactor(index: number) {
    this.selectedFactor = this.rpassFactors[index];
    let lastIndexSelected = -1;
    for (let i = 0; i < this.rpassFactors.length; i++) {
      if (this.rpassFactors[i].isSelected) {
        lastIndexSelected = i;
      }
      this.rpassFactors[i].isSelected = false;
    }
    this.stateFactor = false;
    this.selectedFactor.isSelected = true;
    if (this.selectedFactor.type === PIN_FACTOR) {
      this.factorNameKeySelected = FACTOR_KEYS[this.selectedFactor.type];
      this.isPinFactorSelected = true;
      const eventGA4 = {
        product: 'clave r pass',
        action: RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.ACTION,
        step: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP,
        step_part: RPASS_GA4_CONSTANTS.SELECT_METHODS.STEP_PART,
        category: RPASS_GA4_CONSTANTS.SELECT_METHODS.CATEGORY,
      };
      this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_METHODS_BTN.EVENT, eventGA4);
    } else {
      this.selectMethod();
      await this.showBiometricModal(this.selectedFactor);
    }
    if (lastIndexSelected != -1 && !this.selectedFactor.isSelected) {
      this.selectedFactor = this.rpassFactors[lastIndexSelected];
      this.selectedFactor.isSelected = true;
      this.stateFactor = true;
    }
  }

  public async showBiometricModal(selectedFactor: any) {
    const biometricText = this.createBiometricText(selectedFactor.type);
    const modal = await this.modalController.create({
      component: GenericModalComponent,
      cssClass: ['alert-modal', 'rpass-factor-modal'],
      componentProps: {
        message: `<b>¿Quieres permitir que Banco Ripley <br/>use ${biometricText.title}?</b>`,
        primaryButtonText: 'Dar permiso',
        secondaryButtonText: 'Cancelar',
        icon: biometricText.icon,
        extraClass: 'biometric-icon'
      }
    });
    switch (biometricText.title) {
      case FACE_ID_TITLE:
        const eventGA4_faceid = {
          product: RPASS_GA4_CONSTANTS.FACEID_MODAL.PRODUCT,
          action: RPASS_GA4_CONSTANTS.FACEID_MODAL.ACTION,
          step: RPASS_GA4_CONSTANTS.FACEID_MODAL.STEP,
          step_part: RPASS_GA4_CONSTANTS.FACEID_MODAL.STEP_PART,
          category: RPASS_GA4_CONSTANTS.FACEID_MODAL.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FACEID_MODAL.EVENT, eventGA4_faceid);
        break;
      case FINGERPRINT_TITLE:
        const eventGA4 = {
          product: RPASS_GA4_CONSTANTS.FINGER_MODAL.PRODUCT,
          action: RPASS_GA4_CONSTANTS.FINGER_MODAL.ACTION,
          step: RPASS_GA4_CONSTANTS.FINGER_MODAL.STEP,
          step_part: RPASS_GA4_CONSTANTS.FINGER_MODAL.STEP_PART,
          category: RPASS_GA4_CONSTANTS.FINGER_MODAL.CATEGORY,
        };
        this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINGER_MODAL.EVENT, eventGA4);
        break;
    }
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data === 'primaryButtonPressed') {
      this.factorNameKeySelected = FACTOR_KEYS[selectedFactor.type];
      this.stateFactor = true;
      this.saveRPassFactor();
      switch (biometricText.title) {
        case FACE_ID_TITLE:
          const eventGA4_faceid = {
            product: RPASS_GA4_CONSTANTS.FACEID_MODAL_BTN.PRODUCT,
            action: RPASS_GA4_CONSTANTS.FACEID_MODAL_BTN.ACTION,
            step: RPASS_GA4_CONSTANTS.FACEID_MODAL.STEP,
            step_part: RPASS_GA4_CONSTANTS.FACEID_MODAL.STEP_PART,
            category: RPASS_GA4_CONSTANTS.FACEID_MODAL.CATEGORY,
          };
          this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FACEID_MODAL_BTN.EVENT, eventGA4_faceid);
          break;
        case FINGERPRINT_TITLE:
          const eventGA4 = {
            product: RPASS_GA4_CONSTANTS.FINGER_MODAL_BTN.PRODUCT,
            action: RPASS_GA4_CONSTANTS.FINGER_MODAL_BTN.ACTION,
            step: RPASS_GA4_CONSTANTS.FINGER_MODAL.STEP,
            step_part: RPASS_GA4_CONSTANTS.FINGER_MODAL.STEP_PART,
            category: RPASS_GA4_CONSTANTS.FINGER_MODAL.CATEGORY,
          };
          this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FINGER_MODAL_BTN.EVENT, eventGA4);
          break;
      }
    } else {
      this.resetFactors();
    }
  }

  private createBiometricText(factorType: string) {
    const biometricText = { title: '', icon: '' };
    switch (factorType) {
      case FACE_ID_FACTOR:
        biometricText.title = FACE_ID_TITLE;
        biometricText.icon = 'i-rpass-faceid-factor';
        break;
      case FINGER_FACTOR:
      case BIOMETRIC_FACTOR:
        biometricText.title = FINGERPRINT_TITLE,
          biometricText.icon = 'i-rpass-fingerprint-factor'
        break;
    }
    return biometricText;
  }

  public saveRPassFactor() {
    if (this.addGA4 && this.isPinFactorSelected) {
      const eventGA4 = {
        product: RPASS_GA4_CONSTANTS.CREATE_PIN_BTN.PRODUCT,
        action: RPASS_GA4_CONSTANTS.CREATE_PIN_BTN.ACTION,
        step: RPASS_GA4_CONSTANTS.CREATE_PIN.STEP,
        step_part: RPASS_GA4_CONSTANTS.CREATE_PIN.STEP_PART,
        category: RPASS_GA4_CONSTANTS.CREATE_PIN.CATEGORY,
      };
      this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.CREATE_PIN_BTN.EVENT, eventGA4);
    }
    this.rpassStatus = true;
    const responseObj = this.prepareObj(IDNOWSTEPS.RPASS);
    const response = { data: responseObj };
    this.methodValidity(response);
  }

  public async showModalError() {
    await this.loginService.logoutWithoutRedirection();
    this.utilsService.showErrorCustomAction(
      'Lo sentimos, en estos momentos no podemos atender tu solicitud',
      () => this.router.navigateByUrl('/login', { replaceUrl: true })
    );
  }

  public findKey(contextData: any[], key) {
    try {
      for (const entry of contextData) {
        if (entry.nombreKey === key) {
          if (key === 'enviromentsParams') {
            return entry.enviromentsParams;
          }
          return entry.valorKey;
        }
      }
      return;
    } catch (error) {
      return null;
    }
  }

  public compararPin() {
    const pinCompleto1 = this.pin.join('-');
    const pinCompleto2 = this.repeatPin.join('-');
    if (pinCompleto1.length === 11 && pinCompleto2.length === 11 && pinCompleto1 === pinCompleto2) {
      this.pinStatus = true;
      this.stateFactor = true;
    } else {
      this.pinStatus = false;
      this.stateFactor = false;
    }
  }

  public onKey(event, index: number): void {
    if (this.pin.length === 6 && this.repeatPin.length === 6) {
      this.compararPin();
    }
    this.validateInput();
    const { target }: { target: HTMLInputElement } = event;
    let nextElementIndex: number;
    if (event.keyCode === 8 || event.keyCode === 229) {
      if (index === 0) {
        this.pin = [];
        return;
      } else {
        this.pin = [];
        nextElementIndex = 1;
      }
    } else {
      if (index === 5) {
        target.blur();
        return;
      } else {
        nextElementIndex = index + 2;
      }
    }
    const nextElement = target.parentElement.parentElement.querySelector(`div.code-box:nth-of-type(${nextElementIndex}) input`) as HTMLInputElement;
    nextElement.focus();
  }

  public onKeyRepeat(event, index: number): void {
    if (this.repeatPin.length === 6) {
      this.compararPin();
    }
    this.validateInputRepeat();
    const { target }: { target: HTMLInputElement } = event;
    let nextElementIndex: number;
    if (event.keyCode === 8 || event.keyCode === 229) {
      if (index === 0) {
        this.repeatPin = [];
        return;
      } else {
        this.repeatPin = [];
        nextElementIndex = 1;
      }
    } else {
      if (index === 5) {
        target.blur();
        return;
      } else {
        nextElementIndex = index + 2;
      }
    }
    const nextElement = target.parentElement.parentElement.querySelector(`div.code-box:nth-of-type(${nextElementIndex}) input`) as HTMLInputElement;
    nextElement.focus();
  }

  public validateInput() {
    this.isInputValid = this.pin.length === 6;
    for (const password of this.pin) {
      if (!password || password.length < 1) { this.isInputValid = false; }
    }
  }

  public validateInputRepeat() {
    this.isInputValid = this.repeatPin.length === 6;
    for (const password of this.repeatPin) {
      if (!password || password.length < 1) { this.isInputValid = false; }
    }
  }

  private resetFactors() {
    this.rpassFactors.forEach(factor => factor.isSelected = false);
    this.factorNameKeySelected = "";
    this.isPinFactorSelected = false;
    this.selectedFactor = null;
    this.isInputValid = false;
    this.stateFactor = false;
    this.pinStatus = false;
    this.repeatPin = [];
    this.pin = [];
  }

  public onDocumentChecked() {
    this.widgetService.documentType = this.documentType;
    this.widgetService.enrollmentType = this.enrollmentTypeFlows[this.documentType];
  }

  public initMatchFace() {
    const eventGA4 = {
      product: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION_BTN.PRODUCT,
      action: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION_BTN.ACTION,
      step: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.STEP,
      step_part: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.STEP_PART,
      category: RPASS_GA4_CONSTANTS.FACE_INSTRUCTION.CATEGORY,
    };
    this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.FACE_INSTRUCTION_BTN.EVENT, eventGA4);
    this.promptAsked = true;
    const responseObj = this.prepareObj(IDNOWSTEPS.BIOMETRIA);
    const response = { data: responseObj };
    this.methodValidity(response);
  }

  public initEnrollment() {
    const eventGA4_prepare_init = {
      product: RPASS_GA4_CONSTANTS.SELECT_DNI_BTN.PRODUCT,
      action: RPASS_GA4_CONSTANTS.SELECT_DNI_BTN.ACTION,
      step: RPASS_GA4_CONSTANTS.SELECT_DNI.STEP,
      step_part: RPASS_GA4_CONSTANTS.SELECT_DNI.STEP_PART,
      category: RPASS_GA4_CONSTANTS.SELECT_DNI.CATEGORY,
    };
    this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.SELECT_DNI_BTN.EVENT, eventGA4_prepare_init);
    this.loading = true;
    this.promptAskedInit = true;
    const responseObj = this.prepareObj(IDNOWSTEPS.INITENROLLMENT);
    const response = { data: responseObj };
    this.methodValidity(response);
  }

  public initFinalAnalizeDNI() {
    this.promptAskedOCR = true;
    const responseObj = this.prepareObj(IDNOWSTEPS.OCR_DNI);
    const response = { data: responseObj };
    this.methodValidity(response);
    const eventGA4 = {
      product: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION_BTN.PRODUCT,
      action: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION_BTN.ACTION,
      step: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.STEP,
      step_part: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.STEP_PART,
      category: RPASS_GA4_CONSTANTS.DNI_INSTRUCTION.CATEGORY,
    };
    this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.DNI_INSTRUCTION_BTN.EVENT, eventGA4);
  }

  public prepareObj(event: string) {
    const response = {
      rut: this.rut,
      userType: this.documentType ? this.documentType : 'CPA',
      deviceType: UtilsService.viewportLabel().toLowerCase(),
      evento: event,
      localizacion: 'localizacion',
      ip: this.clientIp,
      userAgent: window.navigator.userAgent.toLowerCase(),
      os: this.channelOs,
      uuidDevice: this.uuidDevice,
      contextData: this.previousStep.contextData
    };
    return response;
  }

  public async updateOAuthToken(): Promise<void> {
    const bio: IBiometriaAccount = {
      userId: Number(this.rut),
      ip: this.clientIp,
      deviceType: this.deviceService.getWildField1(),
      userType: this.documentType ? this.documentType : 'CPA',
      event1: 'InitPoliticas',
      localizacion: '00.00,00.00',
      userAgent: window.navigator.userAgent.toLowerCase(),
      country: DEFAULT_WIDGET_CONFIG_DATA.country,
      channel: DEFAULT_WIDGET_CONFIG_DATA.channel,
      operation: this.enrollmentTypeFlows[this.documentType ? this.documentType : 'CPA'],
      os: this.utilsService.getDeviceOS().toUpperCase(),
      contextData: '',
    };
    try {
      const oauthResponse = await this.digitalAuthService.getOAuthToken(bio);
      this.widgetService.token = oauthResponse.access_token;
    } catch (error) {
      console.error('DigitalAuthService getOAuthToken error: ', error);
      this.showModalError();
    }
  }

  //* IDNOW Methods */

  public async initEnrollmentRequest() {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].generateInitEnrollmentRequest(this.channelOs, this.appId,
        this.clientIp, window.navigator.userAgent.toLowerCase(), this.rut, this.documentType ? this.documentType : 'CPA', '', '',
        this.enrollmentType,
        (response) => {
          this.widgetService.initEnrollment = response;
          this.uuidDevice = this.widgetService.uuidDevice = JSON.parse(response).uuidDevice;
          this.widgetService.os = JSON.parse(response).os;
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async processInitEnrollment(data: any) {
    const code = this.findKey(data.contextData, 'code');
    const description = this.findKey(data.contextData, 'description');
    const serverEnrollmentKey = this.findKey(data.contextData, 'serverEnrollmentKey');
    const uuidTransaction = this.findKey(data.contextData, 'uuidTransaction');
    const env = JSON.stringify(this.findKey(data.contextData, 'enviromentsParams'));
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].processInitEnrollmentResponse(code, description, serverEnrollmentKey,
        uuidTransaction, env,
        (response) => {
          this.widgetService.processInitEnrollment = response;
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async saveSecureData(data: any) {
    await new Promise((resolve) => {
      const type = data.contextData[0].nombreKey;
      const value = this.findKey(data.contextData, type);
      window['idNowSecuritySuite'].saveSecureData(value, type, 'secret',
        () => {
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async getDeviceStatus() {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].getDeviceStatusRequest(this.channelOs, APP_ID, this.clientIp,
        window.navigator.userAgent.toLowerCase(), this.rut, this.documentType ? this.documentType : 'CPA', '',
        (response) => {
          this.widgetService.getDeviceStatus = response;
          this.uuidDevice = JSON.parse(response).uuidDevice;
          this.widgetService.uuidDevice = JSON.parse(response).uuidDevice;
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async finishEnrollmentRequest() {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].generateFinishEnrollmentRequest(this.channelOs, APP_ID,
        this.clientIp, window.navigator.userAgent.toLowerCase(), this.rut, this.documentType ? this.documentType : 'CPA', '',
        (response) => {
          this.widgetService.finishEnrollment = response;
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async frontDniData() {
    this.loading = false;
    await new Promise((resolve, reject) => {
      window['idNowSecuritySuite'].retrieveFrontDniData(COUNTRY, this.stepId, this.uuidDevice, 'true', COUNTRY,
        async (response) => {
          this.loading = false;
          this.widgetService.finalAnalizeDNIFront = response;
          this.promptAskedOCR = true;
          const iconDNI = FLIP_DNI_ICON[this.widgetService.documentType];
          const buttons = [{
            cssClass: 'button-accept-for-rpass',
            text: 'Continuar',
            handler: async () => {
              const eventGA4 = {
                product: RPASS_GA4_CONSTANTS.MODAL_BUTTON.PRODUCT,
                action: RPASS_GA4_CONSTANTS.MODAL_BUTTON.ACTION,
                step: RPASS_GA4_CONSTANTS.MODAL_BUTTON.STEP,
                step_part: RPASS_GA4_CONSTANTS.MODAL_BUTTON.STEP_PART,
                category: RPASS_GA4_CONSTANTS.MODAL_BUTTON.CATEGORY,
              };
              this.addGA4 && this.googleAnalyticsService.gtagPushEventWithId(RPASS_GA4_CONSTANTS.MODAL_BUTTON.EVENT, eventGA4);
              resolve(true)
            }
          }];
          const template = { icon: iconDNI, title: '¡Bien hecho!', message: 'Ahora tomaremos una foto de tu DNI por detrás' };
          await this.customAlertsService.promptForCustomAction(template, 'warning-br-pass', buttons);
        },
        (err) => {
          this.loading = false;
          console.error("frontDniData ", { err });
          reject(err);
        });
    });
  }

  public async backDniData() {
    await new Promise((resolve, reject) => {
      window['idNowSecuritySuite'].retrieveBackDniData(COUNTRY, this.stepId, this.uuidDevice, 'true', COUNTRY,
        (response) => {
          this.loadingProps = LOADING_PROPS.default;
          this.loading = true;
          this.widgetService.finalAnalizeDNIBack = response;
          this.promptAskedOCR = true;
          resolve(true);
        },
        (err) => {
          console.error("backDniData ", { err });
          reject(err);
        });
    });
  }

  public async retrieveZoom() {
    this.loadingProps = LOADING_PROPS.matchFace;
    this.loading = true;
    await new Promise(async (resolve, reject) => {
      window['idNowSecuritySuite'].retrieveZoomData('', '', '',
        this.uuidDevice, window.navigator.userAgent.toLowerCase(), '', this.stepId, 'location',
        this.clientIp, this.channelOs, '', APP_ID, '',
        async (response) => {
          this.widgetService.faceMap = JSON.parse(response).faceMap;
          this.widgetService.sessionId = JSON.parse(response).sessionId;
          this.promptAsked = true;
          this.widgetService.retrieveZoom = response;
          resolve(true);
        },
        (err) => {
          console.error("retrieveZoom ", { err });
          this.promptAsked = false;
          reject(err);
        });
    });
  }

  public async processFinishEnrollment(data: any) {
    const code = this.findKey(data.contextData, 'code');
    const description = this.findKey(data.contextData, 'description') || '';
    const finalScoring = this.findKey(data.contextData, 'finalScoring');
    const seed = this.findKey(data.contextData, 'seed');
    const serverEnrollmentKey = this.findKey(data.contextData, 'serverEnrollmentoKey2');
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].processfinishEnrollmentResponse(code, description, finalScoring, seed,
        serverEnrollmentKey,
        (response) => {
          this.widgetService.processFinishEnrollment = response;
          this.completeFlow();
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  public async saveSecureDataActive(enrollmentValue: any) {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].saveSecureData(enrollmentValue, ENROLLMENT_KEY, 'secret',
        (response) => {
          resolve(true);
        },
        (err) => {
          console.error('SaveSecureData failed:', err);
          this.showModalError();
          resolve(false);
        }
      );
    });
  }

  public async clearEnrollmentIdNow() {
    await new Promise((resolve) => {
      window['idNowSecuritySuite'].clearEnrollment(
        (res) => {
          resolve(true);
        },
        (err) => {
          console.error(err);
          this.showModalError();
          resolve(false);
        });
    });
  }

  private setProgressBar(stepNow: number) {
    this.progressBar = stepNow / this.stepsSize;
    this.progressBarValueChange.emit(this.progressBar);
  }
}
