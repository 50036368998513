import { IGoogleTagManagerEvent, IGoogleTagV2ManagerEvent } from '@common/interfaces/google-tag-manager.interface';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {

    constructor() {
      
    }

    public pushTag(item: IGoogleTagManagerEvent) {

    }

    public pushTagV2(item: IGoogleTagV2ManagerEvent) {
    }

    public pushTagObject(item: object) {
    }
}
