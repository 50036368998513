import { Injectable } from '@angular/core';
import {
  ILoanDataRequest,
  ILoanDataResponse,
  IQuotaPendingRequest,
  IQuotaPendingResponse,
  IValidateNiubizRequest,
  IValidateNiubizResponse,
  IValidatePaymentRequest,
  IValidatePaymentResponse
} from '@common/interfaces/loan.interface';
import {
  LOAN_PAYMENT_DATA,
  QUOTA_PENDING_DATA,
  VALIDATE_NIUBIZ_PROCESS,
  VALIDATE_PAYMENT_PROCESS
} from '@common/mocks/loan.mocks';
import { environment } from '@environments/environment';
import { HttpService } from '@services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor(
    private http: HttpService,
  ) { }

  public getLoanInformation(request: ILoanDataRequest): Promise<ILoanDataResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/information';
    return this.http.post(url, request, LOAN_PAYMENT_DATA).toPromise();
  }

  public getLoanPaymentInformation(request: ILoanDataRequest): Promise<ILoanDataResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment-information';
    return this.http.post(url, request, LOAN_PAYMENT_DATA).toPromise();
  }

  public getQuotaPending(request: IQuotaPendingRequest): Promise<IQuotaPendingResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/quota-pending';
    return this.http.post(url, request, QUOTA_PENDING_DATA).toPromise();
  }

  public validateNiubizProcess(request: IValidateNiubizRequest): Promise<IValidateNiubizResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment/validate-process';
    return this.http.post(url, request, VALIDATE_NIUBIZ_PROCESS).toPromise();
  }

  public executionLoanPayments(request: IValidatePaymentRequest): Promise<IValidatePaymentResponse> {
    const url = environment.BASE_BACKEND_WORKER + '/product/loans/payment/execution';
    return this.http.post(url, request, VALIDATE_PAYMENT_PROCESS).toPromise();
  }
}
