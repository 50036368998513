import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { IPaymentValidationResponse, IPaymentExecutionRequest, IPaymentExecutionResponse } from '@common/interfaces/payment.interface';
import { PaymentService } from '@services/payment/payment.service';
import { environment } from '@environments/environment';
import { IVoucherPage } from '@common/interfaces/voucher.interface';
import { PAYMENTS_ROUTE, VOUCHER_ROUTE } from '@common/constants/routes.constants';
import { Router } from '@angular/router';
import { PAYMENTS_VOUCHER } from '@common/constants/voucher.constants';
import { CurrencyPipe } from '@angular/common';
import { PeruvianCurrencyPipe } from '@pipes/peruvian-currency/peruvian-currency.pipe';
import {
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { AlertService } from '@services/alert/alert.service';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { NIUBIZ_MESSAGE_BIN_VALIDATION, PAYMENT_ERROR_MESSAGES } from '@common/constants/payments.constants';
import { GoogleTagManagerService } from '@services/google-tag-manager/google-tag-manager.service';
import { GA4_CONSTANTS, GTM_STEPS } from '@common/constants/tag/tag-payments.constants';
import { IUserInformation } from '@common/interfaces/user.interface';
import { LoanService } from '@services/loan/loan.service';
import { IValidatePaymentRequest } from '@common/interfaces/loan.interface';
import {
  FLOW_RIPLEY_CARD
} from '@common/constants/tag/tag-payments.constants';
import { SessionExpireSoonComponent } from '@components/payment-modal/session-expire-soon/session-expire-soon.component';
import { AuthService } from '@services/auth/auth.service';
import { SCREEN_WIDTH } from '@common/constants/misc.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  @Input() secondaryButtonText: string;
  @Input() primaryButtonText: string;
  @Input() icon: string;
  @Input() dynamicSource: Observable<string>;
  @Input() validationResult: IPaymentValidationResponse;
  @Input() identifierId: string;
  @Input() productName: string;
  @Input() amount: number;
  @Input() email: string;
  @Input() userInformation: IUserInformation;
  @Input() productType: string;
  @Input() isPres: boolean;
  @Input() dues: number;
  @Input() flowInitName: string;
  @Input() cardDetailInfo?: any;
  @Input() documentEncrypt?: string;
  @Input() paymentType?: string;
  public paymentResultToken: string;
  public loadingPayment: boolean;
  public subscription: Subscription;
  public voucher: IVoucherPage;
  public cardErrorMessage: string;
  public expiryErrorMessage: string;
  public cvvErrorMessage: string;
  public peruvianPipe: PeruvianCurrencyPipe;
  public lastBin: string;
  public paymentModalForm: FormGroup;
  public paymentElements = {
    cardNumber: {} as any,
    cardCvv: {} as any,
    cardExpiry: {} as any
  };
  public paymentValues = {} as any;
  public elementStyles = {
    base: {
      color: '#666666',
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '13.5px',
      fontSmoothing: 'antialiased',
      placeholder: {
        color: '#999999',
      },
      autofill: {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };
  public expirationCheckInterval: NodeJS.Timeout
  public expirationTimeout: NodeJS.Timeout
  public time = 0
  public expirationTime = 0
  public remainingTime = 0
  public SECOND_IN_MS = 1000;
  public MINUTE_IN_SECONDS = 60;

  constructor(
    private viewController: ModalController,
    private paymentService: PaymentService,
    private paymentLoanService: LoanService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private gtmService: GoogleTagManagerService,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.title =  '';
    this.message = '';
    this.secondaryButtonText = '';
    this.primaryButtonText = '';
    this.icon = 'i-smartphone-purple';
  }

  ngOnInit() {
    this.subscription = new Subscription();
    if (this.dynamicSource) {
      this.subscription.add(
        this.dynamicSource.subscribe((message) => {
          this.message = message;
        })
      );
    }
    if (!this.userInformation) {
      this.paymentModalForm = this.formBuilder.group({
        name: [ '', [Validators.required]],
        lastName: [ '', [Validators.required]],
        email: ['', [Validators.required]],
        testCode: [''],
        insuranceCheck: [false],
      });
    } else {
      this.paymentModalForm = this.formBuilder.group({
        name: [ this.userInformation.names, [Validators.required]],
        lastName: [ this.userInformation.lastName, [Validators.required]],
        email: ['', [Validators.required]],
        testCode: [''],
        insuranceCheck: [false],
      });
    }
    GA4_CONSTANTS[this.productType].NIUBIZ_MODAL.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    this.googleAnalyticsService.gtagPushEventWithId(GA4_CONSTANTS[this.productType].NIUBIZ_MODAL.EVENT, 
      GA4_CONSTANTS[this.productType].NIUBIZ_MODAL.TAGS);
  }

  async initExpiredTime() {
    const { value: time } = await this.paymentService.getNiubizWaitTime()
    this.expirationTime = time
    this.remainingTime = this.expirationTime

    this.expirationTimeout = setTimeout(async () => {
      await this.beforeExpired()
    }, this.expirationTime * this.SECOND_IN_MS)

    this.expirationCheckInterval = setInterval(async () => {
      this.remainingTime--
      if (this.remainingTime <= 0) {
        clearInterval(this.expirationCheckInterval);
      }
    }, this.SECOND_IN_MS)
    await this.authService.refreshToken()
  }

  leftPad(number: number, targetLength: number) {
    var output = number.toString() + '';
    while (output.length < targetLength) {
      output = '0' + output;
    }
    return output;
  }

  get formmatedTime() {
    const remainingTime = this.remainingTime || 0;
    const seconds = remainingTime % this.MINUTE_IN_SECONDS;
    const minutes = (remainingTime - seconds) / this.MINUTE_IN_SECONDS;
    return `${this.leftPad(minutes, 2)}:${this.leftPad(seconds, 2)} minutos`;
  }

  async beforeExpired() {
    try {
      await this.authService.refreshToken()
      if (!this.authService.isAuthenticated()) {
        await this.dismissModal(false)
      } else {
        const modal = await this.viewController.create({
          component: SessionExpireSoonComponent,
          cssClass: 'session-expire-modal-payment',
          backdropDismiss: false,
        });
        await modal.present();
        const { data: { renovate } } = await modal.onDidDismiss();
        await this.dismissModal(renovate)
      }
    }catch{
      await this.dismissModal(false)
    }
  }

  async ionViewDidEnter() {
    this.voucher = PAYMENTS_VOUCHER;
    const currencyPipe = new CurrencyPipe('en-US');
    this.peruvianPipe = new PeruvianCurrencyPipe(currencyPipe);
    this.paymentModalForm.controls.email.setValue(this.validationResult.email);
    const configuration = {
      sessionkey: this.validationResult.sessionKey,
      channel: environment.PAYMENTS_CONFIG.CHANNEL,
      merchantid: this.validationResult.merchantId,
      purchasenumber: `${this.validationResult.operationNumber}`,
      amount: `${this.amount}`,
      language: environment.PAYMENTS_CONFIG.LANGUAGE,
      font: environment.PAYMENTS_CONFIG.FONT,
    };
    payform.setConfiguration(configuration);
    this.createButtons();
    await this.initExpiredTime()
  }

  createButtons() {
    const TIMEOUT_BUTTONS = 100;
    const cardElement = document.getElementById('payment-card-number');
    if (!cardElement) { setTimeout(this.createButtons, TIMEOUT_BUTTONS); } else {
      this.paymentElements.cardNumber = payform.createElement(
        'card-number',
        {
          style: this.elementStyles,
          placeholder: 'Número de tarjeta de débito',
        },
        'payment-card-number'
      );

      this.paymentElements.cardCvv = payform.createElement(
        'card-cvc',
        {
          style: this.elementStyles,
          placeholder: 'CVV',
        },
        'payment-card-cvv'
      );

      this.paymentElements.cardExpiry = payform.createElement(
        'card-expiry',
        {
          style: this.elementStyles,
          placeholder: 'MM/AA',
        },
        'payment-card-expiry'
      );
    }
    this.setHandlers();
  }

  private setHandlers() {
    this.paymentElements.cardNumber.then(el => {
      el.on('change', (data) => {
        if (data[0] && data[0].code == 'invalid_number') {
          this.cardErrorMessage = data[0].message;
        } else {
          if(data[0].code == 'invalid_number') {
            this.cardErrorMessage = '';
          }
        }
      });
      el.on('bin', (binNumber) => {
        this.valitadeBin(binNumber);
      });
    });
    this.paymentElements.cardExpiry.then(el => {
      el.on('change', (data) => {
        if (data[0] && data[0].code == 'invalid_expiry') {
          this.expiryErrorMessage = data[0].message;
        } else {
          this.expiryErrorMessage = '';
        }
      });
    });
    this.paymentElements.cardCvv.then(el => {
      el.on('change', (data) => {
        if (data[0] && data[0].code == 'invalid_cvc') {
          this.cvvErrorMessage = data[0].message;
        } else {
          this.cvvErrorMessage = '';
        }
      });
    });
  }

  private async valitadeBin(binNumber){
    if(binNumber !== this.lastBin) {
      this.lastBin = binNumber;
      this.callToService(binNumber);
    } else {
      if(this.cardErrorMessage !== NIUBIZ_MESSAGE_BIN_VALIDATION){
        this.lastBin = binNumber;
        this.callToService(binNumber);
      }
    }
  }

  private callToService(binNumber) {
    this.paymentService.valitadeBin({bin: binNumber}).then(
      (result) => {
        if(result.binType === 'C') {
          this.cardErrorMessage = NIUBIZ_MESSAGE_BIN_VALIDATION;
        } else {
          this.cardErrorMessage = '';
        }
      }
    ).catch((e) => {
      this.cardErrorMessage = ''
    });
  }

  private async doPayment() {
    try {
      this.loadingPayment = true;
      const testCode = this.paymentModalForm.controls.testCode.value;
      if (testCode) {
        await this.showAlertModal(testCode, true);
        return;
      }
      const paymentData = {
        name: this.paymentModalForm.controls.name.value,
        lastName: this.paymentModalForm.controls.lastName.value,
        email: this.validationResult.email,
        currencyConversion: false,
        installment: 0,
        alias: 'KS'
      };
      this.paymentResultToken = (await payform.createToken(
        [
          this.paymentElements.cardNumber,
          this.paymentElements.cardExpiry,
          this.paymentElements.cardCvv
        ],
        paymentData
      )).transactionToken;
      this.isPres ? await this.loanPaymentsProcess() : await this.paymentProcess();
    } catch (e) {
      const alertMessage = await this.alertService.openErrorAlert(
        this.router.url, false, true, null,
        e || '¡Lo sentimos! No pudimos realizar tu pago, intenta nuevamente en unos minutos');
      alertMessage.present();
      await alertMessage.onDidDismiss();
      this.loadingPayment = false;
    }
  }

  async loanPaymentsProcess() {
    try {
      await this.paymentLoanService.executionLoanPayments({
        sessionId: this.validationResult.sessionId,
        tokenId: this.paymentResultToken,
        entityIdentification: 'NBZ',
      } as IValidatePaymentRequest).then(async executionResult => {
        await this.dismissModal();
        // executionResult.code === 0 ? this.goToVoucher(executionResult) : this.showAlertModal(executionResult.code, false);
        if (executionResult.code === 0) {
          sessionStorage.setItem('identifierId-prestamo', executionResult.transactionId);
          this.goToVoucher(executionResult);
        } else { this.showAlertModal(executionResult.code, false); }
      }).catch(async error => {
        await this.showAlertModal(error.code, false);
      });
    } catch (e) {
      const alertMessage = await this.alertService.openErrorAlert(
      this.router.url, false, true, null,
      e || '¡Lo sentimos! No pudimos realizar tu pago, intenta nuevamente en unos minutos');
      alertMessage.present();
      await alertMessage.onDidDismiss();
      this.loadingPayment = false;
    }
  }

  async paymentProcess() {
    try {
      this.paymentService.executePayment({
        identifierId: this.identifierId,
        tokenId: this.paymentResultToken
      } as IPaymentExecutionRequest).then(async executionResult => {
        if (executionResult.code === 0) {
          sessionStorage.setItem('identifierId', executionResult.transactionId);
          this.goToVoucher(executionResult);
          this.dismissModal();
        } else {
          await this.dismissModal();
          this.showAlertModal(executionResult.code, false);
        }
      }).catch(async error => {
        await this.dismissModal();
        this.showAlertModal(error.code, false);
      });
    } catch (e) {
      const alertMessage = await this.alertService.openErrorAlert(
      this.router.url, false, true, null,
      e || '¡Lo sentimos! No pudimos realizar tu pago, intenta nuevamente en unos minutos');
      alertMessage.present();
      await alertMessage.onDidDismiss();
      this.loadingPayment = false;
    }
  }

  async showAlertModal(errorCode: string | number, testMode: boolean) {
    if (this.productType == 'PRESTAMO') {
      GA4_CONSTANTS[this.productType].ERROR_PAYMENT.TAGS.type = this.productName.toLocaleLowerCase();
    }
    this.googleAnalyticsService.gtagPushEventWithId(GA4_CONSTANTS[this.productType].ERROR_PAYMENT.EVENT, 
      GA4_CONSTANTS[this.productType].ERROR_PAYMENT.TAGS);
    if (errorCode in PAYMENT_ERROR_MESSAGES) {
      const modalParams = PAYMENT_ERROR_MESSAGES[errorCode];
      const modal = await this.viewController.create({
        component: AlertModalComponent,
        cssClass: 'form-alert-modal-payment',
        backdropDismiss: false,
        componentProps: {
          ...modalParams,
          testMode
        }
      });
      modal.present();
      await modal.onDidDismiss();
      this.loadingPayment = false;
    } else {
      const alertMessage = await this.alertService.openErrorAlert(
      this.router.url, false, true, null,
      '¡Lo sentimos! No pudimos realizar tu pago, intenta nuevamente en unos minutos');
      alertMessage.present();
      this.cardErrorMessage = '';
      await alertMessage.onDidDismiss();
      this.dismissModal();
    }
  }

  get isMobile() {
    return window.innerWidth < SCREEN_WIDTH.TABLET;
  }

  goToVoucher(executionResult: IPaymentExecutionResponse) {
    this.voucher.productType = this.productType;
    if (this.flowInitName !== undefined) {
      this.voucher.flowInitName = this.flowInitName;
      if (this.flowInitName === FLOW_RIPLEY_CARD) {
        this.voucher.cardDetailInfo = this.cardDetailInfo;
      }
    }
    this.voucher.card.subHeader.dynamicText = this.paymentModalForm.controls.email.value;
    this.voucher.card.subHeader.hidden = false;
    this.voucher.card.footerLeftSide[1].content = executionResult.transactionDate;
    this.voucher.card.footerRightSide[1].content = executionResult.transactionId;
    this.router.navigate([`${PAYMENTS_ROUTE}/ripley-card/${VOUCHER_ROUTE}`], { replaceUrl: true, state: this.voucher });
    this.voucher.card.header.staticText = this.isPres ? 'Has realizado el pago de tu cuota mensual de tu ' + this.productName +
    ' por ' : 'Has realizado un pago a tu ' + this.productName + ' por ' ;
    this.voucher.card.header.dynamicText = this.peruvianPipe.transform(this.amount);
    --this.dues;
    this.voucher.card.hidden = this.isPres;
    this.voucher.card.dues = this.dues;
    GA4_CONSTANTS[this.productType].VOUCHER_PAGE.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    GA4_CONSTANTS[this.productType].VOUCHER_PAGE.TAGS.pay_selection = executionResult.cardBrand;
    GA4_CONSTANTS[this.productType].VOUCHER_CLICK.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    GA4_CONSTANTS[this.productType].DOWNLOAD_VOUCHER_CLICK.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    this.googleAnalyticsService.gtagPushEventWithId(GA4_CONSTANTS[this.productType].VOUCHER_PAGE.EVENT, 
      GA4_CONSTANTS[this.productType].VOUCHER_PAGE.TAGS);
  }

  get cardElementsValid() {
    return !this.cardErrorMessage && !this.expiryErrorMessage && !this.cvvErrorMessage;
  }

  get isValidForm() {
    return (this.cardElementsValid && this.paymentModalForm.valid) || this.paymentModalForm.controls.testCode.value;
  }

  async dismissModal(renovate = false, isModal = false) {
      if(isModal){
        this.gtmService.pushTagV2(GTM_STEPS[this.productType].closeModal);
      }
      await this.authService.refreshIfTokenIsInvalid()
      return await this.viewController.dismiss({
        renovate
      });
  }

  onPrimaryClick() {
    this.doPayment();
    GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS.product = 'pagar';
    GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    this.googleAnalyticsService.gtagPushEventWithId(GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.EVENT, 
      GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS);
  }

  onSecondaryClick() {
    this.dismissModal();
    GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS.product = 'cancelar';
    GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS.type = this.productType == 'PRESTAMO' ? this.productName.toLocaleLowerCase() : this.paymentType;
    this.googleAnalyticsService.gtagPushEventWithId(GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.EVENT, 
      GA4_CONSTANTS[this.productType].NIUBIZ_CLICK.TAGS);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.expirationCheckInterval)
      clearInterval(this.expirationCheckInterval);
    if (this.expirationTimeout)
      clearTimeout(this.expirationTimeout)
  }

}
