import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RPASS_SMS_GA4_CONSTANTS, TRANSFER_GA4_CONSTANTS } from '@common/constants/transfers.constants';
import { GoogleAnalyticsService } from '@services/google-analytics/google-analytics.service';
import { UtilsService } from '@services/utils/utils';

@Component({
  selector: 'app-transfer-card',
  templateUrl: './transfer-card.component.html',
  styleUrls: ['./transfer-card.component.scss'],
})
export class TransferCardComponent {

  @Input() id: number;
  @Input() title: string;
  @Input() label: string;
  @Input() labelTag: string;
  @Input() buttonText: string;
  @Input() route: string;
  @Input() enabled: boolean;
  @Input() tagManagerActionLabel: string;
  @Input() tagG4: string;
  
  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  public get routerStateInformation(): object {
    return { category: 'Transferencias', option: this.tagManagerActionLabel };
  }

  public capitalize(text: string): string {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  public goToRoute() {
    if (!this.enabled) { return; }

    TRANSFER_GA4_CONSTANTS.TRANSFER_OPTION_ACCOUNT_BTN.TAG.sub_category = this.tagG4;   
    TRANSFER_GA4_CONSTANTS.TRANSFER_OPTION_ACCOUNT_BTN.TAG.label =this.labelTag; 
    this.googleAnalyticsService.gtagPushEvent(TRANSFER_GA4_CONSTANTS.TRANSFER_OPTION_ACCOUNT_BTN.event, TRANSFER_GA4_CONSTANTS.TRANSFER_OPTION_ACCOUNT_BTN.TAG);
    this.utilsService.addEventToTagManagerAndFirebase('Transferencias', 'Click', this.tagManagerActionLabel);
    this.router.navigate([this.route], { state: this.routerStateInformation });
  }

}
